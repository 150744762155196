import { Tz3dTool } from '../z3dTool.js'
import { THREE } from '@/core/thirdPart/lib.js'

/** Tz3dCanvas */
class Tz3dCanvas {
  /** 注释
   * three.js
   * @property {Tz3dViewport}  viewport       - 视口
   * @property {div}  canvas               - div
   * @property {array}  clientSize               - 窗口大小 [0, 0]
   * @property {div}  div               - div真实区域视口
   * @property {THREE.Vector4}  offset               - div真实区域视口的偏移 x:左，y:下，z:右，w:上
   * @property {THREE.Vector2}  resolution               - div真实区域视口的分辨率，非输出转化
   * @param {Tz3dViewport} viewport 视口
   */
  constructor(viewport) {
    this.viewport = viewport
    this._topDiv = Tz3dTool.createDiv(viewport._canvas, viewport.id + '_top')
    this._viewDiv = Tz3dTool.createDiv(this._topDiv, viewport.id + '_view')

    this._topDiv.style.background = '#fff'
    // 参数
    this._offset = new THREE.Vector4(0, 0, 0, 0)
    this._aspectRatio = new THREE.Vector2()
    this.check(this.canvas)

    this.clientWidth = this.div.clientWidth
    this.clientHeight = this.div.clientHeight
  }

  check(div) {
    let style = div ? div.style : null
    if (!style || (style.position && style.width && style.height && style.position === 'absolute')) return
    let pn = div.parentNode
    if (!pn || div.nodeName === 'BODY') {
      if (!style.width && !style.height && div.nodeName === 'BODY') {
        style.width = '100%'
        style.height = '100%'
        div.style.position = 'absolute'
      } else if (style.position !== 'absolute') div.style.position = 'absolute'
    } else {
      this.check(pn)
    }
  }

  get canvas() {
    return this.viewport._canvas
  }

  get clientSize() {
    return this.viewport.clientSize
  }

  get div() {
    return this._viewDiv
  }

  get topDiv() {
    return this._topDiv
  }

  get topDivClientSize() {
    return [this.topDiv.clientWidth, this.topDiv.clientHeight]
  }

  set offset(v) {
    if (this._offset.similar(v)) return
    this._offset.copy(v)
    this.compute()
  }

  set background(v) {
    let color = new THREE.Color(v)
    this.topDiv.style.background = color.getStyle()
  }

  get offset() {
    return this._offset
  }

  set aspectRatio(v) {
    if (this._aspectRatio.similar(v)) return
    this._aspectRatio.copy(v)
    this.compute()
  }

  get aspectRatio() {
    return this._aspectRatio
  }

  setSize(width, height) {
    this.clientWidth = width
    this.clientHeight = height
  }

  /** @description 设置分辨率
   * @param {number|THREE.Vector2} width 宽度比或者是宽高度比
   * @param {number} height 高度比
   */
  setAspectRatio(width, height) {
    if (width) {
      if (width.isVector2) {
        this.aspectRatio.copy(width)
      } else {
        this.aspectRatio.set(width, height)
      }
    } else this.aspectRatio.set(width, height)
  }

  compute(isResetCanvas = true) {
    this.offset.set(this.offset.x || 0, this.offset.y || 0, this.offset.z || 0, this.offset.w || 0)
    let isBfb, left, top, clientSize, asp, aspC, widthX, heightX, widthZ, heightZ
    if (!this.aspectRatio.x || !this.aspectRatio.y) {
      isBfb = true
      left = top = 0
    } else {
      clientSize = this.topDivClientSize
      asp = this.aspectRatio.x / this.aspectRatio.y
      widthX = clientSize[0] - (this.offset.x + this.offset.z)
      heightX = clientSize[1] - (this.offset.y + this.offset.w)
      aspC = widthX / heightX
      widthZ = aspC > asp ? heightX * asp : widthX
      heightZ = aspC > asp ? heightX : widthX / asp
      left = this.offset.x + (widthX - widthZ) * 0.5
      top = this.offset.w + (heightX - heightZ) * 0.5
    }
    this.div.style.left = 'calc(' + left + 'px)'
    this.div.style.top = 'calc(' + top + 'px)'
    this.div.style.width = !isBfb ? widthZ + 'px' : '100%'
    this.div.style.height = !isBfb ? heightZ + 'px' : '100%'
    if (isResetCanvas) this.viewport._resetCanvas(false)
  }
}
export { Tz3dCanvas }
