import { THREE } from '@/core/thirdPart/lib.js'
import { Tz2dPoint, Tz2dLine, Tz2dArc, Tz2dCircle, Tz2dRect } from '../engine/z2dClass.js'

const TzDataType = {
  CHAR: 0, // 字符串
  INTEGER: 1, // 整数
  FLOAT: 2, // 小数
  DATE: 3, // 日期
  CLOB: 4 // 大数值类型
}



const TzFieldType = {
  BOOLEAN: 0, // 布尔类型  下拉
  INTEGER: 1, // 整数 input
  INTRANGE: 2, // 整数 input 要控制输入的值范围，从字段的fieldcontrol取
  FLOAT: 3, // 小数 input
  CHAR: 4, // 字符串   input，要根据字段长度控制输入长度
  CLOB: 5, // 大数值类型，一般对应一个特殊界面，不用生成界面控件
  ENUM: 6, // 枚举  下拉 ，从字段表取对应的枚举项，构成下拉选择数据
  ENUMMULTI: 7, // 多选枚举 checkbox下拉，返回选择枚举的id列表，以CLOB格式存储回数据库
  COLOR: 8, // 颜色，input 弹窗选择，弹出单选窗口，从TPT_Color选择一条数据，返回选择color的id
  COLORVALUE: 9, // 颜色下拉控件
  RENDERMATERIAL: 10, // 渲染素材，input弹窗选择，弹出单选窗口，从TPT_RenderMaterial选择一条数据，返回选择RenderMaterial的id
  SHAPE: 11, // 形状，input 弹窗选择，弹出单选窗口，从TPT_Shape选择一条数据，返回选择shape的id
  FILE: 12, // 文件，选择本机文件控件，根据该字段的文件后缀类型，约束选择文件，选择完成后，上传文件至文件服务器，并在文件表中记录一条数据，并返回该行id
  FILECLOB: 13, // 文件流，选择本机文件控件，选择完成后，转换成CLOB，存储到数据库
  OBJECTTYPE: 14, // 对象类型，input 弹窗选择，弹出单选窗口，从TPT_ObjectType选择一条数据，返回选择ObjectType的id
  OBJECT: 15, // 对象，input 弹窗选择，弹出单选窗口，从TPT_Object选择一条数据，返回选择Object的id
  OBJECTS: 16, // 多个对象，input 弹窗选择，弹出多选窗口，从TPT_Object选择多数据，返回选择Object的id列表，以CLOB格式存储回数据库
  FN: 17, // 公式，input 弹窗选择，弹出单选窗口，从TFT_Fn选择一条数据，返回选择Fn的id
  DATE: 18, // 日期
  USER: 19, // 用户
  LABELS: 20, // 多选标签
  OBJECTTYPES: 21, // 多选对象类型,input 弹窗选择，弹出单选窗口，从TPT_ObjectCategory选择多条数据，返回选择ObjectType的ids
  RENDERMATERIALPROPERTY: 22, // 材质属性
  SYSOBJ: 23, // 系统对象
  CABBODY: 24, // 柜身方案
  CABEDGE: 25, // 封边方案
  OPENDOORBODY: 26, // 门材料方案
  OPENDOOREDGE: 27, // 门封边方案
  FIELD: 28, // 字段
  COLORCODE: 29, // 色卡代码
  SHAPEPROJECT: 30, // 形状方案
  FNS: 31, // 多选公式
  BRAND: 32, // 品牌
  IDS: 33, // id字符串，逗号分隔
  STYLE: 34, // 风格，input 弹窗选择，弹出单选窗口，从TPT_Shape选择一条数据，返回选择shape的id,
  SAMPLEROOM: 35, // 样板间，input 弹窗选择，弹出单选窗口，从TPT_SampleRoomEigenvalue选择一条数据，返回选择shape的id
  TZBASIC: 36, // TzBasic 类
  VECTOR3: 37, // 3d点  存储为 { x: 0, y: 0, z: 0 }
  VECTOR2: 38, // 2d点  存储为 { x: 0, y: 0 }
  POINT: 39, // svg 2d点  存储为 { x: 0, y: 0 }
  LINE: 40, // svg 线  存储为 { ps: { x: 0, y: 0 }, pe: { x: 0, y: 0 } }
  ARC: 41, // svg 弧  存储为 { pc : { x: 0, y: 0 },  r: 100, startAngle: 30, endAngle: 90, counterClockwise: true }
  CIRCLE: 42, // svg 圆  存储为 { pc : { x: 0, y: 0 },  r: 100 }
  RECT: 43, // svg 矩形  存储为 { x: 0, y: 0, w: 100, h: 100 }
  PATH: 44, // svg 路径 存储为 { cmds... }
  VARIANT: 45, // 变量
  OBJECTFUNCS: 46, // 对象功能
  PAVE: 47, // 铺法
  ROOMTYPE: 48, // 房间类型
  SHAPEITEM3DID: 49, // 形状item3dId
  SAMPLEROOMS: 50, // 多选样板间
  // UNITLENGTH: 51, // 长度单位（有公英制显示）
  // UNITLENGTH_MINI: 52, // 迷你长度单位（有公英制显示，且大于0的最小值在页面显示上英制时，应为1/传入分母）
  NINEGRID: 53, // 九宫格
  OBJECTGROUP: 54, // 对象组
  PARAMETRICHARD: 55, // 参数化硬装
  MATERIAL: 56, // 材質替換
  BUTTON: 57, // 提交按钮
  CABBODYS: 58, // 多选柜身方案
  CABEDGES: 59, // 多选封边方案
  OPENDOORBODYS: 60, // 多选门材料方案
  OPENDOOREDGES: 61, // 多选门封边方案
  CR_OBJECT_INTERNALID: 62, // 对象，从TPT_Object选择一条数据，生成cr_Object，取cr_Object的internalId
  DOOR_THRESHOLD_STONE: 63, // 门槛石
  LENGTH: 64, // 长度
  AREA: 65, // 面积
  VOLUME: 66, // 体积
  DENSITY: 67, // 密度（重量/体积）
  PAVES: 68, // 多选铺贴
  PAVESHAPE: 69, // 铺贴形状
  PAVETYPE: 70, // 铺法
  PARAPET: 71, // 护墙
  MOULDBODY: 72, // 线材材料方案
  OBJECTKIND: 73, // 对象分类
  PROPERTYTYPE: 74, // 属性对象类型
  PROPERTY: 75, // 属性对象
  DOORMODEL: 76, // 门造型
  CABBODYKIND: 77, // 单选柜身方案分类
  CABEDGEKIND: 78, // 封边方案分类
  OPENDOORBODYKIND: 79, // 开门材料方案分类
  OPENDOOREDGEKIND: 80, // 开门封边方案分类
  SHAPEKIND: 81, // 形状分类
  COLORKIND: 82, // 颜色分类
  CABBODYSET: 83, // 柜身方案集合
  CABEDGESET: 84, // 封边方案集合
  OPENDOORBODYSET: 85, // 开门材料方案集合
  OPENDOOREDGESET: 86, // 开门封边方案集合
  SHAPESET: 87, // 形状集合
  COLORSET: 88, // 颜色集合
  OBJECTSET: 89, // 对象集合
  PROPERTYKIND: 90, // 属性对象集合
}

const TzAttributeItemType = {
  GROUP: 0, // 组
  FIELD: 1 // 字段
}

let TzFloatFieldTypes = [TzFieldType.FLOAT, TzFieldType.LENGTH, TzFieldType.AREA, TzFieldType.VOLUME, TzFieldType.DENSITY]
let TzNumberFieldTypes = [TzFieldType.INTEGER, TzFieldType.INTRANGE, TzFieldType.USER].concat(TzFloatFieldTypes)
let TzStringFieldTypes = [TzFieldType.CHAR]
let TzObjectFieldTypes = [
  TzFieldType.RENDERMATERIAL,
  TzFieldType.SHAPE,
  TzFieldType.OBJECTTYPE,
  TzFieldType.OBJECT,
  TzFieldType.OBJECTS,
  TzFieldType.OBJECTTYPES,
  TzFieldType.LABELS,
  TzFieldType.FN,
  // TzFieldType.ENUMCLOB,
  TzFieldType.CLOB,
  TzFieldType.COLOR,
  TzFieldType.SYSOBJ,
  TzFieldType.CABBODY,
  TzFieldType.CABEDGE,
  TzFieldType.OPENDOORBODY,
  TzFieldType.OPENDOOREDGE,
  TzFieldType.BRAND,
  TzFieldType.SAMPLEROOM,
  TzFieldType.SAMPLEROOMS,
  TzFieldType.CABBODYS,
  TzFieldType.CABEDGES,
  TzFieldType.OPENDOORBODYS,
  TzFieldType.OPENDOOREDGES,
  TzFieldType.PAVE,
  TzFieldType.PAVES,
  TzFieldType.PAVESHAPE,
  TzFieldType.MOULDBODY,
  TzFieldType.OBJECTKIND,
  TzFieldType.PROPERTYTYPE,
  TzFieldType.PROPERTY,
  TzFieldType.DOORMODEL,
  TzFieldType.CABBODYKIND,
  TzFieldType.CABEDGEKIND,
  TzFieldType.OPENDOORBODYKIND,
  TzFieldType.OPENDOOREDGEKIND,
  TzFieldType.SHAPEKIND,
  TzFieldType.COLORKIND,
  TzFieldType.CABBODYSET,
  TzFieldType.CABEDGESET,
  TzFieldType.OPENDOORBODYSET,
  TzFieldType.OPENDOOREDGESET,
  TzFieldType.SHAPESET,
  TzFieldType.COLORSET,
  TzFieldType.OBJECTSET,
  TzFieldType.PROPERTYKIND
]
let TzMultiObjectFieldTypes = [
  TzFieldType.OBJECTS,
  TzFieldType.OBJECTTYPES,
  TzFieldType.LABELS,
  TzFieldType.SAMPLEROOMS,
  TzFieldType.CABBODYS,
  TzFieldType.CABEDGES,
  TzFieldType.OPENDOORBODYS,
  TzFieldType.OPENDOOREDGES,
  TzFieldType.PAVES
]
let TzEnumFieldTypes = [
  TzFieldType.BOOLEAN,
  TzFieldType.ENUM,
  TzFieldType.ENUMMULTI,
  TzFieldType.SHAPEPROJECT,
  TzFieldType.OBJECTFUNCS,
  TzFieldType.SHAPEITEM3DID,
  TzFieldType.NINEGRID
]
let TzColorFieldTypes = [TzFieldType.COLORVALUE]
let TzFileFieldTypes = [TzFieldType.FILE, TzFieldType.FILECLOB]
let TzDateFieldTypes = [TzFieldType.DATE]

function fieldType2DataType (fieldType) {
  switch (fieldType) {
    case TzFieldType.CHAR:
    case TzFieldType.COLORVALUE:
    case TzFieldType.COLORCODE:
    case TzFieldType.IDS:
    case TzFieldType.VECTOR3:
    case TzFieldType.VECTOR2:
    case TzFieldType.POINT:
    case TzFieldType.LINE:
    case TzFieldType.ARC:
    case TzFieldType.CIRCLE:
    case TzFieldType.RECT:
    case TzFieldType.PATH:
      return TzDataType.CHAR
    case TzFieldType.FLOAT:
    case TzFieldType.LENGTH:
    case TzFieldType.AREA:
    case TzFieldType.VOLUME:
    case TzFieldType.DENSITY:
      return TzDataType.FLOAT
    case TzFieldType.DATE:
      return TzDataType.DATE
    case TzFieldType.CLOB:
    case TzFieldType.ENUMMULTI:
    case TzFieldType.FILECLOB:
    case TzFieldType.OBJECTS:
    case TzFieldType.OBJECTTYPES:
    case TzFieldType.LABELS:
    case TzFieldType.SAMPLEROOMS:
    case TzFieldType.CABBODYS:
    case TzFieldType.CABEDGES:
    case TzFieldType.OPENDOORBODYS:
    case TzFieldType.OPENDOOREDGES:
    case TzFieldType.TZBASIC:
    case TzFieldType.PAVES:
      return TzDataType.CLOB
    default:
      return TzDataType.INTEGER
  }
}

function fieldTypeDefaultValue (fieldType) {
  switch (fieldType) {
    case TzFieldType.BOOLEAN:
      return false
    case TzFieldType.CHAR:
    case TzFieldType.ENUMMULTI:
    case TzFieldType.COLORVALUE:
    case TzFieldType.OBJECTS:
    case TzFieldType.DATE:
    case TzFieldType.LABELS:
    case TzFieldType.SAMPLEROOMS:
    case TzFieldType.CABBODYS:
    case TzFieldType.CABEDGES:
    case TzFieldType.OPENDOORBODYS:
    case TzFieldType.OPENDOOREDGES:
    case TzFieldType.OBJECTTYPES:
    case TzFieldType.COLORCODE:
    case TzFieldType.FNS:
    case TzFieldType.IDS:
    case TzFieldType.PAVES:
      return ''
    case TzFieldType.CLOB:
    case TzFieldType.FILECLOB:
    case TzFieldType.TZBASIC:
      return null
    case TzFieldType.VECTOR3:
      return new THREE.Vector3()
    // return getV3()
    case TzFieldType.VECTOR2:
      return new THREE.Vector2()
    case TzFieldType.POINT:
      return new Tz2dPoint(0, 0)
    case TzFieldType.LINE:
      return new Tz2dLine(Tz2dPoint(0, 0), Tz2dPoint(0, 0))
    case TzFieldType.ARC:
      return new Tz2dArc(Tz2dPoint(0, 0), 0, 0, 0, true)
    case TzFieldType.CIRCLE:
      return new Tz2dCircle(Tz2dPoint(0, 0), 0)
    case TzFieldType.RECT:
      return new Tz2dRect(0, 0, 0, 0)
    default:
      return 0
  }
}

class TzInternal {
  constructor() {
    this._v = 0
  }

  _begin () {
    this._v++
  }

  _end () {
    this._v--
    if (this._v < 0) {
      this._v = 0
    }
  }

  _true () {
    return this._v > 0
  }

  _false () {
    return this._v === 0
  }
}

class TzEvent {
  constructor(_internal) {
    this.eventHandle = {}
    this._internal = _internal
  }

  add (eventName, func) {
    if (this.eventHandle[eventName]) {
      if (this.eventHandle[eventName].indexOf(func) === -1) {
        this.eventHandle[eventName].push(func)
      }
    } else {
      this.eventHandle[eventName] = [func]
    }
  }

  insert (eventName, index, func) {
    if (this.eventHandle[eventName]) {
      if (this.eventHandle[eventName].indexOf(func) === -1) {
        if (this.eventHandle[eventName].length <= index) this.eventHandle[eventName].push(func)
        else this.eventHandle[eventName].splice(index, 0, func)
      }
    } else {
      this.eventHandle[eventName] = [func]
    }
  }

  replace (eventName, func) {
    let target = this.eventHandle[eventName]
    if (target) {
      this.eventHandle[eventName] = [func]
    } else {
      this.add(eventName, func)
    }
  }

  remove (eventName, func) {
    try {
      let target = this.eventHandle[eventName]
      if (target) {
        let index = target.indexOf(func)
        if (index >= 0) {
          target.splice(index, 1)
        }
      }
    } catch (e) { }
  }

  clear (eventName) {
    try {
      let target = this.eventHandle[eventName]
      if (target) {
        target = []
      }
    } catch (e) { }
  }

  clearAll () {
    this.eventHandle = []
  }

  fire (eventName, ...argument) {
    if (this._internal && this._internal._true()) return
    let target = this.eventHandle[eventName]
    if (target) {
      for (let i in target) {
        try {
          target[i](...argument)
        } catch (e) { }
      }
    }
  }

  copy (event) {
    this.eventHandle = event.eventHandle
  }
}

class TzBasicField {
  constructor(basic, groupIndex, fieldName, titles, fieldType, defaultValue, callBackCreateBasic = null) {
    this.basic = basic
    this.groupIndex = groupIndex
    this.fieldName = fieldName
    this.titles = titles
    this.fieldType = fieldType
    // this.digit = 4
    this.lengthDenominator = 64
    this.callBackCreateBasic = callBackCreateBasic
    this.visible = true
    this.editAble = true
    this.isArray = Array.isArray(defaultValue)
    this.defaultValue = defaultValue
    if (this.isArray && fieldType === TzFieldType.TZBASIC) {
      this._nextBasicKey = 0
    }
    this.bUndoRedoReplace = false
    this.initValue()
  }

  initValue () {
    if (this.isArray) {
      this._value = []
      this.defaultValue.forEach(vDefault => {
        this._value.push(this._doCopyValue(this.fieldType, vDefault))
      })
    } else {
      this._value = this._doCopyValue(this.fieldType, this.defaultValue)
    }
  }

  get index () {
    return this.basic.fields.indexOf(this)
  }

  clear () {
    if (this.isArray) {
      if (this.fieldType === TzFieldType.TZBASIC) {
        for (let i = this._value.length - 1; i >= 0; i--) {
          this._value[i].free()
        }
      }
      this._value = []
    } else if (this.fieldType === TzFieldType.TZBASIC && this._value) {
      this._value.free()
      this._value = null
    }
    this.initValue()
  }

  free () {
    this.clear()
  }

  get enumTitles () {
    return this._enumTitles
  }

  set enumTitles (v) {
    this._enumTitles = v
  }

  get enumIcons () {
    return this._enumIcons
  }

  set enumIcons (v) {
    this._enumIcons = v
  }

  get objectTypeIds () {
    return this._objectTypeIds
  }

  set objectTypeIds (v) {
    this._objectTypeIds = v
  }

  get colorIdField () {
    return this._colorIdField
  }

  set colorIdField (v) {
    this._colorIdField = v
  }

  _fieldTypeDefaultValue () {
    switch (this.fieldType) {
      case TzFieldType.TZBASIC:
        return {}
      // return this._createChildBasic(true)
      default:
        return fieldTypeDefaultValue(this.fieldType)
    }
  }

  _createChildBasic (bBindEvent, ...args) {
    let basic = this.callBackCreateBasic ? this.callBackCreateBasic(...args) : null
    if (basic) {
      basic._parentField = this
      if (bBindEvent) {
        basic.event.add('onBeginUpdate', this._onChildBasicBeginUpdate.bind(this))
        basic.event.add('onEndUpdate', this._onChildBasicEndUpdate.bind(this))
        if (this.isArray && this.fieldType === TzFieldType.TZBASIC) {
          basic.event.add('onDelete', this._onChildBasicDelete.bind(this))
          basic.key = this._nextBasicKey++
        }
      }
    }
    return basic
  }

  findBasic (key) {
    if (this.isArray) {
      for (let i = 0; i < this._value.length; i++) {
        let basic = this._value[i]
        if (basic.key === key) return basic
      }
    } else {
      if (this._value && this._value.key === key) return this._value
    }
    return null
  }

  createBasic (...args) {
    let item = this._createChildBasic(true, ...args)
    this._value = item
    return item
  }

  addItem (...args) {
    if (!this.isArray) return null
    let item = this._createChildBasic(true, ...args)
    this._value.push(item)
    return item
  }

  insertItem (index, ...args) {
    if (!this.isArray) return null
    let item = this._createChildBasic(true, ...args)
    this._value.splice(index, 0, item)
    return item
  }

  _onChildBasicBeginUpdate (sender) {
    this.basic.beginUpdate()
  }

  _onChildBasicEndUpdate (sender) {
    this.basic.endUpdate()
  }

  _undoRedoPushChildBasic (childBasic) {
    this._value.push(childBasic)
  }

  _onChildBasicDelete (sender) {
    if (this.isArray) {
      let index = this._value.indexOf(sender)
      if (index >= 0) {
        this._value.splice(index, 1)
      }
    }
  }

  _doCopyValue (fieldType, vSource) {
    switch (fieldType) {
      case TzFieldType.TZBASIC:
        if (!vSource) return null
        let basic = null
        if (this.isArray) basic = this.addItem()
        else basic = this.createBasic()
        if (basic) basic.copy(vSource)
        else basic = vSource.clone()
        basic.key = vSource.key
        basic._parentField = this
        return basic
      case TzFieldType.VECTOR3:
      case TzFieldType.VECTOR2:
      case TzFieldType.POINT:
      case TzFieldType.LINE:
      case TzFieldType.ARC:
      case TzFieldType.CIRCLE:
      case TzFieldType.RECT:
        return vSource ? vSource.clone() : null
      default:
        return vSource
    }
  }

  copy (fSource) {
    let self = this
    self.visible = fSource.visible
    self.editAble = fSource.editAble
    if (self.isArray) {
      self._value = []
      for (let i = 0; i < fSource._value.length; i++) {
        let vSource = fSource._value[i]
        let item = this._doCopyValue(self.fieldType, vSource)
        if (self.fieldType !== TzFieldType.TZBASIC) {
          self._value.push(item)
        }
      }
    } else {
      self._value = this._doCopyValue(self.fieldType, fSource._value)
    }
    this.basic.fieldValueChange(this)
  }

  equalTo (field) {
    return field ? this.valueEqual(field._value) : false
  }

  valueEqual (v) {
    let self = this
    if (self.isArray) {
      if (self._value.length !== v.length) return false
      for (let i = 0; i < self._value.length; i++) {
        if (!isEqual(self._value[i], v[i])) return false
      }
      return true
    } else {
      return isEqual(self._value, v)
    }

    function isEqual (vSelf, v) {
      switch (self.fieldType) {
        case TzFieldType.TZBASIC:
        case TzFieldType.POINT:
        case TzFieldType.LINE:
        case TzFieldType.ARC:
        case TzFieldType.CIRCLE:
        case TzFieldType.RECT:
        case TzFieldType.PATH:
          return vSelf && vSelf.equalTo(v)
        case TzFieldType.VECTOR3:
        case TzFieldType.VECTOR2:
          return vSelf.equals(v)
        case TzFieldType.CLOB:
          return false
        default:
          return vSelf === v
      }
    }
  }

  get dataType () {
    return fieldType2DataType(this.fieldType)
  }

  get value () {
    return this._value
  }

  set value (v) {
    if (this.isArray) {
      this._value = v
      this.basic.fieldValueChange(this)
      return
    }

    switch (this.fieldType) {
      case TzFieldType.TZBASIC:
      case TzFieldType.VECTOR3:
      case TzFieldType.VECTOR2:
      case TzFieldType.POINT:
      case TzFieldType.LINE:
      case TzFieldType.ARC:
      case TzFieldType.CIRCLE:
      case TzFieldType.RECT:
        if (this._value && v) {
          this._value.copy(v)
        } else {
          this._value = v
        }
        break
      default:
        this._value = v
        break
    }
    this.basic.fieldValueChange(this)
  }

  _jsonRead (jo, key, vDefault) {
    if (jo.hasOwnProperty(key)) {
      return jo[key]
    } else {
      return vDefault
    }
  }

  _doFromJson (jo) {
    let self = this
    self.initValue()

    let v = jo[self.fieldName]
    if (v === null || v === undefined) return

    if (self.isArray) {
      if (self.fieldType === TzFieldType.TZBASIC) self._nextBasicKey = 0
      v.forEach((item, index) => {
        let itemValue = fromValue(self._value[index], item)
        if (itemValue !== null && itemValue !== undefined) {
          self._value.splice(index, 1, itemValue)
        }
      })
      if (self.fieldType === TzFieldType.TZBASIC) {
        self._nextBasicKey = 0
        self._value.forEach(basic => {
          self._nextBasicKey = Math.max(self._nextBasicKey, basic.key + 1)
        })
      }
    } else {
      self._value = fromValue(self._value, v)
    }

    function fromValue (org, v) {
      if (self.fieldType === TzFieldType.TZBASIC) {
        if (!org) org = self._createChildBasic(true)
        if (org) org.fromJson(JSON.stringify(v))
        return org
      }

      switch (self.fieldType) {
        case TzFieldType.POINT:
          return new Tz2dPoint(v.x, v.y)
        case TzFieldType.LINE:
          return new Tz2dLine(v.ps.x, v.ps.y, v.pe.x, v.pe.y)
        case TzFieldType.ARC:
          return new Tz2dArc(v.pc.x, v.pc.y, v.r, v.startAngle, v.endAngle, v.counterClockwise)
        case TzFieldType.CIRCLE:
          return new Tz2dCircle(v.pc.x, v.pc.y, v.r)
        case TzFieldType.RECT:
          return new Tz2dRect(v.x, v.y, v.w, v.h)
        case TzFieldType.PATH:
          return v
        case TzFieldType.VECTOR3:
          return new THREE.Vector3(v.x, v.y, v.z)
        case TzFieldType.VECTOR2:
          return new THREE.Vector2(v.x, v.y)
        default:
          return v
      }
    }
  }

  _doToJsonObj () {
    let self = this

    let v = null
    if (self.isArray) {
      for (let i = 0; i < self._value.length; i++) {
        let jo = toJo(self._value[i])
        if (!jo) jo = self._fieldTypeDefaultValue()
        if (!v) v = []
        v.push(jo)
      }
    } else {
      v = toJo(self._value)
    }

    return v

    function toJo (v) {
      if (v === null || v === undefined) return null
      if (self.valueEqual(self.defaultValue)) return null

      switch (self.fieldType) {
        case TzFieldType.TZBASIC:
          return v.toJsonObj()
        case TzFieldType.VECTOR3:
          return { x: v.x, y: v.y, z: v.z }
        case TzFieldType.VECTOR2:
          return { x: v.x, y: v.y }
        case TzFieldType.POINT:
          return { x: v.x, y: v.y }
        case TzFieldType.LINE:
          return { ps: { x: v.ps.x, y: v.ps.y }, pe: { x: v.pe.x, y: v.pe.y } }
        case TzFieldType.ARC:
          return {
            pc: { x: v.pc.x, y: v.pc.y },
            r: v.r,
            startAngle: v.startAngle,
            endAngle: v.endAngle,
            counterClockwise: v.counterClockwise
          }
        case TzFieldType.CIRCLE:
          return { pc: { x: v.pc.x, y: v.pc.y }, r: v.r }
        case TzFieldType.RECT:
          return { x: v.x, y: v.y, w: v.w, h: v.h }
        default:
          return v
      }
    }
  }

  toAttributeItems () {
    let item = null
    // 组合对象，如床组合
    if (this.isArray && this.value.some(v => v.basic)) {
      item = new TzAttributeItem(this.titles, TzAttributeItemType.GROUP)
      this.value.forEach((v, index) => {
        let children = v.toAttributeItems()
        item.children.push(...children)
      })
    } else {
      if (this.fieldType === TzFieldType.TZBASIC) {
        if (this.value) {
          item = new TzAttributeItem(this.titles, TzAttributeItemType.GROUP)
          let children = this.value.toAttributeItems()
          item.children.push(...children)
        }
      } else {
        item = new TzAttributeItem(
          this.titles,
          TzAttributeItemType.FIELD,
          this.fieldType,
          this.value,
          this,
          this.basic.callBackModifyFieldValue.bind(this.basic),
          this.isArray,
          this.objectTypeIds,
          this.defaultValue,
          this.enumTitles,
          this.enumIcons,
          this.editAble,
          this.visible
        )
      }
    }
    return [item]
  }
}

class TzBasic {
  constructor() {
    this.key = 0
    this._parentField = null
    this._beginUpdateTimes = 0
    this._beginInternalTimes = 0
    this._reading = false
    this.groups = []
    this.fields = []
    this.unAutoDefineGetSetFields = []
    this.titles = ['', '', '']
    this._initFields()
    // this._initFieldsOver()
    this._initValue()
  }

  get event () {
    if (!this._event) {
      this._event = new TzEvent()
    }
    return this._event
  }

  _clone () {
    return new TzBasic()
  }

  clone () {
    let o = this._clone()
    o.copy(this)
    return o
  }

  _doCopyConfig (source) { }

  copyConfig (source) {
    this.beginUpdate()
    this._doCopyConfig(source)
    this.endUpdate()
  }

  copy (source) {
    for (let i = 0; i < this.fields.length; i++) {
      if (i > source.fields.length - 1) break
      this.fields[i].copy(source.fields[i])
    }
  }

  afterUndoRedo () { }

  get parentField () {
    return this._parentField
  }

  get parentFieldIndex () {
    return this._parentField ? this._parentField.index : 0
  }

  get parentFieldItemIndex () {
    if (this._parentField && this._parentField.isArray) {
      return this._parentField._value.indexOf(this)
    } else {
      return 0
    }
  }

  get parentGroupIndex () {
    return this._parentField ? this._parentField.groupIndex : 0
  }

  get parentBasic () {
    return this._parentField ? this._parentField.basic : null
  }

  get topParentBasic () {
    let p = this.parentBasic
    return p ? p.topParentBasic : this
  }

  get index () {
    if (this._parentField && this._parentField.isArray) {
      return this._parentField._value.indexOf(this)
    } else {
      return -1
    }
  }

  get prevItem () {
    let i = this.index
    if (i <= 0) return null
    return this._parentField._value[i - 1]
  }

  get nextItem () {
    let i = this.index
    if (i < 0) return null
    if ((i = this._parentField._value.length - 1)) return null
    return this._parentField._value[i + 1]
  }

  parentLevelOf (childBasic) {
    let level = -1
    let p = childBasic.parentBasic
    while (p) {
      level++
      if (p === this) {
        return level
      }
      p = p.parentBasic
    }
    return -1
  }

  _initFields () {
    this._addGroup([])
  }

  _initFieldsOver () {
    let self = this
    for (let i = 0; i < self.fields.length; i++) {
      let field = self.fields[i]
      if (this.unAutoDefineGetSetFields.includes(field)) continue
      Object.defineProperty(self, field.fieldName, {
        get () {
          switch (field.fieldType) {
            case TzFieldType.BOOLEAN:
              return field.Value === 1
            default:
              return field.value
          }
        },
        set (newValue) {
          switch (field.fieldType) {
            case TzFieldType.BOOLEAN:
              field.value = newValue ? 1 : 0
              break
            default:
              field.value = newValue
              break
          }
        },
        enumerable: true,
        configurable: true
      })
    }
  }

  _addGroup (titles) {
    // 约定 第0组 属性页面不可见
    this.groups.push(titles)
  }

  _addField (groupIndex, fieldName, titles, fieldType, defaultValue, callBackCreateBasic = null, autoDefineGetSet = true) {
    let f = new TzBasicField(this, groupIndex, fieldName, titles, fieldType, defaultValue, callBackCreateBasic)
    this.fields.push(f)
    if (!autoDefineGetSet) this.unAutoDefineGetSetFields.push(f)
    return f
  }

  /**
   * @description 字段值有效判断
   * @param {*} field
   * @param {*} value
   * @returns [bValid, validValue, errorMsg]
   */
  _checkFieldValueValid (field, value) {
    return [true, value, ['', '', '']]
  }

  _onModifyFieldValue (field, value, extra) {
    field.value = value
  }

  /**
   * 字段值修改回调
   * @param {*} field
   * @param {*} value
   * @param {*} extra - 改值的附加信息
   */
  callBackModifyFieldValue (field, value, extra) {
    let validInfo = this._checkFieldValueValid(field, value) // [bValid, validValue, errorMsg]
    // if (validInfo[0]) {
    this.beginUpdate()
    this._onModifyFieldValue(field, validInfo[1], extra)
    this.endUpdate()
    // }
    return validInfo
  }

  fieldValueChange (field) {
    if (this._parentField) {
      this._parentField.basic.fieldValueChange(this._parentField)
    }
  }

  equalTo (compare) {
    if (!compare) return false
    if (this.fields.length !== compare.fields.length) return false
    try {
      for (let i = 0; i < this.fields.length; i++) {
        if (!this.fields[i].equalTo(compare.fields[i])) return false
      }
    } catch (error) {
      return false
    }
    return true
  }

  get reading () {
    return this._reading
  }

  clear () {
    for (let i = 0; i < this.fields.length; i++) {
      let field = this.fields[i]
      field.clear()
    }
  }

  free () {
    this.event.fire('onDelete', this)
    this.clear()
    this.fields = []
    this.groups = []
  }

  beginUpdate () {
    if (this._beginUpdateTimes === 0) {
      this.event.fire('onBeginUpdate', this)
    }
    this._beginUpdateTimes++
  }

  endUpdate () {
    this._beginUpdateTimes = Math.max(0, this._beginUpdateTimes - 1)
    if (this._beginUpdateTimes === 0) {
      this.event.fire('onEndUpdate', this)
    }
  }

  isUpdating () {
    return this._beginUpdateTimes > 0
  }

  _initValue () {
    this.fields.forEach(field => {
      field.initValue()
    })
  }

  _jsonRead (jo, key, v) {
    if (jo.hasOwnProperty(key)) {
      return jo[key]
    } else {
      return v
    }
  }

  _doFromJson (jo) {
    this.fields.forEach(field => {
      field._doFromJson(jo)
    })
  }

  _doToJsonObj () {
    let jsonObj = {}

    this.fields.forEach(field => {
      let jo = field._doToJsonObj()
      if (jo !== null && jo !== undefined) {
        Object.defineProperty(jsonObj, field.fieldName, {
          value: jo,
          enumerable: true
        })
      }
    })

    return Object.keys(jsonObj).length ? jsonObj : null
  }

  toJsonObj () {
    return this._doToJsonObj()
  }

  toJson () {
    let jo = this.toJsonObj()
    if (jo) {
      return JSON.stringify(jo)
    } else {
      return ''
    }
  }

  fromJson (AJson) {
    this._reading = true
    this._initValue() // 初始化值

    // 如果JSON里面有对应字段才重新赋值
    if (AJson) {
      let jo = JSON.parse(AJson)
      this._doFromJson(jo)
    }
    this._reading = false
  }

  /**
   * @description 输出属性UI结构, 默认按group和field自动构建,派生类可视情况重载
   */
  toAttributeTree () {
    let tree = new TzAttributeTree()
    let page = this.toAttributePage(0)
    tree.pages.push(page)
    return tree
  }

  toAttributePage () {
    let page = new TzAttributePage()
    let items = this.toAttributeItems()
    page.items.push(...items)
    return page
  }

  toAttributeItems () {
    let items = []
    for (let i = 1; i < this.groups.length; i++) {
      let group = this.groups[i]
      let titles = group.titles || group.slice(0, 3)
      let groupItem = new TzAttributeItem(titles, TzAttributeItemType.GROUP)
      for (let j = 0; j < this.fields.length; j++) {
        let field = this.fields[j]
        if (field.groupIndex !== i) continue
        let fieldItems = field.toAttributeItems()
        groupItem.children.push(...fieldItems)
      }
      if (groupItem.children.length > 0) {
        items.push(groupItem)
      }
    }
    return items
  }
}

class TzSort extends TzBasic {
  constructor(dataTypes) {
    super()
    this.dataTypes = dataTypes
    this.itemValues = []
    this.items = []
  }

  _compare (values1, values2) {
    let c = 0
    for (let i = 0; i < this.dataTypes.length; i++) {
      let dataType = this.dataTypes[i]
      switch (dataType) {
        case TzDataType.CHAR:
        case TzDataType.CLOB:
          c = values1[i].localeCompare(values2[i])
          break
        case TzDataType.FLOAT:
          c = values1[i] - values2[i]
          if (Math.abs(c) < 0.000001) c = 0
          break
        default:
          c = values1[i] - values2[i]
          break
      }
      if (c < 0) return -1
      if (c > 0) return 1
    }
    return 0
  }

  _findCorrectIndex (checkIndex, item, itemValues) {
    if (item === null) return checkIndex
    if (this.items[checkIndex] === item) return checkIndex
    let index = checkIndex + 1
    while (index < this.items.length) {
      let c = this._compare(itemValues, this.itemValues[index])
      if (c !== 0) break
      if (this.items[index] === item) return index
      index++
    }
    index = checkIndex - 1
    while (index >= 0) {
      let c = this._compare(itemValues, this.itemValues[index])
      if (c !== 0) break
      if (this.items[index] === item) return index
      index--
    }
    return -1
  }

  _findIndex (small, large, item, itemValues) {
    if (large - small < 2) return -1
    let center = small + Math.trunc((large - small) / 2)
    let c = this._compare(itemValues, this.itemValues[center])
    if (c < 0) return this._findIndex(small, center, item, itemValues)
    if (c > 0) return this._findIndex(center, large, item, itemValues)
    return this._findCorrectIndex(center, item, itemValues)
  }

  _indexOfItem (item, itemValues) {
    if (!this.itemValues.length) {
      return -1
    }

    let c = this._compare(itemValues, this.itemValues[0])
    if (c === 0) {
      return this._findCorrectIndex(0, item, itemValues)
    } else if (c < 0) {
      return -1
    }

    c = this._compare(itemValues, this.itemValues[this.itemValues.length - 1])
    if (c === 0) {
      return this._findCorrectIndex(this.itemValues.length - 1, item, itemValues)
    } else if (c > 0) {
      return -1
    }

    return this._findIndex(0, this.itemValues.length - 1, item, itemValues)
  }

  _findInsertIndex (small, large, values) {
    if (large - small < 2) {
      return small + 1
    }

    let center = small + Math.trunc((large - small) / 2)
    let c = this._compare(values, this.itemValues[center])
    if (c === 0) {
      while (center < large && c === 0) {
        center++
        c = this._compare(values, this.itemValues[center])
      }
      return center
    }

    if (c < 0) {
      return this._findInsertIndex(small, center, values)
    } else {
      return this._findInsertIndex(center, large, values)
    }
  }

  _insertIndexOfItem (itemValues) {
    let len = this.itemValues.length
    if (len === 0) {
      return 0
    }

    if (this._compare(itemValues, this.itemValues[len - 1]) >= 0) {
      return len
    }

    let iFrom = 0
    while (iFrom < len) {
      let c = this._compare(itemValues, this.itemValues[iFrom])
      if (c < 0) return iFrom
      if (c === 0) {
        iFrom++
      } else {
        break
      }
    }

    return this._findInsertIndex(iFrom, len - 1, itemValues)
  }

  addItem (item, itemValues) {
    let index = this._insertIndexOfItem(itemValues)
    this.items.splice(index, 0, item)
    this.itemValues.splice(index, 0, itemValues)
  }

  findItem (item, itemValues) {
    let index = this._indexOfItem(item, itemValues)
    return index >= 0 ? this.items[index] : null
  }

  replaceItemByItemValues (itemValuesOld, itemValuesNew) {
    if (!itemValuesNew || !Array.isArray(itemValuesNew)) return false
    let index = this._indexOfItem(null, itemValuesOld)
    if (index < 0) return false
    this.itemValues[index] = itemValuesNew
    return true
  }

  deleteItem (index) {
    this.items.splice(index, 1)
    this.itemValues.splice(index, 1)
  }

  deleteItemByKey (item, itemValues) {
    let index = this._indexOfItem(item, itemValues)
    if (index >= 0) {
      this.deleteItem(index)
    }
  }

  firstItem () {
    let c = this.count()
    if (c > 0) {
      return this.items[0]
    } else {
      return null
    }
  }

  lastItem () {
    let c = this.count()
    if (c > 0) {
      return this.items[c - 1]
    } else {
      return null
    }
  }

  prevItem (index) {
    if (index > 0) {
      return this.items[index - 1]
    } else {
      return null
    }
  }

  nextItem (index) {
    let c = this.count()
    if (index < c - 1) {
      return this.items[index + 1]
    } else {
      return null
    }
  }

  count () {
    if (this.items.length) {
      return this.items.length
    } else {
      return 0
    }
  }

  clear () {
    this.items = []
    this.itemValues = []
  }
}

/**
 * @description 属性UI数据
 */
class TzAttributeTree {
  constructor() {
    this.pages = []
  }
}

/**
 * @description 属性UI分页
 * @property {string[]} titles-标题(多语种)
 */
class TzAttributePage {
  constructor(titles) {
    this.titles = titles
    this.items = []
  }
}

/**
 * @description 属性UI条目
 * @property {string[]} titles-标题(多语种)
 * @property {TzAttributeItemType} itemType-条目类型(组/字段)
 * @property {TzFieldType} valueType-值类型
 * @property {*} value-值
 * @property {*} field-绑定的字段
 * @property {*} callBackModifyFieldValue-值修改的回调函数

 * 当itemType为TzAttributeItemType.FIELD时需要的参数
 * @property {Boolean} isArray-是否为数组
 * @property {Array} objectTypeIds-对象可选分类id
 * @property {*} defaultValue-默认值
 * @property {Array} enumTitles-枚举选项
 * @property {Array} enumIcons-枚举选项对应的图标
 * @property {Boolean} editAble-值是否能修改
 * @property {Boolean} visible-值是否显示
 */
class TzAttributeItem {
  constructor(
    titles,
    itemType,
    valueType,
    value,
    field,
    callBackModifyFieldValue,
    isArray,
    objectTypeIds,
    defaultValue,
    enumTitles,
    enumIcons,
    editAble,
    visible
  ) {
    this.titles = titles
    this.itemType = itemType
    this.valueType = valueType
    this.value = value
    this.field = field
    this.callBackModifyFieldValue = callBackModifyFieldValue
    this.children = []

    this.isArray = isArray
    this.objectTypeIds = objectTypeIds
    this.defaultValue = defaultValue
    this.enumTitles = enumTitles
    this.enumIcons = enumIcons
    this.editAble = editAble
    this.visible = visible
  }
}

function classToJson (obj) {
  if (!obj) return ''
  let map = new Map()
  for (let k of Object.keys(obj)) {
    switch (typeof obj[k]) {
      case 'number':
      case 'boolean':
      case 'string':
        map.set(k, obj[k])
        break
      case 'object':
        map.set(k, classToJson(obj[k]))
        break
    }
  }
  return JSON.stringify([...map])
}

export { TzDataType, TzFieldType }
export {
  TzNumberFieldTypes,
  TzStringFieldTypes,
  TzFloatFieldTypes,
  TzObjectFieldTypes,
  TzMultiObjectFieldTypes,
  TzEnumFieldTypes,
  TzColorFieldTypes,
  TzFileFieldTypes,
  TzDateFieldTypes
}
export { fieldType2DataType, fieldTypeDefaultValue }
export { TzInternal, TzEvent }
export { TzBasic, TzBasicField, TzSort, classToJson }
export { TzAttributeTree, TzAttributePage, TzAttributeItem, TzAttributeItemType }

export default { TzDataType, TzFieldType, TzBasic }
