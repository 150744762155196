<template>
  <!-- 围板 -->
  <div class="layer-board">
    <!-- 围板参数 -->
    <div class="common-set-title" @click="show.one=!show.one">
      <span class="mr5">围板参数</span>
      <el-icon v-if="show.one"><CaretBottom /></el-icon>
      <el-icon v-else><CaretTop /></el-icon>
    </div>
    <div v-if="show.one" class="common-set-content">
      <div class="common-board-content">
        <div class="commonset">
          <div class="commonset-tit">类型</div>
          
            <el-radio-group v-model="radio1" size="small">
              <el-radio value="1">内嵌</el-radio>
              <el-radio value="2">
                外盖 <el-input-number class="common-input-width6" size="small" disabled v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
              </el-radio>
            </el-radio-group>
        
          
        </div>
        <div class="commonset">
          <div class="commonset-tit">方向</div>
          <el-checkbox-group size="small" v-model="check">
            <el-checkbox label="上" :value="1" />
            <el-checkbox label="下" :value="2" />
            <el-checkbox label="左" :value="3" />
            <el-checkbox label="右" :value="4" />
          </el-checkbox-group>
      
        </div>
        <div class="commonset">
          <div class="commonset-tit">结构</div>
          <el-radio-group v-model="radio1" size="small">
            <el-radio :value="1">侧包上下</el-radio>
            <el-radio :value="2">上下盖测</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <!-- 围板参数 -->
    <!-- 参数 -->
    <div class="common-set-title" @click="show.two=!show.two">
      <span class="mr5">参数</span>
      <el-icon v-if="show.two"><CaretBottom /></el-icon>
      <el-icon v-else><CaretTop /></el-icon>
    </div>
    <div v-if="show.two" class="common-set-content">
      <div class="common-board-content">
        <div class="commonset">
          <div class="commonset-tit">深度</div>
          <el-input-number class="common-input-width mr5" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
          <el-radio size="small" :value="1">深度自适应</el-radio>
        </div>
        <div class="commonset">
          <div class="commonset-tit">厚度</div>
          <el-input-number class="common-input-width" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
        </div>
        <div class="commonset">
          <div class="commonset-tit">四边内缩</div>
          <el-input-number class="common-input-width" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
        </div>
        <div class="commonset">
          <div class="commonset-tit">方向</div>
          <!-- <el-checkbox  /> -->
          <el-radio-group v-model="radio1" size="small">
            <el-radio :value="1">深度方向</el-radio>
            <el-radio :value="2">宽高方向</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <!-- 参数 -->
    <!-- 背板 -->
    <div class="common-set-title" @click="show.three=!show.three">
      <span class="mr5">背板</span>
      <el-icon v-if="show.three"><CaretBottom /></el-icon>
      <el-icon v-else><CaretTop /></el-icon>
    </div>
    <div  v-if="show.three" class="common-set-content">
      <div class="common-board-content">
        <div class="commonset">
          <div class="commonset-tit">背板工艺</div>
          <div class="common-input-width">
            <el-select
              v-model="value"
              placeholder="请选择"
       
            >
             
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <!-- 背板 -->
    <!-- 板材 -->
    <div class="common-set-title" @click="show.five=!show.five">
      <span class="mr5">板材</span>
      <el-icon v-if="show.five"><CaretBottom /></el-icon>
      <el-icon v-else><CaretTop /></el-icon>
    </div>
    <div v-if="show.five" class="common-set-content">
      <div class="common-board-content">
        <div class="commonset">
          <div class="commonset-tit">基材</div>
          <div class="common-input-width">
            <el-select
              v-model="value"
              placeholder="基材"
       
            >
              <el-option label="多层板" :value="1"></el-option>
            </el-select>
          </div>
        </div>
        <div class="commonset">
          <div class="commonset-tit">纹理</div>
          <div class="common-input-width">
           
            <el-select
            class="imgselect"
            v-model="value1"
            placeholder="纹理"
            @change="changeSelection"
            ref="layboardselect"
            >
              <el-option
              v-for="(va,vak) in valueArr"
              :key="vak"
              :label="va.text"
              :value="va.id"
              >
              <div style="display: flex;align-items: center;">
                <img
                  :src="va.src"
                  alt=""
                  width="30px"
                  height="30px"
                />
          
                <span>{{ va.text }}</span>
              </div>
               
              </el-option>
              
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <!-- 板材 -->
  </div>
</template>

<script setup lang="ts">
import {ref} from 'vue';

const show = ref({
  one: true,
  two: true,
  three: true,
  four: true,
  five: true
});
const num = ref(0)
const radio1 = ref('');
const value = ref('')
const value1 = ref('')
const check = ref([]);
const valueArr = ref([
  {text:'纹理一',src:require('@/assets/tools/board/layboard5.png'),id: 1}
])
const layboardselect = ref()
const changeSelection = (val) => {

  for (let index in valueArr.value) {
    let obj = valueArr.value[index]
    if (obj.id == val) {
      layboardselect.value.$el.children[0].children[0].setAttribute(
        'style',
        `background: url(${obj.src}) no-repeat; 
        width:80px; height: 80px;
        border: none;
        background-position: 0% center;
        background-size:130%`
      )
    }
  }
  
}



</script>

<style scoped lang="less">
@import '@/assets/css/board.less';

.layer-board {
  width: 100%;
  padding: 0 10px;
}


:deep(.imgselect) {
  width: 80px;
  height: 70px;
  .el-select__placeholder {
    display: none !important;
  }
  .el-select__wrapper {
    padding: 5px !important;
    width: 80px !important;
    height: 70px !important;
  }
}
</style>