/** Tz3dCtrlEvent */
class Tz3dCtrlEvent {
    constructor(z3d) {
      this.z3d = z3d
      this.enabled = false
  
      // 撤销反撤销
      this.callbackUndo = null
      this.callbackRedo = null
      this.callbackSave = null
  
      this._init()
  
    
    }
  
    _init() {
      this._bindEvents()
    }
  
    _bindEvents() {
      this.enabled = true
      this._mouseWheelEvent = this._mouseWheel.bind(this)
      document.addEventListener('wheel', this._mouseWheelEvent, false)
      this._mouseLeaveEvent = this._mouseLeave.bind(this)
      document.addEventListener('mouseleave', this._mouseLeaveEvent, false)
      this._mouseEnterEvent = this._mouseEnter.bind(this)
      document.addEventListener('mouseenter', this._mouseEnterEvent, false)
      this._mouseMoveEvent = this._mouseMove.bind(this)
      document.addEventListener('mousemove', this._mouseMoveEvent, false)
      this._mouseDownEvnet = this._mouseDown.bind(this)
      document.addEventListener('mousedown', this._mouseDownEvnet, false)
      this._mouseDblClickEvnet = this._mouseDblClick.bind(this)
      document.addEventListener('dblclick', this._mouseDblClickEvnet, false)
      this._mouseUpEvent = this._mouseUp.bind(this)
      document.addEventListener('mouseup', this._mouseUpEvent, false)
      this._keyDownEvent = this._keyDown.bind(this)
      document.addEventListener('keydown', this._keyDownEvent, false)
      this._keyUpEvent = this._keyUp.bind(this)
      document.addEventListener('keyup', this._keyUpEvent, false)
    }
  
    _unbindEvent() {
      document.removeEventListener('wheel', this._mouseWheelEvent, false)
      document.removeEventListener('mouseleave', this._mouseLeaveEvent, false)
      document.removeEventListener('mouseenter', this._mouseEnterEvent, false)
      document.removeEventListener('mousemove', this._mouseMoveEvent, false)
      document.removeEventListener('mousedown', this._mouseDownEvnet, false)
      document.removeEventListener('dblclick', this._mouseDblClickEvnet, false)
      document.removeEventListener('mouseup', this._mouseUpEvent, false)
      document.removeEventListener('keydown', this._keyDownEvent, false)
      document.removeEventListener('keyup', this._keyUpEvent, false)
      this.enabled = false
    }
  
    _mouseWheel(event) {
      // if (!this.enabled) return
    }
  
    _mouseLeave(event) {
      // if (!this.enabled) return
    }
  
    _mouseEnter(event) {
      // if (!this.enabled) return
    }
  
    _mouseMove(event) {
      // if (!this.enabled) return
    }
  
    _mouseDown(event) {
      // if (!this.enabled) return
    }
  
    _mouseDblClick(event) {
      // if (!this.enabled) return
    }
  
    _mouseUp(event) {
      // if (!this.enabled) return
    }
  
    _keyDown(event) {
      if (!this.enabled) return
      this._onKeyDown(event)
    }
  
    _keyUp(event) {
      // if (!this.enabled) return
    }
  
    _onKeyDown(event) {
      let key = event.keyCode ? event.keyCode : event.which
      switch (key) {
        case 65: // a
          this._tryDisplayUpdateAlways(event)
          break
        case 83: // s
          this._trySave(event)
          break
        case 86: // v
          this._tryStatsVisible(event)
          break
        case 90: // z
          this._tryUndoRedo(event)
          break
        default:
          break
      }
    }
  
    /**  @description 撤销反撤销 */
    _tryUndoRedo(event) {
      if (event.target.nodeName === 'INPUT') return
      if (Tz3dCtrlEvent.getEventHelperKey(event, true)) {
        if (this.callbackUndo) this.callbackUndo(0)
      } else if (Tz3dCtrlEvent.getEventHelperKey(event, false, true)) {
        if (this.callbackRedo) this.callbackRedo(0)
      }
    }
  
    /**  @description 保存 */
    _trySave(event) {
      if (event.target.nodeName === 'INPUT') return
      if (Tz3dCtrlEvent.getEventHelperKey(event, true, false, false, false)) {
        if (this.callbackSave) {
          this.callbackSave()
        }
        event.preventDefault()
      }
    }
  
    _tryStatsVisible(event) {
      if (event.target.nodeName === 'INPUT') return
      if (Tz3dCtrlEvent.getEventHelperKey(event, true, true, true, true)) {
        this.__isStatsVisible = !this.__isStatsVisible
        this.z3d.viewports.forEach(viewport => {
          viewport.statsVisible = this.__isStatsVisible
        })
      }
    }
  
    _tryDisplayUpdateAlways(event) {
      if (event.target.nodeName === 'INPUT') return
      if (Tz3dCtrlEvent.getEventHelperKey(event, true, true, true, true)) {
        this.__displayUpdateAlways = !this.__displayUpdateAlways
        this.z3d.viewports.forEach(viewport => {
          viewport.update.displayUpdateAlways = this.__displayUpdateAlways
        })
      }
    }
  
    static getEventHelperKey(event, ctrlKey, shiftKey, altKey, metaKey) {
      return event.ctrlKey === !!ctrlKey && event.shiftKey === !!shiftKey && event.altKey === !!altKey && event.metaKey === !!metaKey
    }
  }
  export { Tz3dCtrlEvent }