import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import '@/api/interceptor';

import { Tz3d } from '@/core/engine/z3d'




const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.config.globalProperties.$z3d =  new Tz3d();
app.config.globalProperties.$z3d.aTitle = 'z3d_Main';
app.config.globalProperties.$viewport2d = null;
app.config.globalProperties.$viewport3d = null;


app.use(store).use(router).use(ElementPlus).mount('#app')
