<template>
  <div class="design-right">
    <div class="title">楼层属性</div>
    <div class="content">
      <div class="navtab">
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane label="参数设置" name="first"></el-tab-pane>
          <el-tab-pane label="风格替换" name="second"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="center-main">
        <div class="tittop">
          <span>基础参数</span>
          <span>icon</span>
        </div>
        <el-form :model="form" label-width="auto" size="small">
          
          <el-form-item label="名称">
            <div class="formitem">
        
              <el-input v-model="form.name" disabled style="flex:1;"/>
              <span>mm</span>
            </div>
            
          </el-form-item>

          <el-form-item label="宽度">
            <div class="formitem">
              <el-radio-group v-model="extendDir.L_R"  style="margin-right: 10px">
                <el-radio-button value="left">左</el-radio-button>
                <el-radio-button value="right">右</el-radio-button>
              </el-radio-group>
              <el-input v-model="form.width"  style="flex:1;" @keyup.enter="handleChange(extendDir.L_R)"/>
              <span>mm</span>
            </div>
            
          </el-form-item>
          <el-form-item label="深度">
            <div class="formitem">
              <el-radio-group v-model="extendDir.F_B"  style="margin-right: 10px">
                <el-radio-button value="front">前</el-radio-button>
                <el-radio-button value="back">后</el-radio-button>
              </el-radio-group>
              <el-input v-model="form.length"  style="flex:1;" @keyup.enter="handleChange(extendDir.F_B)"/>
              <span>mm</span>
            </div>
          </el-form-item>
          <el-form-item label="厚度">
            <div class="formitem">
              <el-radio-group v-model="extendDir.T_B"  style="margin-right: 10px">
                <el-radio-button value="top">上</el-radio-button>
                <el-radio-button value="bottom">下</el-radio-button>
              </el-radio-group>
              <el-input v-model="form.thickness" disabled style="flex:1;" @keyup.enter="handleChange(extendDir.T_B)"/>
              <span>mm</span>
            </div>
          </el-form-item>
          <!-- <el-form-item label="移动">
            <div class="formitem">
              <el-radio-group v-model="tabPosition"  style="margin-right: 10px">
                <el-radio-button value="top">1</el-radio-button>
                <el-radio-button value="right">2</el-radio-button>
                <el-radio-button value="qian">3</el-radio-button>
              </el-radio-group>
              <el-input v-model="form.name" style="flex:1;"/>
            </div>
          </el-form-item>
          <el-form-item label="角度">
            <div class="formitem">
              <el-radio-group v-model="tabPosition">
                <el-radio-button value="top">X</el-radio-button>
                <el-radio-button value="right">Y</el-radio-button>
                <el-radio-button value="qian">Z</el-radio-button>
              </el-radio-group>
            </div>
          </el-form-item> -->
        </el-form>
      </div>
      <div class="center-main"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref,reactive,watch} from 'vue'
import type { TabsPaneContext,TabsInstance  } from 'element-plus'

import { ElMessage, ElMessageBox } from 'element-plus'

const curNode = ref<any>()



const props = defineProps<{ z3d: Object }>();
const activeName = ref('first')
const form = ref({
  name: '',
  width:'', // 宽
  length:'', // 深
  thickness:'' // 厚
})
const tabPosition = ref<TabsInstance['tabPosition']>('left')
const handleClick = (tab: TabsPaneContext, event: Event) => {
  console.log(tab, event)
}

const extendDir =ref({
  L_R:'left',
  F_B:'front',
  T_B:'top'

})

const handleChange = (type)=>{

}


</script>

<style scoped lang="less">
.design-right {
  width: 100%;
  .title {
    width: 100%;
    height: 30px;
    font-weight: bold;
    line-height: 30px;
    background: #F7F7F8;
    padding: 0 10px;
  }
  .content {
    width: 100%;
    padding: 0 10px;
    .navtab {
      width: 160px;
      margin: 0 auto;
    }
    .center-main {
      width: 100%;
      // height: 400px;
      // background: plum;
      .tittop {
        width: 100%;
        height: 20px;
        margin-bottom: 10px;
        line-height: 20px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.formitem {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
</style>