import { THREE } from '@/core/thirdPart/lib.js'
import {Tz3dGizmoSpaceMode,Tz3dGizmoMode} from './z3dConst.js'

import {  TzEvent } from '@/core/basic/zBasic.js'
import {  Tz3dTool } from '../z3dTool.js'
import {Tz3dCtrlEvent} from '../z3dCtrlEvent'

/** Tz3dGizmo */
class Tz3dGizmo extends THREE.Object3D {
  /** 注释
  * @property {Tz3dViewport}  viewport               - 所属viewport
  *
  * @property {boolean}  visible               - 是否显示
  * @property {TzEvent}  event               - 事件
  * @property {boolean}  collisonAble               - 是否允许碰撞
  * @property {array}  modes               - Tz3dGizmoMode数组
  * @property {number}  rotationSnap               - 旋转对齐角度 默认 5

  * @param {Tz3dViewport} viewport 当前viewport
  */
  constructor(viewport) {
    super()
    this._viewport = viewport
    this.layers.set(viewport.layerId)
    this.visible = false
    this._enable = true

    this.collisonAble = true

    this._spaceMode = Tz3dGizmoSpaceMode.world
    this.defaultModes = [Tz3dGizmoMode.translate, Tz3dGizmoMode.rotate]
    // this.defaultModes = [Tz3dGizmoMode.scale]
    this.modes = [...this.defaultModes]
    this._transMode = 0
    this.zoom = 1
    this.size = 1
    this.rotationSnap = 5
    this._init()

    this._plan = new THREE.Plane().setFromNormalAndCoplanarPoint(new THREE.Vector3(0, 1, 0), new THREE.Vector3(0, 0, 0))
    this._pointerSp = { x: 0, y: 0, button: 0 }

    this._dragNodeSp = new THREE.Vector3()
    this._dragNodeSq = new THREE.Quaternion()
    this._dragNodeSs = new THREE.Vector3()

    this._dragSr = new THREE.Vector3()
    this._dragSp = new THREE.Vector3()

    this._isDragging = false
    this._dragNode = null
    this._dragNodes = []
    this._dragGizmoMesh = null
    this._onDragCreateEndCallBack = null
    this._hoverGizmo = null

    this._draggingAngleFont = null

    this._effectVisible = true
  }

  get event() {
    if (!this._event) {
      this._event = new TzEvent()
    }
    return this._event
  }

  _init() {
    let material = new THREE.MeshBasicMaterial({
      depthTest: false, // false
      depthWrite: false, // false
      transparent: true,
      side: THREE.DoubleSide
    })
    let matInvisible = material.clone()
    matInvisible.opacity = 0.5
    let matRed = matInvisible.clone()
    matRed.color.set(0xff0000)
    let matGreen = matInvisible.clone()
    matGreen.color.set(0x00ff00)
    let matBlue = matInvisible.clone()
    matBlue.color.set(0x0000ff)
    let matRotXZ = matInvisible.clone()
    matRotXZ.color.set(0x00ffff)
    let matRotXY = matInvisible.clone()
    matRotXY.color.set(0x00ffff)
    let matRotYZ = matInvisible.clone()
    matRotYZ.color.set(0x00ffff)
    let matRotWR = matRotXZ.clone()
    let matRotW = matInvisible.clone()
    matRotW.color.set(0xffffff)
    matRotW.opacity = 1
    let matRotS = matRotXZ.clone()
    matRotS.color.set(0x00fafa)
    matRotS.opacity = 1

    let aw = 0.1 // arrowWidth
    let ahd = 0.36 // arrowHeightDis
    let ahd1 = 0.2 // arrowHeightDis
    let ra = 0.36 // rot 弧度 占比
    let rw = 0.1 // 厚度
    let rr = 0.8 // 半径
    let wr = rr + rw
    let nr = rr - rw
    let r0 = rr + rw * 0.5
    let r1 = rr - rw * 0.5
    let rfd = 8 // 旋转分段45°
    let rfdw = 1 // 旋转分段线 1°
    let sA = Math.PI * (0.25 + ra * 0.25)
    let eA = Math.PI * (0.25 - ra * 0.25)

    // 箭头 ->
    let arrowShape = new THREE.Shape()
      .moveTo(-aw * 0.5, ahd)
      .lineTo(-aw * 0.5, 0.78)
      .lineTo(-aw, 0.78)
      .lineTo(0, 0.96)
      .lineTo(aw, 0.78)
      .lineTo(aw * 0.5, 0.78)
      .lineTo(aw * 0.5, ahd)
    let arrowGeometry = new THREE.ShapeBufferGeometry(arrowShape)

    let arrowShape1 = new THREE.Shape()
      .moveTo(-aw * 0.5, ahd1)
      .lineTo(-aw * 0.5, 0.4)
      .lineTo(-aw, 0.4)
      .lineTo(0, 0.56)
      .lineTo(aw, 0.4)
      .lineTo(aw * 0.5, 0.4)
      .lineTo(aw * 0.5, ahd1)
    let arrowGeometry1 = new THREE.ShapeBufferGeometry(arrowShape1)

    // 旋转geo顶部
    let rotGeometryT = function() {
      let rotShapeT = new THREE.Shape()
        .moveTo(Math.cos(sA) * nr, Math.sin(sA) * nr)
        .lineTo(Math.cos(sA + Math.PI * rw) * rr, Math.sin(sA + Math.PI * rw) * rr)
        .lineTo(Math.cos(sA) * wr, Math.sin(sA) * wr)
        .absarc(0, 0, rr + rw * 0.5, sA, eA, true)
        .lineTo(Math.cos(eA) * wr, Math.sin(eA) * wr)
        .lineTo(Math.cos(eA - Math.PI * rw) * rr, Math.sin(eA - Math.PI * rw) * rr)
        .lineTo(Math.cos(eA) * nr, Math.sin(eA) * nr)
        .absarc(0, 0, rr - rw * 0.5, eA, sA, false)
      return new THREE.ShapeBufferGeometry(rotShapeT)
    }
    // 旋转geo正面
    let rotGeometryF = function() {
      let rotGeometryF_A = new THREE.CylinderBufferGeometry(rr, rr, rw, 9, 1, true, eA, Math.PI * ra * 0.5)

      let rotShapeGF = new THREE.ShapeBufferGeometry(
        new THREE.Shape()
          .moveTo(-rw, 0)
          .lineTo(0, Math.sin(Math.PI * rw * 0.5) * 2)
          .lineTo(rw, 0)
      )
      let obj0 = new THREE.Mesh(rotShapeGF)
      obj0.position.set(Math.cos(sA) * rr, 0, Math.sin(sA) * rr)
      obj0.rotation.set(0, eA, Math.PI / 2)
      obj0.updateMatrix()
      let rotGeometryF_B = obj0.geometry.clone()
      rotGeometryF_B.applyMatrix4(obj0.matrix)
      let obj1 = new THREE.Mesh(rotShapeGF)
      obj1.position.set(Math.cos(eA) * rr, 0, Math.sin(eA) * rr)
      obj1.rotation.set(0, Math.PI * 1.75 - sA, Math.PI / 2)
      obj1.updateMatrix()
      let rotGeometryF_C = obj1.geometry.clone()
      rotGeometryF_C.applyMatrix4(obj1.matrix)
      let rotGeometryF = Tz3dTool.mergeBufferGeometries([rotGeometryF_A, rotGeometryF_B, rotGeometryF_C])
      obj0.geometry.dispose()
      obj1.geometry.dispose()
      rotGeometryF_A.dispose()
      rotGeometryF_B.dispose()
      rotGeometryF_C.dispose()
      return rotGeometryF
    }

    // 圆
    let rotGeometryTRrM = function(i, isX) {
      let jg = (Math.PI / 360) * rfdw
      let starA = isX ? ((i * Math.PI) / rfd) * 2 + jg : ((i * Math.PI) / rfd) * 2 - jg
      let endA = isX ? (((i + 1) * Math.PI) / rfd) * 2 - jg : ((i * Math.PI) / rfd) * 2 + jg
      let rShape = new THREE.Shape().absarc(0, 0, r0, starA, endA, false).absarc(0, 0, r1, endA, starA, true)
      let geo = new THREE.ShapeBufferGeometry(rShape)
      return geo
    }
    let rotGeometryTRr = function() {
      let geos = []
      for (let i = 0; i < rfd; i++) {
        geos.push(rotGeometryTRrM(i, true))
      }
      let geo = Tz3dTool.mergeBufferGeometries(geos, false)
      for (let i = 0; i < geos.length; i++) {
        geos[i].dispose()
      }
      return geo
    }
    let rotGeometryTRrX = function() {
      let geos = []
      for (let i = 0; i < rfd; i++) {
        geos.push(rotGeometryTRrM(i, false))
      }
      let geo = Tz3dTool.mergeBufferGeometries(geos, false)
      for (let i = 0; i < geos.length; i++) {
        geos[i].dispose()
      }
      return geo
    }
    let rotGeometryTRrS = function() {
      let rShape = new THREE.Shape()
      let starA = 0
      let endA = Math.PI * 0.5
      rShape.absarc(0, 0, r0, starA, endA, false).absarc(0, 0, r1, endA, starA, true)
      let geo = new THREE.ShapeBufferGeometry(rShape)
      return geo
    }

    // 圆
    let rotGeometryFRrM = function(i, isX) {
      let jg = (Math.PI / 360) * rfdw
      let starA = isX ? ((i * Math.PI) / rfd) * 2 + jg : ((i * Math.PI) / rfd) * 2 - jg
      let endA = isX ? (((i + 1) * Math.PI) / rfd) * 2 - jg : ((i * Math.PI) / rfd) * 2 + jg
      let geo = new THREE.CylinderBufferGeometry(rr, rr, rw, 18, 1, true, starA, endA - starA)
      return geo
    }
    let rotGeometryFRr = function() {
      let geos = []
      for (let i = 0; i < rfd; i++) {
        geos.push(rotGeometryFRrM(i, true))
      }
      let geo = Tz3dTool.mergeBufferGeometries(geos, false)
      for (let i = 0; i < geos.length; i++) {
        geos[i].dispose()
      }
      return geo
    }
    let rotGeometryFRrX = function() {
      let geos = []
      for (let i = 0; i < rfd; i++) {
        geos.push(rotGeometryFRrM(i, false))
      }
      let geo = Tz3dTool.mergeBufferGeometries(geos, false)
      for (let i = 0; i < geos.length; i++) {
        geos[i].dispose()
      }
      return geo
    }
    let rotGeometryFRrS = function() {
      let starA = 0
      let endA = Math.PI * 0.5
      let geo = new THREE.CylinderBufferGeometry(rr, rr, rw, 18, 1, true, starA, endA - starA)
      return geo
    }
    let gizmoTranslate = {
      T_X: [[new THREE.Mesh(arrowGeometry, matRed), null, [Math.PI / 2, 0, -Math.PI / 2]]],
      T_Y: [[new THREE.Mesh(arrowGeometry, matGreen)]],
      T_Z: [[new THREE.Mesh(arrowGeometry, matBlue), null, [Math.PI / 2, 0, 0]]],
      S_X_U: [[new THREE.Mesh(arrowGeometry1, matRed), null, [Math.PI / 2, 0, -Math.PI / 2]]],
      S_X_D: [[new THREE.Mesh(arrowGeometry1, matRed), null, [Math.PI / 2, 0, Math.PI / 2]]],
      S_Y_U: [[new THREE.Mesh(arrowGeometry1, matGreen)]],
      S_Y_D: [[new THREE.Mesh(arrowGeometry1, matGreen), null, [Math.PI, 0, 0]]],
      S_Z_U: [[new THREE.Mesh(arrowGeometry1, matBlue), null, [Math.PI / 2, 0, 0]]],
      S_Z_D: [[new THREE.Mesh(arrowGeometry1, matBlue), null, [-Math.PI / 2, 0, 0]]],
      R_XZ: [
        // [new THREE.Mesh(rotGeometryT(), matRotXZ), null, [Math.PI / 2, 0, 0], null, 'R_XZT'],
        // [new THREE.Mesh(rotGeometryF(), matRotXZ), null, null, null, 'R_XZF'],
        // [new THREE.Mesh(rotGeometryTRr(), matRotWR), null, [Math.PI / 2, 0, 0], null, 'R_XZTR'],
        // [new THREE.Mesh(rotGeometryTRrX(), matRotW), null, [Math.PI / 2, 0, 0], null, 'R_XZTR'],
        // [new THREE.Mesh(rotGeometryTRrS(), matRotS), null, [Math.PI / 2, 0, 0], null, 'R_XZTS'],
        // [new THREE.Mesh(rotGeometryFRr(), matRotWR), null, null, null, 'R_XZFR'],
        // [new THREE.Mesh(rotGeometryFRrX(), matRotW), null, null, null, 'R_XZFR'],
        // [new THREE.Mesh(rotGeometryFRrS(), matRotS), null, null, null, 'R_XZFS']
      ],
      R_XY: [
        // [new THREE.Mesh(rotGeometryT(), matRotXY), null, null, null, 'R_XYT'],
        // [new THREE.Mesh(rotGeometryF(), matRotXY), null, [Math.PI / 2, Math.PI / 2, 0], null, 'R_XYF'],
        // [new THREE.Mesh(rotGeometryTRr(), matRotWR), null, null, null, 'R_XYTR'],
        // [new THREE.Mesh(rotGeometryTRrX(), matRotW), null, null, null, 'R_XYTR'],
        // [new THREE.Mesh(rotGeometryTRrS(), matRotS), null, null, null, 'R_XYTS'],
        // [new THREE.Mesh(rotGeometryFRr(), matRotWR), null, [Math.PI / 2, 0, 0], null, 'R_XYFR'],
        // [new THREE.Mesh(rotGeometryFRrX(), matRotW), null, [Math.PI / 2, 0, 0], null, 'R_XYFR'],
        // [new THREE.Mesh(rotGeometryFRrS(), matRotS), null, [Math.PI / 2, 0, 0], null, 'R_XYFS']
      ],
      R_YZ: [
        // [new THREE.Mesh(rotGeometryT(), matRotYZ), null, [0, -Math.PI / 2, 0], null, 'R_YZT'],
        // [new THREE.Mesh(rotGeometryF(), matRotYZ), null, [0, 0, Math.PI / 2], null, 'R_YZF'],
        // [new THREE.Mesh(rotGeometryTRr(), matRotWR), null, [0, Math.PI / 2, 0], null, 'R_YZTR'],
        // [new THREE.Mesh(rotGeometryTRrX(), matRotW), null, [0, Math.PI / 2, 0], null, 'R_YZTR'],
        // [new THREE.Mesh(rotGeometryTRrS(), matRotS), null, [0, Math.PI / 2, 0], null, 'R_YZTS'],
        // [new THREE.Mesh(rotGeometryFRr(), matRotWR), null, [0, 0, Math.PI / 2], null, 'R_YZFR'],
        // [new THREE.Mesh(rotGeometryFRrX(), matRotW), null, [0, 0, Math.PI / 2], null, 'R_YZFR'],
        // [new THREE.Mesh(rotGeometryFRrS(), matRotS), null, [0, 0, Math.PI / 2], null, 'R_YZFS']
      ]
    }

    for (let name in gizmoTranslate) {
      for (let i = 0; i < gizmoTranslate[name].length; i++) {
        let object = gizmoTranslate[name][i][0].clone()
        let position = gizmoTranslate[name][i][1]
        let rotation = gizmoTranslate[name][i][2]
        let scale = gizmoTranslate[name][i][3]
        let tag = gizmoTranslate[name][i][4]

        object.name = name
        object.tag = tag

        if (position) {
          object.position.set(position[0], position[1], position[2])
        }
        if (rotation) {
          object.rotation.set(rotation[0], rotation[1], rotation[2])
        }
        if (scale) {
          object.scale.set(scale[0], scale[1], scale[2])
        }

        object.updateMatrix()

        let tempGeometry = object.geometry.clone()
        tempGeometry.applyMatrix4(object.matrix)
        object.geometry = tempGeometry
        object.renderOrder = Infinity

        object.position.set(0, 0, 0)
        object.rotation.set(0, 0, 0)
        object.scale.set(1, 1, 1)
        object.layers.set(this.viewport.layerId)
        object.isGizmo = true

        this.add(object)
      }
    }
    this.z3d._scene.add(this)
  }

  /**  @description get：获取Tz3d */
  get z3d() {
    return this._viewport.z3d
  }

  get viewport() {
    return this._viewport
  }

  /**  @description get：获取TzViewport 的相机 */
  get camera() {
    return this._viewport.cameraCtrl.camera
  }

  /**  @description get：获取TzViewport 的相机控制器 */
  get camCtrl() {
    return this._viewport.cameraCtrl.camCtrl
  }

  get enable() {
    return this._enable
  }

  /**  @description getset：是否允许 */
  set enable(v) {
    this._enable = v
    this.updateTrans()
  }

  get spaceMode() {
    return this._spaceMode
  }

  /**  @description getset：坐标空间设置  Tz3dGizmoSpaceMode */
  set spaceMode(v) {
    this._spaceMode = v
    this.updateTrans()
  }

  get transMode() {
    return this._transMode
  }

  set transMode(v) {
    this._transMode = Number(v)
    switch (this._transMode) {
      case 0:
        this.modes = [Tz3dGizmoMode.translate, Tz3dGizmoMode.rotate]
        break
      case 1:
        this.modes = [Tz3dGizmoMode.scale]
        break
      default:
        break
    }
    this.updateTrans()
  }

  get dragNode() {
    return this._dragNode
  }

  get dragNodes() {
    return this._dragNodes
  }

  get isDragging() {
    return this._isDragging
  }

  /**  @description getset：是否在拖拽中 */
  set isDragging(v) {
    this._isDragging = v
    this._viewport.cameraCtrl.enableRotate = !v
    this._viewport.cameraCtrl.enablePan = !v
    this.updateTrans()
  }

  /**  @description getset：拖拽Node 设置 */
  set dragNode(v) {
    this._dragNode = v
    this._doDragPrepare()

    this.updateTrans()
  }

  get onlyDrag() {
    return this._isDragging && this.dragNode && !this._dragGizmoMesh
  }

  get dragGizmoMesh() {
    return this._dragGizmoMesh
  }

  set hoverGizmo(v) {
    if (v !== this._hoverGizmo) {
      if (this._hoverGizmo) this._hoverGizmo.material.opacity = 0.5
      this._hoverGizmo = v
      if (this._hoverGizmo) this._hoverGizmo.material.opacity = 0.9
      this.render()
    }
  }

  get effectVisible() {
    return this._effectVisible
  }

  set effectVisible(v) {
    v = !!v
    if (this._effectVisible === v) return
    this._effectVisible = v
    this.updateTrans()
  }

  _getPointer(event) {
    if (this._viewport) {
      return this._viewport.getScreenPointer(event)
    } else {
      return {
        x: 0,
        y: 0,
        button: event.button
      }
    }
  }

  _getRayPoint(position) {
    let rayPoint = position.clone().sub(this._dragNodeSp)
    rayPoint.add(this._dragSp)
    return rayPoint
  }

  setPlan_np(planNor, rayPoint) {
    this._plan.setFromNormalAndCoplanarPoint(planNor, rayPoint)
  }

  /**  @description 设置拖拽平面 默认 原点  */
  setPlan_default() {
    this.setPlan_np(this.camCtrl.transForward.clone(), new THREE.Vector3(0, 0, 0))
  }

  /**  @description 设置拖拽平面
   * @param {number}} y -高度
   */
  setPlan_posY(y = 0) {
    this.setPlan_np(this.camCtrl.transForward.clone(), new THREE.Vector3(0, y.toM(), 0))
  }

  /**  @description 根据node 设置拖拽平面高度
   * @param {Tz3dNode} node - node
   */
  setPlan_node(node) {
    this.setPlan_np(this.camCtrl.transForward.clone(), new THREE.Vector3(0, node.size.y / 2000, 0))
  }

  _getHoverAbleMeshes() {
    let meshes = []
    if (this.visible) {
      for (let i = 0; i < this.children.length; i++) {
        let obj = this.children[i]
        if (obj.visible) {
          meshes.push(obj)
        }
      }
    }
    return meshes
  }

  _onMouseMove(event) {
    if (!this.dragNode || this.isDragging) return
    let pointer = this._getPointer(event)
    let intersect = this._raycaskGizmo(event, pointer)
    this.hoverGizmo = intersect ? intersect.object : null
  }

  _doDragPrepare() {
    if (!this.dragNode || this.isDragging) return
    this._dragNodeSp = this.dragNode.wPos.toM()
    this._dragNodeSs = this.dragNode.wSize.toM()
    this._dragNodeSq = this.dragNode.wAngle.toQuaternionForDegree_world()
    this.updateDragNodeObject()
  }

  updateDragNodeObject() {
    let dn = this.dragNode
    if (!dn) return
    dn.graphic.dragPrepare()
    if (this._viewport.z3d.focusGraphic !== dn.graphic) {
      dn.graphic.focusNode = dn
      dn.graphic.isSelect = true
    }
    let self = this
    self._dragNodes = [dn]
    let graphics = Array.from(self._viewport.z3d.selectGraphics)
    if (graphics.length < 2) return
    graphics.forEach(graphic => {
      if (graphic !== dn.graphic && graphic.isTCRObject) {
        let node = graphic.node3d(self._viewport)
        self.dragNodes.push(node)
      }
    })
  }

  updateDragSpByDragNode(event) {
    if (!this.dragNode) return
    let dp = this._dragNodeSp.clone()
    dp.y -= this._dragNodeSs.y * 0.5
    this._dragSp = this.dragNode.rayPoint ? this.dragNode.rayPoint.toM() : dp
    let planNor = this.camCtrl.transForward.clone()
    this.setPlan_np(planNor, this._dragSp)
    dp = this._viewport.planeIntersect(this._getPointer(event), this._plan)
    this._dragSp = dp || this._dragSp
  }

  tryStartDragNode(node, event, onDragCreateEndCallBack) {
    if (!node) return false
    let np = node.nodeProperties
    if (!np.moveAbles[0] && !np.moveAbles[1] && !np.moveAbles[2]) return false

    this.dragNode = node
    this.updateDragSpByDragNode(event)
    this._onDragCreateEndCallBack = onDragCreateEndCallBack
    this.dragBegin(event)
    return true
  }

  tryStartDragGizmo(event) {
    if (!this.dragNode) return false
    let pointer = this._getPointer(event)
    let intersect = this._raycaskGizmo(event, pointer)
    return this._setDragGizmoMesh(intersect, pointer, event)
  }

  _raycaskGizmo(event, pointer) {
    let meshes = this._getHoverAbleMeshes()
    if (!meshes) return null
    return this._viewport.intersect(pointer, meshes)
  }

  _setDragGizmoMesh(rayIntersect, pointer, event) {
    this._dragGizmoMesh = rayIntersect.object
    if (!this._dragGizmoMesh) return false
    this._doDragPrepare()
    this.dragBegin(event)

    this._pointerSp = pointer
    this._dragSp = rayIntersect.point.clone()
    this._dragSr = this._dragSp.clone().sub(this._dragNodeSp)

    let name = this._dragGizmoMesh.name
    this._dragGizmoMesh.updateWorldMatrix(true)
    let iQue = new THREE.Quaternion()
    this._dragGizmoMesh.getWorldQuaternion(iQue)
    let planNor = rayIntersect.face.normal.clone().applyQuaternion(iQue)
    this.setPlan_np(planNor, this._getRayPoint(this._dragNodeSp))

    if (name.search('R_') !== -1) {
      let f = this._draggingAngleFont
      if (!f) {
        f = this._draggingAngleFont = Tz3dTool.createFont(this._viewport.div, this._viewport.id)
        f.style.backgroundColor = '#333'
        f.style.color = '#fff'
        f.style.lineHeight = 20 + 'px'
        f.style.borderRadius = '10%'
        f.style.textAlign = 'center'
      }
      let pos = this._viewport.getWorldPosToScreenPointer(this._dragNodeSp)
      f.hidden = false
      f.style.transform = 'translate(-50%, -50%) translate(' + pos.x + 'px, ' + pos.y + 'px)'

      this._setFontAngle(this._getAxisAngleByName(name, this.dragNode.wAngle))
    }
    return true
  }

  _getAxisAngleByName(name, angle) {
    let dragWAngle, v
    if (name === 'R_XZ') {
      dragWAngle = angle.toEulerForDegree_world().reorder('YZX')
      v = dragWAngle.y * THREE.Math.RAD2DEG
    } else if (name === 'R_XY') {
      dragWAngle = angle.toEulerForDegree_world().reorder('YZX')
      v = dragWAngle.z * THREE.Math.RAD2DEG
    } else if (name === 'R_YZ') {
      dragWAngle = angle.toEulerForDegree_world().reorder('YXZ')
      v = dragWAngle.x * THREE.Math.RAD2DEG
    }
    return v
  }

  _setFontAngle(v) {
    let f = this._draggingAngleFont
    if (!f) return
    let vs = v.round(0) + '°'
    f.innerText = vs
    f.style.width = vs.length * 6 + 14 + 'px'
  }

  dragUpdateToward(y) {
    this._dragNodeSp.y = y.toM()
    let planNor = this.camCtrl.transForward.clone()
    this._dragSp = this._dragNodeSp.clone()
    this.setPlan_np(planNor, this._getRayPoint(this._dragNodeSp))
  }

  /**  @description 拖拽开始
   * @param {event} event - event
   */
  dragBegin(event) {
    this.isDragging = true
    this._isDragEnding = false
    let dg = this.dragNode.graphic
    if (dg) {
      dg.sgdc.dragBegin(event, this._viewport, this.dragNode)
    }
    this.updateTrans()
    this.event.fire('dragBegin', event)
  }

  /**  @description 拖拽中
   * @param {event} event - event
   */
  dragging(event) {
    let dn = this.dragNode
    if (dn) {
      if (dn.customDrag) {
        let dg = dn.graphic
        if (dg) {
          dg.sgdc.dragging(event, this._viewport, dn)
        }
      } else {
        this.updateTrans(event)
      }
    }
  }

  /**  @description 拖拽结束
   * @param {event} event - event
   * @param {boolean} isCancel - 是否撤销拖拽
   */
  async dragEnd(event = null, isCancel = false) {
    if (!this.isDragging) return
    if (this._isDragEnding) return
    this._isDragEnding = true
    if (this._draggingAngleFont) this._draggingAngleFont.hidden = true

    let needDelete = this._onDragCreateEndCallBack && isCancel
    this.updateTrans(event)
    let dg = this.dragNode ? this.dragNode.graphic : null
    if (dg && dg.sgdc) {
      if (!isCancel) await dg.sgdc.dragEnd(event, this._viewport, this.dragNode)
      if (!needDelete && this.dragNode) {
        if (dg.sgdc.isDragCancel(event, this._viewport, this.dragNode)) {
          this._viewport.drawCtrl.clearDragNode(this.dragNode.isDragCreating)
          return false
        }
      }
    }

    this._viewport.drawCtrl.clearDragNode(needDelete)
    if (dg) {
      dg.dragClear()
      if (dg.isFocus) dg.createHelperNodes()
      if (needDelete) {
        if (dg.graphic) dg.graphic.free()
        else dg.free()
      }
    }
    this.isDragging = false
    this._dragGizmoMesh = null
    // this.modes = [...this.defaultModes]
    this.updateTrans()
    this.event.fire('dragEnd', event, isCancel)

    if (this._onDragCreateEndCallBack) {
      let callBack = this._onDragCreateEndCallBack
      this._onDragCreateEndCallBack = null
      callBack(this._viewport, event, isCancel)
      let dn = this.dragNode
      if (dn && dn.graphic && dn.graphic.sgdc && dn.graphic.sceneUI.dragCreateContinue) {
        dn.graphic.sgdc.dragging(event, this._viewport, dn)
      }
    }
    return true
  }

  cancelDrag() {
    let dn = this.dragNode
    if (dn) {
      dn.graphic.dragCancel(this._viewport)
      dn.wPos = this._dragNodeSp.toMM()
      dn.wSize = this._dragNodeSs.toMM()
      dn.wAngle = this._dragNodeSq.toVector3Degree_world()
      dn.updateRender(true)
      dn.box.applyNode()
      dn.box.updateRender()
    }
    this.dragEnd(null, true)
  }

  _dragCollision(event) {
    let dn = this.dragNode
    if (this.collisonAble) {
      let dg = dn.graphic
      if (dg) {
        dg.sgdc.dragging(event, this._viewport, dn)
        return
      }
    }
    dn.box.applyNode()
    dn.box.updateRender()
  }

  /**  @description 更新gizmo 以及 drangNode空间属性
   * @param {event} event - event
   */
  updateTrans(event) {
    let n = this._dragNode
    if (!this._enable || !this._effectVisible || !n) {
      this.visible = false
      return this.render()
    }

    if (n.customDrag) return this.render()

    if (!n.isSelect) {
      this.dragNode = null
      return this.render()
    }
    function checkUnvisible(handle, value, moreOrLess, clamp) {
      if (moreOrLess ? value > clamp : value < clamp) {
        handle.scale.set(1e-10, 1e-10, 1e-10)
        handle.visible = false
      }
    }

    function adjustAngle(rs, angle) {
      if (rs) {
        let jr = (rs / 180) * Math.PI
        angle = Math.round(angle / jr) * jr
      }
      return angle
    }

    let cameraPosition = this._viewport.camera.position
    let position = n.wPos.toM()
    let angle = 0
    let eye = position.clone().sub(this._viewport.camera.position)
    eye.normalize()
    let rs = this.rotationSnap
    let np = n.nodeProperties
    let isLocal = (n.graphic && n.graphic.sgdc ? n.graphic.sgdc.spaceMode : this.spaceMode) !== Tz3dGizmoSpaceMode.world
    let dragNodeSq = this._dragNodeSq
    let gmesh = this._dragGizmoMesh
    let gQua = isLocal || this.transMode === 1 ? (event ? dragNodeSq : n.quaternion) : new THREE.Quaternion()

    let unitX = new THREE.Vector3(1, 0, 0)
    let unitY = new THREE.Vector3(0, 1, 0)
    let unitZ = new THREE.Vector3(0, 0, 1)
    let unit = { R_YZ: unitX, R_XZ: unitY, R_XY: unitZ }

    if (event) {
      let pointer = this._getPointer(event)
      // 更新物体位置旋转
      let pos = this._viewport.planeIntersect(pointer, this._plan)
      if (pos) {
        let transPos = new THREE.Vector3()
        transPos = pos.clone().sub(this._dragSp)
        if (!this.onlyDrag && gmesh) {
          let name = gmesh.name
          let tag = gmesh.tag

          if (name.search('T_') !== -1) {
            if (isLocal) transPos.applyQuaternion(dragNodeSq.clone().conjugate())
            transPos.x = name === 'T_X' && np.moveAbles[0] ? transPos.x : 0
            transPos.y = name === 'T_Y' && np.moveAbles[1] ? transPos.y : 0
            transPos.z = name === 'T_Z' && np.moveAbles[2] ? transPos.z : 0
            if (isLocal) transPos.applyQuaternion(dragNodeSq)
            n.wPos = transPos.add(this._dragNodeSp).toMM()
          } else if (name.search('R_') !== -1) {
            if (tag && tag.search('R_') !== -1 && tag.search('F') !== -1) {
              angle = (pointer.x - this._pointerSp.x) * 5
              if (name === 'R_YZ' && np.rotateAbles[0]) {
                angle = adjustAngle(rs, angle)
              } else if (name === 'R_XZ' && np.rotateAbles[1]) {
                angle = adjustAngle(rs, angle)
              } else if (name === 'R_XY' && np.rotateAbles[2]) {
                angle = -adjustAngle(rs, angle)
              } else angle = 0
            } else {
              angle = n.wPos.toM().angleSpEpByNor(pos, this._dragSp, this._plan.normal)
              if (name === 'R_YZ' && np.rotateAbles[0]) {
                angle = adjustAngle(rs, angle)
              } else if (name === 'R_XZ' && np.rotateAbles[1]) {
                angle = adjustAngle(rs, angle)
              } else if (name === 'R_XY' && np.rotateAbles[2]) {
                angle = -adjustAngle(rs, angle)
              }
            }
            let q
            if (isLocal) {
              q = dragNodeSq.clone().multiply(new THREE.Quaternion().setFromAxisAngle(unit[name], angle))
              q.normalize()
            } else {
              q = new THREE.Quaternion().setFromAxisAngle(unit[name], angle) // .clone().applyQuaternion(dragNodeSq)
              q.multiply(dragNodeSq).normalize()
            }
            n.wAngle = q.toVector3Degree_world()
            this._setFontAngle(this._getAxisAngleByName(name, n.wAngle))
          } else if (name.search('S_') !== -1) {
            transPos.applyQuaternion(dragNodeSq.clone().conjugate())
            let addS = new THREE.Vector3()
            if (name.search('S_X') !== -1) {
              addS.x = transPos.x
            } else if (name.search('S_Y') !== -1) {
              addS.y = transPos.y
            } else if (name.search('S_Z') !== -1) {
              addS.z = transPos.z
            }
            if (Tz3dCtrlEvent.getEventHelperKey(event, true)) {
              let l = addS.x || addS.y || addS.z
              addS.x = addS.y = addS.z = np.sizeAbles[0] ? l : 0
            }
            if (!np.sizeAbles[0]) addS.x = 0
            if (!np.sizeAbles[1]) addS.y = 0
            if (!np.sizeAbles[2]) addS.z = 0

            let isD = name.search('_D') !== -1
            if (isD) addS.negate()
            let size = this._dragNodeSs.clone().add(addS)
            size.clamp(new THREE.Vector3(0.001, 0.001, 0.001), new THREE.Vector3(200, 200, 200))
            addS = size.clone().sub(this._dragNodeSs)
            n.wSize.copy(size.toMM())
            let pos = addS.clone().multiplyScalar(0.5)
            if (isD) pos.negate()
            pos.applyQuaternion(gQua)
            n.wPos.copy(pos.add(this._dragNodeSp).toMM())
          }
        } else {
          n.wPos.copy(transPos.add(this._dragNodeSp).toMM())
        }
        position = n.wPos.toM()
        n.updateRender(true)
        this._viewport._onChangeScene()
        this._dragCollision(event)
      }
    }

    if (this.camera.isOrthographicCamera) {
      let cctrl = this._viewport.canvasCtrl
      let zoom = 130 / this.camera.zoom
      let b = cctrl.clientHeight > cctrl.clientWidth ? cctrl.clientHeight : cctrl.clientWidth
      b = b <= 10 ? 10 : b
      this.zoom = zoom * Math.pow(b / 1600, 0.3) * this.size
    } else if (this.camera.isPerspectiveCamera) {
      this.zoom = (n.wPos.toM().distanceTo(cameraPosition) * this.size) / 5
    }

    let visibles = {
      T_X: np.gizmoMeshEnable && np.moveAbles[0] && this.modes.includes(Tz3dGizmoMode.translate),
      T_Y: np.gizmoMeshEnable && np.moveAbles[1] && this.modes.includes(Tz3dGizmoMode.translate),
      T_Z: np.gizmoMeshEnable && np.moveAbles[2] && this.modes.includes(Tz3dGizmoMode.translate),
      R_XZ: np.gizmoMeshEnable && np.rotateAbles[1] && this.modes.includes(Tz3dGizmoMode.rotate),
      R_XY: np.gizmoMeshEnable && np.rotateAbles[2] && this.modes.includes(Tz3dGizmoMode.rotate),
      R_YZ: np.gizmoMeshEnable && np.rotateAbles[0] && this.modes.includes(Tz3dGizmoMode.rotate),
      S_X_U: np.gizmoMeshEnable && np.sizeAbles[0] && this.modes.includes(Tz3dGizmoMode.scale),
      S_X_D: np.gizmoMeshEnable && np.sizeAbles[0] && this.modes.includes(Tz3dGizmoMode.scale),
      S_Y_U: np.gizmoMeshEnable && np.sizeAbles[1] && this.modes.includes(Tz3dGizmoMode.scale),
      S_Y_D: np.gizmoMeshEnable && np.sizeAbles[1] && this.modes.includes(Tz3dGizmoMode.scale),
      S_Z_U: np.gizmoMeshEnable && np.sizeAbles[2] && this.modes.includes(Tz3dGizmoMode.scale),
      S_Z_D: np.gizmoMeshEnable && np.sizeAbles[2] && this.modes.includes(Tz3dGizmoMode.scale)
    }

    this.position.copy(position)
    this.quaternion.copy(gQua)

    let AXIS_FLIP_TRESHOLD = 0.0
    let AXIS_XZ_TOP_FORWORD = 0.3
    let AXIS_XY_TOP_FORWORD = 0.45
    let AXIS_YZ_TOP_FORWORD = 0.45
    let xClamp = unitX
      .clone()
      .applyQuaternion(gQua)
      .dot(eye)
    let xClampAbs = Math.abs(xClamp)
    let yClamp = unitY
      .clone()
      .applyQuaternion(gQua)
      .dot(eye)
    let yClampAbs = Math.abs(yClamp)
    let zClamp = unitZ
      .clone()
      .applyQuaternion(gQua)
      .dot(eye)
    let zClampAbs = Math.abs(zClamp)
    function computeHandleQua(axis0, axis1) {
      let nor = axis0.clone().applyQuaternion(gQua)
      let _nor = nor.clone().cross(eye)
      _nor.applyQuaternion(gQua.clone().conjugate()).normalize()
      let hq = new THREE.Quaternion().setFromUnitVectors(axis1, _nor)
      return hq
    }
    for (let i = 0; i < this.children.length; i++) {
      let child = this.children[i]
      let childName = child.name
      let childTag = child.tag
      child.visible = visibles[childName] && !this.onlyDrag
      if (!child.visible) continue

      if (gmesh) {
        let gName = gmesh.name
        if (gmesh !== child) {
          if (gName.search('T_') !== -1) child.visible = false
          else if (gName.search('R_') !== -1) {
            if (gName.search('R_XZ') !== -1) {
              if (!['R_XZTR', 'R_XZTS', 'R_XZFR', 'R_XZFS'].includes(childTag)) child.visible = false
            } else if (gName.search('R_XY') !== -1) {
              if (!['R_XYTR', 'R_XYTS', 'R_XYFR', 'R_XYFS'].includes(childTag)) child.visible = false
            } else {
              if (!['R_YZTR', 'R_YZTS', 'R_YZFR', 'R_YZFS'].includes(childTag)) child.visible = false
            }
          } else if (gName.search('S_') !== -1) child.visible = false
        } else if (gName.search('R_') !== -1) child.visible = false
      } else {
        if (!this.modes.includes(Tz3dGizmoMode.translate)) {
          if (childName.search('T_') >= 0) child.visible = false
        }
        if (!this.modes.includes(Tz3dGizmoMode.rotate)) {
          if (childName.search('R_') >= 0) child.visible = false
        }
        if (!this.modes.includes(Tz3dGizmoMode.scale)) {
          if (childName.search('S_') >= 0) child.visible = false
        }
      }
      if (!child.visible) continue

      if (childName === 'R_XZ') {
        if (childTag === 'R_XZT') {
          checkUnvisible(child, yClampAbs, false, AXIS_XZ_TOP_FORWORD)
        } else if (childTag === 'R_XZF') {
          checkUnvisible(child, yClampAbs, true, AXIS_XZ_TOP_FORWORD)
        }
        if (childTag === 'R_XZTR' || childTag === 'R_XZTS') {
          if (gmesh) checkUnvisible(child, yClampAbs, false, AXIS_XZ_TOP_FORWORD)
          else child.visible = false
        }
        if (childTag === 'R_XZFR' || childTag === 'R_XZFS') {
          if (gmesh) checkUnvisible(child, yClampAbs, true, AXIS_XZ_TOP_FORWORD)
          else child.visible = false
        }
      }

      if (childName === 'R_XY') {
        if (childTag === 'R_XYT') {
          checkUnvisible(child, zClampAbs, false, AXIS_XY_TOP_FORWORD)
        } else if (childTag === 'R_XYF') {
          checkUnvisible(child, zClampAbs, true, AXIS_XY_TOP_FORWORD)
        }
        if (childTag === 'R_XYTR' || childTag === 'R_XYTS') {
          if (gmesh) checkUnvisible(child, zClampAbs, false, AXIS_XY_TOP_FORWORD)
          else child.visible = false
        }
        if (childTag === 'R_XYFR' || childTag === 'R_XYFS') {
          if (gmesh) checkUnvisible(child, zClampAbs, true, AXIS_XY_TOP_FORWORD)
          else child.visible = false
        }
      }

      if (childName === 'R_YZ') {
        if (childTag === 'R_YZT') {
          checkUnvisible(child, xClampAbs, false, AXIS_YZ_TOP_FORWORD)
        } else if (childTag === 'R_YZF') {
          checkUnvisible(child, xClampAbs, true, AXIS_YZ_TOP_FORWORD)
        }
        if (childTag === 'R_YZTR' || childTag === 'R_YZTS') {
          if (gmesh) checkUnvisible(child, xClampAbs, false, AXIS_YZ_TOP_FORWORD)
          else child.visible = false
        }
        if (childTag === 'R_YZFR' || childTag === 'R_YZFS') {
          if (gmesh) checkUnvisible(child, xClampAbs, true, AXIS_YZ_TOP_FORWORD)
          else child.visible = false
        }
      }

      if (!child.visible) continue

      child.scale.set(child.scale.x > 0 ? 1 : -1, child.scale.y > 0 ? 1 : -1, child.scale.z > 0 ? 1 : -1).multiplyScalar(this.zoom)
      if (childName.search('S_') === -1) {
        if (childName.search('X') !== -1) {
          child.scale.x = Math.abs(child.scale.x) * (xClamp > AXIS_FLIP_TRESHOLD ? -1 : 1)
        }
        if (childName.search('Y') !== -1) {
          child.scale.y = Math.abs(child.scale.y) * (yClamp > AXIS_FLIP_TRESHOLD ? -1 : 1)
        }
        if (childName.search('Z') !== -1) {
          child.scale.z = Math.abs(child.scale.z) * (zClamp > AXIS_FLIP_TRESHOLD ? -1 : 1)
        }
      } else {
        let pos = child.position.clone()
        let size = n.wSize.toM()
        if (childName.search('X_U') !== -1) {
          pos.x = size.x * 0.5
        }
        if (childName.search('X_D') !== -1) {
          pos.x = -size.x * 0.5
        }
        if (childName.search('Y_U') !== -1) {
          pos.y = size.y * 0.5
        }
        if (childName.search('Y_D') !== -1) {
          pos.y = -size.y * 0.5
        }
        if (childName.search('Z_U') !== -1) {
          pos.z = size.z * 0.5
        }
        if (childName.search('Z_D') !== -1) {
          pos.z = -size.z * 0.5
        }
        // pos.applyQuaternion(dragNodeSq)
        child.position.copy(pos)
      }

      let childQua
      if (childName === 'T_X' || childName === 'S_X_U' || childName === 'S_X_D') {
        childQua = computeHandleQua(unitX, unitZ)
        child.quaternion.copy(childQua)
      } else if (childName === 'T_Y' || childName === 'S_Y_U' || childName === 'S_Y_D') {
        childQua = computeHandleQua(unitY, unitX)
        child.quaternion.copy(childQua)
      } else if (childName === 'T_Z' || childName === 'S_Z_U' || childName === 'S_Z_D') {
        childQua = computeHandleQua(unitZ, unitX)
        child.quaternion.copy(childQua)
      }

      if (childTag === 'R_XZTS' || childTag === 'R_XZFS') {
        childQua = new THREE.Quaternion().setFromAxisAngle(unitY, angle)
        child.quaternion.copy(childQua)
      } else if (childTag === 'R_XYTS' || childTag === 'R_XYFS') {
        childQua = new THREE.Quaternion().setFromAxisAngle(unitZ, angle)
        child.quaternion.copy(childQua)
      } else if (childTag === 'R_YZTS' || childTag === 'R_YZFS') {
        childQua = new THREE.Quaternion().setFromAxisAngle(unitX, angle)
        child.quaternion.copy(childQua)
      }
    }

    this.visible = true
    this.render()
  }

  /**  @description 渲染当前视口 */
  render() {
    console.log('渲染viewport33')
    this._viewport.render()
  }
}
export { Tz3dGizmo }
