<template>
  <!-- 背板 -->
  <div class="layer-board">
    <!-- 接合样式 -->
    <div class="common-set-title" @click="show.one=!show.one">
      <span class="mr5">接合样式</span>
      <el-icon v-if="show.one"><CaretBottom /></el-icon>
      <el-icon v-else><CaretTop /></el-icon>
    </div>
    <div v-if="show.one" class="common-set-content">
      <div class="common-board-content">
        <div class="commonset">
          <div class="common-input-width">
            <el-radio-group v-model="radio1" size="small">
              <el-radio value="1">四边插槽</el-radio>
              <el-radio value="2">厚背板</el-radio>
              <el-radio value="3">外盖背板</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <img class="img2" src="@/assets/tools/board/backboard1.png" alt="">
    </div>
    <!-- 接合样式 -->
    <!-- 背板参数 -->
    <div class="common-set-title" @click="show.two=!show.two">
      <span class="mr5">背板参数</span>
      <el-icon v-if="show.two"><CaretBottom /></el-icon>
      <el-icon v-else><CaretTop /></el-icon>
    </div>
    <div v-if="show.two" class="common-set-content">
      <div class="common-board-content">
        <div class="commonset">
          <div class="commonset-tit">厚度(BT)</div>
          <el-input-number class="common-input-width" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
        </div>
        <div class="commonset">
          <div class="commonset-tit">缩进(BSV)</div>
          <el-input-number class="common-input-width" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
        </div>
        <div class="commonset">
          <div class="commonset-tit">槽深(SD)</div>
          <el-input-number class="common-input-width" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
        </div>
        <div class="commonset">
          <div class="commonset-tit">断开背板</div>
          <el-checkbox  />
        </div>
      </div>
      <img class="img2" src="@/assets/tools/board/backboard2.png" alt="">
    </div>
    <!-- 背板参数 -->
    <!-- 背板加固拉条 -->
    <div class="common-set-title" @click="show.three=!show.three">
      <span class="mr5">背板加固拉条</span>
      <el-icon v-if="show.three"><CaretBottom /></el-icon>
      <el-icon v-else><CaretTop /></el-icon>
    </div>
    <div  v-if="show.three" class="common-set-content">
      <div class="common-board-content">
        <div class="commonset">
          <div class="commonset-tit">方向</div>
          <el-select
          class="common-input-width"
          v-model="value"
          placeholder="方向"
        
          >
            <el-option label="横向" :value="1"></el-option>
          </el-select>
        </div>
        <div class="commonset">
          <div class="commonset-tit">厚度</div>
          <el-input-number class="common-input-width" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
        </div>
        <div class="commonset">
          <div class="commonset-tit">宽度</div>
          <el-input-number class="common-input-width" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
        </div>
        <div class="commonset">
          <div class="commonset-tit">数量</div>
          <el-input-number class="common-input-width" v-model="num" :min="0" :step="1" :precision="0" :controls="false" controls-position="right"/>
        </div>
        <div class="commonset">
          <div class="commonset-tit">缩进</div>
          <el-input-number class="common-input-width" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
        </div>
        <div class="commonset">
          <div class="commonset-tit"><el-checkbox  label="均分"/></div>
          <div class="common-input-width13">
            <div class="flx"><span class="mr5">上内空高度</span><el-input-number class="common-input-width6" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/></div>
            <div class="flx"><span class="mr5">下内空高度</span><el-input-number class="common-input-width6" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/></div>
          </div>
         
        </div>
      </div>
      <img class="img2" src="@/assets/tools/board/backboard3.png" alt="">
    </div>
    <!-- 背板加固拉条 -->
    <!-- 板材 -->
    <div class="common-set-title" @click="show.four=!show.four">
      <span class="mr5">板材</span>
      <el-icon v-if="show.four"><CaretBottom /></el-icon>
      <el-icon v-else><CaretTop /></el-icon>
    </div>
    <div v-if="show.four" class="common-set-content">
      <div class="common-board-content">
        <div class="commonset">
          <div class="commonset-tit">基材</div>
          <div class="common-input-width">
            <el-select
              v-model="value"
              placeholder="基材"
             
            >
              <el-option label="多层板" :value="1"></el-option>
            </el-select>
          </div>
        </div>
        <div class="commonset">
          <div class="commonset-tit">纹理</div>
          <div class="common-input-width">
           
            <el-select
            class="imgselect"
            v-model="value1"
            placeholder="纹理"
            @change="changeSelection"
            ref="layboardselect"
            >
              <el-option
              v-for="(va,vak) in valueArr"
              :key="vak"
              :label="va.text"
              :value="va.id"
              >
              <div style="display: flex;align-items: center;">
                <img
                  :src="va.src"
                  alt=""
                  width="30px"
                  height="30px"
                />
          
                <span>{{ va.text }}</span>
              </div>
               
              </el-option>
              
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <!-- 板材 -->
  </div>
</template>

<script setup lang="ts">
import {ref} from 'vue';

const show = ref({
  one: true,
  two: true,
  three: true,
  four: true
});
const num = ref(0)
const radio1 = ref('');
const value = ref('')
const value1 = ref('')
const valueArr = ref([
  {text:'纹理一',src:require('@/assets/tools/board/layboard5.png'),id: 1}
])
const layboardselect = ref()
const changeSelection = (val) => {

  for (let index in valueArr.value) {
    let obj = valueArr.value[index]
    if (obj.id == val) {
      layboardselect.value.$el.children[0].children[0].setAttribute(
        'style',
        `background: url(${obj.src}) no-repeat; 
        width:80px; height: 80px;
        border: none;
        background-position: 0% center;
        background-size:130%`
      )
    }
  }
  
}



</script>

<style scoped lang="less">
@import '@/assets/css/board.less';

.layer-board {
  width: 100%;
  padding: 0 10px;
}


:deep(.imgselect) {
  width: 80px;
  height: 70px;
  .el-select__placeholder {
    display: none !important;
  }
  .el-select__wrapper {
    padding: 5px !important;
    width: 80px !important;
    height: 70px !important;
  }
}
</style>