import { TzDataType, TzSort } from '@/core/basic/zBasic.js'

/** Tz3dDrawCtrlDownKeys */
class Tz3dDrawCtrlDownKeys {
  constructor(drawCtrl) {
    this._drawCtrl = drawCtrl
    this._keys = new TzSort([TzDataType.INTEGER])
    this._init()
  }

  _init() {
    this._timeDo_(600)
  }

  get drawCtrl() {
    return this.drawCtrl
  }

  _timeDo_(time = 1000) {
    let self = this
    if (!self) return
    setTimeout(_ => {
      self._timeDo_(time)
      self._timeDo()
    }, time)
  }

  _timeDo() {
    if (!this) return
    for (let i = this._keys.items.length - 1; i > -1; i--) {
      let item = this._keys.items[i]
      if (!item) continue
      item.time -= 1
      if (item.time <= 0) {
        this._keys.deleteItem(i)
      } else if (item.time > 1) {
        item.time = 1
      }
    }
  }

  includes(key) {
    return !!this._keys.findItem(null, [key])
  }

  push(key) {
    let keyItem = this._keys.findItem(null, [key])
    if (!keyItem) {
      keyItem = { time: 1 }
      this._keys.addItem(keyItem, [key])
      return true
    } else {
      keyItem.time += 1
      return false
    }
  }

  remove(key) {
    this._keys.deleteItemByKey(null, [key])
  }

  morekey(v) {
    return this._keys.length > v
  }

  get hasHelperKey() {
    return this.includes(16) || this.includes(17) || this.includes(18) || this.includes(17) || this.includes(91)
  }
}
export { Tz3dDrawCtrlDownKeys }
