<template>
  <!-- 立板 -->
  <div class="layer-board">
    <!-- 左侧板 -->
    <div class="common-set-title" @click="show.one=!show.one">
      <span class="mr5">左侧板</span>
      <el-icon v-if="show.one"><CaretBottom /></el-icon>
      <el-icon v-else><CaretTop /></el-icon>
    </div>
    <div v-if="show.one" class="common-set-content">
      <el-checkbox  />
      <img class="m5 img1" src="@/assets/tools/board/standboard1.png" alt="">
      <div class="common-board-content">
        <div class="commonset">
          <div class="commonset-tit">左侧间距</div>
          <el-input-number class="common-input-width" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
        </div>
      </div>
    </div>
    <!-- 左侧板 -->
     <!-- 中立板 -->
    <div class="common-set-title" @click="show.two=!show.two">
      <span class="mr5">中立板</span>
      <el-icon v-if="show.two"><CaretBottom /></el-icon>
      <el-icon v-else><CaretTop /></el-icon>
    </div>
    <div v-if="show.two" class="common-set-content">
      <el-checkbox  />
      <img class="m5 img1" src="@/assets/tools/board/standboard2.png" alt="">
      <div class="common-board-content">
        <div class="commonset">
          <div class="commonset-tit">数量</div>
          <el-input-number class="common-input-width" v-model="num" :min="0" :step="1" :precision="0" :controls="false" controls-position="right"/>
        </div>
      </div>
    </div>
    <!-- 中立板 -->
    <!-- 右侧板 -->
    <div class="common-set-title" @click="show.three=!show.three">
      <span class="mr5">右侧板</span>
      <el-icon v-if="show.three"><CaretBottom /></el-icon>
      <el-icon v-else><CaretTop /></el-icon>
    </div>
    <div v-if="show.three" class="common-set-content">
      <el-checkbox  />
      <img class="m5 img1" src="@/assets/tools/board/standboard3.png" alt="">
      <div class="common-board-content">
        <div class="commonset">
          <div class="commonset-tit">右侧间距</div>
          <el-input-number class="common-input-width" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
        </div>
      </div>
    </div>
    <!-- 右侧板 -->
    <!-- 参数 -->
    <div class="common-set-title" @click="show.four=!show.four">
      <span class="mr5">参数</span>
      <el-icon v-if="show.four"><CaretBottom /></el-icon>
      <el-icon v-else><CaretTop /></el-icon>
    </div>
    <div v-if="show.four" class="common-set-content">
      <el-checkbox  />
      <img class="m5 img1" src="@/assets/tools/board/standboard4.png" alt="">
      <div class="common-board-content">
        <div class="commonset">
          <div class="commonset-tit">厚度</div>
          <el-input-number class="common-input-width" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
        </div>
        <div class="commonset">
          <div class="commonset-tit">缩进</div>
          <el-input-number class="common-input-width" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
        </div>
      </div>
    </div>
    <!-- 参数 -->
    <!-- 板材 -->
    <div class="common-set-title" @click="show.five=!show.five">
      <span class="mr5">板材</span>
      <el-icon v-if="show.five"><CaretBottom /></el-icon>
      <el-icon v-else><CaretTop /></el-icon>
    </div>
    <div v-if="show.five" class="common-set-content">
      <div class="common-board-content">
        <div class="commonset">
          <div class="commonset-tit">基材</div>
          <div class="common-input-width">
            <el-select
              v-model="value"
              placeholder="基材"
            
            >
              <el-option label="多层板" :value="1"></el-option>
            </el-select>
          </div>
        </div>
        <div class="commonset">
          <div class="commonset-tit">纹理</div>
          <div class="common-input-width">
           
            <el-select
            class="imgselect"
            v-model="value1"
            placeholder="纹理"
            @change="changeSelection"
            ref="layboardselect"
            >
              <el-option
              v-for="(va,vak) in valueArr"
              :key="vak"
              :label="va.text"
              :value="va.id"
              >
              <div style="display: flex;align-items: center;">
                <img
                  :src="va.src"
                  alt=""
                  width="30px"
                  height="30px"
                />
          
                <span>{{ va.text }}</span>
              </div>
               
              </el-option>
              
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <!-- 板材 -->
  </div>
</template>

<script setup lang="ts">
import {ref} from 'vue';

const show = ref({
  one: true,
  two: true,
  three: true,
  four: true,
  five: true
});
const num = ref(0)
const value = ref('')
const value1 = ref('')
const valueArr = ref([
  {text:'纹理一',src:require('@/assets/tools/board/layboard5.png'),id: 1}
])
const layboardselect = ref()
const changeSelection = (val) => {

  for (let index in valueArr.value) {
    let obj = valueArr.value[index]
    if (obj.id == val) {
      layboardselect.value.$el.children[0].children[0].setAttribute(
        'style',
        `background: url(${obj.src}) no-repeat; 
        width:80px; height: 80px;
        border: none;
        background-position: 0% center;
        background-size:130%`
      )
    }
  }
  
}



</script>

<style scoped lang="less">
@import '@/assets/css/board.less';

.layer-board {
  width: 100%;
  padding: 0 10px;
}


:deep(.imgselect) {
  width: 80px;
  height: 70px;
  .el-select__placeholder {
    display: none !important;
  }
  .el-select__wrapper {
    padding: 5px !important;
    width: 80px !important;
    height: 70px !important;
  }
}
</style>