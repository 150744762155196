
import { THREE } from '@/core/thirdPart/lib.js'
import { Tz3dDrawCtrlState,TzMouseButton } from './z3dConst.js'
import {Tz3dDrawCtrlDownKeys} from './z3dDrawCtrlDownKeys'
import {Tz3dTool,Tz3dMath,TzEventListener,TzTimer} from '../z3dTool'
import {Tz3dCtrlEvent} from '../z3dCtrlEvent'
import {TzEvent} from '@/core/basic/zBasic'
import { Tz2dPoint } from '../z2dClass.js'

/** Tz3dDrawCtrl */
class Tz3dDrawCtrl {
  /** 注释
   * @property {number}  id               - 唯一Id
   * @property {domElement}  domElement               - 当前事件依附的div
   * @property {booleen}  enabled       - 事件的是否允许触发
   * @property {TvkpxDrawCtrlType}  drawCtrlType       - drawCtrl类型
   * @property {event}  eventDown       - 鼠标按下事件信息
   * @property {event}  eventMove       - 鼠标移动事件信息
   * @property {event}  eventUp       - 鼠标松开事件信息
   * @property {event}  eventDblClick       - 鼠标双击事件信息
   * @property {event}  eventEnter       - 鼠标进入事件信息
   * @property {event}  eventLeave       - 鼠标离开事件信息
   * @property {event}  eventWheel       - 鼠标滚轮事件信息
   * @property {Tz3dDrawCtrlDownKeys}  downKeys       - 键盘点击事件信息
   * @property {booleen}  isDown       - 当前鼠标是否是按下状态
   * @property {booleen}  isDoTopNode       - 是否选中为顶层node 默认true
   * @property {TzNode}  nDragNode       - 拖拽node
   * @property {fun}  onDragCreateInit       - 拖拽创建物体的回调
   * @property {fun}  onDragCreateEnd       - 拖拽创建结束的回调
   * @property {fun}  oldRender       - 为此DrawCtrl之前 viewport的render机制
   * @property {booleen}  isDraggingObject       - 当前是否处于复制node中
   */
  constructor() {
    this.id = -1
    this.drawCtrlType = -1
    this.domElement = document
    this._viewport = null
    this.cursor = 'auto'
    this.enabled = true
    this._state = Tz3dDrawCtrlState.NONE
    this.eventDown = null
    this.eventMove = null
    this.eventUp = null
    this.eventDblClick = null
    this.eventEnter = null
    this.eventLeave = null
    this.eventWheel = null
    this.downKeys = new Tz3dDrawCtrlDownKeys(this)

    this.isDown = false
    this.isDownShift = false
    this.isDoTopNode = true
    this._keyboardMovingCamera = false

    this.nDragNode = null
    this.dragReplaceId = 0
    this.dragReplaceNode = null
    this.onDragCreateInit = null
    this.onDragCreateEnd = null
    this.onRestoreDefaultCtrl = null
    this.onMouseSelect = null
    this.transModeListener = new TzEventListener()
    this.nodeTransformChangeListener = new TzEventListener()
    this._allowDragCreateEnd = false
    this._rectNodesPos = []
    this._svgRectId = -1

    this.oldRender = function() {}

    this._zoomOld = -1
    this._zoomOldPos = null

    this.isDraggingObject = false
    this.draggingNode = null
    this.draggingNodeStartPos = null

    // --------------
    this._callbackHoverSelect_bind = this._callbackHoverSelect.bind(this)
  }

  get event() {
    if (!this._event) {
      this._event = new TzEvent()
    }
    return this._event
  }

  set event(v) {
    this._event = v
  }

  get isDesignDrawCtrl() {
    return false
  }

  _onCtrlExit() {}

  /**  @description 清空viewport等 */
  free() {
    this.svgRect.visible = false
    if (this._viewport) {
      this._unbindEvent()
      this._viewport._camCtrlChange_render = this.oldRender
      this._viewport.cameraCtrl.endMoveCamera()
    }
  }

  /**  @description get：获取Tz3d */
  get z3d() {
    return this._viewport ? this._viewport.z3d : null
  }

  /**  @description getset：所属的视口 */
  get viewport() {
    return this._viewport
  }

  /**  @description getset：所属的视口的相机控制器 */
  get cameraCtrl() {
    return this._viewport ? this._viewport.cameraCtrl : null
  }

  /**  @description getset：所属的视口的相机控制器的状态 */
  get camState() {
    return this._viewport ? this._viewport.cameraCtrl.camState : null
  }

  get state() {
    return this._state
  }

  /**  @description getset：控制的状态 */
  set state(v) {
    if (this._state === v) return
    // this.event.fire('onStateChange', this._state, v)
    this._state = v
    if (this.viewport.callbackViewportChange) this.viewport.callbackViewportChange()
  }

  addDragCreateTempNode(graphic, size) {
    let n = graphic.addNode3d(this.viewport, null)
    n.createMeshCube(size.x, size.y, size.z)
    n.updateRender()
    return n
  }

  replaceDragCreateObj(nDragTemp, nDragReplace) {
    this._doReplaceDragNode(nDragTemp, nDragReplace)
  }

  _doReplaceDragNode(nDragTemp, nDragReplace) {
    if (!nDragTemp) return
    if (!nDragReplace) return

    nDragReplace.wPos.copy(nDragTemp.wPos)
    nDragReplace.wPos.y += nDragReplace.size.y / 2
    nDragReplace.wAngle.copy(nDragTemp.wAngle)
    nDragReplace.updateRender(true)
    nDragReplace.wPos.y -= nDragReplace.cBox.min.y
    nDragReplace.updateRender(true)
    if (!nDragTemp.isDragCreating) {
      nDragReplace.graphic._updateValueByNode(nDragReplace)
    }

    this.viewport.gizmo._dragNode = nDragReplace
    this.viewport.gizmo.dragUpdateToward(nDragReplace.wPos.y)
    this.viewport.gizmo.updateDragNodeObject()
    if (nDragTemp.graphic) nDragTemp.graphic.free()
    if (nDragReplace.graphic.sgdc) {
      nDragReplace.graphic.sgdc.doPrepareValidHitNodes(this.viewport)
    }

    this.nDragNode = nDragReplace
  }

  _bindEvents() {
    this.enabled = true
    this.domElement = this._viewport._renderer.domElement
    this._mouseWheelEvent = this._mouseWheel.bind(this)
    this.domElement.addEventListener('wheel', this._mouseWheelEvent, false)
    this._orbStartEvent = this._orbStart.bind(this)
    this.camCtrl.addEventListener('start', this._orbStartEvent, false)
    this._orbChangeEvent = this._orbChange.bind(this)
    this.camCtrl.addEventListener('change', this._orbChangeEvent, false)
    this._orbEndEvent = this._orbEnd.bind(this)
    this.camCtrl.addEventListener('end', this._orbEndEvent, false)
    this._mouseLeaveEvent = this._mouseLeave.bind(this)
    this.domElement.addEventListener('mouseleave', this._mouseLeaveEvent, false)
    this._mouseEnterEvent = this._mouseEnter.bind(this)
    this.domElement.addEventListener('mouseenter', this._mouseEnterEvent, false)
    this._mouseMoveEvent = this._mouseMove.bind(this)
    this.domElement.addEventListener('mousemove', this._mouseMoveEvent, false)
    this._mouseDownEvnet = this._mouseDown.bind(this)
    this.domElement.addEventListener('mousedown', this._mouseDownEvnet, false)
    this._mouseDblClickEvnet = this._mouseDblClick.bind(this)
    this.domElement.addEventListener('dblclick', this._mouseDblClickEvnet, false)
    this._mouseUpEvent = this._mouseUp.bind(this)
    this.domElement.addEventListener('mouseup', this._mouseUpEvent, false)
    this._mouseMove_DomEvent = this._mouseMove_Dom.bind(this)
    document.addEventListener('mousemove', this._mouseMove_DomEvent, false)
    this._mouseUp_DomEvent = this._mouseUp_Dom.bind(this)
    document.addEventListener('mouseup', this._mouseUp_DomEvent, false)
    this._keyDownEvent = this._keyDown.bind(this)
    document.addEventListener('keydown', this._keyDownEvent, false)
    this._keyUpEvent = this._keyUp.bind(this)
    document.addEventListener('keyup', this._keyUpEvent, false)
    this._keyDownViewEvent = this._keyDownView.bind(this)
    this.domElement.addEventListener('keydown', this._keyDownViewEvent, false)
    this._keyUpViewEvent = this._keyUpView.bind(this)
    this.domElement.addEventListener('keyup', this._keyUpViewEvent, false)
    this.camState.saveState()
  }

  _unbindEvent() {
    this.domElement = this._viewport._renderer.domElement
    this.domElement.removeEventListener('wheel', this._mouseWheelEvent, false)
    this.camCtrl.removeEventListener('start', this._orbStartEvent, false)
    this.camCtrl.removeEventListener('change', this._orbChangeEvent, false)
    this.camCtrl.removeEventListener('end', this._orbEndEvent, false)
    this.domElement.removeEventListener('mouseleave', this._mouseLeaveEvent, false)
    this.domElement.removeEventListener('mouseenter', this._mouseEnterEvent, false)
    this.domElement.removeEventListener('mousemove', this._mouseMoveEvent, false)
    this.domElement.removeEventListener('mousedown', this._mouseDownEvnet, false)
    this.domElement.removeEventListener('dblclick', this._mouseDblClickEvnet, false)
    this.domElement.removeEventListener('mouseup', this._mouseUpEvent, false)
    document.removeEventListener('mousemove', this._mouseMove_DomEvent, false)
    document.removeEventListener('mouseup', this._mouseUp_DomEvent, false)
    document.removeEventListener('keydown', this._keyDownEvent, false)
    document.removeEventListener('keyup', this._keyUpEvent, false)
    this.enabled = false
    this.camState.applyState()
    this._viewport.z3d.clearSelection()
    this._viewport.gizmo.updateTrans()
  }

  set viewport(value) {
    if (this._viewport) {
      this._unbindEvent()
    }
    this._viewport = value
    if (this._viewport) {
      this._bindEvents()
      if (this.id < 0) {
        this.id = this.z3d.getNextId()
      }
    }
    this._viewport.div.style.cursor = this.cursor
    this._onSetViewport()
  }

  /**  @description 重置viewport时执行的方法 */
  _onSetViewport() {
    let self = this
    if (self._viewport && self._viewport._renderer.domElement) {
      self.domElement = self._viewport._renderer.domElement
      self.oldRender = self._viewport._camCtrlChange_render
      // self._viewport._camCtrlChange_render = function () {
      //   if (self._viewport) self._viewport.gizmo.updateTrans()
      // }
      self._zoomOld = self.camera.zoom
    }
  }

  /**  @description get：所属的视口的相机 */
  get camera() {
    return this._viewport.camera
  }

  /**  @description get：所属的视口的相机控制器 */
  get camCtrl() {
    return this._viewport.cameraCtrl.camCtrl
  }

  _mouseEnter(event) {
    if (!this.enabled || !this.viewport) return
    this.viewport._onChangeScene()
    this.eventEnter = event
    this._onMouseEnter(event)
  }

  _mouseLeave(event) {
    if (!this.enabled) return
    this.eventLeave = event
    this._onMouseLeave(event)
  }

  _mouseDblClick(event) {
    if (!this.enabled || !this.viewport) return
    this.viewport._onChangeScene()
    this.eventDblClick = event
    this._onMouseDblClick(event)
  }

  _mouseDown(event) {
    if (!this.enabled || !this.viewport) return
    this.viewport._onChangeScene()
    this.eventDown = event
    if (this.isDown) return
    this.isDown = true
    if (Tz3dCtrlEvent.getEventHelperKey(event, false, true)) {
      this.isDownShift = true
    }
    this.button = this.getEventButton(event.button)
    this._onMouseDown(event)

    // 传递到sgdc
    let dn = this.dragNode
    if (dn && dn.graphic && dn.graphic.sgdc) {
      dn.graphic.sgdc.onMouseDown(event, this)
    }
  }

  _mouseMove(event) {
    if (!this.enabled || !this.viewport) return
    this.viewport._onChangeScene()
    this.eventMove = event
    this._onMouseMove(event)
    if (this.viewport.gizmo) this.viewport.gizmo._onMouseMove(event)
  }

  _mouseMove_Dom(event) {
    if (!this.enabled || !this.viewport) return
    this.eventMove = event
    this._onMouseMove_Dom(event)
  }

  _mouseUp(event) {
    if (!this.enabled || !this.viewport) return
    this.viewport._onChangeScene()
    this.eventUp = event
    this.button = this.getEventButton(event.button)
    this._onMouseUp(event)
    this.isDown = false
    this.eventDown = null
  }

  _mouseUp_Dom(event) {
    if (!this.enabled) return
    this.eventUp = event
    if (!this.isDown) return
    this._onMouseUp_Dom(event)
  }

  _mouseWheel(event) {
    if (!this.enabled || !this.viewport) return
    this.viewport._onChangeScene()
    this.eventWheel = event
    this._onMouseWheel(event)
  }

  _orbStart(event) {
    if (!this.enabled) return
    this._onOrbStart(event)
  }

  _orbChange(event) {
    if (!this.enabled) return
    this._onOrbChange(event)
  }

  _orbEnd(event) {
    if (!this.enabled) return
    this._onOrbEnd(event)
  }

  _keyDownView(event) {
    if (!this.enabled) return
    let key = event.keyCode ? event.keyCode : event.which
    if (this.downKeys.push(key)) {
      this._onKeyDownView(event)
    }
  }

  _keyUpView(event) {
    if (!this.enabled || !this.viewport) return
    this.viewport._onChangeScene()
    let key = event.keyCode ? event.keyCode : event.which
    this.downKeys.remove(key)
    this._onKeyUpView(event)
  }

  _keyDown(event) {
    if (!this.enabled) return
    let key = event.keyCode ? event.keyCode : event.which
    this.downKeys.push(key)
    this._onKeyDown(event)
  }

  _keyUp(event) {
    if (!this.enabled) return
    let key = event.keyCode ? event.keyCode : event.which
    this.downKeys.remove(key)
    this._onKeyUp(event)
  }

  /**  @description 鼠标事件进入的调用 */
  _onMouseEnter(event) {
    this.tryStartDragCreate(event)
  }

  /**  @description 鼠标事件离开的调用 */
  _onMouseLeave(event) {
    if (this.state !== Tz3dDrawCtrlState.RECTSELECT) {
      if (this.z3d.hoverGraphics.length) {
        this.z3d.hoverGraphics.forEach(g => {
          g.isHover = false
        })
        this.render()
      }
    }
  }

  /**  @description 鼠标事件双击的调用 */
  _onMouseDblClick(event) {}

  /**  @description 鼠标事件按下的调用 */
  _onMouseDown(event) {
    switch (this.state) {
      case Tz3dDrawCtrlState.NONE:
        if (this.button === TzMouseButton.LEFT) {
          if (this.tryStartDragGizmo(event)) return
          this.state = Tz3dDrawCtrlState.NONE
        } else {
          this.state = Tz3dDrawCtrlState.DRAGCAMERA
        }
        break
      default:
        break
    }
  }

  _doMouseHover(event) {
    let nHover = this.viewport.svgNodeRender?.getHoverNode(event)
    // if (!nHover) nHover = this.viewport.svgRulerRender.getHoverNode(event)
    // if (!nHover) nHover = this.viewport.svgTextRender.getHoverNode(event)
    if (!nHover) nHover = this.viewport.getHoverNode(event, this._callbackHoverSelect_bind, this.isDoTopNode)
    if (this.isDoTopNode && nHover) nHover = nHover.selectTopNode || nHover

    if (nHover) {
      this.z3d.hoverGraphics.forEach(g => {
        if (g !== nHover.graphic) {
          g.isHover = false
        }
      })
      if (nHover.graphic) {
        nHover.graphic.hoverNode = nHover
        nHover.graphic.isHover = true
      }
      return nHover
    }

    this.z3d.hoverGraphics.forEach(g => {
      g.isHover = false
    })
    return nHover
  }

  /**  @description 鼠标事件移动的调用 */
  _onMouseMove(event) {
    switch (this.state) {
      case Tz3dDrawCtrlState.NONE:
        if (this.isDown && this.getPointerLength(this.eventDown, event) > 0) {
          if (this.tryStartRectSelect(event)) return
          if (this.tryStartDragNode(event)) return
          if (this.tryStartDragCamera(event)) return
        }
        this._doMouseHover(event)
        break
      case Tz3dDrawCtrlState.DRAGNODE:
      case Tz3dDrawCtrlState.DRAGGIZMO:
        this.viewport.gizmo.dragging(event)
        break
      case Tz3dDrawCtrlState.DRAGCREATE:
        this._doReplaceDragNode(this.dragReplaceId, this.dragReplaceNode)
        this.viewport.gizmo.dragging(event)
        break
      case Tz3dDrawCtrlState.RECTSELECT:
        this._doingRectSelect(event)
        break
      case Tz3dDrawCtrlState.DRAGCAMERA:
        break
    }
  }

  _onMouseMove_Dom(event) {}

  /**  @description 鼠标事件抬起的调用 */
  _onMouseUp(event) {
    switch (this.button) {
      case TzMouseButton.LEFT:
        this._onMouseUpLeft(event)
        break
      case TzMouseButton.RIGHT:
        this._onMouseUpRight(event)
        break
    }
  }

  _doMouseSelect(event) {
    return
    let nHover = this.viewport.svgNodeRender.getHoverNode(event)
    // if (!nHover) nHover = this.viewport.svgRulerRender.getHoverNode(event)
    // if (!nHover) nHover = this.viewport.svgTextRender.getHoverNode(event)
    if (!nHover) nHover = this.viewport.getHoverNode(event, this._callbackHoverSelect_bind, this.isDoTopNode)
    if (this.isDoTopNode && nHover) nHover = nHover.selectTopNode || nHover

    let isMultiSelect = event.shiftKey
    if (!nHover) {
      if (!isMultiSelect) {
        this.z3d.clearSelection()
      }
      return
    }

    let nGraphic = nHover.graphic
    if (nGraphic) {
      if (isMultiSelect) {
        if (!nGraphic.isSelect) nGraphic.focusNode = nHover
        nGraphic.isSelect = !nGraphic.isSelect
      } else {
        if (nGraphic.isFocus) {
          nGraphic.focusNode = nHover
          return
        }
        this.z3d.clearSelection()
        nGraphic.focusNode = nHover
        nGraphic.isSelect = true
        // Tz3dTool.consoleLogObjects(nHover.bodyMesh)
      }
      if (nGraphic.isSelect && this.onMouseSelect) this.onMouseSelect(nGraphic)
    }
  }

  get svgRect() {
    if (!this._viewport) return
    let svgRect = null
    if (this._svgRectId > -1) {
      svgRect = this._viewport.svgNodeRender.getNodeById(this._svgRectId)
    }
    if (!svgRect) {
      svgRect = this._viewport.svgNodeRender.root.addChild()
      svgRect.nodeProperties.disableMousePowers()
      svgRect.nodeProperties.selectAble = false
      svgRect.createShape_Rect()
      svgRect.nodeAttr.stroke.stroke.value = '#87cefa'
      svgRect.nodeAttr.fill.fill.value = '#ffffff' //背景色
      svgRect.nodeAttr.fill.opacity.value = 0.2
      this._svgRectId = svgRect.id
    }
    if (this.viewport.is2d) {
      let rt = svgRect.svg.root._transform
      svgRect._transform.scale.set(1 / rt.scale.x, 1 / rt.scale.y)
      svgRect._transform.translate.set(-rt.translate.x, -rt.translate.y)
    } else {
      svgRect._transform.scale.set(1, 1)
      svgRect._transform.translate.set(0, 0)
    }
    return svgRect
  }

  _doingRectSelect(event) {
    if (!this.eventDown) return
    let sx, ex, sy, ey
    sx = this.eventDown.offsetX
    sy = this.eventDown.offsetY
    ex = event.offsetX
    ey = event.offsetY
    let svgRect = this.svgRect
    let sizeE = new THREE.Vector2(sx - ex, sy - ey).abs()
    let centerE = new THREE.Vector2(sx + ex, sy + ey).multiplyScalar(0.5)

    svgRect.shape.set(new Tz2dPoint(centerE.x, centerE.y), sizeE.x, sizeE.y)
    if (this.viewport.is2d) {
      let rt = svgRect.svg.root._transform
      svgRect.pos.set(-rt.translate.x / rt.scale.x, 0, -rt.translate.y / rt.scale.x)
    }
    svgRect.update()
    let box = new THREE.Box2().setFromCenterAndSize(centerE, sizeE)
    let selectGraphics = this.z3d.selectGraphics
    this._rectNodesPos.forEach(nodePos => {
      let node = nodePos[0]
      let pos = nodePos[1]
      let grahpic = node.graphic
      if (!grahpic) return
      if (box.containsPoint(pos)) {
        if (!grahpic.isRectSelectting) {
          grahpic.isRectSelectting = true
          selectGraphics.push(grahpic)
          grahpic.updateEffect()
        }
      } else {
        if (grahpic.isRectSelectting) {
          grahpic.isRectSelectting = false
          let index = selectGraphics.indexOf(grahpic)
          if (index > -1) {
            selectGraphics.splice(index, 1)
            grahpic.updateEffect()
          }
        }
      }
    })
  }

  _doRectSelect(event) {
    this._rectNodesPos = []
    this.svgRect.visible = false
    let z3d = this.z3d
    let selectGraphics = z3d.selectGraphics
    let len = selectGraphics.length
    if (!len) return
    for (let i = 0; i < len; i++) {
      selectGraphics[i].updateEffect()
    }
    let grahpic = selectGraphics[len - 1]
    for (let viewport of z3d.viewports) {
      viewport.gizmo.dragNode = grahpic.dragNode(viewport)
    }
    grahpic.createHelperNodes()
    z3d.event.fire('onFocusGraphicChange', selectGraphics[len - 2], grahpic)
    this.render()
  }

  _onMouseUpLeft(event) {
    switch (this.state) {
      case Tz3dDrawCtrlState.NONE:
      case Tz3dDrawCtrlState.DRAGCAMERA:
        if (this.isDown && this.getPointerLength(event, this.eventDown) < 6) {
          this._doMouseSelect(event)
        }
        this.state = Tz3dDrawCtrlState.NONE
        break
      case Tz3dDrawCtrlState.DRAGCREATE:
        if (!this._allowDragCreateEnd) return
        if (!this.viewport.gizmo.dragEnd(event, false)) {
          this.escape()
          if (this.onRestoreDefaultCtrl) {
            this.onRestoreDefaultCtrl(this)
          }
        } else {
          this.state = Tz3dDrawCtrlState.NONE
        }
        break
      case Tz3dDrawCtrlState.DRAGNODE:
      case Tz3dDrawCtrlState.DRAGGIZMO:
        if (!this.viewport.gizmo.dragEnd(event, false)) {
          this.escape()
          if (this.onRestoreDefaultCtrl) {
            this.onRestoreDefaultCtrl(this)
          }
        } else {
          this.state = Tz3dDrawCtrlState.NONE
        }
        break
      case Tz3dDrawCtrlState.RECTSELECT:
        this._doRectSelect(event)
        this.state = Tz3dDrawCtrlState.NONE
        break
    }
  }

  _onMouseUpRight(event) {
    switch (this.state) {
      case Tz3dDrawCtrlState.DRAGNODE:
      case Tz3dDrawCtrlState.DRAGCREATE:
      case Tz3dDrawCtrlState.DRAGGIZMO:
      case Tz3dDrawCtrlState.RECTSELECT:
        this.escape()
        if (this.onRestoreDefaultCtrl) {
          this.onRestoreDefaultCtrl(this)
        }
        break
      default:
        this.state = Tz3dDrawCtrlState.NONE
        break
    }
  }

  /**  @description 鼠标事件抬起的调用-之外的抬起监听 */
  _onMouseUp_Dom(event) {
    switch (this.state) {
      case Tz3dDrawCtrlState.DRAGNODE:
      case Tz3dDrawCtrlState.DRAGCREATE:
      case Tz3dDrawCtrlState.DRAGGIZMO:
        if (!this.viewport.gizmo.dragEnd(event, false)) {
          this.escape()
          if (this.onRestoreDefaultCtrl) {
            this.onRestoreDefaultCtrl(this)
          }
        }
        break
      case Tz3dDrawCtrlState.DRAGCAMERA:
        break
      case Tz3dDrawCtrlState.RECTSELECT:
        if (this.getEventButton(event.button) !== TzMouseButton.RIGHT) {
          this._doRectSelect(event)
        } else {
          this.escape()
          if (this.onRestoreDefaultCtrl) {
            this.onRestoreDefaultCtrl(this)
          }
        }
        break
    }
    this.isDown = false
    this.eventDown = null
    this.state = Tz3dDrawCtrlState.NONE
  }

  /**  @description 鼠标事件滚轮的调用 */
  _onMouseWheel(event) {
    let dn = this.dragNode
    if (dn && dn.graphic && dn.graphic.sgdc) {
      if (dn.graphic.sgdc.onMouseWheel(event, this)) {
        this._onMouseMove(event)
        return
      }
    }
    this.viewport.gizmo.updateTrans()
    this.viewport.cameraCtrl._doMouseWheelRoam(event)
  }

  get mouseEvent() {
    return this.viewport.mouseEvent
  }

  _onOrbStart(event) {
    if (this.camera.isOrthographicCamera) {
      let evn = this.mouseEvent
      if (evn && evn.type === 'wheel') {
        this._viewport.cameraCtrl.doCameraChange = function() {}
        let pointer = this.viewport.getScreenPointer(evn)
        this._zoomOldPos = this.viewport.planeIntersect(pointer, this.viewport.worldPlan)
      }
    }
  }

  _onOrbChange(event) {
    if (this.camera.isOrthographicCamera) {
      let evn = this.mouseEvent
      if (evn && evn.type === 'wheel') {
        this._doMouseWheelZoomByPoint(evn)
        this._viewport.cameraCtrl._doCameraChange()
        this._viewport.cameraCtrl.doCameraChange = null
      }
    }
  }

  _onOrbEnd(event) {
    if (this.camera.isOrthographicCamera) {
    }
  }

  /**  @description 键盘事件按下的调用 -此viewport */
  _onKeyDownView(event) {
    let key = event.keyCode ? event.keyCode : event.which
    let isInput = event.target.nodeName === 'INPUT'
    switch (key) {
      case 27: // esc
        this.escape()
        break
      case 46: // del
        if (isInput) break
        this.delete()
        break
      case 70: // f
        break
      case 82: // r
        if (isInput) break
        this.changeType()
        break
      case 90: // z
        break
      case 32: // space
        if (isInput) break
        this.cameraCtrl.moveCameraObject(this.viewport.z3d.focusGraphic ? this.viewport.z3d.focusGraphic.focusNode : null)
        break
      case 65:
      case 68:
      case 69:
      case 81:
      case 83:
      case 87:
        if (isInput) break
        this.tryStartKeyBoardMoveCamera()
        break
      case 107: // +
        if (isInput) break
        // this.viewport.definition *= 1.2
        break
      case 109: // -
        if (isInput) break
        // this.viewport.definition /= 1.2
        break
    }
    this.tryKeyDownBoard(event)
  }

  /**  @description 键盘事件抬起的调用 -此viewport */
  _onKeyUpView(event) {}

  /**  @description 键盘事件按下的调用 -之外监听 */
  _onKeyDown(event) {}

  /**  @description 键盘事件抬起的调用 -之外监听 */
  _onKeyUp(event) {
    if (!this.keyboardMovingCameraAble) {
      // this.viewport.svgNodeRender.update()
      // this.viewport.svgTextRender.update()
      // this.viewport.svgRulerRender.update()
    }
  }

  escape() {
    // if (this.state === Tz3dDrawCtrlState.NONE) return
    this._doEscape()
    this.render()
    this.isDown = false
    if (this.state !== Tz3dDrawCtrlState.CUSTOM) {
      this.state = Tz3dDrawCtrlState.NONE
    }
    this.camCtrl.clearState()
  }

  clearDragNode(bFree) {
    if (bFree) {
      let nDragObj = this.nDragNode.graphic.data
      if (nDragObj) {
        nDragObj.displayClear(this.z3d.viewports)
        nDragObj.free()
      } else {
        this.nDragNode.free()
      }

      // if (this.nDragNode && this.nDragNode.graphic) {
      //   switch (this.nDragNode.graphic.graphicCategory) {
      //     case TzSceneGraphicCategory.TCR_OBJECT:
      //       this.nDragNode.graphic.crObject.free()
      //       break
      //     case TzSceneGraphicCategory.HOUSEOBJECT:
      //       let nDragObj = this.nDragNode.graphic.data
      //       nDragObj.displayClear(this.z3d.viewports)
      //       nDragObj.free()
      //       break
      //     default:
      //       this.nDragNode.free()
      //       break
      //   }
      // }
    }
    this.nDragNode = null
  }

  /**  @description Esc的调用 */
  _doEscape() {
    switch (this.state) {
      case Tz3dDrawCtrlState.DRAGNODE:
      case Tz3dDrawCtrlState.DRAGGIZMO:
        this.viewport.gizmo.cancelDrag()
        break
      case Tz3dDrawCtrlState.DRAGCREATE:
        this.viewport.gizmo.cancelDrag()
        break
      case Tz3dDrawCtrlState.RECTSELECT:
        this.cancelRectSelect()
        break
      case Tz3dDrawCtrlState.NONE:
        if (this.z3d) this.z3d.clearSelection()
        break
    }
    this.viewport.gizmo.dragNode = null
    this.render()
  }

  delete() {
    this._doDelete()
  }

  /**  @description delete的调用 */
  _doDelete() {
    if (this.viewport.callbackGraphicDelete) {
      this.viewport.callbackGraphicDelete(this.viewport, this.z3d.focusGraphic)
    }
  }

  changeType() {
    if (!this.viewport.gizmo.dragNode) return
    let tm = 0
    if (this.viewport.gizmo.transMode === 0) tm = 1
    this.transModeListener.fire(tm)
  }

  _doMouseWheelZoomByPoint() {
    if (!this.camera.isOrthographicCamera) return
    if (this.camera.zoom !== this._zoomOld && this._zoomOldPos) {
      this._zoomOld = this.camera.zoom
      let pointer = this.viewport.getScreenPointer(event)
      let pos = this.viewport.planeIntersect(pointer, this.viewport.worldPlan)

      if (pos) {
        pos.sub(this._zoomOldPos).setY(0)
        this.camera.position.add(pos.negate())
        this.camera.updateProjectionMatrix()
        this.camCtrl.target.add(pos)
        this.camCtrl.update()
      }
      this.render()
    }
  }

  /**  @description 是否允许覆盖选中的回调 {@link Tz3dNode} */
  _callbackHoverSelect(node) {
    if (!this._viewport || !node.graphic) return
    return true
  }

  /**  @description 获取event 转成的 {@link TzMouseButton}类型 */
  getEventButton(button) {
    if (Tz3dTool.ieVersionLess(9)) {
      return TzMouseButton.LEFT
    } else {
      let buttons = [
        TzMouseButton.LEFT,
        TzMouseButton.MIDDLE,
        TzMouseButton.RIGHT,
        TzMouseButton.NONE,
        TzMouseButton.NONE,
        TzMouseButton.NONE,
        TzMouseButton.NONE
      ]
      return buttons[button]
    }
  }

  /**  @description 获取const 其它项
   * @param {const} enums 定义的const 枚举
   * @param {number} type 当前项
   * @param {number} addIndex 其它项的偏移量
   */
  getConstOther(consts, type, addIndex = 1) {
    let newType = type
    if (addIndex !== 0) {
      let index = -1
      let indexs = []
      for (let v in consts) {
        if (type === consts[v]) index = indexs.length
        indexs.push(consts[v])
      }
      index = (index + addIndex) % indexs.length
      if (index >= 0) newType = indexs[index]
      indexs = []
    }
    return newType
  }

  /**  @description 获取event 转成的屏幕坐标 */
  getScreenPointer(event) {
    return Tz3dTool.getScreenPointer(this.domElement, event)
  }

  /**  @description 获取两个event的位置差 返回：number */
  getPointerLength(event0, event1) {
    let length = 0
    if (event0 && event1) {
      length = new THREE.Vector2(event1.clientX - event0.clientX, event1.clientY - event0.clientY).length()
    }
    return length
  }

  /**  @description 开始拖拽Gizmo辅助控制器 返回：booleen */
  tryStartDragGizmo(event) {
    if (!this.viewport.gizmo.tryStartDragGizmo(event)) return false
    this.state = Tz3dDrawCtrlState.DRAGGIZMO
    return true
  }

  /**  @description 开始划区域选择nodes 返回：booleen */
  tryStartRectSelect(event) {
    let self = this
    if (!self.isDownShift || !Tz3dCtrlEvent.getEventHelperKey(event, false, true)) return false
    self.state = Tz3dDrawCtrlState.RECTSELECT
    let _rectNodesPos = (self._rectNodesPos = [])
    self.z3d.clearSelection()
    let viewport = self.viewport
    pushNodePos(viewport.root)
    let svgRect = self.svgRect
    svgRect.shape.set(new Tz2dPoint(-100, -100), 1, 1)
    svgRect.update()
    svgRect.visible = true
    return true

    function pushNodePos(pnode) {
      let n = 0
      pnode.children.items.forEach(node => {
        if (!node.nodeProperties.selectAble) return
        if (!self._callbackRectSelect(node)) return
        if (node.nodeProperties.selectParent) return
        if (pushNodePos(node)) return
        let pos = node.wPos.toM()
        pos = Tz3dTool.getWorldPosToScreenPointer(viewport, pos)
        _rectNodesPos.push([node, pos])
        n += 1
      })
      return n > 0
    }
  }

  /**  @description 是否允许框选选中的回调 {@link Tz3dNode} */
  _callbackRectSelect(node) {
    if (!node.graphic) return
    if (!node.graphic.isTCRObject) return
    return true
  }

  /**  @description 开始拖拽单个node 返回：booleen */
  tryStartDragNode(event) {
    if (!this.z3d.hoverGraphics.length) return false
    if (!this.z3d.hoverGraphics[0].isSelect) return false
    this.nDragNode = this.z3d.hoverGraphics[0].dragNode(this.viewport)
    if (!this.nDragNode) return false
    if (!this.viewport.gizmo.tryStartDragNode(this.nDragNode, event)) return false
    this.state = Tz3dDrawCtrlState.DRAGNODE
    return true
  }

  /**  @description 开始拖拽相机 属于操控相机控制器 返回：booleen */
  tryStartDragCamera() {
    this.state = Tz3dDrawCtrlState.DRAGCAMERA
    return true
  }

  /**  @description 按下键盘按键事件 */
  tryKeyDownBoard(event) {
    let dn = this.dragNode
    if (dn && dn.graphic && dn.graphic.sgdc) {
      dn.graphic.sgdc.onKeyDown(event, this)
    }
  }

  /**  @description 开始qewasd操控相机移动 */
  tryStartKeyBoardMoveCamera() {
    let self = this
    if (self._keyboardMovingCamera) return
    self._keyboardMovingCamera = true
    let r = function() {
      if (!self.keyboardMovingCameraAble || !self.enabled || !self.camCtrl) {
        self._keyboardMovingCamera = false
        return
      }
      self.viewport._onChangeScene()
      self._moving_WSADQE()
      requestAnimationFrame(r)
    }
    r()
  }

  get keyboardMovingCameraAble() {
    if (this.downKeys.hasHelperKey) return false
    return (
      this.downKeys.includes(65) ||
      this.downKeys.includes(68) ||
      this.downKeys.includes(69) ||
      this.downKeys.includes(81) ||
      this.downKeys.includes(83) ||
      this.downKeys.includes(87)
    )
  }

  _moving_WSADQE() {
    let vec, vecN, qua, p, y, s, pos, target, forward
    s = 0.05
    pos = this.camera.position
    target = this.camCtrl.target
    forward = this.camCtrl.transForward
    vec = pos.clone().sub(target)
    vecN = vec.clone().setY(0)
    qua = new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(0, 0, -1), vecN.normalize())
    p = new THREE.Vector3()
    if (this.downKeys.includes(65)) p.x += s + 0.02
    if (this.downKeys.includes(68)) p.x -= s + 0.02
    if (this.downKeys.includes(69)) p.y += s
    if (this.downKeys.includes(81)) p.y -= s
    if (this.downKeys.includes(83)) p.z -= s + 0.02
    if (this.downKeys.includes(87)) p.z += s + 0.02
    y = p.y
    p.y = 0
    p.applyQuaternion(qua)
    p.y += this.camera.isOrthographicCamera ? 0 : y

    if (forward.x > 0) p.set(p.y, p.x, -p.z)
    else if (forward.x < 0) p.set(p.y, -p.x, -p.z)
    else if (forward.z > 0) p.set(-p.x, p.z, p.y)
    else if (forward.z < 0) p.set(-p.x, -p.z, p.y)
    let newtarget = target.clone().add(p)
    if (newtarget.length() > this.camCtrl.maxDistance * 0.5) return
    pos.add(p)
    target.add(p)

    this.viewport.svgNodeRender.update()
    this.viewport.svgTextRender.update()
    this.viewport.svgRulerRender.update()
    if (this.viewport.callbackCameraChange) {
      this.viewport.callbackCameraChange(this.viewport)
    }
    this.render()
  }

  /**  @description 创建拖拽物体 根据 this.onDragCreateInit */
  async tryStartDragCreate(event) {
    let self = this
    if (self.state !== Tz3dDrawCtrlState.NONE) return
    let nDragNode = self.onDragCreateInit ? self.onDragCreateInit(self.viewport) : null
    let dragNodeSp
    if (nDragNode) {
      self.nDragNode = nDragNode
      self.z3d.clearSelection()
      nDragNode.graphic.isSelect = true
      dragNodeSp = nDragNode.wPos
    } else {
      if (self.isDraggingObject) {
        while (self.isDraggingObject && !self.draggingNode) {
          await Tz3dTool.awaitTime(2)
        }
        self.isDraggingObject = false
        self.nDragNode = self.draggingNode
        dragNodeSp = self.draggingNodeStartPos || (self.nDragNode ? self.nDragNode.wPos : null)
        self.draggingNode = null
        self.draggingNodeStartPos = null
      } else return
    }
    if (!self.nDragNode) return

    let pointer = self.viewport.getScreenPointer(event)
    self.viewport.gizmo.setPlan_posY(nDragNode ? 0 : self.nDragNode.wPos.y - self.nDragNode.wSize.y / 2)
    let pos = self.viewport.planeIntersect(pointer, self.viewport.gizmo._plan)
    if (pos) {
      pos = pos.toMM()
      self.nDragNode.wPos.x = pos.x
      self.nDragNode.wPos.z = pos.z
      self.nDragNode.updateRender(true)
    }
    // 多选位置修正
    let dragSpChange = self.nDragNode.wPos.clone().sub(dragNodeSp)
    for (const node of self.viewport.gizmo.dragNodes) {
      if (node.id === self.nDragNode.id) continue
      node.wPos.add(dragSpChange)
      node.updateRender(true)
    }

    if (!self.viewport.gizmo.tryStartDragNode(self.nDragNode, event, self.onDragCreateEnd)) return false
    self.viewport._renderer.domElement.focus()

    this._allowDragCreateEnd = false
    self.state = Tz3dDrawCtrlState.DRAGCREATE
    setTimeout(() => {
      this._allowDragCreateEnd = true
    }, 500)
  }

  /**  @description 连续创建拖拽物体 根据 this.onDragCreateInit */
  tryStartDragCreateContinue(event) {
    this.state = Tz3dDrawCtrlState.NONE
    this.tryStartDragCreate(event)
    this.state = Tz3dDrawCtrlState.DRAGCREATE
  }

  /**  @description 退出画区域选择对象的操作 */
  cancelRectSelect() {
    this._rectNodesPos = []
    this.svgRect.visible = false
    this.z3d.clearSelection()
    this.render()
  }

  /** @description get 获取gizmo的拖拽node */
  get dragNode() {
    return this.viewport.gizmo.dragNode
  }

  /** @description get 是否正在按空格键 */
  get isKeySpace() {
    return this.downKeys.includes(32)
  }

  /** @description 此viewport渲染 {@link Tz3dViewport} */
  render() {
    console.log('渲染viewport22')
    if (this.viewport) {
      this.viewport.render()
    }
  }
}
export { Tz3dDrawCtrl }
