<template>
  <div class="boardindex">
    <div class="boardindex-title">
      <div>添加板件</div>
      <div>
        <el-icon @click="closeWin"><Close /></el-icon>
      </div>
    </div>

      <div class="boardindex-main">
        <ul>
          <li 
          v-for="(item,k) in boardArr" 
          :key="k" 
          :class="active===item.value?'liactive':''"
          @click="getActive(item)">{{ item.title }}</li>
        </ul>
        <div class="boardindex-main-tab">
          <el-scrollbar style="height: calc(90vh - 30px);overflow-y: auto;">
            <!-- 层板 -->
            <layerBoard v-if="active===1"/>
            <!-- 立板 -->
            <standBoard v-if="active===2"/>
            <!-- 背板 -->
            <backBoard v-if="active===3"/>
            <!-- 踢脚板 -->
            <kickBoard v-if="active===4"/>
            <!-- 拉条 -->
            <pullBar v-if="active===5"/>
            <!-- 前挡板 -->
            <frontPanel v-if="active===6"/>
            <!-- 顶挡板 -->
            <topPanel v-if="active===7" />
            <!-- 围板 -->
            <fencePanel v-if="active===8" />
            <!-- 抽屉挡条 -->
            <drawerStopper v-if="active===9"/>
            <!-- 弧形板 -->
            <curvedBoard v-if="active===10"/>
          </el-scrollbar>
        </div>
      </div>

  </div>
</template>

<script setup lang="ts">
import {ref} from 'vue';
import layerBoard from './layer-board.vue';
import standBoard from './stand-board.vue';
import backBoard from './back-board.vue';
import kickBoard from './kick-board.vue';
import pullBar from './pull-bar.vue';
import frontPanel from './front-panel.vue';
import topPanel from './top-panel.vue';
import fencePanel from './fence-board.vue';
import drawerStopper from './drawer-stopper.vue';
import curvedBoard from './curved-board.vue';

const modelgoods = defineModel();
const boardArr = [
  {title: '层板',value:1},
  {title: '立板',value:2},
  {title: '背板',value:3},
  {title: '踢脚板',value:4},
  {title: '拉条',value:5},
  {title: '前挡板',value:6},
  {title: '顶挡板',value:7},
  {title: '围板',value:8},
  {title: '抽屉挡条',value:9},
  {title: '弧形板',value:10},
]
const active = ref(1);
const getActive = (val:any) => {
  active.value = val.value;
}
const closeWin = () => {
  modelgoods.value = -1;
}
</script>

<style scoped lang="less">
.boardindex {
  width: 100%;
  // height: 30px;
  // background: pink;
  &-title {
    width: 100%;
    // height: 50px;
    // background: pink;
    display: flex;
    padding: 6px 10px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F1F1F3;
  }
  &-main {
    width: 100%;
    // padding: 0 10px;
    height: calc(90vh - 30px);
    display: flex;
    ul {
      list-style: none;
      width: 60px;
      height: 100%;
      padding: 5px;
      // background: pink;
      border-right: 1px solid #F1F1F3;
      li {
        width: 100%;
        height: 25px;
        line-height: 25px;
      }
      .liactive {
        color: #409EFF;
      }
    }
    &-tab {
      flex: 1;
    }
  }
}

</style>