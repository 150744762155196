<template>
  <nav>
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
  </nav>
  <router-view/>
</template>

<script setup>
import { onMounted,getCurrentInstance } from 'vue';
import { Tz3d } from '@/core/engine/z3d'
import { TzTool } from '@/core/basic/zTool'


onMounted(()=>{
  TzTool.init();
})
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  div {
    box-sizing: border-box;
  }
  font-size: 12px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
