<!-- /** *@desc 中间渲染区域 *@author llw *@date */ -->

<template>
  <div class="home2d3dMainAreaBox">
    <div class="mainArea" id="mainArea"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch,getCurrentInstance   } from 'vue'

import { Tz3dViewportType } from '@/core/engine/z3d/z3dConst.js'

import { THREE } from '@/core/thirdPart/lib.js'


const instance = getCurrentInstance()
function initZ3d() {
   

      let mainArea = document.getElementById('mainArea')
      let miniArea = document.getElementById('miniArea')
     
      instance.appContext.config.globalProperties.$viewport3d = instance.appContext.config.globalProperties.$z3d.addViewport(mainArea, Tz3dViewportType.VIEW3D)
      instance.appContext.config.globalProperties.$viewport3d.aTitle = 'viewport3d_Main'

      instance.appContext.config.globalProperties.$viewport2d = instance.appContext.config.globalProperties.$z3d.addViewport( miniArea, Tz3dViewportType.VIEW2D)
      instance.appContext.config.globalProperties.$viewport2d.lightVisible = false
      instance.appContext.config.globalProperties.$viewport2d.aTitle = 'viewport2d_Main'
      // this.$viewportMap = this.$z3d.addViewport(document.getElementById('mapArea'), Tz3dViewportType.VIEW2D)
 
    //   this.$store.commit('comandCenter/setHouseFloorDataUpdateNum')
    }

function  newTest() {
      for (let i = 0; i < 2; i++) {
        var geometry = new THREE.BoxBufferGeometry(0.2, 0.2, 0.2)
        var object = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff }))

        object.position.x = Math.random() * 8 - 4
        object.position.y = Math.random() * 8 - 4
        object.position.z = Math.random() * 8 - 4

        object.rotation.x = Math.random() * 2 * Math.PI
        object.rotation.y = Math.random() * 2 * Math.PI
        object.rotation.z = Math.random() * 2 * Math.PI

        object.scale.x = Math.random() + 0.5
        object.scale.y = Math.random() + 0.5
        object.scale.z = Math.random() + 0.5

          console.log('aaaaaa')
        instance.appContext.config.globalProperties.$z3d._scene.add(object)
      }
    }

onMounted(()=>{
 
    initZ3d()
    newTest()
})
</script>
<style lang="less" scoped>
.home2d3dMainAreaBox {
  width: 100%;
  height:800px;
  
  overflow: hidden;
  .mainArea {
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
</style>
