import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  key: 0,
  class: "cad-box"
}
const _hoisted_4 = { class: "demo-image" }
const _hoisted_5 = { class: "dialog-footer" }

import { ref, onMounted, watch,reactive } from 'vue'
import Head from '@/views/head/index.vue'
import Left from '@/views/left/index.vue'
import Right from '@/views/right/index.vue'
import Three3d from "./three3d/index.vue";
import Cad3d from "./cad3d/index.vue";



export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const cadVisible = ref(false)
// let initWidth = ref(0) // 父元素的宽-自适应值
// let initHeight = ref(0) // 父元素的高-自适应值
// let startclientX = ref(0) // 元素拖拽前距离浏览器的X轴位置
// let startclientY = ref(0) //元素拖拽前距离浏览器的Y轴位置
// let elLeft = ref(0) // 元素的左偏移量
// let elTop = ref(0) // 元素的右偏移量
// let back_box = ref()
// // 页面初始化
// function initBodySize() {
//     initWidth.value = back_box.value.clientWidth; // 拿到父元素宽
//     initHeight.value = initWidth.value * ((1080 * 0.88) / (1920 - 1080 * 0.02)); // 根据宽计算高实现自适应
// }
//   // 拖拽开始事件
//   function dragstart(e) {
//     console.log(e);
//     startclientX.value = e.clientX; // 记录拖拽元素初始位置
//     startclientY.value = e.clientY;
//   }
//   // 拖拽完成事件
//   function dragend(e) {
//     console.log(e);
//     let x = e.clientX - startclientX.value; // 计算偏移量
//     let y = e.clientY - startclientY.value;
//     elLeft.value += x; // 实现拖拽元素随偏移量移动
//     elTop.value += y;
//   }
//   onMounted(()=>{
//     initBodySize()

//   })

const dxfUrl = ref('')
const selectIndex = ref(0)
const matArr = ref([
  '/mat2.jpg','/mat3.jpg','/mat5.jpg'
])

const color = ref('#808080')

const form = ref({
  depth:600,
  mat:matArr.value[0]
})
const dialogVisible = ref(false)
const Three3dRef = ref()

const Cad3dRef = ref()

const changeColor = (color)=>{
  console.log('color',color)

}
const changeMat = (mat,index)=>{
  form.value.mat = mat
  selectIndex.value = index
}

const handleCAD = (url)=>{
  cadVisible.value = true
  if(url){
    dxfUrl.value = url
    Three3dRef.value.toParseDXF(url,form.value,color.value)
  }
}



const handleEvent = (type)=>{
  const z3d = Three3dRef.value.getz3d()

  switch(type){
    case 'del':
    case 'clear':
    Three3dRef.value.toDelCurModel(type)
      break;
    case 'back':

    z3d.controls.enableRotate = false
      z3d.ctrl3d.state = 13
      break;
    case 'door':

    z3d.controls.enableRotate = false
    z3d.ctrl3d.state = 3
      break;
    case 'line':

       z3d.controls.enableRotate = false
    z3d.ctrl3d.state = 2
      break;
    case 'undo':
    Three3dRef.value.toUndo()
      break
    case 'redo':
    Three3dRef.value.toRedo()
      break
    default:
    Three3dRef.value.toScaleCab()

  }


  
}

const handleFinishOrder = ()=>{

}

const toDrawPlank = (type)=>{
  const z3d = Three3dRef.value.getz3d()
  z3d.ctrl3d.state = type
  z3d.controls.enableRotate = false
  console.log(type)
}

const handleDxf3d = (val)=>{
  cadVisible.value = false
  if(val){
    Three3dRef.value.parseDxf3d(val,form.value,color.value)
  }
}

onMounted(()=>{
    const paramValue = window.location.search.substring(7)
    // 在控制台中输出参数值
   if(paramValue) localStorage.setItem('3dtoken',paramValue)

  
})

return (_ctx: any,_cache: any) => {
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_header, null, {
            default: _withCtx(() => [
              _createVNode(Head, {
                onImportCAD: handleCAD,
                onFinishOrder: handleFinishOrder,
                onHandleEvent: handleEvent
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_container, { class: "home-main" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_main, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(Three3d, {
                      ref_key: "Three3dRef",
                      ref: Three3dRef
                    }, null, 512)
                  ]),
                  (cadVisible.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(Cad3d, {
                          ref_key: "Cad3dRef",
                          ref: Cad3dRef,
                          onHandleDxf: handleDxf3d,
                          dxfUrl: dxfUrl.value
                        }, null, 8, ["dxfUrl"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_el_aside, { class: "home-right" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_scrollbar, { height: "100%" }, {
                    default: _withCtx(() => [
                      _createVNode(Right, { z3d: Three3dRef.value }, null, 8, ["z3d"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: dialogVisible.value,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((dialogVisible).value = $event)),
      title: "基础配置",
      width: "500",
      draggable: ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_el_button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (dialogVisible.value = false))
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("取消")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (dialogVisible.value = false))
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode(" 确定 ")
            ])),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          model: form.value,
          "label-width": "auto",
          style: {"max-width":"500px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: "柜体深度" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: form.value.depth,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.depth) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "材质" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(matArr.value, (mat, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(["img-box", {'activeSelect':index==selectIndex.value}]),
                      key: index
                    }, [
                      _createVNode(_component_el_image, {
                        style: {"width":"100%","height":"100%"},
                        src: mat,
                        fit: "contain",
                        onClick: ($event: any) => (changeMat(mat,index))
                      }, null, 8, ["src", "onClick"])
                    ], 2))
                  }), 128))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})