
import { THREE } from '@/core/thirdPart/lib.js'
import {Tz3dTool} from './z3dTool'
import {Tz3dCtrlEvent} from './z3dCtrlEvent'
import sky_a from './texture/sky/sky_a.js'
import gp from './texture/gp.js'
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
// import { TextureLoader } from '@/core/engine/three/TextureLoader.js'
import { TextureLoader } from 'three'
import {Tz3dConst} from './z3d/z3dConst.js'
import {Tz3dViewport} from './z3d/z3dViewport.js'

/** Tz3dModelModeType */
// 显示模式 线框 材质
const Tz3dModelModeType = {
    MAT: 0,
    LINE: 1,
    MAT_LINE: 2,
    LINE_TRANSP: 3
  } 
/** Tz3dPublicMaterial */
class Tz3dPublicMaterial {
    constructor(z3d) {
      this.z3d = z3d
  
      // node选中材质
      this.lineMaterial = new LineMaterial({
        color: 0xffffff,
        linewidth: 1, // 0.0018
        depthTest: false,
        depthWrite: false,
        transparent: true
      })
      this.edgeMaterial = new THREE.LineBasicMaterial({
        color: new THREE.Color(0x000000)
      })
      // this.edgeMaterial.polygonOffset = true
      // this.edgeMaterial.polygonOffsetFactor = -0.75
      // this.edgeMaterial.polygonOffsetUnits = -4
      this.collisionMaterial = new THREE.LineBasicMaterial({
        color: new THREE.Color(0x00ff00),
        blending: THREE.AdditiveBlending,
        transparent: true
      })
      this.loadProgressBoxMat = new THREE.MeshBasicMaterial({
        color: new THREE.Color(0x3f77fb),
        opacity: 0.7,
        depthTest: false, // false
        depthWrite: false, // false
        transparent: true
      })
  
      // 标尺材质
      this.rulerMaterial = new THREE.LineBasicMaterial({
        color: 0x666666,
        depthTest: false, // false
        depthWrite: false, // false
        transparent: true
      })
      this.rulerFontMaterial0 = new THREE.MeshBasicMaterial({
        color: 0x111111,
        depthTest: false, // false
        depthWrite: false, // false
        transparent: true,
        fog: false
      })
      this.rulerFontMaterial1 = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        depthTest: false, // false
        depthWrite: false, // false
        transparent: true,
        fog: false
      })
      this.rulerMarkMaterial = new THREE.MeshBasicMaterial({
        color: 0x3388ff,
        depthTest: false, // false
        depthWrite: false, // false
        transparent: true,
        side: THREE.DoubleSide,
        fog: false
      })
    }
  }
  /** Tz3dNodeEffectColor */
  class Tz3dNodeEffectColor {
    constructor() {
      this.effectColor_hover = 0x4affff
      this.effectColor_focus = 0x1074bc
      this.effectColor_select = 0x1074bc
      this.effectColor_subset = 0xffa631 // 杏黄
  
      this.effectEdgeColor_hover = 0x4affff
      this.effectEdgeColor_focus = 0x1074bc
      this.effectEdgeColor_select = 0x1074bc
      this.effectEdgeColor_subset = 0xffa631 // 杏黄
  
      this.effectColor_lock = 0xbbbbbb
    }
  
    copy(src) {
      this.effectColor_hover = src.effectColor_hover
      this.effectColor_focus = src.effectColor_focus
      this.effectColor_select = src.effectColor_select
      this.effectColor_subset = src.effectColor_subset
  
      this.effectEdgeColor_hover = src.effectEdgeColor_hover
      this.effectEdgeColor_focus = src.effectEdgeColor_focus
      this.effectEdgeColor_select = src.effectEdgeColor_select
      this.effectEdgeColor_subset = src.effectEdgeColor_subset // 杏黄
  
      this.effectColor_lock = src.effectColor_lock
    }
  }
/** Tz3d */
class Tz3d {
    /** 注释
    * three.js
    * @property {THREE.Scene}  _scene             - 总场景
    * @property {THREE.Scene}  _nodeScene       - node场景
    * @property {TzEvent}  event               - 事件
    * @property {TzSceneUI}  zSceneUI               - 数据UI层
    * @property {Tz3dViewport[]}  viewports               - 视口数组
    * @property {Tz3dPublicMaterial}  publicMaterial               - 公共材质库
    * @property {Tz3dNodeEffectColor}  nodeEffectColor               - node 覆盖选中聚焦颜色库
    * @property {TzGraphic[]}  hoverGraphics               -  覆盖对象图形数组
    * @property {TzGraphic[]}  selectGraphics               - 选中对象图形数组
    * @property {booleen}  isMultiSelect               - 对象图形是否多选
  
    * @property {Tz3dCache} cache                    - cache 缓存
    * @param {TzSceneUI} zSceneUI 数据UI层
    */
    constructor(zSceneUI) {
      // three.js
      this._scene = null
      this._nodeScene = null
      this._envMap = null
      this._gridHelper = null
  
      // custom
      this.zSceneUI = zSceneUI
      this.viewports = []
  
      this.viewTextures = 1
  
      this._nextId = 0
  
   
  
      this.publicMaterial = new Tz3dPublicMaterial(this)
      this.nodeEffectColor = new Tz3dNodeEffectColor()
      this.font = null
  
      // node
      this.hoverGraphics = []
      this.selectGraphics = []
      this.isMultiSelect = false
  
      // cache
      this.cache = null
      this.ctrlEvent = null
  
      this._init()
    }
  
    get event() {
      if (!this._event) {
        this._event = new TzEvent()
      }
      return this._event
    }
  
    free() {}
  
    /** @description 通过id选中node
     * @param {number} id Tz3dNode.id
     */
    getNodeById(id) {
      for (let viewport of this.viewports) {
        let n = viewport.getNodeById(id)
        if (n) return n
      }
      return null
    }
  
    /** @description 设置2D对象是否可见
     * @param {boolean} visible  是否可见
     */
    setSvgNodeVisible(visible) {
      this.viewports.forEach(viewport => {
        if (visible) {
          viewport.svgNodeRender.showAll()
        } else {
          viewport.svgNodeRender.hideAll()
        }
      })
    }
  
    /** @description 设置2D文字是否可见
     * @param {boolean} visible  是否可见
     */
    setSvgTextVisible(visible) {
      this.viewports.forEach(viewport => {
        if (visible) {
          viewport.svgTextRender.showAll()
        } else {
          viewport.svgTextRender.hideAll()
        }
      })
    }
  
    /** @description 设置2D标尺是否可见
     * @param {boolean} visible  是否可见
     */
    setSvgRulerVisible(visible) {
      this.viewports.forEach(viewport => {
        if (visible) {
          viewport.svgRulerRender.showAll()
        } else {
          viewport.svgRulerRender.hideAll()
        }
      })
    }
  
    /** @description 清除所有选中对象 */
    clearSelection() {
      let old = this.focusGraphic
      if (!old) return
      let graphics = Array.from(this.selectGraphics)
      graphics.forEach(graphic => {
        graphic.focusNode = null
        graphic.isSelect = false
      })
      this.selectGraphics = []
      this.event.fire('onFocusGraphicChange', old, null)
    }
  
    /** @description 设置所有选中对象的效果是否显示 */
    setSelectionEffectVisible(v) {
      v = !!v
      this.selectGraphics.forEach(graphic => {
        graphic.effectVisible = v
      })
      this.viewports.forEach(viewport => {
        viewport.gizmo.effectVisible = v
      })
    }
  
    get focusGraphic() {
      return this.selectGraphics.length ? this.selectGraphics[this.selectGraphics.length - 1] : null
    }
  
    /** @description  getset: 整个场景的选中聚焦对象图形 */
    set focusGraphic(v) {
      let old = this.focusGraphic
      if (old === v) return
      if (v) {
        v.isSelect = true
      } else {
        this.clearSelection()
      }
    }
  
    get focusNode() {}
  
    _init() {
      this._initPrototype()
      this._initScene()
    }
  
    _initPrototype() {
      Tz3dTool.init()
    
      this.cache = Tz3dTool.z3dCache
      this.ctrlEvent = new Tz3dCtrlEvent(this)
    }
  

  
    _initScene() {
      this._scene = new THREE.Scene()
      // this._scene.background = new THREE.Color(Tz3dConst._viewBackgroundColor)
      this._scene.add((this._nodeScene = new THREE.Scene()))
      this.initEnvMap()
      this.initLight()
    }
  
    setLightVisible(layerId = -1, v) {
      if (layerId > -1) {
        if (v) {
          this._light0.layers.enable(layerId)
          this._light1.layers.enable(layerId)
          this._light2.layers.enable(layerId)
          this._light3.layers.enable(layerId)
          this._light4.layers.enable(layerId)
          this._lightH.layers.enable(layerId)
        } else {
          this._light0.layers.disable(layerId)
          this._light1.layers.disable(layerId)
          this._light2.layers.disable(layerId)
          this._light3.layers.disable(layerId)
          this._light4.layers.disable(layerId)
          this._lightH.layers.disable(layerId)
        }
      }
    }
  
    initLight(layerId = -1) {
      if (!this._light0) {
        this._light0 = new THREE.DirectionalLight(0xffffff, 0.36)
        this._light0.position.copy(Tz3dConst._light0_position)
        this._scene.add(this._light0)
        this._light1 = new THREE.DirectionalLight(0xffffff, 0.35)
        this._light1.position.copy(Tz3dConst._light1_position)
        this._scene.add(this._light1)
        this._light2 = new THREE.DirectionalLight(0xffffff, 0.34)
        this._light2.position.copy(Tz3dConst._light2_position)
        this._scene.add(this._light2)
        this._light3 = new THREE.DirectionalLight(0xffffff, 0.28)
        this._light3.position.copy(Tz3dConst._light3_position)
        this._scene.add(this._light3)
        this._light4 = new THREE.DirectionalLight(0xffffff, 0.41)
        this._light4.position.copy(Tz3dConst._light4_position)
        this._light4.castShadow = true
        this._light4.shadow.mapSize.width = 1024
        this._light4.shadow.mapSize.height = 1024
        this._light4.shadow.camera.near = 0.1
        this._light4.shadow.camera.far = 200
        this.setShadowPosition(0, 0)
        this._scene.add(this._light4)
        this._lightH = new THREE.HemisphereLight(0xffffff, 0xeeeeee, 0.56)
        this._lightH.position.copy(Tz3dConst._lightH_position)
        this._scene.add(this._lightH)
  
        this._light0.layers.set(31)
        this._light1.layers.set(31)
        this._light2.layers.set(31)
        this._light3.layers.set(31)
        this._light4.layers.set(31)
        this._lightH.layers.set(31)
      }
      this.setLightVisible(layerId, true)
    }
  
    setShadowPosition(x, z) {
      if (!this._light4) return
      if (this._light4.posX === x && this._light4.posZ === z) return
      this._light4.posX = x
      this._light4.posZ = z
      const d = 10
      this._light4.shadow.camera.left = -d + x
      this._light4.shadow.camera.right = d + x
      this._light4.shadow.camera.top = d - z
      this._light4.shadow.camera.bottom = -d - z
      this._light4.shadow.camera.updateProjectionMatrix()
    }
  
    initGridHelper(layerId, size, divisions, color1, color2) {
      if (this._gridHelper == null) {
        this._gridHelper = Tz3dTool.createGridHelper(size, divisions, color1, color2)
        this._gridHelper.layers.set(31)
        this._gridHelper.position.y -= Tz3dConst._gridHelper_position_y
        this._scene.add(this._gridHelper)
      }
      if (layerId > -1) this._gridHelper.layers.enable(layerId)
    }
  
    initGridPlaneBasic(layerId, size) {
      if (!this._gridPlaneBasic) {
        size = size || Tz3dConst._gridPlane_size
        let geo = new THREE.PlaneBufferGeometry(size, size, 1, 1)
        geo.shapeTo3d()
        let tex = new TextureLoader().load(gp.dataUrl)
        tex.wrapS = THREE.RepeatWrapping
        tex.wrapT = THREE.RepeatWrapping
        tex.repeat.set(50, 50)
        this._gridPlaneBasic = new THREE.Mesh(
          geo,
          new THREE.MeshBasicMaterial({
            color: new THREE.Color(0xcccccc),
            map: tex,
            transparent: true
          })
        )
        this._gridPlaneBasic.receiveShadow = true
        this._gridPlaneBasic.layers.set(31)
        this._gridPlaneBasic.position.y -= Tz3dConst._gridPlane_position_y
        this._scene.add(this._gridPlaneBasic)
      }
      if (layerId > -1) this._gridPlaneBasic.layers.enable(layerId)
    }
  
    initGridPlaneStandard(layerId, size) {
      if (!this._gridPlaneStandard) {
        size = size || Tz3dConst._gridPlane_size
        let geo = new THREE.PlaneBufferGeometry(size, size, 1, 1)
        geo.shapeTo3d()
        let tex = new TextureLoader().parse(gp.dataUrl)
        tex.wrapS = THREE.RepeatWrapping
        tex.wrapT = THREE.RepeatWrapping
        tex.repeat.set(50, 50)
        this._gridPlaneStandard = new THREE.Mesh(
          geo,
          new THREE.MeshStandardMaterial({
            color: new THREE.Color(0xcccccc),
            map: tex,
            transparent: true
          })
        )
        this._gridPlaneStandard.receiveShadow = true
        this._gridPlaneStandard.layers.set(31)
        this._gridPlaneStandard.position.y -= Tz3dConst._gridPlane_position_y
        this._scene.add(this._gridPlaneStandard)
      }
      if (layerId > -1) this._gridPlaneStandard.layers.enable(layerId)
    }
  
    initEnvMap(layerId = -1) {
      let self = this
      if (!self._cubeBox) {
        let tex = new TextureLoader().load(sky_a.dataUrl) // .load('src/core/engine/texture/sky/sky_a.jpg')
        let cubeGeo = new THREE.SphereBufferGeometry(Tz3dConst._cubeBox_size, 108, 108)
        self._cubeBox = new THREE.Mesh(
          cubeGeo,
          new THREE.MeshBasicMaterial({
            color: new THREE.Color(0xffffff),
            map: tex,
            side: THREE.DoubleSide
          })
        )
        self._cubeBox.layers.set(31)
        self._scene.add(self._cubeBox)
      }
      if (layerId > -1) self._cubeBox.layers.enable(layerId)
      return self
    }
  
 
  
    /** @description 返回： 唯一Id */
    getNextId() {
      return ++this._nextId
    }
  
    /** @description 返回：1-30空置 否则 为 0 viewport的层级 Id */
    getlayerId() {
      let layerIds = []
      for (let viewport of this.viewports) {
        layerIds.push(viewport.layerId)
      }
      for (let i = 1; i < 31; i++) {
        if (!layerIds.includes(i)) return i
      }
      return 0
    }
  
    /** @description 添加视口 返回：{@link Tz3dViewport}
     * @param {div} canvas 渲染目标画布
     * @param {Tz3dViewportType} viewportType 视口类型
     * @returns {Tz3dViewport}
     */
    addViewport(canvas, viewportType) {
      console.log('初始化viewport')
      let viewport = new Tz3dViewport(canvas, viewportType, this)
      this.viewports.push(viewport)
      return viewport
    }
  
    /** @description 渲染所有视口 */
    render() {
      console.log('渲染viewport')
      for (let viewportName in this.viewports) {
        this.viewports[viewportName].render()
      }
    }
  
    /** @description 根据视口层级id获取 {@link Tz3dViewport} 可能为空
     * @param {int} layerId 视口图层Tz3dViewport.layerId
     * @returns {Tz3dViewport}
     */
    getViewport(layerId) {
      console.log('getViewport',layerId)
      for (let viewport of this.viewports) {
        if (viewport.layerId === layerId) return viewport
      }
      return null
    }
  
    /** @description 根据视口层级id数组获取 {@link Tz3dViewport} 可能为空 */
    getViewports(layerIds) {
      let viewports = []
      for (let viewport of this.viewports) {
        if (layerIds.indexOf(viewport.layerId) !== -1) viewports.push(viewport)
      }
      return viewports
    }
  
    /** @description 根据视口层级id获取 {@link Tz3dViewport} 可能为空 */
    findViewport(layerId) {
      return this.getViewport(layerId)
    }
  
    clearViewTextures() {
      for (let i = this.viewTextures.items.length - 1; i >= 0; i--) {
        this.viewTextures.items[i].free()
      }
    }
  }

  export {Tz3d,Tz3dModelModeType}
