import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "design-right" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "navtab" }
const _hoisted_4 = { class: "center-main" }
const _hoisted_5 = { class: "formitem" }
const _hoisted_6 = { class: "formitem" }
const _hoisted_7 = { class: "formitem" }
const _hoisted_8 = { class: "formitem" }

import { ref,reactive,watch} from 'vue'
import type { TabsPaneContext,TabsInstance  } from 'element-plus'

import { ElMessage, ElMessageBox } from 'element-plus'


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    z3d: {}
  },
  setup(__props: any) {

const curNode = ref<any>()



const props = __props;
const activeName = ref('first')
const form = ref({
  name: '',
  width:'', // 宽
  length:'', // 深
  thickness:'' // 厚
})
const tabPosition = ref<TabsInstance['tabPosition']>('left')
const handleClick = (tab: TabsPaneContext, event: Event) => {
  console.log(tab, event)
}

const extendDir =ref({
  L_R:'left',
  F_B:'front',
  T_B:'top'

})

const handleChange = (type)=>{

}



return (_ctx: any,_cache: any) => {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[23] || (_cache[23] = _createElementVNode("div", { class: "title" }, "楼层属性", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_tabs, {
          modelValue: activeName.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((activeName).value = $event)),
          class: "demo-tabs",
          onTabClick: handleClick
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, {
              label: "参数设置",
              name: "first"
            }),
            _createVNode(_component_el_tab_pane, {
              label: "风格替换",
              name: "second"
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[21] || (_cache[21] = _createElementVNode("div", { class: "tittop" }, [
          _createElementVNode("span", null, "基础参数"),
          _createElementVNode("span", null, "icon")
        ], -1)),
        _createVNode(_component_el_form, {
          model: form.value,
          "label-width": "auto",
          size: "small"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: "名称" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_el_input, {
                    modelValue: form.value.name,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.name) = $event)),
                    disabled: "",
                    style: {"flex":"1"}
                  }, null, 8, ["modelValue"]),
                  _cache[11] || (_cache[11] = _createElementVNode("span", null, "mm", -1))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "宽度" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_el_radio_group, {
                    modelValue: extendDir.value.L_R,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((extendDir.value.L_R) = $event)),
                    style: {"margin-right":"10px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_radio_button, { value: "left" }, {
                        default: _withCtx(() => _cache[12] || (_cache[12] = [
                          _createTextVNode("左")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_el_radio_button, { value: "right" }, {
                        default: _withCtx(() => _cache[13] || (_cache[13] = [
                          _createTextVNode("右")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_el_input, {
                    modelValue: form.value.width,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.width) = $event)),
                    style: {"flex":"1"},
                    onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (handleChange(extendDir.value.L_R)), ["enter"]))
                  }, null, 8, ["modelValue"]),
                  _cache[14] || (_cache[14] = _createElementVNode("span", null, "mm", -1))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "深度" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_el_radio_group, {
                    modelValue: extendDir.value.F_B,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((extendDir.value.F_B) = $event)),
                    style: {"margin-right":"10px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_radio_button, { value: "front" }, {
                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                          _createTextVNode("前")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_el_radio_button, { value: "back" }, {
                        default: _withCtx(() => _cache[16] || (_cache[16] = [
                          _createTextVNode("后")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_el_input, {
                    modelValue: form.value.length,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((form.value.length) = $event)),
                    style: {"flex":"1"},
                    onKeyup: _cache[7] || (_cache[7] = _withKeys(($event: any) => (handleChange(extendDir.value.F_B)), ["enter"]))
                  }, null, 8, ["modelValue"]),
                  _cache[17] || (_cache[17] = _createElementVNode("span", null, "mm", -1))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "厚度" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_el_radio_group, {
                    modelValue: extendDir.value.T_B,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((extendDir.value.T_B) = $event)),
                    style: {"margin-right":"10px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_radio_button, { value: "top" }, {
                        default: _withCtx(() => _cache[18] || (_cache[18] = [
                          _createTextVNode("上")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_el_radio_button, { value: "bottom" }, {
                        default: _withCtx(() => _cache[19] || (_cache[19] = [
                          _createTextVNode("下")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_el_input, {
                    modelValue: form.value.thickness,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((form.value.thickness) = $event)),
                    disabled: "",
                    style: {"flex":"1"},
                    onKeyup: _cache[10] || (_cache[10] = _withKeys(($event: any) => (handleChange(extendDir.value.T_B)), ["enter"]))
                  }, null, 8, ["modelValue"]),
                  _cache[20] || (_cache[20] = _createElementVNode("span", null, "mm", -1))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _cache[22] || (_cache[22] = _createElementVNode("div", { class: "center-main" }, null, -1))
    ])
  ]))
}
}

})