/*
 * @Author: your name
 * @Date: 2021-05-11 10:32:05
 * @LastEditTime: 2021-06-25 15:13:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \bSpaceDesign\src\core\engine\z3d\z3dCameraState.js
 */
/** Tz3dCameraState */
class Tz3dCameraState {
  constructor(cameraCtrl) {
    this._cameraCtrl = cameraCtrl
    this._states = []
  }

  get viewport() {
    return this._cameraCtrl.viewport
  }

  get camCtrl() {
    return this._cameraCtrl.camCtrl
  }

  get camera() {
    return this._cameraCtrl.camera
  }

  get _state() {
    let state = {}
    state.minDistance = this.camCtrl.minDistance
    state.maxDistance = this.camCtrl.maxDistance
    state.minPolarAngle = this.camCtrl.minPolarAngle
    state.maxPolarAngle = this.camCtrl.maxPolarAngle
    state.mouseButtons = this.camCtrl.mouseButtons
    state.enableZoom = this.camCtrl.enableZoom
    state.enablePan = this.camCtrl.enablePan
    state.enableRotate = this.camCtrl.enableRotate
    state.rotateSpeed = this.camCtrl.rotateSpeed
    state.panSpeed = this.camCtrl.panSpeed
    state.polarLevelAngle = this.camCtrl.polarLevelAngle
    state.fov = this.camera.fov
    return state
  }

  get currState() {
    return this._states[this._states.length - 1]
  }

  getState(index = 0) {
    return this._states[index]
  }

  saveTrans() {
    let currState = this.currState
    if (currState) {
      currState.saveTrans = true
      currState.target = this.camCtrl.target.clone()
      currState.position = this.camera.position.clone()
      currState.transForward = this.camCtrl.transForward.clone()
    }
  }

  restoreTrans(index = -1) {
    let state = this.getState(index)
    if (state && state.saveTrans) {
      this.camCtrl.target.copy(state.target)
      this.camera.position.copy(state.position)
      this.camCtrl.transForward.copy(state.transForward)
    }
  }

  saveZoom() {
    let currState = this.currState
    if (currState) {
      currState.viewZoom = this.viewport.viewZoom
    }
  }

  restoreZoom(index = -1) {
    let state = this.getState(index)
    if (state && state.viewZoom !== null) {
      this.viewport.viewZoom = state.viewZoom
    }
  }

  saveState(index = 0) {
    if (index < 0 || !this._states.length || this._states.length < index) {
      this._states.push(this._state)
    } else {
      this._states[index] = this._state
    }
  }

  removeState(index = -1) {
    index = index < 0 ? this._states.length - 1 : index
    if (index < this._states.length) {
      this._states.splice(index, 1)
    }
  }

  applyState(index = -1, isMinDistance) {
    if (!this._states.length) return
    index = index < 0 ? this._states.length - 1 : index
    let state = this._states[index]
    if (isMinDistance) this.camCtrl.minDistance = state.minDistance
    this.camCtrl.maxDistance = state.maxDistance
    this.camCtrl.minPolarAngle = state.minPolarAngle
    this.camCtrl.maxPolarAngle = state.maxPolarAngle
    this.camCtrl.mouseButtons = state.mouseButtons
    this._cameraCtrl.enableZoom = state.enableZoom
    this._cameraCtrl.enablePan = state.enablePan
    this._cameraCtrl.enableRotate = state.enableRotate
    this.camCtrl.rotateSpeed = state.rotateSpeed
    this.camCtrl.panSpeed = state.panSpeed
    this.camCtrl.polarLevelAngle = state.polarLevelAngle
    this.camera.fov = state.fov
    this.restoreTrans(index)
    this.restoreZoom(index)

    this.camCtrl.update()
  }
}
export { Tz3dCameraState }
