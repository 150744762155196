<template>
  <div v-show="visible" id="drag">
    <div class="back_box" ref="back_box">

      <div class="drag_box" draggable="true" @dragstart="dragstart($event)" @dragend="dragend($event)"
        :style="`left:${elLeft}px;top:${elTop}px`">
        <div class="drag-title">
          自由建柜
        </div>
        <el-scrollbar style="height: calc(90vh - 80px);overflow-y: auto; margin-left: 10px;">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item title="抽屉参数" name="1">
              <div class="drag-item">
                <div class="drag-item-title">对齐方式</div>
                <el-select v-model="form.way" placeholder="Select" size="small" class="label-width" @change="changeWay">
                  <el-option v-for="item in wayArr" :key="item.type" :label="item.label" :value="item.type" />
                </el-select>
              </div>

              <div v-if="form.way == '2'" class="drag-item">
                <div class="drag-item-title">抽屉数量</div>
                <el-input v-model="form.nums" type="number" :min="1" size="small" class="label-width" @blur="changeNums"
                  placeholder="Please input" />
              </div>

              <div class="drag-item">
                <div class="drag-item-title">抽屉高度</div>
                <el-input v-model="form.height" type="number" :min="10" size="small" class="label-width" @blur="changeHeight"
                  placeholder="Please input" />
              </div>
            </el-collapse-item>

            <el-collapse-item title="掩盖方式" name="2">
              <div class="drag-way">
                <div class="drag-way-title">掩门整体</div>
                <div class="drag-way-content">
                  <div class="drag-way-subcontent">
                    <el-select v-model="form.cover" placeholder="Select" size="small" class="label-width1" @change="changeCover">
                      <el-option v-for="item in coverArr" :key="item.type" :label="item.label" :value="item.type" />
                    </el-select>
                    <div class="drag-way-desc">掩盖方式</div>
                  </div>

                  <div class="drag-way-subcontent">
                    <el-input v-model="form.height" type="number" :min="0" size="small" class="label-width1"
                      placeholder="Please input" />
                    <div class="drag-way-desc">缝隙值</div>
                  </div>

                </div>
              </div>

              <div class="drag-way" v-for="coverItem in coverData">
                <div class="drag-way-title">{{ coverItem.title }}</div>
                <div class="drag-way-content">
                  <div class="drag-way-subcontent">
                    <el-select v-model="coverItem.coverType" placeholder="Select" size="small" class="label-width1">
                      <el-option v-for="item in coverArr" :key="item.type" :label="item.label" :value="item.type" />
                    </el-select>
                    <div class="drag-way-desc">掩盖方式</div>
                  </div>

                  <div class="drag-way-subcontent">
                    <el-input v-model="coverItem.coverValue" type="number" :min="0" size="small" class="label-width2"
                      placeholder="Please input" />
                    <div class="drag-way-desc">掩盖值</div>
                  </div>

                  <div class="drag-way-subcontent">
                  <el-input v-model="coverItem.gapValue" type="number" :min="0" size="small" class="label-width2"
                    placeholder="Please input" />
                  <div class="drag-way-desc">缝隙值</div>
                </div>

                </div>

             

              </div>

            </el-collapse-item>
            <el-collapse-item v-if="form.cover === '1'" title="挡板参数" name="3">
              <div class="drag-item">
                <div class="drag-item-title">抽屉挡板</div>
                <el-radio-group v-model="form.isBaff">
                  <el-radio value="1" size="small">有</el-radio>
                  <el-radio value="2" size="small">无</el-radio>
                </el-radio-group>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-scrollbar>


        <div class="drag-bottom">
          <el-button @click="cancelDrawer">取消</el-button>
          <el-button type="primary"  @click="handleDrawer">确定</el-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineEmits,defineProps, onMounted } from 'vue'
import { Plus } from '@element-plus/icons-vue'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  boxHeight:{
    type: Number,
    default: 0
  }

})
const wayArr = ref([{ type: '1', label: '自由摆放' }, { type: '2', label: '填充' }, { type: '3', label: '下对齐' }, { type: '4', label: '上对齐' }])
const coverArr = ref([{ type: '1', label: '内嵌' }, { type: '2', label: '半盖' }, { type: '3', label: '全盖' }])
const coverData = ref([
  { type: '1', title: '左掩',coverType:'1',coverValue:0,gapValue:0 }, 
{ type: '2', title: '右掩',coverType:'1',coverValue:0,gapValue:0 },
{ type: '3', title: '上掩',coverType:'1',coverValue:0,gapValue:0 },
{ type: '4', title: '下掩',coverType:'1',coverValue:0,gapValue:0 },

])
const genForm = ()=>{
  return {
  name: '',
  way: '1',
  height: 100,
  nums:1,
  cover: '1',
  isBaff: '1'

}
}
const form = ref(genForm())
const activeNames = ref(['1'])
const handleChange = (val: string[]) => {
  console.log(val)
}

const changeCover = (val: string) => {
  coverData.value.forEach(item=>{
    item.coverType = val
  })


}
const emits = defineEmits(['drawDrawer','paramsDrawer' ])

const cancelDrawer = () => {
 
 emits('drawDrawer',[])
 form.value = genForm()
}
const handleDrawer = () => {
 
  emits('drawDrawer',form.value)
  form.value = genForm()
}

const changeWay = (val)=>{
  if(val==='2') form.value.height = props.boxHeight
  else form.value.height = 100
  emits('paramsDrawer',{way:val,nums:1,height:form.value.height })
}

const changeNums = ()=>{
  form.value.height = props.boxHeight/form.value.nums
  // emits('paramsDrawer',{way:form.value.way,height:form.value.height,nums:form.value.nums})
}

const changeHeight = ()=>{

  emits('paramsDrawer',{way:form.value.way,height:form.value.height,nums:form.value.nums})
}





let initWidth = ref(0) // 父元素的宽-自适应值
let initHeight = ref(0) // 父元素的高-自适应值
let startclientX = ref(0) // 元素拖拽前距离浏览器的X轴位置
let startclientY = ref(0) //元素拖拽前距离浏览器的Y轴位置
let elLeft = ref(0) // 元素的左偏移量
let elTop = ref(0) // 元素的右偏移量
let back_box = ref()
// 页面初始化
function initBodySize() {
  initWidth.value = back_box.value.clientWidth; // 拿到父元素宽
  initHeight.value = initWidth.value * ((1080 * 0.88) / (1920 - 1080 * 0.02)); // 根据宽计算高实现自适应
}
// 拖拽开始事件
function dragstart(e) {
  console.log(e);
  startclientX.value = e.clientX; // 记录拖拽元素初始位置
  startclientY.value = e.clientY;
}
// 拖拽完成事件
function dragend(e) {
  console.log(e);
  let x = e.clientX - startclientX.value; // 计算偏移量
  let y = e.clientY - startclientY.value;
  elLeft.value += x; // 实现拖拽元素随偏移量移动
  elTop.value += y;
}
onMounted(() => {
  initBodySize()
})
</script>

<style scoped lang="less">
.label-width {
  width: 120px;
}

.label-width1 {
  width: 110px;
}

.label-width2 {
  width: 50px;
}
.back_box {
  background: #ccc;
  /* width: 200px;
  height: 50vh;
  position: fixed; */
  position: absolute;
  top: 50px;
  left: 10px;
}


.drag_box {
  width: 250px;
  height: 90vh;
  background: #ffff;
  position: absolute;
  z-index: 10;
  user-select: none;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
}

.drag-bottom {
  position: absolute;
  width: 250px;
  height: 50px;
  bottom: 0;
  left: 0;
  padding: 10px;
  border-top: 1px solid #ccc;
  display: flex;
  align-items: center;
  background: white;
  display: flex;
  justify-content: right;
}

.drag-title {
  width: 100%;
  height: 30px;
  padding: 5px 10px;
  background: #F7F7F8;
  font-size: 14px;
}

.drag-item {
  margin: 0 5px 5px 0;
  display: flex;
  justify-content: space-between;

  &-title {
    margin-bottom: 10px;
  }

  .plus-but {
    width: 100%;
    margin-bottom: 10px;
  }

  .shuangji {
    width: 100%;
    text-align: center;
  }

  .box-butts {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

    .boxbut {
      padding: 5px 10px;
      // background: #E3EFFE;
      background: #F5F5F6;
      border-radius: 2px;
      text-align: center;
    }

    .boxbutactive {
      background: #E3EFFE;
      color: #409EFF;
    }
  }

  .box-opt {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

    .boxbox {
      padding: 10px;
      height: 120px;
      // background: #E3EFFE;
      background: #F5F5F6;
      border-radius: 2px;
      text-align: center;

      img {
        width: 80%;
        margin-bottom: 10px;
      }

      .t1 {
        margin-bottom: 10px;
        text-align: center;
      }

      .t2 {
        width: 16px;
        background: #EDEDEF;
        padding: 2px 0;
        margin: 0 auto;
        border-radius: 2px;
      }
    }

    .boxboxactive {
      background: #E3EFFE;
      // color: #409EFF;
    }
  }
}

.drag-way {
  margin: 0 5px 5px 0;
  display: flex;
  flex-direction: column;

  &-desc {
    margin-left: 5px;
    color:#ccc
  }

  &-content {
    display: flex;
    justify-content: space-between;
  }
  &-subcontent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }


}
</style>