<template>
  <!-- 弧形板 -->
  <div class="layer-board">
    <!-- 生成方式 -->
    <div class="common-set-title" @click="show.one=!show.one">
      <span class="mr5">生成方式</span>
      <el-icon v-if="show.one"><CaretBottom /></el-icon>
      <el-icon v-else><CaretTop /></el-icon>
    </div>
    <div v-if="show.one" class="common-set-content">
      
      <div class="common-board-content">
        <div class="commonset">
          <el-radio-group v-model="radio1" size="small">
            <el-radio value="1">选面</el-radio>
            <el-radio value="2">选边</el-radio>
            <el-radio value="3">内空</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <!-- 生成方式 -->
    <!-- 生成类型 -->
    <div class="common-set-title" @click="show.two=!show.two">
      <span class="mr5">生成类型</span>
      <el-icon v-if="show.two"><CaretBottom /></el-icon>
      <el-icon v-else><CaretTop /></el-icon>
    </div>
    <div v-if="show.two" class="common-set-content">
      
      <div class="common-board-content">
        <div class="commonset">
          <el-radio-group v-model="radio1" size="small">
            <el-radio value="1">板件</el-radio>
            <el-radio value="2">门板</el-radio>
          </el-radio-group>
        </div>
        
      </div>
    </div>
    <!-- 生成类型 -->
    <!-- 参数 -->
    <div class="common-set-title" @click="show.three=!show.three">
      <span class="mr5">参数</span>
      <el-icon v-if="show.three"><CaretBottom /></el-icon>
      <el-icon v-else><CaretTop /></el-icon>
    </div>
    <div  v-if="show.three" class="common-set-content">
      <div class="common-board-content">
        <div class="commonset">
          <div class="commonset-tit3">厚度</div>
          <el-input-number class="common-input-width" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
        </div>
        <div class="commonset">
          <div class="commonset-tit3">缩进</div>
          <el-input-number class="common-input-width" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
        </div>
        <div class="commonset">
          <div class="commonset-tit3">位置</div>
          <el-radio-group v-model="radio1" size="small">
            <el-radio value="1">外盖</el-radio>
            <el-radio value="2">内嵌</el-radio>
          </el-radio-group>
        </div>
        <div class="commonset">
          <div class="commonset-tit3">延申</div>
          <el-input-number class="common-input-width4 mr5" size="small" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
          <el-input-number class="common-input-width4 mr5" size="small" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
          <el-input-number class="common-input-width4 mr5" size="small" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
          <el-input-number class="common-input-width4 mr5" size="small" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
        </div>
      </div>
    </div>
    <!-- 参数 -->
    <!-- 生产信息 -->
    <div class="common-set-title" @click="show.four=!show.four">
      <span class="mr5">生产信息</span>
      <el-icon v-if="show.four"><CaretBottom /></el-icon>
      <el-icon v-else><CaretTop /></el-icon>
    </div>
    <div v-if="show.four" class="common-set-content">
      <div class="common-board-content">
        <div class="commonset">
          <div class="commonset-tit3">槽深</div>
          <el-input-number class="common-input-width" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
        </div>
        <div class="commonset">
          <div class="commonset-tit3">槽宽</div>
          <el-input-number class="common-input-width" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
        </div>
        <div class="commonset">
          <div class="commonset-tit3">槽间距</div>
          <el-input-number class="common-input-width" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
        </div>
        <div class="commonset">
          <div class="commonset-tit3">板件加长</div>
        <span class="mr5">D1</span><el-input-number class="common-input-width6 mr5" size="small" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
        <span class="mr5">D2</span><el-input-number class="common-input-width6" size="small" v-model="num" :min="0" :step="0.1" :precision="1" :controls="false" controls-position="right"/>
        </div>
        <div class="commonset">
          <div class="commonset-tit3">五金安装方案</div>
          <div class="common-input-width">
            <el-select
            v-model="value"
            placeholder="请选择"
      
          >
            <el-option label="三合一方案" :value="1"></el-option>
          </el-select>
          </div>
        </div>
        <div class="commonset">
          <div class="commonset-tit3">连接件方案方向</div>
          <div class="common-input-width">
            <el-select
            v-model="value"
            placeholder="请选择"
      
          >
            <el-option label="全部不打" :value="1"></el-option>
          </el-select>
          </div>
        </div>
        <div class="commonset">
          <div class="commonset-tit3">连接件被安装</div>
          <el-checkbox />
        </div>
      </div>
    </div>
    <!-- 生产信息 -->
    <!-- 板材 -->
    <div class="common-set-title" @click="show.five=!show.five">
      <span class="mr5">板材</span>
      <el-icon v-if="show.five"><CaretBottom /></el-icon>
      <el-icon v-else><CaretTop /></el-icon>
    </div>
    <div v-if="show.five" class="common-set-content">
      <div class="common-board-content">
        <div class="commonset">
          <div class="commonset-tit3">基材</div>
          <div class="common-input-width">
            <el-select
              v-model="value"
              placeholder="基材"
       
            >
              <el-option label="多层板" :value="1"></el-option>
            </el-select>
          </div>
        </div>
        <div class="commonset">
          <div class="commonset-tit3">纹理</div>
          <div class="common-input-width">
           
            <el-select
            class="imgselect"
            v-model="value1"
            placeholder="纹理"
            @change="changeSelection"
            ref="layboardselect"
            >
              <el-option
              v-for="(va,vak) in valueArr"
              :key="vak"
              :label="va.text"
              :value="va.id"
              >
              <div style="display: flex;align-items: center;">
                <img
                  :src="va.src"
                  alt=""
                  width="30px"
                  height="30px"
                />
          
                <span>{{ va.text }}</span>
              </div>
               
              </el-option>
              
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <!-- 板材 -->
  </div>
</template>

<script setup lang="ts">
import {ref} from 'vue';

const show = ref({
  one: true,
  two: true,
  three: true,
  four: true,
  five: true
});
const num = ref(0)
const radio1 = ref('');
const value = ref('')
const value1 = ref('')
const valueArr = ref([
  {text:'纹理一',src:require('@/assets/tools/board/layboard5.png'),id: 1}
])
const layboardselect = ref()
const changeSelection = (val) => {

  for (let index in valueArr.value) {
    let obj = valueArr.value[index]
    if (obj.id == val) {
      layboardselect.value.$el.children[0].children[0].setAttribute(
        'style',
        `background: url(${obj.src}) no-repeat; 
        width:80px; height: 80px;
        border: none;
        background-position: 0% center;
        background-size:130%`
      )
    }
  }
  
}



</script>

<style scoped lang="less">
@import '@/assets/css/board.less';

.layer-board {
  width: 100%;
  padding: 0 10px;
}


:deep(.imgselect) {
  width: 80px;
  height: 70px;
  .el-select__placeholder {
    display: none !important;
  }
  .el-select__wrapper {
    padding: 5px !important;
    width: 80px !important;
    height: 70px !important;
  }
}
</style>