import axios from 'axios';
import { ElMessage, ElMessageBox } from 'element-plus'

const token3d = localStorage.getItem('3dtoken')
axios.interceptors.request.use(
  (config) => {
    const token = token3d??'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJkZWZhdWx0XzY2MmI3ZWIyYjhlYzE3LjcxMTM2MjQ3IiwiaWF0IjoxNzE0MTI2NTE0Ljc1NzQ1OSwibmJmIjoxNzE0MTI2NTE0Ljc1NzQ1OSwiZXhwIjoxNzE0NzMxMzE0Ljc1NzQ1OSwiaWQiOjEsInBob25lIjoiMTg0MDgyNzI3NTUiLCJ1c2VybmFtZSI6IjE4NDA4MjcyNzU1Iiwiand0X3NjZW5lIjoiZGVmYXVsdCJ9.sxKHGStpunpbxP8TV05-14hTOAZ9KOrEnOqXQz4IH-U';
    if (token) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // do something
    return Promise.reject(error);
  }
);
// add response interceptors
axios.interceptors.response.use(
  (response) => {

    if ((response.headers['content-disposition'] || ! /^application\/json/.test(response.headers['content-type'])) && response.status === 200) {
      return response
    }
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
     
      // @ts-ignore
      return Promise.reject(new Error(res.message || 'Error'));
    }
    return res;
  },
  (error) => {
    ElMessage.error({
      content: error.message || 'Request Error',
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);
