import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/tools/cabinet/sidepack1.png'


const _hoisted_1 = { class: "layer-board" }
const _hoisted_2 = {
  key: 0,
  class: "common-set-content"
}
const _hoisted_3 = { class: "common-board-content" }
const _hoisted_4 = { class: "commonset2" }
const _hoisted_5 = { class: "commonset2" }
const _hoisted_6 = { class: "commonset2" }
const _hoisted_7 = { class: "commonset2" }
const _hoisted_8 = { class: "commonset2" }
const _hoisted_9 = {
  key: 1,
  class: "common-set-content"
}
const _hoisted_10 = { class: "common-board-content" }
const _hoisted_11 = { class: "commonset2" }
const _hoisted_12 = { class: "common-input-widthflex" }
const _hoisted_13 = {
  key: 2,
  class: "common-set-content"
}
const _hoisted_14 = { class: "common-board-content" }
const _hoisted_15 = { class: "commonset2" }
const _hoisted_16 = { class: "common-input-widthflex" }
const _hoisted_17 = { class: "commonset2" }
const _hoisted_18 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_19 = ["src"]

import {ref} from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'side-cover',
  setup(__props) {

const show = ref({
  one: true,
  two: true,
  three: true,
  four: true,
  five: true
});
const num = ref(0)
const radio1 = ref('');
const value = ref('')
const value1 = ref('')
const valueArr = ref([
  {text:'纹理一',src:require('@/assets/tools/board/layboard5.png'),id: 1}
])
const layboardselect = ref()
const changeSelection = (val) => {

  for (let index in valueArr.value) {
    let obj = valueArr.value[index]
    if (obj.id == val) {
      layboardselect.value.$el.children[0].children[0].setAttribute(
        'style',
        `background: url(${obj.src}) no-repeat; 
        width:80px; height: 80px;
        border: none;
        background-position: 0% center;
        background-size:130%`
      )
    }
  }
  
}




return (_ctx: any,_cache: any) => {
  const _component_CaretBottom = _resolveComponent("CaretBottom")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_CaretTop = _resolveComponent("CaretTop")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "common-set-title",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (show.value.one=!show.value.one))
    }, [
      _cache[10] || (_cache[10] = _createElementVNode("span", { class: "mr5" }, "参数", -1)),
      (show.value.one)
        ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_CaretBottom)
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_el_icon, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_CaretTop)
            ]),
            _: 1
          }))
    ]),
    (show.value.one)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "commonset2" }, [
              _createElementVNode("div", { class: "commonset2-tit3" }, "参数示意"),
              _createElementVNode("img", {
                class: "img3",
                src: _imports_0,
                alt: ""
              })
            ], -1)),
            _createElementVNode("div", _hoisted_4, [
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "commonset2-tit3" }, "柜体名称", -1)),
              _createVNode(_component_el_input, { class: "common-input-widthflex" })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _cache[12] || (_cache[12] = _createElementVNode("div", { class: "commonset2-tit3" }, "柜宽(W)", -1)),
              _createVNode(_component_el_input_number, {
                class: "common-input-widthflex",
                modelValue: num.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((num).value = $event)),
                min: 0,
                step: 0.1,
                precision: 1,
                controls: false,
                "controls-position": "right"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[13] || (_cache[13] = _createElementVNode("div", { class: "commonset2-tit3" }, "柜深(D)", -1)),
              _createVNode(_component_el_input_number, {
                class: "common-input-widthflex",
                modelValue: num.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((num).value = $event)),
                min: 0,
                step: 0.1,
                precision: 1,
                controls: false,
                "controls-position": "right"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _cache[14] || (_cache[14] = _createElementVNode("div", { class: "commonset2-tit3" }, "柜高(H)", -1)),
              _createVNode(_component_el_input_number, {
                class: "common-input-widthflex",
                modelValue: num.value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((num).value = $event)),
                min: 0,
                step: 0.1,
                precision: 1,
                controls: false,
                "controls-position": "right"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[15] || (_cache[15] = _createElementVNode("div", { class: "commonset2-tit3" }, "柜身板厚(CT)", -1)),
              _createVNode(_component_el_input_number, {
                class: "common-input-widthflex",
                modelValue: num.value,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((num).value = $event)),
                min: 0,
                step: 0.1,
                precision: 1,
                controls: false,
                "controls-position": "right"
              }, null, 8, ["modelValue"])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "common-set-title",
      onClick: _cache[5] || (_cache[5] = ($event: any) => (show.value.two=!show.value.two))
    }, [
      _cache[17] || (_cache[17] = _createElementVNode("span", { class: "mr5" }, "背板参数", -1)),
      (show.value.two)
        ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_CaretBottom)
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_el_icon, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_CaretTop)
            ]),
            _: 1
          }))
    ]),
    (show.value.two)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _cache[19] || (_cache[19] = _createElementVNode("div", { class: "commonset2" }, [
              _createElementVNode("div", { class: "commonset2-tit3" }, "参数示意")
            ], -1)),
            _createElementVNode("div", _hoisted_11, [
              _cache[18] || (_cache[18] = _createElementVNode("div", { class: "commonset2-tit3" }, "类型", -1)),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_el_select, {
                  modelValue: value.value,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((value).value = $event)),
                  placeholder: "请选择"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: "无背板",
                      value: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "common-set-title",
      onClick: _cache[7] || (_cache[7] = ($event: any) => (show.value.three=!show.value.three))
    }, [
      _cache[20] || (_cache[20] = _createElementVNode("span", { class: "mr5" }, "板材", -1)),
      (show.value.three)
        ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_CaretBottom)
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_el_icon, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_CaretTop)
            ]),
            _: 1
          }))
    ]),
    (show.value.three)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _cache[21] || (_cache[21] = _createElementVNode("div", { class: "commonset2-tit3" }, "基材", -1)),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_el_select, {
                  modelValue: value.value,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((value).value = $event)),
                  placeholder: "请选择"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: "多层板",
                      value: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _cache[22] || (_cache[22] = _createElementVNode("div", { class: "commonset2-tit3" }, "纹理", -1)),
              _createVNode(_component_el_select, {
                class: "imgselect",
                modelValue: value1.value,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((value1).value = $event)),
                placeholder: "纹理",
                onChange: changeSelection,
                ref_key: "layboardselect",
                ref: layboardselect
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(valueArr.value, (va, vak) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: vak,
                      label: va.text,
                      value: va.id
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("img", {
                            src: va.src,
                            alt: "",
                            width: "30px",
                            height: "30px"
                          }, null, 8, _hoisted_19),
                          _createElementVNode("span", null, _toDisplayString(va.text), 1)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})