

/** Tz3dNode  */
class Tz3dNode  {
  /** 注释
  * @property {Tz3d}  z3d       - 总控制
  * @property {Tz3dViewport}  viewport               - node所属viewport

  * @property {number}  id               - 唯一Id
  * @property {TzSort}  children       - 子对象数组
  * @property {Tz3dNodeType}  nodeType       - node type类型
  * @property {number}  customNodeIndex       - 自定义id
  * @property {Tz3dNodeProperties}  nodeProperties       - node的基础属性
  * @property {Tz3dSubset[]}  subsets       - 模型子部件
  * @property {Tz3dNodeBox}  box       - node的选中框的一种 移动选装纠正使用
  * @property {TzSceneGraphic}  graphic       - TzSceneGraphic
  * @property {*}  data       - **
  * @property {fun}  fun       - 方法执行集

  * @property {THREE.Mesh}  bodyMesh       - 顶层主体模型
  * @property {THREE.Mesh}  collisionMesh       - 碰撞模型 不显示
  * @property {Tz3dMeshType}  meshType       - 模型类型
  * @property {Tz3dHitEffectMode}  hitEffectMode       - 选中聚焦效果模式
  * @property {TzHitType}  hitType       - 选中聚焦等类型
  * @property {booleen}  isLoadProgressBox       - 是否加载进度盒
  * @property {string}  modelUrl       - 如果是加载模型，此即为模型地址
  * @property {THREE.Vector3}  rayPoint       - 最近一次射线触碰到的模型点 mm
  * @property {booleen}  bodyAble       - 是否允许显示主体模型
  * @property {booleen}  pureAble       - 是否允许显示白模型
  * @property {booleen}  edgeAble       - 是否允许显示网格模型
  *
  * @property {booleen}  isSelect       - 是否选中对象
  * @property {booleen}  isFocus       - 是否聚焦对象
  * @property {booleen}  isHover       - 是否覆盖对象
  * @property {booleen}  isLock       - 是否锁定
  * @property {booleen}  isDragging       - get : 是否是拖拽对象
  * @property {booleen}  isDragCreating       - get : 是否是拖拽创建对象
  * @property {boTz3dNodeoleen}  selectTopNode       - get : 能被选中的父对象 包含自身
  *
  * @property {THREE.Vector3}  posOrg       - 初始位置
  * @property {THREE.Vector3}  sizeOrg       - 初始大小
  * @property {THREE.Vector3}  angleOrg       - 初始角度
  * @property {THREE.Vector3}  pos       - 位置
  * @property {THREE.Vector3}  size       - 大小
  * @property {THREE.Vector3}  angle       - 角度
  * @property {THREE.Vector3}  wPos       - 世界位置
  * @property {THREE.Vector3}  wSize       - 世界大小
  * @property {THREE.Vector3}  wAngle       - 世界角度
  * @property {array}  mirror       - 镜像[false,false,false]   :[x,y,z]
  *
  * @param {Tz3dViewport} viewport viewport对象
  * @param {Tz3dNode} parent node对象
  * @param {booleen} visible 对象是否显示
  */
  constructor(viewport, parent) {
  
    // custom
    // this._nodeType = Tz3dNodeType.MESH3D
    this.subsets = []
    // this.box = new Tz3dNodeBox(this)

    this.adsorbNode = null // 吸附对象
    this.points = []
    this.isLocal = true
    this.display = true
    this._funDelegateSet = {} // 方法委托集

    // three.js
    this.bodyMesh = null
    this.mid = -1
    // this._initBodyMesh()
    // this.eid = this.z3d.getNextId()
    this.edgeMesh = null
    this.edgeUseBoxPoints = false
    this.collisionMesh = null
    this.progressMesh = null
    // this.meshType = Tz3dMeshType.NONE
    // this.hitEffectMode = Tz3dHitEffectMode.DEFAULT
    // this.hitType = TzHitType.NONE
    this.hitSubset = -2
    this.isLoadProgressBox = false
    this.loading = false
    this.modelUrl = ''
    this.createHouse = 89
    this.rayPoint = null
    this.useClipPlanes = []
    this.clipPlanes = []
    this.clipIntersection = false
    this.clipPlanesIsLocal = true
    this.isEdgeGeoAddCache = false
    this._loadedSucceed = true

    this.bodyAble = true
    this.pureAble = true
    this.edgeAble = true

    this.callbackCollisionPos = null
    this.callbackCollisionAngle = null
    this.callbackCollisionSize = null

    // transform
    // this.mirror = this.nodeProperties.mirror
    this._effectColor = null
    this._box3 = null
    this._boxWithChild = null
  }

  

  /** @description 渲染node的{@link Tz3dViewport} */
  render() {
    console.log('渲染viewport44')
    if (this.viewport) this.viewport.render()
  }
}
export { Tz3dNode }
