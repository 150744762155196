import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/tools/board/backboard1.png'
import _imports_1 from '@/assets/tools/board/backboard2.png'
import _imports_2 from '@/assets/tools/board/backboard3.png'


const _hoisted_1 = { class: "layer-board" }
const _hoisted_2 = {
  key: 0,
  class: "common-set-content"
}
const _hoisted_3 = { class: "common-board-content" }
const _hoisted_4 = { class: "commonset" }
const _hoisted_5 = { class: "common-input-width" }
const _hoisted_6 = {
  key: 1,
  class: "common-set-content"
}
const _hoisted_7 = { class: "common-board-content" }
const _hoisted_8 = { class: "commonset" }
const _hoisted_9 = { class: "commonset" }
const _hoisted_10 = { class: "commonset" }
const _hoisted_11 = { class: "commonset" }
const _hoisted_12 = {
  key: 2,
  class: "common-set-content"
}
const _hoisted_13 = { class: "common-board-content" }
const _hoisted_14 = { class: "commonset" }
const _hoisted_15 = { class: "commonset" }
const _hoisted_16 = { class: "commonset" }
const _hoisted_17 = { class: "commonset" }
const _hoisted_18 = { class: "commonset" }
const _hoisted_19 = { class: "commonset" }
const _hoisted_20 = { class: "commonset-tit" }
const _hoisted_21 = { class: "common-input-width13" }
const _hoisted_22 = { class: "flx" }
const _hoisted_23 = { class: "flx" }
const _hoisted_24 = {
  key: 3,
  class: "common-set-content"
}
const _hoisted_25 = { class: "common-board-content" }
const _hoisted_26 = { class: "commonset" }
const _hoisted_27 = { class: "common-input-width" }
const _hoisted_28 = { class: "commonset" }
const _hoisted_29 = { class: "common-input-width" }
const _hoisted_30 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_31 = ["src"]

import {ref} from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'back-board',
  setup(__props) {

const show = ref({
  one: true,
  two: true,
  three: true,
  four: true
});
const num = ref(0)
const radio1 = ref('');
const value = ref('')
const value1 = ref('')
const valueArr = ref([
  {text:'纹理一',src:require('@/assets/tools/board/layboard5.png'),id: 1}
])
const layboardselect = ref()
const changeSelection = (val) => {

  for (let index in valueArr.value) {
    let obj = valueArr.value[index]
    if (obj.id == val) {
      layboardselect.value.$el.children[0].children[0].setAttribute(
        'style',
        `background: url(${obj.src}) no-repeat; 
        width:80px; height: 80px;
        border: none;
        background-position: 0% center;
        background-size:130%`
      )
    }
  }
  
}




return (_ctx: any,_cache: any) => {
  const _component_CaretBottom = _resolveComponent("CaretBottom")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_CaretTop = _resolveComponent("CaretTop")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "common-set-title",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (show.value.one=!show.value.one))
    }, [
      _cache[17] || (_cache[17] = _createElementVNode("span", { class: "mr5" }, "接合样式", -1)),
      (show.value.one)
        ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_CaretBottom)
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_el_icon, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_CaretTop)
            ]),
            _: 1
          }))
    ]),
    (show.value.one)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_el_radio_group, {
                  modelValue: radio1.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((radio1).value = $event)),
                  size: "small"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_radio, { value: "1" }, {
                      default: _withCtx(() => _cache[18] || (_cache[18] = [
                        _createTextVNode("四边插槽")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_el_radio, { value: "2" }, {
                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                        _createTextVNode("厚背板")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_el_radio, { value: "3" }, {
                      default: _withCtx(() => _cache[20] || (_cache[20] = [
                        _createTextVNode("外盖背板")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ])
          ]),
          _cache[21] || (_cache[21] = _createElementVNode("img", {
            class: "img2",
            src: _imports_0,
            alt: ""
          }, null, -1))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "common-set-title",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (show.value.two=!show.value.two))
    }, [
      _cache[22] || (_cache[22] = _createElementVNode("span", { class: "mr5" }, "背板参数", -1)),
      (show.value.two)
        ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_CaretBottom)
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_el_icon, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_CaretTop)
            ]),
            _: 1
          }))
    ]),
    (show.value.two)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _cache[23] || (_cache[23] = _createElementVNode("div", { class: "commonset-tit" }, "厚度(BT)", -1)),
              _createVNode(_component_el_input_number, {
                class: "common-input-width",
                modelValue: num.value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((num).value = $event)),
                min: 0,
                step: 0.1,
                precision: 1,
                controls: false,
                "controls-position": "right"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[24] || (_cache[24] = _createElementVNode("div", { class: "commonset-tit" }, "缩进(BSV)", -1)),
              _createVNode(_component_el_input_number, {
                class: "common-input-width",
                modelValue: num.value,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((num).value = $event)),
                min: 0,
                step: 0.1,
                precision: 1,
                controls: false,
                "controls-position": "right"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[25] || (_cache[25] = _createElementVNode("div", { class: "commonset-tit" }, "槽深(SD)", -1)),
              _createVNode(_component_el_input_number, {
                class: "common-input-width",
                modelValue: num.value,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((num).value = $event)),
                min: 0,
                step: 0.1,
                precision: 1,
                controls: false,
                "controls-position": "right"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _cache[26] || (_cache[26] = _createElementVNode("div", { class: "commonset-tit" }, "断开背板", -1)),
              _createVNode(_component_el_checkbox)
            ])
          ]),
          _cache[27] || (_cache[27] = _createElementVNode("img", {
            class: "img2",
            src: _imports_1,
            alt: ""
          }, null, -1))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "common-set-title",
      onClick: _cache[6] || (_cache[6] = ($event: any) => (show.value.three=!show.value.three))
    }, [
      _cache[28] || (_cache[28] = _createElementVNode("span", { class: "mr5" }, "背板加固拉条", -1)),
      (show.value.three)
        ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_CaretBottom)
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_el_icon, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_CaretTop)
            ]),
            _: 1
          }))
    ]),
    (show.value.three)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _cache[29] || (_cache[29] = _createElementVNode("div", { class: "commonset-tit" }, "方向", -1)),
              _createVNode(_component_el_select, {
                class: "common-input-width",
                modelValue: value.value,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((value).value = $event)),
                placeholder: "方向"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_option, {
                    label: "横向",
                    value: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _cache[30] || (_cache[30] = _createElementVNode("div", { class: "commonset-tit" }, "厚度", -1)),
              _createVNode(_component_el_input_number, {
                class: "common-input-width",
                modelValue: num.value,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((num).value = $event)),
                min: 0,
                step: 0.1,
                precision: 1,
                controls: false,
                "controls-position": "right"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _cache[31] || (_cache[31] = _createElementVNode("div", { class: "commonset-tit" }, "宽度", -1)),
              _createVNode(_component_el_input_number, {
                class: "common-input-width",
                modelValue: num.value,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((num).value = $event)),
                min: 0,
                step: 0.1,
                precision: 1,
                controls: false,
                "controls-position": "right"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _cache[32] || (_cache[32] = _createElementVNode("div", { class: "commonset-tit" }, "数量", -1)),
              _createVNode(_component_el_input_number, {
                class: "common-input-width",
                modelValue: num.value,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((num).value = $event)),
                min: 0,
                step: 1,
                precision: 0,
                controls: false,
                "controls-position": "right"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _cache[33] || (_cache[33] = _createElementVNode("div", { class: "commonset-tit" }, "缩进", -1)),
              _createVNode(_component_el_input_number, {
                class: "common-input-width",
                modelValue: num.value,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((num).value = $event)),
                min: 0,
                step: 0.1,
                precision: 1,
                controls: false,
                "controls-position": "right"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_component_el_checkbox, { label: "均分" })
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _cache[34] || (_cache[34] = _createElementVNode("span", { class: "mr5" }, "上内空高度", -1)),
                  _createVNode(_component_el_input_number, {
                    class: "common-input-width6",
                    modelValue: num.value,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((num).value = $event)),
                    min: 0,
                    step: 0.1,
                    precision: 1,
                    controls: false,
                    "controls-position": "right"
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _cache[35] || (_cache[35] = _createElementVNode("span", { class: "mr5" }, "下内空高度", -1)),
                  _createVNode(_component_el_input_number, {
                    class: "common-input-width6",
                    modelValue: num.value,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((num).value = $event)),
                    min: 0,
                    step: 0.1,
                    precision: 1,
                    controls: false,
                    "controls-position": "right"
                  }, null, 8, ["modelValue"])
                ])
              ])
            ])
          ]),
          _cache[36] || (_cache[36] = _createElementVNode("img", {
            class: "img2",
            src: _imports_2,
            alt: ""
          }, null, -1))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "common-set-title",
      onClick: _cache[14] || (_cache[14] = ($event: any) => (show.value.four=!show.value.four))
    }, [
      _cache[37] || (_cache[37] = _createElementVNode("span", { class: "mr5" }, "板材", -1)),
      (show.value.four)
        ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_CaretBottom)
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_el_icon, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_CaretTop)
            ]),
            _: 1
          }))
    ]),
    (show.value.four)
      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("div", _hoisted_26, [
              _cache[38] || (_cache[38] = _createElementVNode("div", { class: "commonset-tit" }, "基材", -1)),
              _createElementVNode("div", _hoisted_27, [
                _createVNode(_component_el_select, {
                  modelValue: value.value,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((value).value = $event)),
                  placeholder: "基材"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: "多层板",
                      value: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_28, [
              _cache[39] || (_cache[39] = _createElementVNode("div", { class: "commonset-tit" }, "纹理", -1)),
              _createElementVNode("div", _hoisted_29, [
                _createVNode(_component_el_select, {
                  class: "imgselect",
                  modelValue: value1.value,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((value1).value = $event)),
                  placeholder: "纹理",
                  onChange: changeSelection,
                  ref_key: "layboardselect",
                  ref: layboardselect
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(valueArr.value, (va, vak) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: vak,
                        label: va.text,
                        value: va.id
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_30, [
                            _createElementVNode("img", {
                              src: va.src,
                              alt: "",
                              width: "30px",
                              height: "30px"
                            }, null, 8, _hoisted_31),
                            _createElementVNode("span", null, _toDisplayString(va.text), 1)
                          ])
                        ]),
                        _: 2
                      }, 1032, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})