import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo.png'
import _imports_1 from '@/assets/svg/5.svg'
import _imports_2 from '@/assets/svg/6.svg'
import _imports_3 from '@/assets/svg/11.svg'
import _imports_4 from '@/assets/svg/12.svg'
import _imports_5 from '@/assets/svg/13.svg'
import _imports_6 from '@/assets/svg/7.svg'
import _imports_7 from '@/assets/svg/8.svg'
import _imports_8 from '@/assets/svg/14.svg'
import _imports_9 from '@/assets/svg/9.svg'
import _imports_10 from '@/assets/svg/10.svg'


const _hoisted_1 = { class: "design-head" }
const _hoisted_2 = { class: "center" }
const _hoisted_3 = { class: "ullist" }
const _hoisted_4 = { class: "end" }
const _hoisted_5 = { class: "ullist" }

import { ref} from 'vue'
import type { TabsInstance  } from 'element-plus'
import { genFileId,ElMessage, ElMessageBox } from 'element-plus'
import {RefreshRight} from '@element-plus/icons-vue'


// 工具栏
// import ClearTool from '@/assets/tools/clear.png';
// import DelTool from '@/assets/tools/del.png';
// import FileTool from '@/assets/tools/file.png';
// import ALbumTool from '@/assets/tools/albme.png';
// import CameraTool from '@/assets/tools/camera.png';
// import PaperTool from '@/assets/tools/paper.png';
// import RedoTool from '@/assets/tools/redo.png';
// import SaveTool from '@/assets/tools/save.png';
// import ToolTool from '@/assets/tools/tool.png';
// import UodoTool from '@/assets/tools/uodo.png';


const uploadaction = 'https://api.zaowuzhi.cn/api/v1/upload?app_id=287eab5a28&identity=33f01880fa858b6364b0da178e14044f'

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  emits: ['importCAD','finishOrder','handleEvent'],
  setup(__props, { emit: __emit }) {

const emits = __emit

const upload = ref()
const loading = ref(false)
const fileList = ref()
const tabPosition = ref<TabsInstance['tabPosition']>('left')
const uploadshow = ref(false);
const uploadload = ref(false)
const circleUrl = ref('')

const onFinish = ()=>{
  loading.value = true
  emits('finishOrder',true)
  setTimeout(()=>{
    loading.value = false
  },1000)
}
function changeDemoFile(file: any,demoFileList:any){
  return
}


// 上传文件之前的钩子，对文件大小进行校验
const beforeUpload = (file) => {
  const isLt2M = file.size / 1024 / 1024 < 5;
  const str = file.name.split('.');
  if(str[1] !== 'dxf') {
    handleFail(1)
    return str[1];
  }
  // if (!isLt2M) {
  //   ElMessage.error('上传文件大小不能超过 5MB!')
  //   handleFail(2)
  //   return isLt2M;
  // }
}
// 切换上传文件
const handleExceed = (files) => { 
  upload.value.clearFiles()
  const file = files[0];
  file.uid = genFileId()
  upload.value.handleStart(file)
  upload.value.submit(file)
}
// 获取上传进度
const handleProgress = (event) => {
  uploadshow.value = false;
  uploadload.value = true;
  // console.log(`当前进度：${event.percent}%`);
}
// 上传成功时
const success = (res:any) => {
  if(res.data){
    emits('importCAD',res.data.file)
    uploadload.value = false;
  }
}
// 上传失败或者文件不满足要求
const handleFail = (val) => {
  const title = '导入文件不满足以下要求，请检查后重试'
  let content = '上传文件大小不能超过 5MB'
  const oktit = '重新上传'
  const canceltit = '我知道了'
  const typetit = 'error'
  if(val===1){
    content = '请上传CAD文件！'
  }
  
  ElMessageBox.confirm(
    content,
    title,
    {
      confirmButtonText: oktit,
      cancelButtonText: canceltit,
      type: typetit,
    }
  ).then(() => {
    upload.value.$el.querySelector('input').click(); 
  })
  .catch(() => {
    // 取消
  })
}

const handleClick=(type)=> {
  emits('handleEvent',type)
}


return (_ctx: any,_cache: any) => {
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_avatar = _resolveComponent("el-avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "logo" }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }),
      _createElementVNode("span", { class: "logo-tit" }, "Logo")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _cache[6] || (_cache[6] = _createStaticVNode("<li data-v-4d7e10f2><div class=\"icon\" data-v-4d7e10f2><img src=\"" + _imports_1 + "\" alt=\"文件\" data-v-4d7e10f2></div><div class=\"text\" data-v-4d7e10f2>文件</div></li><li data-v-4d7e10f2><div class=\"icon\" data-v-4d7e10f2><img src=\"" + _imports_2 + "\" alt=\"保存\" data-v-4d7e10f2></div><div class=\"text\" data-v-4d7e10f2>保存</div></li>", 2)),
        _createElementVNode("li", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (handleClick('undo')))
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("div", { class: "icon" }, [
            _createElementVNode("img", {
              src: _imports_3,
              alt: "撤销"
            })
          ], -1),
          _createElementVNode("div", { class: "text" }, "撤销", -1)
        ])),
        _createElementVNode("li", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (handleClick('redo')))
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("div", { class: "icon" }, [
            _createElementVNode("img", {
              src: _imports_4,
              alt: "恢复"
            })
          ], -1),
          _createElementVNode("div", { class: "text" }, "恢复", -1)
        ])),
        _createElementVNode("li", {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (handleClick('clear')))
        }, _cache[5] || (_cache[5] = [
          _createElementVNode("div", { class: "icon" }, [
            _createElementVNode("img", {
              src: _imports_5,
              alt: "清空"
            })
          ], -1),
          _createElementVNode("div", { class: "text" }, "清空", -1)
        ])),
        _createVNode(_component_el_divider, {
          direction: "vertical",
          style: {"margin":"0 15px 0 0","height":"35px"}
        }),
        _cache[7] || (_cache[7] = _createStaticVNode("<li data-v-4d7e10f2><div class=\"icon\" data-v-4d7e10f2><img src=\"" + _imports_6 + "\" alt=\"工具\" data-v-4d7e10f2></div><div class=\"text\" data-v-4d7e10f2>工具</div></li><li data-v-4d7e10f2><div class=\"icon\" data-v-4d7e10f2><img src=\"" + _imports_7 + "\" alt=\"AI\" data-v-4d7e10f2></div><div class=\"text\" data-v-4d7e10f2>AI</div></li>", 2)),
        _createVNode(_component_el_divider, {
          direction: "vertical",
          style: {"margin":"0 15px 0 0","height":"35px"}
        }),
        _cache[8] || (_cache[8] = _createElementVNode("li", null, [
          _createElementVNode("div", { class: "icon" }, [
            _createElementVNode("img", {
              src: _imports_8,
              alt: "图纸&清单"
            })
          ]),
          _createElementVNode("div", { class: "text" }, "图纸&清单")
        ], -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("ul", _hoisted_5, [
        _createElementVNode("li", null, [
          _createVNode(_component_el_input, { placeholder: "搜索帮助" })
        ]),
        _cache[9] || (_cache[9] = _createStaticVNode("<li data-v-4d7e10f2><div class=\"icon\" data-v-4d7e10f2><img src=\"" + _imports_9 + "\" alt=\"协作\" data-v-4d7e10f2></div><div class=\"text\" data-v-4d7e10f2>协作</div></li><li data-v-4d7e10f2><div class=\"icon\" data-v-4d7e10f2><img src=\"" + _imports_10 + "\" alt=\"消息\" data-v-4d7e10f2></div><div class=\"text\" data-v-4d7e10f2>消息</div></li>", 2)),
        _createElementVNode("li", null, [
          _createVNode(_component_el_avatar, {
            class: "ml",
            size: 30,
            src: circleUrl.value
          }, null, 8, ["src"])
        ])
      ])
    ])
  ]))
}
}

})