/*
 * @Author: your name
 * @Date: 2021-05-11 10:52:14
 * @LastEditTime: 2021-05-11 11:40:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \bSpaceDesign\src\core\engine\z3d\z3dConst.js
 */
import { THREE } from '@/core/thirdPart/lib.js'
const TzMouseButton = {
  NONE: 0,
  LEFT: 1,
  RIGHT: 2,
  LEFTRIGHT: 3,
  MIDDLE: 4,
  LEFTMIDDLE: 5,
  RIGHTMIDDLE: 6,
  LEFTRIGHTMIDDLE: 7
}

// 方向
const TzDir = {
  TOP: 0,
  BOTTOM: 1,
  LEFT: 2,
  RIGHT: 3,
  FRONT: 4,
  BACK: 5
}

const TzLengthUnit = {
  MM: 0, // 毫米
  FEET_INCHES: 1, // 英尺英寸
  INCHES: 2 // 英寸
}

const TzInchesFormat = {
  FRACTIONAL: 0, // 分数
  DECIMAL: 1 // 小数
}

/** Tz3dViewportType */
const Tz3dViewportType = {
  NONE: -1,
  DEFAULT: 0,
  VIEW3D: 1,
  VIEW2D: 2,
  VIEW3D_DISPLAY: 3,
  VIEW2D_DISPLAY: 4,
  VIEW3D_DESIGN: 5,
  VIEW2D_DESIGN: 6,
  VIEW2D_DESIGN_LINE: 7,
  VIEW2D_PDM_PAVE: 8,
  VIEW2D_BSPACE_PAVE: 9,
  VIEW2D_BSPACE_PARA:10
}
/** Tz3dModelModeType */
const Tz3dModelModeType = {
  MAT: 0,
  LINE: 1,
  MAT_LINE: 2,
  LINE_TRANSP: 3
}
/** Tz3dNodeType */
const Tz3dNodeType = {
  MESH3D: 0,
  SVG: 1
}
/** Tz3dSubsetMaterialType */
const Tz3dSubsetMaterialType = {
  COLOR: 0,
  TEXTURE: 1
}
/** Tz3dSubsetUvMode */
const Tz3dSubsetUvMode = {
  TITLE: 0,
  MIRROR: 1,
  STRETCH: 2
}
/** Tz3dSubsetUvStart */
const Tz3dSubsetUvStart = {
  LEFT_BOTTOM: 0,
  RIGHT_BOTTOM: 1,
  RIGHT_TOP: 2,
  LEFT_TOP: 3
}
/** Tz3dHitEffectMode */
const Tz3dHitEffectMode = {
  DEFAULT: 0,
  MAT: 1,
  GLOW: 2,
  EDGE: 3
}
/** Tz3dMeshType */
const Tz3dMeshType = {
  NONE: 0,
  POLYGON: 1,
  MODEL: 2,
  PLAN: 3,
  CUBE: 4,
  CIRCLE: 5,
  RING: 6,
  SPHERE: 7,
  CONE: 8,
  CYLINDER: 9,
  TORUS: 10,
  TEXT: 11,
  CROSS: 12,
  LINE: 13,
  ARC_CIRCLE: 14
}
/** TzHitType */
const TzHitType = {
  NONE: 0,
  HIT: 1,
  FOCUS: 2,
  SELECT: 3,
  SUBSET: 4
}
/** Tz3dNodeBoxMode */
const Tz3dNodeBoxMode = {
  none: -1,
  box: 0,
  points: 1,
  polygonBox: 2
}
/** Tz3dConstBase */
const Tz3dConstBase = 1 // 以m为计量
/** Tz3dConst */
const Tz3dConst = {
  _light0_position: new THREE.Vector3(0, 0, 300).multiplyScalar(Tz3dConstBase),
  _light1_position: new THREE.Vector3(300 * Math.sin(Math.PI / 3), 0, -150).multiplyScalar(Tz3dConstBase),
  _light2_position: new THREE.Vector3(-300 * Math.sin(Math.PI / 3), 0, -150).multiplyScalar(Tz3dConstBase),
  _light3_position: new THREE.Vector3(0, -100, 0).multiplyScalar(Tz3dConstBase),
  _light4_position: new THREE.Vector3(0, 100, 0).multiplyScalar(Tz3dConstBase),
  _lightH_position: new THREE.Vector3(0, 100, 0).multiplyScalar(Tz3dConstBase),
  _gridHelper_position_y: 0.03 * Tz3dConstBase,
  _gridHelper_divisions: 200,
  _gridPlane_size: 200 * Tz3dConstBase,
  _gridPlane_position_y: 0.032 * Tz3dConstBase,
  _cubeBox_size: 300 * Tz3dConstBase,
  _viewBackgroundColor: 0xecf0f2, // 0xecf0f2
  _camera_near: 0.001 * Tz3dConstBase, // 0.001 * Tz3dConstBase,
  _camera_far: 600 * Tz3dConstBase,
  _camera_fov: 60 * Tz3dConstBase,
  _camera3d_position: new THREE.Vector3(0, 8, 12).multiplyScalar(Tz3dConstBase),
  _camera2d_position: new THREE.Vector3(0, 100, 0).multiplyScalar(Tz3dConstBase),
  _camCtrl_target: new THREE.Vector3(0, 0.005, 0).multiplyScalar(Tz3dConstBase),
  _camCtrl_minDistance: 0.68 * Tz3dConstBase,
  _camCtrl_maxDistance: 200 * Tz3dConstBase,
  _camCtrl_minZoom: 5 / Tz3dConstBase,
  _camCtrl_maxZoom: 300 / Tz3dConstBase,
  _camCtrl_targetMaxLength: 100 / Tz3dConstBase,
  _camCtrl_DefaultZoom: 60 / Tz3dConstBase,
  _camCtrl_position: new THREE.Vector3(0, 3, 6).multiplyScalar(Tz3dConstBase)
}
/** Tz3dGizmoMode */
const Tz3dGizmoMode = {
  translate: 0,
  rotate: 1,
  scale: 2
}
/** Tz3dGizmoSpaceMode */
const Tz3dGizmoSpaceMode = {
  world: 0,
  local: 1
}
/** Tz3dDrawCtrlState */
const Tz3dDrawCtrlState = {
  NONE: 0,
  DRAGCAMERA: 1,
  DRAGNODE: 2,
  DRAGCREATE: 3,
  DRAGGIZMO: 4,
  RECTSELECT: 5,
  CUSTOM: 6
}
export { Tz3dConst, Tz3dConstBase }
export { TzMouseButton, Tz3dGizmoMode, Tz3dSubsetMaterialType }
export {
  TzDir,
  Tz3dSubsetUvMode,
  Tz3dSubsetUvStart,
  Tz3dViewportType,
  Tz3dModelModeType,
  Tz3dGizmoSpaceMode,
  Tz3dNodeType,
  Tz3dMeshType,
  Tz3dHitEffectMode,
  TzHitType,
  Tz3dNodeBoxMode
}
export { Tz3dDrawCtrlState }

export { TzLengthUnit, TzInchesFormat }
