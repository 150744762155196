
import { THREE } from '@/core/thirdPart/lib.js'
import {Tz3dConst,Tz3dConstBase,TzDir} from './z3dConst.js'
import {Tz3dTool,Tz3dMath,TzEventListener,TzTimer} from '../z3dTool'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {  Tz3dCameraState } from './z3dCameraState'
import TWEEN from '@tweenjs/tween.js'
/** Tz3dCameraCtrl */
class Tz3dCameraCtrl {
  /** 注释
   * three.js
   * @property {Tz3dViewport}  viewport       - 视口
   * @property {div}  canvas               - div
   * @property {array}  clientSize               - 窗口大小 [0, 0]
   * @property {div}  div               - div真实区域视口
   * @property {THREE.Vector4}  offset               - div真实区域视口的偏移 x:左，y:下，z:右，w:上
   * @property {THREE.Vector2}  resolution               - div真实区域视口的分辨率，非输出转化
   * @param {Tz3dViewport} viewport 视口
   */
  constructor(viewport) {
    this._viewport = viewport
    this._camera = null
    this._camCtrl = null
    this._is2d = false
    this._viewDir = TzDir.TOP
    this._viewZoom = 100

    this._camereChangeVec = null
    this._camereChanging = false

    this.doCameraChangeStart = null
    this.doCameraChange = null
    this.doCameraChangeEnd = null

    this._roamBirdTimer = new TzTimer(320)
    this._roamBird = false
    this._rb_rTrans = []
    this._rb_bTrans = []
    this._tween = null
    this._tweenId = 0

    // 定义属性
    this._minDistance = Tz3dConst._camCtrl_minDistance
    this._maxDistance = Tz3dConst._camCtrl_maxDistance
    this._minZoom = Tz3dConst._camCtrl_minZoom
    this._maxZoom = Tz3dConst._camCtrl_maxZoom

    this._camState = new Tz3dCameraState(this)

    this.roamBirdStartListener = new TzEventListener()
    this.roamBirdEndListener = new TzEventListener()

    this.camCtrlChange = this._camCtrlChange.bind(this)
    this._camCtrlChange_render = this._viewport.render.bind(this._viewport)
  }

  get viewport() {
    return this._viewport
  }

  get z3d() {
    return this._viewport.z3d
  }

  get gizmo() {
    return this._viewport.gizmo
  }

  get canvasCtrl() {
    return this._viewport.canvasCtrl
  }

  get layerId() {
    return this._viewport.layerId
  }

  get renderer() {
    return this._viewport.renderer
  }

  get camera() {
    return this._camera
  }

  get camCtrl() {
    return this._camCtrl
  }

  get camState() {
    return this._camState
  }

  get is2d() {
    return this._is2d
  }

  set enableRotate(v) {
    this._camCtrl.enableRotate = this.viewport.enableRotate && v
  }

  set enablePan(v) {
    this._camCtrl.enablePan = this.viewport.enablePan && v
  }

  set enableZoom(v) {
    this._camCtrl.enableZoom = this.viewport.enableZoom && v
  }

  initCamera3d(position) {
    this._camera = new THREE.PerspectiveCamera(45, this.canvasCtrl.clientWidth / this.canvasCtrl.clientHeight, Tz3dConst._camera_near, Tz3dConst._camera_far)
    this.cameraLayer = [this.layerId]
    if (position) {
      this._camera.position.set(position[0], position[1], position[2])
    } else {
      this._camera.position.copy(Tz3dConst._camera3d_position)
    }
    this._camera.fov = Tz3dConst._camera_fov
    this._is2d = false
  }

  initCamera2d(position, zoom) {
    this._camera = new THREE.OrthographicCamera(
      this.canvasCtrl.clientWidth / -2,
      this.canvasCtrl.clientWidth / 2,
      this.canvasCtrl.clientHeight / 2,
      this.canvasCtrl.clientHeight / -2,
      Tz3dConst._camera_near,
      Tz3dConst._camera_far
    )
    this.cameraLayer = [this.layerId]
    this._camera.zoom = zoom || 60
    if (!position || position.length < 3) {
      this._camera.position.copy(Tz3dConst._camera2d_position)
    } else {
      this._camera.position.set(position[0], position[1], position[2]).multiplyScalar(Tz3dConstBase)
    }
    this._camera.updateProjectionMatrix()
    this._is2d = true
  }

  // LEFT,MIDDLE,RIGHT,ROTATE,DOLLY,PAN(0 1 2 3 4 5)
  initCameraCtrl(isCamCtrlMoving, mouseButtons, target, minDistance, maxDistance) {
    this._camCtrl = new OrbitControls(this._camera, this.renderer.domElement)
    this._camCtrl.addEventListener('start', this._onCameraChangeStart.bind(this))
    this._camCtrl.addEventListener('change', this._onCameraChange.bind(this))
    this._camCtrl.addEventListener('end', this._onCameraChangeEnd.bind(this))
    this.setMouseButtons(mouseButtons)
    this.setTarget(target)
    this.setMinmaxDistance(minDistance, maxDistance)
    this._isCamCtrlMoving = isCamCtrlMoving
    this._camCtrl.update()
    this._camCtrl.addEventListener('change', this.camCtrlChange)
    return this
  }

  updateView(width, height) {
    if (this._camera.isPerspectiveCamera) {
      this._camera.aspect = width / height
    } else if (this._camera.isOrthographicCamera) {
      this._camera.left = width / -2
      this._camera.right = width / 2
      this._camera.top = height / 2
      this._camera.bottom = height / -2
    }
    this._camera.updateProjectionMatrix()
  }

  /** @description 设置相机位置 单位 mm
   * @param {THREE.Vector3} center 中心位置 mm
   * @param {THREE.Vector3} size 区域大小 mm
   * @param {number} offset 偏移比例 相对比
   */
  set2dCameraPos(center, size, offset = 0.2) {
    if (!center || !size) {
      return
    }
    let _center = center.toM()
    this._camCtrl.target.copy(_center)
    let position = this._camera.position
    switch (this.viewDir) {
      default:
      case TzDir.TOP:
      case TzDir.BOTTOM:
        position.set(_center.x, position.y, _center.z)
        break
      case TzDir.LEFT:
      case TzDir.RIGHT:
        position.set(position.x, _center.y, _center.z)
        break
      case TzDir.FRONT:
      case TzDir.BACK:
        position.set(_center.x, _center.y, position.z)
        break
    }
    let b0 = this.canvasCtrl.clientHeight / this.canvasCtrl.clientWidth
    let b1 = size.z / size.x
    let h = b0 > b1 ? (b0 / b1) * size.z : size.z
    this.setZoom(this.getZoom((h * (1 + Number(offset))).toM()))
    this._camera.updateProjectionMatrix()
    this._camCtrl.update()
    this.render()
  }

  /** @description 设置相机位置 根据trans直接设置 单位 mm
   * @param {THREE.Vector3} pos 位置 mm
   * @param {THREE.Vector3} size 大小 mm
   * @param {THREE.Vector3} angle 角度
   * @param {number} ratio 比例
   */
  setAutoCameraPos_psa(pos, size, angle, ratio = 1.5) {
    let fov = this._camera.fov ? this._camera.fov : 52.6
    let fovB = Math.tan(((fov / 2) * Math.PI) / 180.0) * 2
    let sizeLength = (size.length() * ratio) / (fovB || 0.00001)
    let minL = sizeLength < 800 ? sizeLength * 1.8 : sizeLength
    let cPos = new THREE.Vector3(0, 0, minL < 180 ? 180 : minL)
    if (angle) cPos.applyQuaternion(angle.toQuaternionForDegree_world())
    if (this._is2d) {
      let vec = this._camCtrl.target
        .clone()
        .sub(this._camera.position)
        .normalize()
      this._camera.position.copy(vec.multiplyScalar(cPos))
    } else {
      this._camera.position.copy(
        pos
          .clone()
          .add(cPos)
          .toM()
      )
    }

    this._camCtrl.target.copy(pos.toM())
    this._camCtrl.minDistance = Tz3dMath.v3MinLenth(size.toM())
    this._camCtrl.maxDistance = sizeLength.toM() * 10
    if (this._is2d) {
      this.setZoom(this.getZoom(sizeLength.toM()))
    }
    this._camera.updateProjectionMatrix()
    this._camCtrl.update()
    this.render()
  }

  /** @description 根据 length  距离设置相机位置 单位 mm */
  adaptiveCamera(length) {
    if (this._camera) {
      this.setZoom(800000 / length)
      this.setTarget([0, 0, 0])
      this.setPositionResetY()
      this._camCtrl.update()
      this.render()
    }
  }

  /** @description 动画移动至目标中心正面 -透视相机 单位 mm
   *  @param {node|THREE.Vector3} node -node或者THREE.Vector3 mm
   *  @param {THREE.Vector3} normal -朝向/法线
   *  @param {THREE.Vector3 | THREE.Vector2} size -大小 mm
   */
  moveCamera3dTo(node, normal, size, ratio = 1) {
    function getDis(length) {
      ratio = Number(ratio)
      let _dis = Number(length) * (ratio || 1)
      _dis = _dis < 1000 ? _dis * 1.6 : _dis
      return _dis <= 0 ? 6000 : _dis
    }
    if (node && this._camera && this._camera.isPerspectiveCamera) {
      let pos = node
      let dis = 6000
      if (node.isNode) {
        pos = node.wPos.clone()
        dis = getDis(node.wSize.length())
      } else if (size) {
        dis = getDis(size.length())
      }
      normal = normal || pos.clone().set(0, 0, 1)
      let target = pos.toM()
      let position = normal
        .clone()
        .normalize()
        .multiplyScalar(dis.toM())
      this.moveCamera(position.add(target), target)
    }
  }

  /**
   * @description 相机自适应尺寸 单位 mm
   * @param {THREE.Vector2 / Tz2dPoint} targetSize -目标尺寸
   * @param {Number} spaceDist -边缘空白
   * @param {THREE.Vector2 / Tz2dPoint} center -目标中心点
   */
  cameraAdaptiveSize_Orthographic(targetSize, spaceDist, center) {
    let rs = new THREE.Vector2()
    this.renderer.getSize(rs)
    let w = rs.x / this._camera.zoom
    let h = rs.y / this._camera.zoom
    let s = targetSize.x > targetSize.y ? (targetSize.x + spaceDist * 2) / 1000 / w : (targetSize.y + spaceDist * 2) / 1000 / h
    this.setZoom(this._camera.zoom / s)
    this._camera.updateProjectionMatrix()
    if (center) {
      this._camera.position.set(center.x.toM(), this._camera.position.y, center.y.toM())
      this._camCtrl.target.set(center.x.toM(), 0, center.y.toM())
    }
  }

  setMouseButtons(mouseButtons) {
    const mouses = [THREE.MOUSE.LEFT, THREE.MOUSE.MIDDLE, THREE.MOUSE.RIGHT, THREE.MOUSE.ROTATE, THREE.MOUSE.DOLLY, THREE.MOUSE.PAN]
    if (mouseButtons && mouseButtons.length > 1) {
      this._camCtrl.mouseButtons = {
        MIDDLE: mouses[mouseButtons[0]],
        RIGHT: mouses[mouseButtons[1]],
        LEFT: mouses[mouseButtons[2]]
      }
    }
  }

  setTarget(target) {
    if (target && target.length > 1) {
      this._camCtrl.target.set(target[0].toM(), target[1].toM(), target[2].toM()).multiplyScalar(Tz3dConstBase)
    } else {
      this._camCtrl.target.copy(Tz3dConst._camCtrl_target)
    }
  }

  /** @description  设置视窗控制器最近最远 单位 mm
   * @param {number} min -最近 mm
   * @param {number} max -最远 mm
   */
  setMinmaxDistance(min, max) {
    this._camCtrl.minDistance = this._minDistance = min == null ? this._minDistance : min.toM() * Tz3dConstBase
    this._camCtrl.maxDistance = this._maxDistance = max == null ? this._maxDistance : max.toM() * Tz3dConstBase
    this._minZoom = max == null ? this._minZoom : 1000 / (max.toM() * Tz3dConstBase)
    this._maxZoom = min == null ? this._maxZoom : 30 / (min.toM() * Tz3dConstBase)
    if (this._camera.isOrthographicCamera) {
      this._camCtrl.minZoom = this._minZoom
      this._camCtrl.maxZoom = this._maxZoom
    }
  }

  setPosition(pos) {
    if (pos && pos.length > 1) {
      this._camera.position.set(pos[0].toM(), pos[1].toM(), pos[2].toM()).multiplyScalar(Tz3dConstBase)
    } else {
      this._camera.position.copy(Tz3dConst._camCtrl_position)
    }
    this._camera.updateProjectionMatrix()
  }

  setZoom(zoom) {
    if (zoom > 0) {
      zoom = this._camCtrl.minZoom > zoom ? this._camCtrl.minZoom : zoom
      zoom = this._camCtrl.maxZoom < zoom ? this._camCtrl.maxZoom : zoom
      this._camera.zoom = zoom
      this._camera.updateProjectionMatrix()
    }
  }

  setPositionResetZ() {
    let length = this._camera.position
      .clone()
      .sub(this._camCtrl.target)
      .length()
    this._camera.position.set(0, 0, length)
    this._camera.updateProjectionMatrix()
  }

  setPositionResetY() {
    let length = this._camera.position
      .clone()
      .sub(this._camCtrl.target)
      .length()
    this._camera.position.set(0, length, 0)
    this._camera.updateProjectionMatrix()
  }

  setPositionResetXZ(pos) {
    let y = this._camera.position.y
    this._camera.position.set(pos[0].toM(), y, pos[1].toM())
    this._camera.updateProjectionMatrix()
  }

  setCamCtrlUptate() {
    this._camCtrl.update()
  }

  /** @description getset：视窗缩放比 越近越大 0 ~ 300 */
  set viewZoom(value) {
    if (!value) return
    let min = this._camCtrl.minZoom / 2.5
    value = value < min ? min : value
    let isDrawCtrlAble = !this._roamBird && !this._roamBirdTimer.doing
    if (this._viewZoom !== value && isDrawCtrlAble) {
      this._viewZoom = value
      if (this._camera.isPerspectiveCamera) {
        let targetDistance = this.getTargetDistance(value)
        let target = this._camCtrl.target
        let pos = this._camera.position
        let newPos = pos.clone().sub(target)
        newPos
          .normalize()
          .multiplyScalar(targetDistance)
          .add(target)
        this._camera.position.copy(newPos)
        this._camera.updateProjectionMatrix()
      } else if (this._camera.isOrthographicCamera) {
        this.setZoom(value)
      }
      if (this.gizmo) this.gizmo.updateTrans()
      this.doCameraChangeOnce()
      this.render()
    }
  }

  get viewZoom() {
    if (this._camera.isPerspectiveCamera) {
      let target = this._camCtrl.target
      let position = this._camera.position
      let targetDistance = position
        .clone()
        .sub(target)
        .length()
      return this.getZoom(targetDistance)
    } else if (this._camera.isOrthographicCamera) {
      this._viewZoom = this._camera.zoom
    }
    return this._viewZoom
  }

  getZoom(distance) {
    let targetDistance = distance
    // half of the fov is center to top of screen
    let fov = this._camera.fov ? this._camera.fov : 52.6
    targetDistance *= Math.tan(((fov / 2) * Math.PI) / 180.0)
    targetDistance = targetDistance > 0 ? targetDistance : 0.0001

    let height = this.canvasCtrl.clientHeight
    if (this._camera.isOrthographicCamera) height = this._camera.top - this._camera.bottom
    let zoom = height / targetDistance / 2
    return zoom
  }

  getTargetDistance(zoom) {
    zoom = zoom > 0 ? zoom : 0.0001
    let height = this.canvasCtrl.clientHeight
    if (this._camera.isOrthographicCamera) height = this._camera.top - this._camera.bottom
    let targetDistance = height / zoom / 2
    let fov = this._camera.fov ? this._camera.fov : 52.6
    targetDistance /= Math.tan(((fov / 2) * Math.PI) / 180.0)
    return targetDistance
  }

  cameraSet(is2d) {
    let self = this
    let viewport = self.viewport
    if (self._is2d === is2d) return
    self._camCtrl.dispose()
    if (is2d) {
      self.initCamera2d()
      self.initCameraCtrl(true, [5, 5, 5])
    } else {
      self.initCamera3d()
      self.initCameraCtrl(true)
    }
    function outlineChangeCamera(outline) {
      if (outline) {
        outline.renderCamera = self._camera
        outline.prepareMaskMaterial.fragmentShader = outline.replaceDepthToViewZ(outline.prepareMaskMaterial.fragmentShader, outline.renderCamera)
      }
    }
    outlineChangeCamera(viewport._outlinePass1)
    outlineChangeCamera(viewport._outlinePass2)
    outlineChangeCamera(viewport._outlinePass3)
    outlineChangeCamera(viewport._outlinePass4)
    if (viewport._ssaaPass) viewport._ssaaPass.camera = viewport._camera
    if (viewport._saoPass) {
      viewport._saoPass.camera = viewport._camera
      viewport._saoPass.saoMaterial.defines.PERSPECTIVE_CAMERA = viewport._saoPass.camera.isPerspectiveCamera ? 1 : 0
      viewport._saoPass.saoMaterial.uniforms.cameraInverseProjectionMatrix.value.copy(viewport._saoPass.camera.projectionMatrixInverse)
      viewport._saoPass.saoMaterial.uniforms.cameraProjectionMatrix.value = viewport._saoPass.camera.projectionMatrix
      viewport._saoPass.vBlurMaterial.defines.PERSPECTIVE_CAMERA = viewport._saoPass.camera.isPerspectiveCamera ? 1 : 0
      viewport._saoPass.hBlurMaterial.defines.PERSPECTIVE_CAMERA = viewport._saoPass.camera.isPerspectiveCamera ? 1 : 0
    }

    self.enableRotate = !is2d
    self._camera.updateProjectionMatrix()
    self._camState.saveState()
    self.doCameraChangeOnce()
    self.render()
  }

  cameraForward(dir = TzDir.TOP) {
    let is2d = this._is2d
    let n = Number(dir)
    if (this._viewDir === n && is2d) return
    this._viewDir = n
    let position = this._camera.position
    let target = this._camCtrl.target
    let dis = target.distanceTo(position)
    let p = Tz3dConst._camera2d_position.y
    let transForward = this._camCtrl.transForward
    this._camCtrl.screenSpacePanning = is2d
    switch (n) {
      default:
      case TzDir.TOP:
        position.set(target.x, is2d ? p : target.y + dis, target.z)
        transForward.set(0, 1, 0)
        break
      case TzDir.BOTTOM:
        position.set(target.x, is2d ? -p : target.y - dis, target.z)
        transForward.set(0, -1, 0)
        break
      case TzDir.LEFT:
        position.set(is2d ? -p : target.x - dis, target.y, target.z)
        transForward.set(-1, 0, 0)
        break
      case TzDir.RIGHT:
        position.set(is2d ? p : target.x + dis, target.y, target.z)
        transForward.set(1, 0, 0)
        break
      case TzDir.FRONT:
        position.set(target.x, target.y, is2d ? p : target.z + dis)
        transForward.set(0, 0, 1)
        break
      case TzDir.BACK:
        position.set(target.x, target.y, is2d ? -p : target.z - dis)
        transForward.set(0, 0, -1)
        break
    }
    this._camera.updateProjectionMatrix()
    this._camCtrl.update()
    this.doCameraChangeOnce()
    this.render()
  }

  _onCameraChangeStart() {
    if (this.doCameraChangeStart) this.doCameraChangeStart()
    else this._doCameraChangeStart()
  }

  _onCameraChange() {
    if (this.gizmo) this.gizmo.updateTrans()
    if (this.doCameraChange) this.doCameraChange()
    else this._doCameraChange()
  }

  _onCameraChangeEnd() {
    if (!this._is2d) this.z3d.setShadowPosition(this._camCtrl.target.x, this._camCtrl.target.z)
    if (this.doCameraChangeEnd) this.doCameraChangeEnd()
    else this._doCameraChangeEnd()
  }

  get currCamVec() {
    return this._camCtrl.target
      .clone()
      .sub(this._camCtrl.object.position)
      .normalize()
  }

  /** @description  执行一次相机变动所触发的事件 */
  doCameraChangeOnce() {
    this._onCameraChangeStart()
    this._onCameraChange()
    this._onCameraChangeEnd()
  }

  _doCameraChangeStart() {
    this._camereChanging = true
    this._camereChangeVec = this.currCamVec
    let viewport = this._viewport
    if (viewport.callbackCameraChangeStart) viewport.callbackCameraChangeStart(viewport)
  }

  _doCameraChange() {
    return
    let viewport = this._viewport
    viewport.svgNodeRender.update()
    if (this._camereChanging) {
      viewport.svgRulerRender.hideAll()
      viewport.svgTextRender.hideAll()
      if (this._camereChangeVec && !this._camereChangeVec.similar(this.currCamVec, 0.001)) {
        //  || (!this._is2d && this.gizmo.dragGizmoMesh)
        viewport.svgNodeRender.hideAll()
      }
      viewport.setDivInputActive(false)
      if (viewport.callbackCameraChange) viewport.callbackCameraChange(viewport)
    }
  }

  _doCameraChangeEnd() {
    let self = this
    return
    self._camereChanging = false
    let viewport = self._viewport
    if (viewport.callbackViewportChange) viewport.callbackViewportChange(viewport)
    if (viewport.callbackCameraChangeEnd) viewport.callbackCameraChangeEnd(viewport)
    TzT.lastDo(
      self,
      () => {
        viewport.svgRulerRender.showAll()
        viewport.svgTextRender.showAll()
        viewport.svgNodeRender.showAll()
        viewport.svgRulerRender.update()
        viewport.svgTextRender.update()
        viewport.svgNodeRender.update()
        viewport.setDivInputActive(true)
      },
      280
    )
  }

  _camCtrlChange() {
    if (this._isCamCtrlMoving) {
      let maxLength = Tz3dConst._camCtrl_targetMaxLength
      let target = this._camCtrl.target
      let position = this._camera.position
      if (target.length() > maxLength) {
        let pos = target.clampLength(0, maxLength)
        target.copy(pos)
        if (this._camera.isOrthographicCamera) {
          position.set(pos.x, position.y, pos.z)
        }
        this._camCtrl.update()
      }
      let dis = position.distanceTo(target) * Tz3dConst._camera_near
      this._camera.near = dis.clamp(Tz3dConst._camera_near, 0.5)

      if (this.gridHelperVisible) this._setGridHelperVisible(position.y > 0)
    }
    this._camCtrlChange_render()
  }

  /** @description  设置视窗焦点高度 单位 mm
   * @param {number} v -高度 mm
   * @param {boolean} isTween -是否缓动
   * @param {callback} callback -回调
   */
  setTargetHeight(v, isTween, callback) {
    let h = Number(v)
    if (this._targetHeight !== h) {
      this._targetHeight = h
      let ch = h - this._camCtrl.target.y
      if (isTween) {
        if (this.drawCtrl) this.drawCtrl.moveCameraHeight(h)
      } else {
        this._camCtrl.target.y = h
        this._camera.position.y = this._camera.position.y + ch
        this._camCtrl.update()
        this.render()
        if (callback) callback()
      }
    }
  }

  get roamBird() {
    return this._roamBird
  }

  /** @description 设置当前是漫游还是鸟瞰
   * @param {boolean} val ture 为漫游 false为鸟瞰
   * @param {THREE.Vector3} cpos 位置 毫米
   * @param {THREE.Vector3} ctar 焦点 毫米
   * @param {number} time 时间 毫秒
   */
  setRoamBird(val, cpos, ctar, time) {
    if (this._camera.isOrthographicCamera) return
    if (this._roamBird === val) return

    let isDoing = this._roamBirdTimer.doing
    if (isDoing) {
      if (!this._roamBirdTimer.isAbleStart) return
      this.endMoveCamera()
    }
    let self = this
    self._roamBird = val
    self.roamBirdStartListener.fire(val)

    let sp, st, ep, et
    sp = self._camera.position.clone()
    st = self._camCtrl.target.clone()
    if (val) {
      _computeRoam(cpos, ctar)
      self._roamBirdTimer.dest.roamPT = [ep, et]
      if (!isDoing) {
        self._roamBirdTimer.dest.birdPT = [sp, st]
      }
    } else {
      _computeBird()
      self._roamBirdTimer.dest.birdPT = [ep, et]
      if (!isDoing) {
        self._roamBirdTimer.dest.roamPT = [sp, st]
      }
    }
    self._roamBirdTimer.start()
    self.moveCamera(ep, et, time)

    function _computeRoam(cpos, ctar) {
      self._camState.saveState(1)
      self._camCtrl.minDistance = 0.001
      self._camCtrl.minPolarAngle = Math.PI / 180
      self._camCtrl.maxPolarAngle = Math.PI - Math.PI / 180
      self._camCtrl.mouseButtons = {
        LEFT: THREE.MOUSE.ROTATE,
        MIDDLE: THREE.MOUSE.PAN,
        RIGHT: THREE.MOUSE.PAN
      }
      self.enableZoom = false
      self.enableRotate = true
      self._camCtrl.rotateSpeed = -0.25
      self._camCtrl.panSpeed = 2800

      self._camState.saveState(2)
      self.enablePan = false

      let roamPT = self._roamBirdTimer.dest.roamPT
      if (roamPT) {
        ep = cpos ? cpos.toM() : roamPT[0].clone()
        et = ctar ? ctar.toM() : roamPT[1].clone()
      } else {
        ep = cpos ? cpos.toM() : new THREE.Vector3(0, 1.8, 0)
        et = ctar ? ctar.toM() : new THREE.Vector3(0, 0, -0.0001).add(ep)
      }
    }

    function _computeBird() {
      self._camState.applyState(1)
      self._camState.removeState(2)
      let birdPT = self._roamBirdTimer.dest.birdPT
      if (birdPT) {
        ep = birdPT[0].clone()
        et = birdPT[1].clone()
      } else {
        ep = sp
        et = st
      }
    }
  }

  _doMouseWheelRoam(event) {
    if (this.roamBird) {
      if (!this._camCtrl) return
      let addPos = this._camCtrl.target.clone().sub(this._camera.position)
      addPos.setY(0).normalize()
      addPos.multiplyScalar(event.wheelDelta > 0 ? 0.34 : -0.34)
      this._camera.position.add(addPos)
      this._camCtrl.target.add(addPos)
      this.render()
      this.viewport.doCameraChangeOnce()
    }
  }

  setCameraTargetDistance(distance) {
    let cp = this._camera.position
    let ct = this._camCtrl.target
    let st = ct.clone().sub(cp)
    st.normalize().multiplyScalar(distance)
    st.add(cp)
    ct.copy(st)
    this._camCtrl.update()
    this.render()
  }

  /** @description 动态移动相机
   * @param {THREE.Vector3} cpos 位置 毫米
   * @param {THREE.Vector3} ctar 焦点 毫米
   * @param {number} time 时间 毫秒
   */
  async moveCamera(pos, target, time, callbackEnd) {
    let ctid = ++this._tweenId
    while (this._tween) await Tz3dTool.awaitTime(1)
    if (ctid !== this._tweenId) return
    TWEEN.autoPlay(true)

    let self = this
    let cp = self._camera.position
    let ct = self._camCtrl.target
    time = time ? Number(time) : 990
    if (pos.distanceTo(cp) < 0.3) time = 160
    let target0, t0, t1, starData0, endData0, starData1, endData1
    target0 = target
    t0 = time
    t1 = 120
    if (self.roamBird) {
      let vec = target
        .clone()
        .sub(pos)
        .normalize()
      target0 = vec.multiplyScalar(3).add(pos)
      starData0 = { pos: cp, target: ct }
      endData0 = { pos: pos, target: target0 }
      starData1 = { target: ct }
      endData1 = { target: target }
    } else {
      let vec = ct
        .clone()
        .sub(cp)
        .normalize()
      target0 = vec.multiplyScalar(3).add(cp)
      t0 = 60
      t1 = time
      starData0 = { target: ct }
      endData0 = { target: target0 }
      starData1 = { pos: cp, target: ct }
      endData1 = { pos: pos, target: target }
    }
    let _tween = (self._tween = new TWEEN.Tween(starData0))
    _tween.to(endData0, t0)
    _tween.easing(TWEEN.Easing.Cubic.InOut)
    _tween.on('update', t => update())
    _tween.on('complete', t => {
      if (TWEEN.has(_tween)) {
        TWEEN.remove(_tween)
      }
      _tween = self._tween = new TWEEN.Tween(starData1)
      _tween.to(endData1, t1)
      _tween.easing(TWEEN.Easing.Cubic.InOut)
      _tween.on('update', t => update())
      _tween.on('complete', t => {
        self.endTweenCamera()
        if (callbackEnd) callbackEnd()
      })
      _tween.start()
    })
    self._camCtrl.dispatchEvent({ type: 'start' })
    self._tween.start()

    function update() {
      self._camCtrl.update()
      self._camCtrl.dispatchEvent({ type: 'change' })
      self.render()
    }
  }

  /** @description 动态移动相机 到node
   * @param {TzNode} node 时间 毫秒
   */
  async moveCameraObject(node) {
    if (!node || !node.bodyMesh || !node.graphic.isFocus || node.isDragging) return

    let ctid = ++this._tweenId
    while (this._tween) await Tz3dTool.awaitTime(1)
    if (ctid !== this._tweenId) return

    let cp = this._camera.position
    let ct = this._camCtrl.target

    let nwp = node.bodyMesh.getWorldPosition(new THREE.Vector3())
    let ccp = nwp.clone().sub(ct)
    let ecp = cp.clone().add(ccp)
    this.moveCameraPT(cp, ct, ecp, nwp)
  }

  /** @description 动态移动相机 高度平移
   * @param {number} targetHeight 时间 毫米
   */
  async moveCameraHeight(targetHeight) {
    let ctid = ++this._tweenId
    while (this._tween) await Tz3dTool.awaitTime(1)
    if (ctid !== this._tweenId) return

    let cp = this._camera.position
    let ct = this._camCtrl.target
    let ch = targetHeight - ct.y
    let ecp = cp.clone().setY(cp.y + ch)
    let ect = ct.clone().setY(targetHeight)
    this.moveCameraPT(cp, ct, ecp, ect)
  }

  endTweenCamera() {
    if (this._tween) {
      this._tween.stop()
    }

    TWEEN.autoPlay(false)
    if (TWEEN.has(this._tween)) {
      TWEEN.remove(this._tween)
    }
    this._tween = null
    if (this.roamBird) {
      this.setCameraTargetDistance(0.001)
      this.enablePan = true && this._camState.getState(0).enablePan
    } else if (this._camState.getState(1)) {
      this._camCtrl.minDistance = this._camState.getState(1).minDistance
    }

    this._roamBirdTimer.end()
    this._camCtrl.dispatchEvent({ type: 'end' })
    this.roamBirdEndListener.fire(this.roamBird)
  }

  endMoveCamera() {
    if (this._tween) {
      if (this.roamBird) {
        if (this._tween._valuesEnd.pos) {
          this._camera.position.copy(this._tween._valuesEnd.pos)
        }
        if (this._tween._valuesEnd.target) {
          this._camCtrl.target.copy(this._tween._valuesEnd.target)
        }
      }
      this.endTweenCamera()
    }
  }

  moveCameraPT(sp, st, ep, et, time = 420) {
    TWEEN.autoPlay(true)
    let self = this
    if (et.length() > Tz3dConst._camCtrl_targetMaxLength) {
      let vec = ep.clone().sub(et)
      et = et.clampLength(0, Tz3dConst._camCtrl_targetMaxLength)
      ep = et.clone().add(vec)
    }
    self._tween = new TWEEN.Tween({ pos: sp, target: st })
    self._tween.to({ pos: ep, target: et }, time)
    self._tween.easing(TWEEN.Easing.Cubic.InOut)
    self._tween.on('update', t => {
      self._camCtrl.update()
      self._camCtrl.dispatchEvent({ type: 'change' })
      self.render()
    })
    self._tween.on('complete', t => {
      self._tween = null
      self.endTweenCamera()
    })
    self._camCtrl.dispatchEvent({ type: 'start' })
    self._tween.start()
  }

  set cameraLayer(layers) {
    if (this._camera) {
      this._camera.layers.set(0)
      for (let i = 0; i < layers.length; i++) {
        this._camera.layers.enable(layers[i])
      }
    }
  }

  render() {
    console.log('渲染viewport11')
    this._viewport.render()
  }
}
export { Tz3dCameraCtrl }
