import { TzDataType, TzSort } from '@/core/basic/zBasic.js'

import { THREE } from '@/core/thirdPart/lib.js'
import { SVGRenderer } from 'three/examples/jsm/renderers/SVGRenderer';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { SAOPass } from 'three/examples/jsm/postprocessing/SAOPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { SSAARenderPass } from 'three/examples/jsm/postprocessing/SSAARenderPass';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';


import  Stats  from 'three/examples/jsm/libs/stats.module';


import { CopyShader } from 'three/examples/jsm/shaders/CopyShader';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader';

import {Tz3dConst,Tz3dViewportType} from './z3dConst'
import {Tz3dDrawCtrl} from './z3dDrawCtrl'
import {Tz3dTool,Tz3dUpdate} from '../z3dTool'
import { Tz3dCanvas } from './z3dCanvas'
import { Tz3dCameraCtrl } from './z3dCameraCtrl'
import { Tz3dGizmo } from './z3dGizmo'
import { Tz3dNode } from './z3dNode'
import {

  Tz3dModelModeType,
  // Tz3dCollision,
  // TzSvg,
  // TzDivInput,
 
} from '../z3d'

/** Tz3dViewport */
class Tz3dViewport {
  /** 注释

  * @property {Tz3d}  z3d             - 总控制
  * @property {Tz3dViewportType}  viewportType       - 视口类型
  * @property {number}  id        - 唯一Id
  * @property {number}  layerId      - 层级id
  * @property {array}  clientSize               - 窗口大小 [0, 0]
  * @property {Tz3dCanvas}  canvasCtrl               - Tz3dCanvas窗口控制器

  * @property {boolean}  isPrecisionModel        -  是否为设置加载精模
  * @property {Tz3dNode}  root               - node顶层对象
  * @property {TzSort}  nodes               - node 集合
  * @property {TzSvg}  svgNodeRender               - nodeSvg渲染管理器
  * @property {TzSvg}  svgTextRender               - 文本渲染管理器
  * @property {TzSvg}  svgRulerRender               - 标尺渲染管理器

  * @property {Tz3dGizmo}  gizmo               -  gizmo拖拽等控制器

  * @param {div} canvas div
  * @param {Tz3dViewportType} viewportType 视口类型
  * @param {Tz3d} z3d 总控制
  */
  constructor(canvas, viewportType, z3d) {
    this.z3d = z3d
    this.viewportType = viewportType
    this.id = z3d.getNextId()
    this.layerId = z3d.getlayerId()
    this._canvas = canvas
    this.clientSize = canvas ? [canvas.clientWidth, canvas.clientHeight] : [0, 0]

    this.callbackViewportChange = null
    this.callbackCameraChangeStart = null
    this.callbackCameraChange = null
    this.callbackCameraChangeEnd = null
    this.callbackResize = null
    this.callbackCanvasChange = null
    this.callbackGraphicDelete = null
    this.callbackUndo = null
    this.callbackRedo = null

    this.divInputs = []
    this.validHitNodes = []

    // custom
    this._focusLineWidth = 2
    this._hoverLineWidth = 1
    this._edgeDisplayPercent = 75

    this.svg = null

    this._gridVisible = true
    this._rulerAble = true
    this._rulerVisible = true
    this._bodyVisible = true
    this._edgeVisible = false
    this._pureVisible = false
    this._modelMode = Tz3dModelModeType.MAT

    this._enableRotate = true
    this._enablePan = true
    this._enableZoom = true

    // three.js
    this.isComposerRender = true
    this._renderer = null
    this._composer = null
    this._renderPass = null
    this._outlinePass1 = null
    this._outlinePass2 = null
    this._outlinePass3 = null
    this._outlinePass4 = null
    this._fxaaPass = null
    this._ssaaPass = null
    this._saoPass = null
    this._outLineObjs = []
    this._lightVisible = false
    this._gridHelperVisible = false
    this._groundHelperVisible = false

    // camera
    this._targetHeight = 0
    this.resolutionMats = []
    this._ray = new THREE.Raycaster()
    this._ray.layers.set(this.layerId)
    this._ray.near = 0
    this._ray.far = 300
    this.worldPlan = new THREE.Plane().setFromNormalAndCoplanarPoint(new THREE.Vector3(0, 1, 0), new THREE.Vector3(0, 0, 0))
    this.subsPlan = new THREE.Plane().setFromNormalAndCoplanarPoint(new THREE.Vector3(0, 1, 0), new THREE.Vector3(0, 0, 0))
    this._canvasResize = this._onCanvasResize.bind(this)

    // system
    this._update = new Tz3dUpdate(60, this._renderCurrentViewport.bind(this))
    this._canvasCtrl = new Tz3dCanvas(this)
    // this._collision = new Tz3dCollision(this)
    this._cameraCtrl = new Tz3dCameraCtrl(this)
    this._gizmo = new Tz3dGizmo(this)
    this._drawCtrl = null

    this.defaultDrawCtrl = new Tz3dDrawCtrl()

    // root
    this.isPrecisionModel = false // 精简模
    this.root = new Tz3dNode(this, null)
    z3d._nodeScene.add((this.root.bodyMesh = new THREE.Group()))
    this.nodes = new TzSort([TzDataType.INTEGER])

    // this.svgNodeRender = new TzSvg(this, 'svgNodeRender' + this.id)
    // this.svgTextRender = new TzSvg(this, 'svgTextRender' + this.id, true)
    // this.svgRulerRender = new TzSvg(this, 'svgRulerRender' + this.id)

    this._init()
  }

  resetDivInputLengthUnit(lengthUnit) {
    for (let i = 0; i < this.divInputs.length; i++) {
      let divInput = this.divInputs[i]
      if (divInput) {
        divInput.lengthSetting.lengthUnit = lengthUnit
        divInput.update()
      }
    }
  }

  addDivInput(defaultValue, valueChangeCallback, pos, title) {
    // let divInput = new TzDivInput(this, defaultValue, valueChangeCallback, pos, title)
    // this.divInputs.push(divInput)
    // return divInput
  }

  /** @description get：视窗的div  */
  get div() {
    return this.canvasCtrl.div
  }

  /** @description get：赋予的视窗的div  */
  get canvas() {
    return this._canvas
  }

  get gizmo() {
    return this._gizmo
  }

  get canvasCtrl() {
    return this._canvasCtrl
  }

  /** @description get：视窗的相机控制器  */
  get cameraCtrl() {
    return this._cameraCtrl
  }

  /** @description get：视窗的相机控制器的控制器 底层  */
  get camCtrl() {
    return this._cameraCtrl.camCtrl
  }

  /** @description get：视窗的相机  */
  get camera() {
    return this._cameraCtrl.camera
  }

  get update() {
    return this._update
  }

  /** @description get：视窗的渲染器  */
  get renderer() {
    return this._renderer
  }

  get topDiv() {
    return this.canvasCtrl.topDiv
  }

  /** @description set：视窗性能质量 0：质量 1：平衡 2：性能  */
  set qualityPerformance(v) {
    this.definition = v === 1 ? 1 : v === 2 ? 0.5 : 1.2
  }

  get definition() {
    if (!this._renderer) return 1
    return this._renderer.getPixelRatio()
  }

  /** @description getset：视窗清晰度 0~2 不含0  1为标准 */
  set definition(v) {
    if (!this._renderer) return
    v = Number(v)
    if (!v) v = 1
    if (v > 2) v = 2
    this._renderer.setPixelRatio(v)
    this.render()
  }

  get gridHelperVisible() {
    return this._gridHelperVisible
  }

  /** @description getset：是否显示网格 */
  set gridHelperVisible(v) {
    this._gridHelperVisible = !!v
    this._setGridHelperVisible(v)
  }

  _setGridHelperVisible(v) {
    if (this.__gridHelperVisible === v) return
    this.__gridHelperVisible = v
    if (v) {
      if (!this.z3d._gridHelper) {
        this.z3d.initGridHelper(this.layerId)
      } else {
        this.z3d._gridHelper.layers.enable(this.layerId)
      }
    } else {
      if (this.z3d._gridHelper) {
        this.z3d._gridHelper.layers.disable(this.layerId)
      }
    }
  }

  get groundHelperVisible() {
    return this._groundHelperVisible
  }

  /** @description  getset：是否显示地平面 */
  set groundHelperVisible(v) {
    this._groundHelperVisible = !!v
    this._setGroundHelperVisible(v)
  }

  _setGroundHelperVisible(v) {
    if (this.__groundHelperVisible === v) return
    this.__groundHelperVisible = v
    if (v) {
      if (this.lightVisible) {
        if (!this.z3d._gridPlaneStandard) this.z3d.initGridPlaneStandard(this.layerId)
        else if (this.z3d._gridPlaneStandard) this.z3d._gridPlaneStandard.layers.enable(this.layerId)
        if (this.z3d._gridPlaneBasic) this.z3d._gridPlaneBasic.layers.disable(this.layerId)
      } else {
        if (this.z3d._gridPlaneStandard) this.z3d._gridPlaneStandard.layers.disable(this.layerId)
        if (!this.z3d._gridPlaneBasic) this.z3d.initGridPlaneBasic(this.layerId)
        else if (this.z3d._gridPlaneBasic) this.z3d._gridPlaneBasic.layers.enable(this.layerId)
      }
    } else {
      if (this.z3d._gridPlaneStandard) this.z3d._gridPlaneStandard.layers.disable(this.layerId)
      if (this.z3d._gridPlaneBasic) this.z3d._gridPlaneBasic.layers.disable(this.layerId)
    }
  }

  get groundGridHeight() {
    return this.z3d._gridHelper.position.y.toMM()
  }

  /** @description  set：设置参考网格地面的高度 */
  set groundGridHeight(v) {
    let h = Number(v).toM()
    if (this.z3d._gridHelper) this.z3d._gridHelper.position.y = h
    if (this.z3d._gridPlaneStandard) this.z3d._gridPlaneStandard.position.y = h
    if (this.z3d._gridPlaneBasic) this.z3d._gridPlaneBasic.position.y = h
    this.render()
  }

  /** @description  set：是否显示球星天空 */
  set skyVisible(v) {
    if (v) {
      if (!this.z3d._cubeBox) {
        this.z3d.initEnvMap(this.layerId)
      } else {
        this.z3d._cubeBox.layers.enable(this.layerId)
      }
    } else if (this.z3d._cubeBox) {
      this.z3d._cubeBox.layers.disable(this.layerId)
      this.z3d._cubeBox.layers.disable(this.layerId)
    }
  }

  get shadowVisible() {
    return this._renderer && this._renderer.shadowMap.enabled
  }

  /** @description  getset：是否显示阴影 */
  set shadowVisible(v) {
    if (this._renderer) {
      this._renderer.shadowMap.enabled = !!v
    }
  }

  get lightVisible() {
    return this._lightVisible
  }

  /** @description  getset：是否显示灯光 */
  set lightVisible(v) {
    if (this._lightVisible === !!v) return
    this._lightVisible = !!v
    this.z3d.setLightVisible(this.layerId, this._lightVisible)
    this.groundHelperVisible = this._groundHelperVisible
  }

  get background() {
    return this._renderer.getClearColor().clone()
  }

  /** @description  getset：设置背景色 */
  set background(v) {
    if (!v) return
    if (v.isColor) {
      v = v.getHex()
    }
    this._renderer.setClearColor(v)
  }

  get is2d() {
    return this._cameraCtrl.is2d
  }

  /** @description  getset：是否为2d视窗 */
  set is2d(v) {
    this._cameraCtrl.cameraSet(!!v)
  }

  get viewDir() {
    return this._cameraCtrl._viewDir
  }

  /** @description  getset：设置在3d的空间的视窗朝向 */
  set viewDir(v) {
    this._cameraCtrl.cameraForward(v)
  }

  get edgeDisplayPercent() {
    return this._edgeDisplayPercent
  }

  /** @description  getset：递进 设置其下模型线框的完整度 1-100 100为完全 默认80 */
  set edgeDisplayPercent(v) {
    if (!v) v = 75
    let n = Number(v)
    if (Number.isNaN(n)) n = 75
    if (n < 1) n = 1
    if (n > 100) n = 100
    this._edgeDisplayPercent = n.round()
  }

  /** @description  getset：设置是否允许旋转视窗朝向 */
  set enableRotate(v) {
    this._enableRotate = v
    this._cameraCtrl.enableRotate = v
  }

  get enableRotate() {
    return this._enableRotate && !this.is2d
  }

  /** @description  getset：设置是否允许平移 */
  set enablePan(v) {
    this._enablePan = v
    this._cameraCtrl.enablePan = v
  }

  get enablePan() {
    return this._enablePan
  }

  /** @description  getset：设置是否允许缩放 */
  set enableZoom(v) {
    this._enableZoom = v
    this._cameraCtrl.enableZoom = v
  }

  get enableZoom() {
    return this._enableZoom
  }

  /** @description  getset：设置选中聚焦效果线框的宽度 */
  set focusLineWidth(v) {
    let n = Number(v)
    if (n <= 0 || this._focusLineWidth === n) return
    this._focusLineWidth = n
    let node = this.z3d.focusGraphic ? this.z3d.focusGraphic.node3d(this) : null
    if (node) node.box.mesh.material.linewidth = n
  }

  get focusLineWidth() {
    return this._focusLineWidth
  }

  /** @description  getset：设置覆盖聚焦效果线框的宽度 */
  set hoverLineWidth(v) {
    let n = Number(v)
    if (n <= 0 || this._focusLineWidth === n) return
    this._hoverLineWidth = n
    for (const hg of this.z3d.hoverGraphics) {
      let node = hg.node3d(this)
      if (node) node.box.mesh.material.linewidth = n
    }
  }

  get hoverLineWidth() {
    return this._hoverLineWidth
  }

  set camMinDistance(v) {
    // let n = Number(v)
  }

  get effectColor_hover() {
    return this._effectColor ? this._effectColor.effectColor_hover : this.z3d.nodeEffectColor.effectColor_hover
  }

  get effectColor_focus() {
    return this._effectColor ? this._effectColor.effectColor_focus : this.z3d.nodeEffectColor.effectColor_focus
  }

  get effectColor_select() {
    return this._effectColor ? this._effectColor.effectColor_select : this.z3d.nodeEffectColor.effectColor_select
  }

  get effectColor_subset() {
    return this._effectColor ? this._effectColor.effectColor_select : this.z3d.nodeEffectColor.effectColor_subset
  }

  /** @description 释放 */
  free() {
    // this.z3d.erd.removeListener(this.topDiv, this._canvasResize)
  }

  /** @description 重置为默认drawCtrl：{@link Tz3dDrawCtrl}控制器 */
  restoreDefaultDrawCtrl() {
    this.drawCtrl = this.defaultDrawCtrl
  }

  /** @description  get： {@link Tz3dViewportType}为3d */
  get isVIEW3D() {
    return [Tz3dViewportType.VIEW3D, Tz3dViewportType.VIEW3D_DESIGN, Tz3dViewportType.VIEW3D_DISPLAY].includes(this.viewportType)
  }

  /** @description  get： {@link Tz3dViewportType}为2d */
  get isVIEW2D() {
    return [
      Tz3dViewportType.VIEW2D,
      Tz3dViewportType.VIEW2D_DISPLAY,
      Tz3dViewportType.VIEW2D_DESIGN,
      Tz3dViewportType.VIEW2D_DESIGN_LINE,
      Tz3dViewportType.VIEW2D_PDM_PAVE,
      Tz3dViewportType.VIEW2D_BSPACE_PAVE,
      Tz3dViewportType.VIEW2D_PDM_PARA // PDM 参数化模型
    ].includes(this.viewportType)
  }

  _init() {
    this._initRender()
    switch (this.viewportType) {
      case Tz3dViewportType.VIEW3D:
        this._init3d()
        break
      case Tz3dViewportType.VIEW2D:
        this._init2d()
        break
      case Tz3dViewportType.VIEW3D_DISPLAY:
        this._init3d_display()
        break
      case Tz3dViewportType.VIEW2D_DISPLAY:
        this._init2d_display()
        break
      case Tz3dViewportType.VIEW3D_DESIGN:
        this._init3d_design()
        break
      case Tz3dViewportType.VIEW2D_DESIGN:
        this._init2d_design()
        break
      case Tz3dViewportType.VIEW2D_DESIGN_LINE:
        this._init2d_design_Line()
        break
      case Tz3dViewportType.VIEW2D_PDM_PAVE:
        this._init2d_pdm_Pave()
        break
      case Tz3dViewportType.VIEW2D_BSPACE_PAVE:
        this._init2d_bspace_Pave()
        break
      default:
        this._initDefault()
        break
    }
    this.lightVisible = true
    this.restoreDefaultDrawCtrl()
    this.statsVisible = true
    this.update.displayUpdateAlways = false
    this.render()

    this.z3d.cache.onProgress.add(this._viewOnProgress.bind(this), this.id)
  }

  _viewOnProgress(item, loaded, total) {
    this._onChangeScene()
  }

  setGridVisible(v) {
    this.gridHelperVisible = !!v
    this.groundHelperVisible = !!v
  }

  _initDefault() {
    this._cameraCtrl.initCamera3d()
    this._cameraCtrl.initCameraCtrl(true)
  }

  _init3d() {
    this._cameraCtrl.initCamera3d()
    this._cameraCtrl.initCameraCtrl(true)
    this.skyVisible = true
    this.gridHelperVisible = true
    this.groundHelperVisible = true
    // this._initSAOPass()
    // this._initSSAARenderPass()
  }

  _init2d() {
    this._cameraCtrl.initCamera2d()
    this._cameraCtrl.initCameraCtrl(true, [5, 5, 5])
    this.camCtrl.enableRotate = false
    this.gridHelperVisible = true
  }

  _init3d_display() {
    this._cameraCtrl.initCamera3d()
    this._cameraCtrl.initCameraCtrl(false, [3, 3, 3], [0, 0, 0])
  }

  _init2d_display() {
    this._cameraCtrl.initCamera2d()
    this._cameraCtrl.initCameraCtrl(false, [5, 5, 5])
    this.camCtrl.dispose()
  }

  _init3d_design() {
    this._cameraCtrl.initCamera3d()
    this._cameraCtrl.initCameraCtrl(true)
    this.gridHelperVisible = true
    this.groundHelperVisible = true
  }

  _init2d_design() {
    this._cameraCtrl.initCamera2d()
    this._cameraCtrl.initCameraCtrl(true, [5, 5, 5])
    this.camCtrl.enableRotate = false
    this.gridHelperVisible = true
  }

  _init2d_design_Line() {
    this._cameraCtrl.initCamera2d()
    this._cameraCtrl.initCameraCtrl(true, [5, 5, 5])
    this.camCtrl.enableRotate = false
    this.gridHelperVisible = true
  }

  _init2d_pdm_Pave() {
    this._cameraCtrl.initCamera2d()
    this._cameraCtrl.initCameraCtrl(true, [5, 5, 5])
    this.camCtrl.enableRotate = false
    this.camCtrl.enablePan = false
    this.camCtrl.enableZoom = false
    this.gridHelperVisible = false
  }

  _init2d_bspace_Pave() {
    this._cameraCtrl.initCamera2d()
    this._cameraCtrl.initCameraCtrl(true, [5, 5, 5])
    this.camCtrl.enableRotate = false
    this.camCtrl.enablePan = false
    this.camCtrl.enableZoom = false
    this.gridHelperVisible = false
  }

  _onCanvasResize() {
    this._resetCanvas()
  }

  setCanvasSize(width, height) {
    this._canvasCtrl.setSize(width, height)
    this._cameraCtrl.updateView(width, height)
    if (this._composer) {
      this._composer.setSize(width, height)
    }
    if (this._fxaaPass) {
      this._fxaaPass.uniforms.resolution.value.set(1 / width, 1 / height)
    }
    this._renderer.setSize(width, height)
    for (let i in this.resolutionMats) {
      this.resolutionMats[i].resolution.set(width, height)
    }
    this.svgNodeRender.update()
    this.svgTextRender.update()
    this.svgRulerRender.update()
  }

  _resetCanvas(isCanvasCtrl = true) {
    let width = this.div.clientWidth
    let height = this.div.clientHeight
    this._canvasCtrl.setSize(width, height)
    this.clientSize = [this.topDiv.clientWidth, this.topDiv.clientHeight]
    this._cameraCtrl.updateView(width, height)
    if (this._composer) {
      this._composer.setSize(width, height)
    }
    if (this._fxaaPass) {
      this._fxaaPass.uniforms.resolution.value.set(1 / width, 1 / height)
    }
    this._renderer.setSize(width, height)
    for (let i in this.resolutionMats) {
      this.resolutionMats[i].resolution.set(width, height)
    }
    if (isCanvasCtrl) this.canvasCtrl.compute(false)
    this.render()
    this.svgNodeRender.update()
    this.svgTextRender.update()
    this.svgRulerRender.update()
    if (this.callbackResize) this.callbackResize(this)
  }

  _initWebGl2Render() {
    console.log('创建画布canvas')
    this.webGl2Canvas = document.createElement('canvas')
    let context = this.webGl2Canvas.getContext('webgl2', {
      alpha: true,
      antialias: true
    })
    this._renderer = new THREE.WebGLRenderer({
      canvas: this.webGl2Canvas,
      context: context,
      antialias: true
      // logarithmicDepthBuffer: true // 影响网格质量 但会处理透明等深度关系
    })
    this._renderer.setSize(this.canvasCtrl.clientWidth, this.canvasCtrl.clientHeight)
    this._renderer.localClippingEnabled = true
    // this._renderer.setPixelRatio(window.devicePixelRatio)
    this._renderer.setClearColor(Tz3dConst._viewBackgroundColor)
    this._renderer.outputEncoding = THREE.LinearEncoding // THREE.LinearEncoding
    this._renderer.shadowMap.enabled = true
    this._renderer.shadowMap.type = THREE.PCFSoftShadowMap
    this._renderer.domElement.style.outline = 'none'
    this.div.appendChild(this._renderer.domElement)
  }

  _initSvgRender() {
    this._renderer = new SVGRenderer()
    this._renderer.setSize(window.innerWidth, window.innerHeight)
    this._renderer.setQuality('low')
    this._renderer.localClippingEnabled = true
    this._renderer.setClearColor(Tz3dConst._viewBackgroundColor)
    this.div.appendChild(this._renderer.domElement)
  }

  _initRender() {
    this._initWebGl2Render()
 
    // let oldV = this.z3d.erd.elements.findItem(null, [this.id])
    // if (oldV) {
    //   this.z3d.erd.removeListener(this.topDiv, oldV._canvasResize)
    //   this.z3d.erd.elements.deleteItemByKey(oldV, [this.id])
    // }
    // this.z3d.erd.listenTo(this.topDiv, this._onCanvasResize.bind(this))
    // this.z3d.erd.elements.addItem(this, [this.id])
    
  }

  _initCameraCtrl() {}

  /** @description  添加node */
  addNode() {
    return this.root.addChild()
  }

  /** @description  添加svg node */
  addSvgNode() {
    return this.svgNodeRender.root.addChild()
  }

  /** @description  添加svg 标尺 */
  addSvgRuler() {
    return this.svgRulerRender.root.addChild()
  }

  /** @description  设置视窗焦点高度 单位 mm
   * @param {number} v -高度 mm
   * @param {boolean} isTween -是否缓动
   * @param {callback} callback -回调
   */
  setTargetHeight(v, isTween, callback) {
    let h = Number(v)
    if (this._targetHeight !== h) {
      let _cameraCtrl = this._cameraCtrl
      this._targetHeight = h
      let ch = h - _cameraCtrl.camCtrl.target.y
      if (isTween) {
        _cameraCtrl.moveCameraHeight(h)
      } else {
        _cameraCtrl.camCtrl.target.y = h
        _cameraCtrl.camera.position.y = _cameraCtrl.camera.position.y + ch
        _cameraCtrl.camCtrl.update()
        this.render()
        if (callback) callback()
      }
    }
  }

  _onChangeScene() {
    let self = this
    if (!self._composer) return
    if (self._ssaaPass) {
      self._ssaaPass.enabled = false
      if (self._composer.passes.length <= 2) self.isComposerRender = false
      self.render()
    }
    TzT.lastDo(
      self,
      _ => {
        if (self._ssaaPass) {
          self._ssaaPass.sampleLevel = 2
          self._ssaaPass.enabled = true
          self.isComposerRender = true
          self.render()
          setTimeout(_ => {
            self._ssaaPass.sampleLevel = 5
            self.render()
          }, 330)
        }
      },
      480
    )
  }

  /** @description  **** */
  hideAllDivTxt() {
    let arr = Array.from(this.div.children)
    if (arr instanceof Array) {
      arr
        .filter(item => item.className === 'bulgeDiv')
        .forEach(item => {
          item.style.visibility = 'hidden'
        })
    }
  }

  /** @description  **** */
  setDivInputActive(b) {
    for (let i = 0; i < this.divInputs.length; i++) {
      if (this.divInputs[i]) {
        this.divInputs[i].setActive(b)
      }
    }
    // let arr = Array.from(this.div.children)
    // if (arr instanceof Array) {
    //   arr.filter(item => item.className === 'bulgeDiv').forEach(item => {
    //     item.style.visibility = ''
    //   })
    // }
  }

  camCtrl_mouseButtons(mouseButtons) {
    this._cameraCtrl.setMouseButtons(mouseButtons)
  }

  camCtrl_target(target) {
    this._cameraCtrl.setTarget(target)
  }

  /** @description  设置视窗控制器最近最远 单位 mm
   * @param {number} min -最近 mm
   * @param {number} max -最远 mm
   */
  camCtrl_minmaxDistance(min, max) {
    this._cameraCtrl.setMinmaxDistance(min, max)
  }

  camCtrl_position(pos) {
    this._cameraCtrl.setPosition(pos)
  }

  camCtrl_zoom(zoom) {
    this._cameraCtrl.setZoom(zoom)
  }

  camCtrl_positionResetZ() {
    this._cameraCtrl.setPositionResetZ()
  }

  camCtrl_positionResetY() {
    this._cameraCtrl.setPositionResetY()
  }

  camCtrl_positionResetXZ(pos) {
    this._cameraCtrl.setPositionResetXZ(pos)
  }

  camCtrl_uptate() {
    this._cameraCtrl.setCamCtrlUptate()
  }

  doCameraChangeOnce() {
    this.cameraCtrl.doCameraChangeOnce()
  }

  _initPostprocessing() {
    let self = this
    let parameters = {
      format: THREE.RGBFormat,
      stencilBuffer: false
    }
    let size = self._renderer.getDrawingBufferSize(new THREE.Vector2())
    let renderTarget = new THREE.WebGLMultisampleRenderTarget(size.width, size.height, parameters)
    self._composer = new EffectComposer(self._renderer, renderTarget)
    return self
  }

  _initSAOPass() {
    let self = this
    if (!self._composer) self._initPostprocessing()
    self._saoPass = new SAOPass(self.z3d._scene, self.camera, true, true)
    self._saoPass.params.saoIntensity = 0.00012
    self._saoPass.params.saoKernelRadius = 40
    self._saoPass.params.saoBlurRadius = 4
    self._composer.addPass(self._saoPass)
    return self
  }

  _initSSAARenderPass() {
    if (!this._composer) this._initPostprocessing()
    this._ssaaPass = new SSAARenderPass(this.z3d._scene, this.camera)
    this._ssaaPass.unbiased = true
    this._ssaaPass.sampleLevel = 3
    this._composer.addPass(this._ssaaPass)
    this._composer.addPass(new ShaderPass(CopyShader))
  }

  _createOutlinePass() {
    let self = this
    let pass = new OutlinePass(new THREE.Vector2(self.canvasCtrl.clientWidth, self.canvasCtrl.clientHeight), self.z3d._nodeScene, self.camera)
    pass.edgeStrength = 4
    pass.edgeGlow = 0
    pass.edgeThickness = 0.5
    pass.visibleEdgeColor = new THREE.Color(this.effectColor_hover3d)
    pass.hiddenEdgeColor = new THREE.Color(this.effectColor_hover3d)
    pass.overlayMaterial.blending = THREE.CustomBlending
    return pass
  }

  _initOutlinePass() {
    if (!this._composer) this._initPostprocessing()
    this._renderPass = new RenderPass(this.z3d._scene, this.camera)
    this._composer.addPass(this._renderPass)
    this._outlinePass1 = this._createOutlinePass()
    this._composer.addPass(this._outlinePass1)
    this._outlinePass2 = this._createOutlinePass()
    this._outlinePass2.visibleEdgeColor = new THREE.Color(this.effectColor_focus)
    this._outlinePass2.hiddenEdgeColor = new THREE.Color(this.effectColor_focus)
    this._composer.addPass(this._outlinePass2)
    this._outlinePass3 = this._createOutlinePass()
    this._outlinePass3.visibleEdgeColor = new THREE.Color(this.effectColor_select)
    this._outlinePass3.hiddenEdgeColor = new THREE.Color(this.effectColor_select)
    this._composer.addPass(this._outlinePass3)
    this._outlinePass4 = this._createOutlinePass()
    this._outlinePass4.visibleEdgeColor = new THREE.Color(this.effectColor_subset)
    this._outlinePass4.hiddenEdgeColor = new THREE.Color(this.effectColor_subset)
    this._composer.addPass(this._outlinePass4)
    this._fxaaPass = new ShaderPass(FXAAShader)
    this._fxaaPass.uniforms.resolution.value.set(1 / this.canvasCtrl.clientWidth, 1 / this.canvasCtrl.clientHeight)
    this._composer.addPass(this._fxaaPass)
    return this
  }

  addOutLineObj(obj, index) {
    if (!obj || !obj.isMesh) return
    if (!this._outlinePass1) this._initOutlinePass()
    let objs = []
    switch (index) {
      case 0:
        objs = this._computeAddOutLineObjs(obj, index)
        this._outlinePass1.selectedObjects = objs
        break
      case 1:
        objs = this._computeAddOutLineObjs(obj, index)
        this._outlinePass2.selectedObjects = objs
        break
      case 2:
        objs = this._computeAddOutLineObjs(obj, index)
        this._outlinePass3.selectedObjects = objs
        break
      case 3:
        objs = this._computeAddOutLineObjs(obj, index)
        this._outlinePass4.selectedObjects = objs
        break
    }
  }

  removeOutLineObj(obj, index) {
    if (!obj || !obj.isMesh) return
    let objs = []
    switch (index) {
      case 0:
        if (!this._outlinePass1) break
        objs = this._computeRemoveOutLineObjs(obj, index)
        this._outlinePass1.selectedObjects = objs
        break
      case 1:
        if (!this._outlinePass2) break
        objs = this._computeRemoveOutLineObjs(obj, index)
        this._outlinePass2.selectedObjects = objs
        break
      case 2:
        if (!this._outlinePass3) break
        objs = this._computeRemoveOutLineObjs(obj, index)
        this._outlinePass3.selectedObjects = objs
        break
      case 3:
        if (!this._outlinePass4) break
        objs = this._computeRemoveOutLineObjs(obj, index)
        this._outlinePass4.selectedObjects = objs
        break
      default:
        this.removeOutLineObj(obj, 0)
        this.removeOutLineObj(obj, 1)
        this.removeOutLineObj(obj, 2)
        this.removeOutLineObj(obj, 3)
        break
    }
  }

  _computeAddOutLineObjs(obj, index) {
    let objs = []
    if (!this._outLineObjs[index]) this._outLineObjs[index] = []
    this._outLineObjs[index][obj.id] = obj
    for (let i in this._outLineObjs[index]) {
      let _obj = this._outLineObjs[index][i]
      if (_obj) objs.push(this._outLineObjs[index][i])
    }
    return objs
  }

  _computeRemoveOutLineObjs(obj, index) {
    let objs = []
    if (!this._outLineObjs[index]) return objs
    this._outLineObjs[index][obj.id] = null
    for (let i in this._outLineObjs[index]) {
      let _obj = this._outLineObjs[index][i]
      if (_obj) objs.push(this._outLineObjs[index][i])
    }
    return objs
  }

  initSvg() {
    if (!this.svg) {
      this.svg = Tz3dTool.createSvg(this.div, this.id)
    }
  }

  /** @description 渲染当前视口 */
  render() {
    console.log('渲染viewport44')
    this.update.render()
  }

  _renderCurrentViewport() {
    if (this.stats) {
      this.stats.update()
    }
    if (this._composer && this.isComposerRender) this._composer.render()
    else this._renderer.render(this.z3d._scene, this.camera)
    // if (this.isVIEW3D) { Tz3dTool.consoleLogObjects(this.z3d._nodeScene) }
  }

  /** @description  获取窗口图像jpeg Base64 单位 px
   * @param {number} renderImageWidth -宽度
   * @param {number} renderImageHeight -高度
   * @param {THREE.Box2 | THREE.Box3 } box -盒子
   * @param { THREE.Vector3 } angle -角度
   * @param {number} ratio -缩放比
   */
  getRenderToImage_jpegBase64(renderImageWidth, renderImageHeight, box, angle, ratio = 1) {
    let width = this.canvasCtrl.clientWidth
    let height = this.canvasCtrl.clientHeight
    let zoom = this.camera.zoom
    let pos = this.camera.position.clone()
    let target = this.cameraCtrl.camCtrl.target.clone()

    renderImageWidth = renderImageWidth || width
    renderImageHeight = renderImageHeight || height
    if (this.camera.isOrthographicCamera) {
      let renderB = renderImageWidth / renderImageHeight
      let b = width / height
      let _zoomB = renderB < b ? renderImageWidth / width : renderImageHeight / height
      this.camera.zoom = (zoom * _zoomB) / (ratio || 1)
    } else {
    }
    if (!renderImageWidth) renderImageWidth = 900
    if (!renderImageHeight) renderImageHeight = 600
    this.setCanvasSize(renderImageWidth, renderImageHeight)

    if (box) {
      if (!box.isComputed) box.compute()
      if (box.isBox2) {
        this.viewBox2 = box
      } else if (box.isBox3) {
        this.setAutoCameraPos_psa(box.center, box.size, angle, ratio || 1)
      }
    }

    // 出图
    this._renderCurrentViewport()
    let jpegBase64 = this._renderer.domElement.toDataURL('image/jpeg')

    this._resetCanvas(false)
    if (this.camera.isOrthographicCamera) {
      this.camera.zoom = zoom
    } else {
      this.camera.position.copy(pos)
      this.cameraCtrl.camCtrl.target.copy(target)
      this.cameraCtrl.camCtrl.update()
    }
    return jpegBase64
  }

  _removeDiv() {
    if (this._canvas != null) {
      try {
        this._canvas.removeChild(this.topDiv)
      } catch {}
      if (this.cameraCtrl) this.cameraCtrl.endMoveCamera()
    }
    this._canvas = null
  }

  /** @description  设置div
   * @param {div} canvas -div
   */
  setCanvas(canvas) {
    if (this._canvas === canvas) return
    let sizeOrg = [this.clientSize[0], this.clientSize[1]]
    this._removeDiv()
    if (canvas == null) return
    let size = [canvas.clientWidth, canvas.clientHeight]
    if (this.camera.isOrthographicCamera) {
      let oldMin = sizeOrg[0] < sizeOrg[1] ? sizeOrg[0] : sizeOrg[1]
      let min = size[0] < size[1] ? size[0] : size[1]
      oldMin = oldMin === 0 ? min : oldMin
      let zoom = (this.camera.zoom * min) / oldMin
      this.camCtrl_zoom(zoom)
      this.camCtrl_uptate()
    }
    this._canvas = canvas
    this._canvas.appendChild(this.topDiv)
    this._renderer.setSize(size[0], size[1])
    this._onCanvasResize()
    if (this.drawCtrl) {
      this.drawCtrl.isUpdatedCanvas = true
    }

    this.svgNodeRender.resetCanvas()
    this.svgTextRender.resetCanvas()
    this.svgRulerRender.resetCanvas()

    this.doCameraChangeOnce()

    if (this.callbackCanvasChange) this.callbackCanvasChange(this, canvas)
  }

  /** @description set：是否显示运行状态 */
  set statsVisible(value) {
    if (value) {
      if (!this.stats) {
        this.stats = new Stats()
      }
      this.div.appendChild(this.stats.dom)
      this.stats.dom.style.top = '4px'
      this.stats.dom.style.left = '277px'
    } else if (this.stats) {
      this.div.removeChild_try(this.stats.dom)
    }
  }

  /** @description 获取创建材质，凡是需要创建材质的地方都必须从这获取
   * @param {parameters} parameters -属性
   */
  getUseMaterial(parameters) {
    let mat
    if (this.lightVisible) {
      mat = new THREE.MeshStandardMaterial(parameters)
    } else mat = new THREE.MeshBasicMaterial(parameters)
    // mat.polygonOffset = true
    // mat.polygonOffsetFactor = 0.75
    // mat.polygonOffsetUnits = 4
    return mat
  }

  clearHit() {}

  /** @description 通过id返回node
   * @param {nodeid} id
   */
  getNodeById(id) {
    return this.nodes.findItem(null, [id])
  }

  /** @description 通过ids返回nodes
   * @param {nodeid} id
   */
  getNodesByIds(ids) {
    if (!ids || !ids.length) return []
    let _nodes = []
    let nodes = this.nodes.items
    for (let node of nodes) {
      if (ids.includes(node.id)) {
        _nodes.push(node)
      }
    }
    return _nodes
  }

  /** @description 批量显隐
   * @param {array} ids
   */
  setVisibles(ids, v) {
    let nodes = this.nodes.items
    for (let node of nodes) {
      if (ids.includes(node.id)) {
        node.visible = v
      }
    }
  }

  /** @description 检测该node能否被选中
   * @param {fun} callbackCheck 返回fun
   */
  getSelectAbleNodes(callbackCheck, isDoTopNode = true) {
    let nodes = []
    for (let i = 0, len = this.root?.children?.items?.length; i < len; i++) {
      const child = this.root.children.items[i]
      if (callbackCheck && !callbackCheck(child)) continue
      child.getSelectAbleNodes(nodes, callbackCheck, isDoTopNode)
    }
    return nodes
  }

  /** @description get：获取鼠标事件 */
  get mouseEvent() {
    return window.event
  }

  _doRayTest(onlyOne, isOnlyCollisionMesh, nodes) {
    let self = this
    let hitNodes = []
    let collisionMeshes = []
    for (let node of nodes) {
      if (!node.collisionMesh) continue
      collisionMeshes.push(node.collisionMesh)
    }
    if (!collisionMeshes.length) return hitNodes

    let hitMeshes = self._ray.intersectObjects(collisionMeshes, false)
    for (let hitMesh of hitMeshes) {
      let mesh = hitMesh.object
      let hNode = mesh ? mesh.node : null
      if (isOnlyCollisionMesh && hNode) {
        hNode.rayPoint = hitMesh.point.toMM()
        hNode.rayDistance = hitMesh.distance
        hitNodes.push(hNode)
        if (onlyOne) break
        continue
      }
      rayTestNode(hNode)
    }
    hitNodes.sort((a, b) => (a.rayDistance < b.rayDistance ? -1 : 1))

    if (hitNodes.length) {
      let fn = hitNodes[0]
      let np = fn.selectTopNode
      if (np) np.rayPoint = fn.rayPoint
    }
    return hitNodes

    function rayTestNode(_hNode) {
      if (!_hNode || hitNodes.includes(_hNode)) return
      let subsetMeshes = []
      _hNode.subsets.forOf(subset => subsetMeshes.push(subset.bodyMesh))
      let hitSubset = self._ray.intersectObjects(subsetMeshes, false, [], true)[0]
      if (hitSubset) {
        _hNode.rayPoint = hitSubset.point.toMM()
        _hNode.rayDistance = hitSubset.distance
        hitNodes.push(_hNode)
      }
      if (onlyOne && hitNodes.length) return
      for (let _hNodeChild of _hNode.children.items) {
        rayTestNode(_hNodeChild)
        if (onlyOne && hitNodes.length) return
      }
    }
  }

  /** @description 从发出点和方向 发出射线检测该nodes的对象 返回nodes {@link Tz3dNode} 单位 m
   * @param {THREE.Vector3} origin 发出点 m
   * @param {THREE.Vector3} direction 方向
   * @param {boolean} onlyOne 是否只检测一个
   * @param {boolean} isOnlyCollisionMesh 是否只检测collsionMesh
   * @param {Tz3dNode} nodes 被检测的nodes
   * @param {fun} callbackCheck 被检测的node是否被允许
   */
  rayTest(origin, direction, onlyOne, isOnlyCollisionMesh, nodes, callbackCheck) {
    this._ray.ray.origin.copy(origin)
    this._ray.ray.direction.copy(direction)
    if (!nodes) nodes = this.getSelectAbleNodes(callbackCheck)
    return this._doRayTest(onlyOne, isOnlyCollisionMesh, nodes)
  }

  /** @description 从摄像机发出射线检测该nodes的对象 返回nodes {@link Tz3dNode
   * @param {event} event 屏幕坐标
   * @param {boolean} onlyOne 是否只检测一个
   * @param {boolean} isOnlyCollisionMesh 是否只检测collsionMesh
   * @param {Tz3dNode} nodes 被检测的nodes
   * @param {fun} callbackCheck 被检测的node是否被允许
   */
  rayTestByMouse(event, onlyOne, isOnlyCollisionMesh, nodes, callbackCheck) {
    if (!event) event = this.mouseEvent
    let pointer = this.getScreenPointer(event)
    this._ray.setFromCamera(pointer, this.camera)
    if (!nodes) nodes = this.getSelectAbleNodes(callbackCheck)
    return this._doRayTest(onlyOne, isOnlyCollisionMesh, nodes)
  }

  /** @description 从鼠标点检测自身nodes被覆盖的node 返回: {@link Tz3dNode} 可能为空
   * @param {*} event 屏幕坐标
   * @param {*} callbackCheck 被检测的node是否被允许
   */
  getHoverNode(event, callbackCheck, isDoTopNode = true) {
    let nodes = this.getSelectAbleNodes(callbackCheck, isDoTopNode)
    return this.rayTestByMouse(event, false, false, nodes, callbackCheck)[0]
  }

  /** @description 检测node与被检测nodes碰撞结果 返回: {@link Tz3dNode}[]
   * @param {Tz3dNode} selectNode 选中node
   * @param {Tz3dNode} checkNodes 被检测的nodes
   * @param {boolean} checkChild  是否递归检测子对象
   * @param {fun} callbackCheck 被检测的node是否被允许
   */
  collisionTest(selectNode, checkNodes, checkChild, callbackCheck) {
    return this._collision.checkCollision(selectNode, checkNodes, checkChild, callbackCheck)
  }

  boxCollisionTest(pos, angle, size, checkNodes, checkChild, callbackCheck, excludeNodes) {
    return this._collision.checkBoxCollision(pos, angle, size, checkNodes, checkChild, callbackCheck, excludeNodes)
  }

  get viewBox2() {
    let sy = this._cameraCtrl.getTargetDistance(this.camera.zoom)
    let sx = (this.canvasCtrl.clientWidth / this.canvasCtrl.clientHeight) * sy
    let center = new THREE.Vector2(this.camera.position.x, this.camera.position.z)
    let box2 = new THREE.Box2().setFromCenterAndSize(center.toMM(), new THREE.Vector2(sx, sy).toMM())
    box2.compute()
    return box2
  }

  /** @description setget 正交视图空间应用 THREE.Box2() */
  set viewBox2(v) {
    if (!v || !v.min) return
    if (!v.isComputed) v.compute()
    this.set2dCameraPos(v.center.toV3(), v.size.toV3(), 0)
  }

  /** @description 设置相机位置 单位 mm
   * @param {THREE.Vector3} center 中心位置 mm
   * @param {THREE.Vector3} size 区域大小 mm
   * @param {number} offset 偏移比例 相对比
   */
  set2dCameraPos(center, size, offset = 0.2) {
    this._cameraCtrl.set2dCameraPos(center, size, offset)
  }

  /**  @description 设置相机位置 根据node直接设置
   * @param {Tz3dNode} node node对象
   * @param {number} ratio 比例
   */
  setAutoCameraPos(node, ratio = 1.5) {
    if (!node) return
    this._cameraCtrl.setAutoCameraPos_psa(node.wPos, node.wSize, node.wAngle, ratio)
  }

  /** @description 设置相机位置 根据trans直接设置 单位 mm
   * @param {THREE.Vector3} pos 位置 mm
   * @param {THREE.Vector3} size 大小 mm
   * @param {THREE.Vector3} angle 角度
   * @param {number} ratio 比例
   */
  setAutoCameraPos_psa(pos, size, angle, ratio = 1.5) {
    this._cameraCtrl.setAutoCameraPos_psa(pos, size, angle, ratio)
  }

  /** @description 根据 length  距离设置相机位置 单位 mm */
  adaptiveCamera(length) {
    this._cameraCtrl.adaptiveCamera(length)
  }

  /** @description 动画移动至目标中心正面 -透视相机 单位 mm
   *  @param {node|THREE.Vector3} node -node或者THREE.Vector3 mm
   *  @param {THREE.Vector3} normal -朝向/法线
   *  @param {THREE.Vector3 | THREE.Vector2} size -大小 mm
   */
  moveCamera3dTo(node, normal, size, ratio = 1) {
    this._cameraCtrl.moveCamera3dTo(node, normal, size, ratio)
  }

  /**
   * @description 相机自适应尺寸 单位 mm
   * @param {THREE.Vector2 / Tz2dPoint} targetSize -目标尺寸
   * @param {Number} spaceDist -边缘空白
   * @param {THREE.Vector2 / Tz2dPoint} center -目标中心点
   */
  cameraAdaptiveSize_Orthographic(targetSize, spaceDist, center) {
    this._cameraCtrl.cameraAdaptiveSize_Orthographic(targetSize, spaceDist, center)
    this.svgNodeRender.resetTransform()
    this.svgTextRender.resetTransform()
    this.svgRulerRender.resetTransform()
  }

  get drawCtrl() {
    return this._drawCtrl
  }

  /** @description getset：{@link Tz3dDrawCtrl} 鼠标等事件控制器 */
  set drawCtrl(value) {
    if (this._drawCtrl === value) return
    if (this._drawCtrl) {
      this._drawCtrl._unbindEvent()
      if (this._drawCtrl !== this.defaultDrawCtrl) this._drawCtrl.free()
    }
    this._drawCtrl = value
    if (this._drawCtrl) {
      this._drawCtrl.viewport = this
    }
  }

  setWorldPlan(normal, pt) {
    this.worldPlan.setFromNormalAndCoplanarPoint(normal, pt)
  }

  screenToDomElementLocal(clientX, clientY) {
    let rect = this._renderer.domElement.getBoundingClientRect()
    return new THREE.Vector2(((clientX - rect.left) / rect.width) * 2 - 1, (-(clientY - rect.top) / rect.height) * 2 + 1)
  }

  /** @description 根据 屏幕坐标  获取屏幕比例点
   *  @returns {pointer} 屏幕点
   */
  getScreenPointer(event) {
    return Tz3dTool.getScreenPointer(this._renderer.domElement, event)
  }

  /** @description 根据 屏幕坐标  获取世界坐标 */
  eventToWorld(event) {
    return this.planeIntersect(this.getScreenPointer(event), this.gizmo._plan).toMM() || new THREE.Vector3()
  }

  /** @description 根据 屏幕坐标  获取世界坐标 */
  eventToWorld_Default(event) {
    return this.planeIntersect(this.getScreenPointer(event), this.worldPlan).toMM() || new THREE.Vector3()
  }

  /** @description 根据 世界坐标 获取屏幕坐标   */
  getWorldPosToScreenPointer(worldPos) {
    return Tz3dTool.getWorldPosToScreenPointer(this, worldPos)
  }

  /** @description 根据 屏幕坐标  发送射线检测this.z3d._nodeScene模型 返回 intersect[] */
  intersectObject(event) {
    let v = this.getScreenPointer(event)
    this._ray.setFromCamera(v, this.camera)
    return this._ray.intersectObject(this.z3d._nodeScene, true)
  }

  /** @description 根据 屏幕坐标  发送射线检测this.z3d._nodeScene模型 返回 [nodes, intersectPts:毫米vector3数组] */
  intersectNodes(pt) {
    let mousePt = this.screenToDomElementLocal(pt.x, pt.y)
    this._ray.setFromCamera(mousePt, this.camera)
    let intersects = this._ray.intersectObject(this.z3d._nodeScene, true)
    let nodes = []
    let intersectPts = []
    for (let obj of intersects) {
      if (obj.object.node && obj.object.node.viewport === this && !obj.object.isCollisionMesh) {
        nodes.push(obj.object.node)
        intersectPts.push(obj.point.toMM())
      }
    }
    return [nodes, intersectPts]
  }

  /** @description 根据 屏幕点  发送射线检测meshes模型数组 返回 intersect */
  intersect(pointer, meshes, isOnly) {
    return this.intersects(pointer, meshes, isOnly)[0] || false
  }

  /** @description 根据 屏幕点  发送射线检测meshes模型数组 返回 intersect[]
   * @param {pointer} pointer 屏幕点
   * @param {THREE.Mesh} meshes 被检测是模型数组
   * @param {boolean} isOnly 是否检测到一个就退掉方法
   */
  intersects(pointer, meshes, isOnly) {
    this._ray.setFromCamera(pointer, this.camera)
    if (meshes) {
      return this._ray.intersectObjects(meshes, false, [], isOnly)
    } else {
      return this._ray.intersectObject(this.z3d._nodeScene, true, [], isOnly)
    }
  }

  /** @description 根据 屏幕点  平面THREE.Plan 测meshes模型数组 返回 intersect[]
   *  @param {pointer} pointer  屏幕点
   *  @param {THREE.Plane} plan 平面THREE.Plan
   */
  planeIntersect(pointer, plan) {
    this._ray.setFromCamera(pointer, this.camera)
    let pos = this._ray.ray.intersectPlane(plan, new THREE.Vector3())
    if (!pos) {
      let target = this.camCtrl.target
      let nor = this.camera.position.clone().sub(target)
      this.subsPlan.setFromNormalAndCoplanarPoint(nor.normalize(), target.clone())
      let _pos = this._ray.ray.intersectPlane(this.subsPlan, new THREE.Vector3())
      if (_pos) {
        _pos.sub(this.camera.position).setY(0)
        pos = _pos.normalize().multiplyScalar(260)
      }
    } else pos = pos.clampLength(0, 260)
    return pos
  }

  screenToWorld(x, y) {
    let v = this.screenToDomElementLocal(x, y)
    this._ray.setFromCamera(v, this.camera)
    let pos = this._ray.ray.intersectPlane(this.worldPlan, new THREE.Vector3(0, 0, 0))
    return pos.toMM() // set target(0, 0, 0)
  }

  get edgeVisible() {
    return this._edgeVisible
  }

  set edgeVisible(value) {
    if (this._edgeVisible === value) return
    this._edgeVisible = value
    for (let node of this.root.children.items) {
      node.setEdgeVisible(value, -1, true)
      node.updateRender()
    }
  }

  get bodyVisible() {
    return this._bodyVisible
  }

  set bodyVisible(value) {
    if (this._bodyVisible === value) return
    this._bodyVisible = value
    for (let node of this.root.children.items) {
      node.setBodyVisible(value, -1, true)
      node.updateRender()
    }
  }

  get pureVisible() {
    return this._pureVisible
  }

  set pureVisible(value) {
    if (this._pureVisible === value) return
    this._pureVisible = value
    for (let node of this.root.children.items) {
      node.setPureVisible(value, -1, true)
      node.updateRender()
    }
  }

  /** @description 设置node模型显示模式
   * @param {boolean} bodyVisible 自身node是否显示为线框
   * @param {boolean} edgeVisible 自身node是否显示为主体网格
   * @param {boolean} pureVisible 自身node是否显示为白模
   */
  setModelVisible(bodyVisible, edgeVisible, pureVisible) {
    let _bodyVisible = null
    let _edgeVisible = null
    let _pureVisible = null
    if (this._bodyVisible !== bodyVisible) {
      this._bodyVisible = bodyVisible
      _bodyVisible = bodyVisible
    }
    if (this._edgeVisible !== edgeVisible) {
      this._edgeVisible = edgeVisible
      _edgeVisible = edgeVisible
    }
    if (this._pureVisible !== pureVisible) {
      this._pureVisible = pureVisible
      _pureVisible = pureVisible
    }
    for (let node of this.root.children.items) {
      node.setModelVisible(_bodyVisible, _edgeVisible, _pureVisible, true)
    }
  }

  /** @description getset：模型显示模式 {@link Tz3dModelModeType} */
  set modelMode(value) {
    if (value !== this._modelMode) {
      this._modelMode = value
      if (value === Tz3dModelModeType.MAT) {
        this.setModelVisible(true, false, false)
      } else if (value === Tz3dModelModeType.LINE) {
        this.setModelVisible(true, true, true)
      } else if (value === Tz3dModelModeType.MAT_LINE) {
        this.setModelVisible(true, true, false)
      } else if (value === Tz3dModelModeType.LINE_TRANSP) {
        this.setModelVisible(false, true, false)
      }
    }
    this.render()
  }

  get modelMode() {
    return this._modelMode
  }

  /** @description getset：视窗缩放比 越近越大 0 ~ 300 */
  set viewZoom(value) {
    this._cameraCtrl.viewZoom = value
  }

  get viewZoom() {
    return this._cameraCtrl.viewZoom
  }

  /** 清空nodes */
  doClearNodes() {
    let nodes = this.root.children.items
    for (let i = nodes.length - 1; i > -1; i--) {
      nodes[i].free()
    }
  }
}
export { Tz3dViewport }
