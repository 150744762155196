<template>
  <div>
  <!-- 主渲染区 -->
  <home2d3dMainArea></home2d3dMainArea>
    <leftNav />
   <leftTab  @drawPlank="drawPlank" @moveCamera="moveCamera" />
   <drawerTab  :visible="drawerVisible" :boxHeight="boxHeight" @drawDrawer="changeDrawer" @paramsDrawer="onParamsDrawer"/>

  </div>
 

</template>

<script setup>
import { defineExpose, onMounted, ref,onBeforeUnmount } from "vue";
import home2d3dMainArea from './home2d3dMainArea'
import leftTab from './left-tab.vue'
import drawerTab from './drawer-tab.vue'
import leftNav from '../left/index.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import mitts from "@/utils/bus"

let z3d = null;
let renderer = null
let elementDom = null
let ctrl3d = null;
const boxHeight = ref(0)
const drawerVisible = ref(false)
mitts.on('showDrawer',(showData)=>{
  const {isShow,bHeight} = showData
  drawerVisible.value = isShow
  boxHeight.value = bHeight


})
const drawPlank = (type)=>{
  // switch(type){
  //   case 'cab':
  //   ctrl3d.createNullCab()
  //     break
  //   default:
  //   z3d.controls.enableRotate = false
  //   ctrl3d.state = type
  //   moveCamera(ctrl3d.viewDir)
  //   openVn()

  // }

}

const toUndo = () => {
  //@ts-ignore
  const { editor, spaceRuler } = z3d

  editor.undo()
  spaceRuler.deleteRuler()



}
const toRedo = () => {
  //@ts-ignore
  const { editor } = z3d
  editor.redo()


}

const toScaleCab=()=>{
  const {transControl,cab3d,ctrl3d} = z3d
 
  let tempBox = ctrl3d.createTempBox(cab3d)
  tempBox.name ='cab'
    tempBox.material.opacity = 0.1
    tempBox.material.visible = true
    z3d.scene.add(tempBox)
  transControl.attach(tempBox)

}

const moveCamera = (dir)=>{
  // z3d.moveCamera(dir)
}

const openVn = () => {
  ElMessage({
    showClose: true,
    message: '在画布中点击开始绘制，右键或Esc退出 ',
    type: 'info',
  })
}



const getz3d = () => {
  return z3d
}

const toAddModel = (svgs,binRect)=>{

}

const toDelModel = ()=>{
  z3d.removeChildNode()
}

const toDelCurModel = (type)=>{
  type=='del'? z3d.ctrl3d.toDeleteNode():z3d.ctrl3d.clear() // z3d.clearScene()
 
}


const toFindModelById=(id)=>{
  return z3d.findModelById(id)

}
const toParseDXF =(url,cabSet,color)=>{
 

}

const parseDxf3d =(vecs,cabSet,color)=>{
 
}
function handleContextmenu(event) {
            event.preventDefault(); // 取消默认右键菜单
            ctrl3d.state = 0
            z3d.controls.enableRotate = true;

          }

function handleKeypress(e) {
  var keyNum=window.event ? e.keyCode :e.which;       //获取被按下的键值  
            //判断如果用户按下了回车键（keycody=13）  
            switch(keyNum){
              case 13:
                //回车
                break
              case 27:
                //Esc
                handleContextmenu(e)
                break
              case 32:
                //空格
                break
              case 46:
              toDelCurModel('del')
                //Del
                break
            }
            

            //判断如果用户按下了Shift键(keycode=32)和回车键（keycody=13）  
            if (13 == e.keyCode && e.shiftKey){
                alert('您按下了Shift和回车');  
            }
}

const changeDrawer = (data)=>{
  drawerVisible.value = Object.keys(data).length>0
  z3d.ctrl3d.toDrawDrawer(data)
}
const onParamsDrawer = (data)=>{
  z3d.ctrl3d.paramsDrawerBox(data)
}
onMounted(() => {

});

onBeforeUnmount(() => {
  
  });



defineExpose({
 getz3d,toAddModel,toDelModel,toDelCurModel,toFindModelById,toParseDXF,toUndo,toRedo,parseDxf3d,toScaleCab
});
</script>

<style scoped lang="less">


.three-box{
  width:100%;
  height: calc(100% - 100px);

}
.webgl {
  width: 100%;
  height: 100%;
  outline: none;
  display: block;
}

.webgl-box{
  width: 100%;
  height: 100%;
  outline: none;
  display: block;
}

a {
  color: #08e;
}

.selectBox {
  border: 1px solid #55aaff;
  background-color: rgba(75, 160, 255);
  position: fixed;
  display: block;


}</style>
