import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/tools/cabinet/cab1.png'


const _hoisted_1 = { class: "cabinet" }
const _hoisted_2 = { class: "cabinet-title" }
const _hoisted_3 = { class: "cabinet-main" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "cabinetli" }
const _hoisted_6 = { class: "cabinetli-tit" }
const _hoisted_7 = { class: "cabinet-main-tab" }

import {ref} from 'vue';
import sidePackage from './side-package.vue';
import sideCover from './side-cover.vue';
import sideCoverTop from './side-cover-top.vue';
import sideCoverBot from './side-cover-bot.vue';
import defineCabinet from './define-cabinet.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    "modelValue": {},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

const modelgoods = _useModel(__props, "modelValue");
const boardArr = [
  {title: '侧包顶底',value:1},
  {title: '顶底盖侧',value:2},
  {title: '顶盖侧',value:3},
  {title: '底盖侧',value:4},
  {title: '自定义',value:5},
]
const active = ref(1);
const getActive = (val:any) => {
  active.value = val.value;
}
const closeWin = () => {
  modelgoods.value = -1;
}

return (_ctx: any,_cache: any) => {
  const _component_Close = _resolveComponent("Close")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", null, "柜体库", -1)),
      _createElementVNode("div", null, [
        _createVNode(_component_el_icon, { onClick: closeWin }, {
          default: _withCtx(() => [
            _createVNode(_component_Close)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("ul", null, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(boardArr, (item, k) => {
          return _createElementVNode("li", {
            key: k,
            class: _normalizeClass(active.value===item.value?'liactive':''),
            onClick: ($event: any) => (getActive(item))
          }, [
            _createElementVNode("div", _hoisted_5, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "cabinetli-img" }, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: ""
                })
              ], -1)),
              _createElementVNode("div", _hoisted_6, _toDisplayString(item.title), 1)
            ])
          ], 10, _hoisted_4)
        }), 64))
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_el_scrollbar, { style: {"height":"calc(90vh - 30px)","overflow-y":"auto"} }, {
          default: _withCtx(() => [
            (active.value===1)
              ? (_openBlock(), _createBlock(sidePackage, { key: 0 }))
              : _createCommentVNode("", true),
            (active.value===2)
              ? (_openBlock(), _createBlock(sideCover, { key: 1 }))
              : _createCommentVNode("", true),
            (active.value===3)
              ? (_openBlock(), _createBlock(sideCoverTop, { key: 2 }))
              : _createCommentVNode("", true),
            (active.value===4)
              ? (_openBlock(), _createBlock(sideCoverBot, { key: 3 }))
              : _createCommentVNode("", true),
            (active.value===5)
              ? (_openBlock(), _createBlock(defineCabinet, { key: 4 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})