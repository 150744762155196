import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "boardindex" }
const _hoisted_2 = { class: "boardindex-title" }
const _hoisted_3 = { class: "boardindex-main" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "boardindex-main-tab" }

import {ref} from 'vue';
import layerBoard from './layer-board.vue';
import standBoard from './stand-board.vue';
import backBoard from './back-board.vue';
import kickBoard from './kick-board.vue';
import pullBar from './pull-bar.vue';
import frontPanel from './front-panel.vue';
import topPanel from './top-panel.vue';
import fencePanel from './fence-board.vue';
import drawerStopper from './drawer-stopper.vue';
import curvedBoard from './curved-board.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    "modelValue": {},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

const modelgoods = _useModel(__props, "modelValue");
const boardArr = [
  {title: '层板',value:1},
  {title: '立板',value:2},
  {title: '背板',value:3},
  {title: '踢脚板',value:4},
  {title: '拉条',value:5},
  {title: '前挡板',value:6},
  {title: '顶挡板',value:7},
  {title: '围板',value:8},
  {title: '抽屉挡条',value:9},
  {title: '弧形板',value:10},
]
const active = ref(1);
const getActive = (val:any) => {
  active.value = val.value;
}
const closeWin = () => {
  modelgoods.value = -1;
}

return (_ctx: any,_cache: any) => {
  const _component_Close = _resolveComponent("Close")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", null, "添加板件", -1)),
      _createElementVNode("div", null, [
        _createVNode(_component_el_icon, { onClick: closeWin }, {
          default: _withCtx(() => [
            _createVNode(_component_Close)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("ul", null, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(boardArr, (item, k) => {
          return _createElementVNode("li", {
            key: k,
            class: _normalizeClass(active.value===item.value?'liactive':''),
            onClick: ($event: any) => (getActive(item))
          }, _toDisplayString(item.title), 11, _hoisted_4)
        }), 64))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_el_scrollbar, { style: {"height":"calc(90vh - 30px)","overflow-y":"auto"} }, {
          default: _withCtx(() => [
            (active.value===1)
              ? (_openBlock(), _createBlock(layerBoard, { key: 0 }))
              : _createCommentVNode("", true),
            (active.value===2)
              ? (_openBlock(), _createBlock(standBoard, { key: 1 }))
              : _createCommentVNode("", true),
            (active.value===3)
              ? (_openBlock(), _createBlock(backBoard, { key: 2 }))
              : _createCommentVNode("", true),
            (active.value===4)
              ? (_openBlock(), _createBlock(kickBoard, { key: 3 }))
              : _createCommentVNode("", true),
            (active.value===5)
              ? (_openBlock(), _createBlock(pullBar, { key: 4 }))
              : _createCommentVNode("", true),
            (active.value===6)
              ? (_openBlock(), _createBlock(frontPanel, { key: 5 }))
              : _createCommentVNode("", true),
            (active.value===7)
              ? (_openBlock(), _createBlock(topPanel, { key: 6 }))
              : _createCommentVNode("", true),
            (active.value===8)
              ? (_openBlock(), _createBlock(fencePanel, { key: 7 }))
              : _createCommentVNode("", true),
            (active.value===9)
              ? (_openBlock(), _createBlock(drawerStopper, { key: 8 }))
              : _createCommentVNode("", true),
            (active.value===10)
              ? (_openBlock(), _createBlock(curvedBoard, { key: 9 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})