import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { id: "drag" }
const _hoisted_2 = { class: "drag-item" }
const _hoisted_3 = {
  key: 0,
  class: "drag-item"
}
const _hoisted_4 = { class: "drag-item" }
const _hoisted_5 = { class: "drag-way" }
const _hoisted_6 = { class: "drag-way-content" }
const _hoisted_7 = { class: "drag-way-subcontent" }
const _hoisted_8 = { class: "drag-way-subcontent" }
const _hoisted_9 = { class: "drag-way" }
const _hoisted_10 = { class: "drag-way-title" }
const _hoisted_11 = { class: "drag-way-content" }
const _hoisted_12 = { class: "drag-way-subcontent" }
const _hoisted_13 = { class: "drag-way-subcontent" }
const _hoisted_14 = { class: "drag-way-subcontent" }
const _hoisted_15 = { class: "drag-item" }
const _hoisted_16 = { class: "drag-bottom" }

import { ref, onMounted } from 'vue'
import { Plus } from '@element-plus/icons-vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'drawer-tab',
  props: {
  visible: {
    type: Boolean,
    default: false
  },
  boxHeight:{
    type: Number,
    default: 0
  }

},
  emits: ['drawDrawer','paramsDrawer' ],
  setup(__props, { emit: __emit }) {

const props = __props
const wayArr = ref([{ type: '1', label: '自由摆放' }, { type: '2', label: '填充' }, { type: '3', label: '下对齐' }, { type: '4', label: '上对齐' }])
const coverArr = ref([{ type: '1', label: '内嵌' }, { type: '2', label: '半盖' }, { type: '3', label: '全盖' }])
const coverData = ref([
  { type: '1', title: '左掩',coverType:'1',coverValue:0,gapValue:0 }, 
{ type: '2', title: '右掩',coverType:'1',coverValue:0,gapValue:0 },
{ type: '3', title: '上掩',coverType:'1',coverValue:0,gapValue:0 },
{ type: '4', title: '下掩',coverType:'1',coverValue:0,gapValue:0 },

])
const genForm = ()=>{
  return {
  name: '',
  way: '1',
  height: 100,
  nums:1,
  cover: '1',
  isBaff: '1'

}
}
const form = ref(genForm())
const activeNames = ref(['1'])
const handleChange = (val: string[]) => {
  console.log(val)
}

const changeCover = (val: string) => {
  coverData.value.forEach(item=>{
    item.coverType = val
  })


}
const emits = __emit

const cancelDrawer = () => {
 
 emits('drawDrawer',[])
 form.value = genForm()
}
const handleDrawer = () => {
 
  emits('drawDrawer',form.value)
  form.value = genForm()
}

const changeWay = (val)=>{
  if(val==='2') form.value.height = props.boxHeight
  else form.value.height = 100
  emits('paramsDrawer',{way:val,nums:1,height:form.value.height })
}

const changeNums = ()=>{
  form.value.height = props.boxHeight/form.value.nums
  // emits('paramsDrawer',{way:form.value.way,height:form.value.height,nums:form.value.nums})
}

const changeHeight = ()=>{

  emits('paramsDrawer',{way:form.value.way,height:form.value.height,nums:form.value.nums})
}





let initWidth = ref(0) // 父元素的宽-自适应值
let initHeight = ref(0) // 父元素的高-自适应值
let startclientX = ref(0) // 元素拖拽前距离浏览器的X轴位置
let startclientY = ref(0) //元素拖拽前距离浏览器的Y轴位置
let elLeft = ref(0) // 元素的左偏移量
let elTop = ref(0) // 元素的右偏移量
let back_box = ref()
// 页面初始化
function initBodySize() {
  initWidth.value = back_box.value.clientWidth; // 拿到父元素宽
  initHeight.value = initWidth.value * ((1080 * 0.88) / (1920 - 1080 * 0.02)); // 根据宽计算高实现自适应
}
// 拖拽开始事件
function dragstart(e) {
  console.log(e);
  startclientX.value = e.clientX; // 记录拖拽元素初始位置
  startclientY.value = e.clientY;
}
// 拖拽完成事件
function dragend(e) {
  console.log(e);
  let x = e.clientX - startclientX.value; // 计算偏移量
  let y = e.clientY - startclientY.value;
  elLeft.value += x; // 实现拖拽元素随偏移量移动
  elTop.value += y;
}
onMounted(() => {
  initBodySize()
})

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_button = _resolveComponent("el-button")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "back_box",
      ref_key: "back_box",
      ref: back_box
    }, [
      _createElementVNode("div", {
        class: "drag_box",
        draggable: "true",
        onDragstart: _cache[7] || (_cache[7] = ($event: any) => (dragstart($event))),
        onDragend: _cache[8] || (_cache[8] = ($event: any) => (dragend($event))),
        style: _normalizeStyle(`left:${_unref(elLeft)}px;top:${_unref(elTop)}px`)
      }, [
        _cache[23] || (_cache[23] = _createElementVNode("div", { class: "drag-title" }, " 自由建柜 ", -1)),
        _createVNode(_component_el_scrollbar, { style: {"height":"calc(90vh - 80px)","overflow-y":"auto","margin-left":"10px"} }, {
          default: _withCtx(() => [
            _createVNode(_component_el_collapse, {
              modelValue: activeNames.value,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((activeNames).value = $event)),
              onChange: handleChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_collapse_item, {
                  title: "抽屉参数",
                  name: "1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "drag-item-title" }, "对齐方式", -1)),
                      _createVNode(_component_el_select, {
                        modelValue: form.value.way,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.way) = $event)),
                        placeholder: "Select",
                        size: "small",
                        class: "label-width",
                        onChange: changeWay
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(wayArr.value, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item.type,
                              label: item.label,
                              value: item.type
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    (form.value.way == '2')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "drag-item-title" }, "抽屉数量", -1)),
                          _createVNode(_component_el_input, {
                            modelValue: form.value.nums,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.nums) = $event)),
                            type: "number",
                            min: 1,
                            size: "small",
                            class: "label-width",
                            onBlur: changeNums,
                            placeholder: "Please input"
                          }, null, 8, ["modelValue"])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_4, [
                      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "drag-item-title" }, "抽屉高度", -1)),
                      _createVNode(_component_el_input, {
                        modelValue: form.value.height,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.height) = $event)),
                        type: "number",
                        min: 10,
                        size: "small",
                        class: "label-width",
                        onBlur: changeHeight,
                        placeholder: "Please input"
                      }, null, 8, ["modelValue"])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_collapse_item, {
                  title: "掩盖方式",
                  name: "2"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _cache[14] || (_cache[14] = _createElementVNode("div", { class: "drag-way-title" }, "掩门整体", -1)),
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_el_select, {
                            modelValue: form.value.cover,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.cover) = $event)),
                            placeholder: "Select",
                            size: "small",
                            class: "label-width1",
                            onChange: changeCover
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(coverArr.value, (item) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: item.type,
                                  label: item.label,
                                  value: item.type
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"]),
                          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "drag-way-desc" }, "掩盖方式", -1))
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_el_input, {
                            modelValue: form.value.height,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.height) = $event)),
                            type: "number",
                            min: 0,
                            size: "small",
                            class: "label-width1",
                            placeholder: "Please input"
                          }, null, 8, ["modelValue"]),
                          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "drag-way-desc" }, "缝隙值", -1))
                        ])
                      ])
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(coverData.value, (coverItem) => {
                      return (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, _toDisplayString(coverItem.title), 1),
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("div", _hoisted_12, [
                            _createVNode(_component_el_select, {
                              modelValue: coverItem.coverType,
                              "onUpdate:modelValue": ($event: any) => ((coverItem.coverType) = $event),
                              placeholder: "Select",
                              size: "small",
                              class: "label-width1"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(coverArr.value, (item) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    key: item.type,
                                    label: item.label,
                                    value: item.type
                                  }, null, 8, ["label", "value"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "onUpdate:modelValue"]),
                            _cache[15] || (_cache[15] = _createElementVNode("div", { class: "drag-way-desc" }, "掩盖方式", -1))
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            _createVNode(_component_el_input, {
                              modelValue: coverItem.coverValue,
                              "onUpdate:modelValue": ($event: any) => ((coverItem.coverValue) = $event),
                              type: "number",
                              min: 0,
                              size: "small",
                              class: "label-width2",
                              placeholder: "Please input"
                            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "drag-way-desc" }, "掩盖值", -1))
                          ]),
                          _createElementVNode("div", _hoisted_14, [
                            _createVNode(_component_el_input, {
                              modelValue: coverItem.gapValue,
                              "onUpdate:modelValue": ($event: any) => ((coverItem.gapValue) = $event),
                              type: "number",
                              min: 0,
                              size: "small",
                              class: "label-width2",
                              placeholder: "Please input"
                            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                            _cache[17] || (_cache[17] = _createElementVNode("div", { class: "drag-way-desc" }, "缝隙值", -1))
                          ])
                        ])
                      ]))
                    }), 256))
                  ]),
                  _: 1
                }),
                (form.value.cover === '1')
                  ? (_openBlock(), _createBlock(_component_el_collapse_item, {
                      key: 0,
                      title: "挡板参数",
                      name: "3"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_15, [
                          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "drag-item-title" }, "抽屉挡板", -1)),
                          _createVNode(_component_el_radio_group, {
                            modelValue: form.value.isBaff,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((form.value.isBaff) = $event))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_radio, {
                                value: "1",
                                size: "small"
                              }, {
                                default: _withCtx(() => _cache[18] || (_cache[18] = [
                                  _createTextVNode("有")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_el_radio, {
                                value: "2",
                                size: "small"
                              }, {
                                default: _withCtx(() => _cache[19] || (_cache[19] = [
                                  _createTextVNode("无")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_component_el_button, { onClick: cancelDrawer }, {
            default: _withCtx(() => _cache[21] || (_cache[21] = [
              _createTextVNode("取消")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: handleDrawer
          }, {
            default: _withCtx(() => _cache[22] || (_cache[22] = [
              _createTextVNode("确定")
            ])),
            _: 1
          })
        ])
      ], 36)
    ], 512)
  ], 512)), [
    [_vShow, __props.visible]
  ])
}
}

})