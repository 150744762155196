<template>
  <div id="leftdrag">
    <div class="backnav_box">
      <ul class="dragnav_box">
        <li @click="changeActive(0)">
          <!-- 板件 -->
          <img  src="@/assets/tools/11.png" alt="">
        </li>
        <li @click="changeActive(1)">
          <img  src="@/assets/tools/12.png" alt="">
        </li>
        <li>
          <img  src="@/assets/tools/13.png" alt="">
        </li> 
        <li>
          <img  src="@/assets/tools/14.png" alt="">
        </li>
        <li>
          <img  src="@/assets/tools/15.png" alt="">
        </li>
        <li>
          <img  src="@/assets/tools/16.png" alt="">
        </li>
        <li>
          <img  src="@/assets/tools/17.png" alt="">
        </li>
        <li>
          <img  src="@/assets/tools/18.png" alt="">
        </li>
        <li>
          <img  src="@/assets/tools/19.png" alt="">
        </li>
        <li>
          <img  src="@/assets/tools/20.png" alt="">
        </li>
        <li>
          <img  src="@/assets/tools/21.png" alt="">
        </li>
        <li>
          <img  src="@/assets/tools/22.png" alt="">
        </li>
      </ul>
    </div>
    
    <div class="dragnav-child" v-if="active!==-1">
      <!-- 板件 -->
      <boardNav v-if="active===0" v-model="active"/>
      <!-- 柜体库 -->
      <cabinetNav v-if="active===1" v-model="active"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref} from  'vue';
import boardNav from './board/index.vue';
import cabinetNav  from './cabinet/index.vue';

const active = ref(-1);
const changeActive = (val:number) => {
  // console.log('val',val)
  active.value = val;
}
</script>

<style scoped lang="less">
.backnav_box {
  background: #ccc;
  /* width: 200px;
  height: 50vh;
  position: fixed; */
  position: absolute;
  top: calc((100vh - 500px) / 2);
  left: 5px;
  bottom: 0;
}
.dragnav_box {
  width: 50px;
  height: 500px;
  background: #fff;
  position: absolute;
  z-index: 10;
  user-select: none; 
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  list-style: none;
  padding: 10px 0;
  li {
    text-align: center;
    margin-bottom: 10px;
    img {
      width: 30px;
    }
  }
}
.dragnav-child {
  width: 390px;
  height: 90vh;
  background: #ffff;
  position: absolute;
  z-index: 11;
  top: 50px;
  left: 60px;
  user-select: none; 
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
}
</style>