import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/tools/11.png'
import _imports_1 from '@/assets/tools/12.png'
import _imports_2 from '@/assets/tools/13.png'
import _imports_3 from '@/assets/tools/14.png'
import _imports_4 from '@/assets/tools/15.png'
import _imports_5 from '@/assets/tools/16.png'
import _imports_6 from '@/assets/tools/17.png'
import _imports_7 from '@/assets/tools/18.png'
import _imports_8 from '@/assets/tools/19.png'
import _imports_9 from '@/assets/tools/20.png'
import _imports_10 from '@/assets/tools/21.png'
import _imports_11 from '@/assets/tools/22.png'


const _hoisted_1 = { id: "leftdrag" }
const _hoisted_2 = { class: "backnav_box" }
const _hoisted_3 = { class: "dragnav_box" }
const _hoisted_4 = {
  key: 0,
  class: "dragnav-child"
}

import {ref} from  'vue';
import boardNav from './board/index.vue';
import cabinetNav  from './cabinet/index.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const active = ref(-1);
const changeActive = (val:number) => {
  // console.log('val',val)
  active.value = val;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (changeActive(0)))
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1)
        ])),
        _createElementVNode("li", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (changeActive(1)))
        }, _cache[5] || (_cache[5] = [
          _createElementVNode("img", {
            src: _imports_1,
            alt: ""
          }, null, -1)
        ])),
        _cache[6] || (_cache[6] = _createStaticVNode("<li data-v-613153e8><img src=\"" + _imports_2 + "\" alt=\"\" data-v-613153e8></li><li data-v-613153e8><img src=\"" + _imports_3 + "\" alt=\"\" data-v-613153e8></li><li data-v-613153e8><img src=\"" + _imports_4 + "\" alt=\"\" data-v-613153e8></li><li data-v-613153e8><img src=\"" + _imports_5 + "\" alt=\"\" data-v-613153e8></li><li data-v-613153e8><img src=\"" + _imports_6 + "\" alt=\"\" data-v-613153e8></li><li data-v-613153e8><img src=\"" + _imports_7 + "\" alt=\"\" data-v-613153e8></li><li data-v-613153e8><img src=\"" + _imports_8 + "\" alt=\"\" data-v-613153e8></li><li data-v-613153e8><img src=\"" + _imports_9 + "\" alt=\"\" data-v-613153e8></li><li data-v-613153e8><img src=\"" + _imports_10 + "\" alt=\"\" data-v-613153e8></li><li data-v-613153e8><img src=\"" + _imports_11 + "\" alt=\"\" data-v-613153e8></li>", 10))
      ])
    ]),
    (active.value!==-1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (active.value===0)
            ? (_openBlock(), _createBlock(boardNav, {
                key: 0,
                modelValue: active.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((active).value = $event))
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          (active.value===1)
            ? (_openBlock(), _createBlock(cabinetNav, {
                key: 1,
                modelValue: active.value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((active).value = $event))
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})