import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { id: "drag" }
const _hoisted_2 = { class: "drag-item" }
const _hoisted_3 = { class: "drag-item" }
const _hoisted_4 = { class: "box-butts" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "drag-item" }
const _hoisted_7 = { class: "box-opt" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "t1" }
const _hoisted_11 = { class: "t2" }

import {ref, onMounted} from 'vue'
import {Plus} from '@element-plus/icons-vue'
import QIcon from '@/assets/choice/1.png'
import WIcon from '@/assets/choice/2.png'
import EIcon from '@/assets/choice/3.png'
import RIcon from '@/assets/choice/4.png'
import TIcon from '@/assets/choice/5.png'
import YIcon from '@/assets/choice/6.png'
import UIcon from '@/assets/choice/7.png'



export default /*@__PURE__*/_defineComponent({
  __name: 'left-tab',
  emits: ['drawPlank','moveCamera'],
  setup(__props, { emit: __emit }) {

const emits = __emit

const zDir = {
    TOP: 0,
    BOTTOM: 1,
    LEFT: 2,
    RIGHT: 3,
    FRONT: 4,
    BACK: 5
  }
  
const activeitem = ref(1);
const arr1 = [
  {value:1,title:'前视图',dir:zDir.FRONT},
  {value:2,title:'左视图',dir:zDir.LEFT},
  {value:3,title:'顶视图',dir:zDir.TOP},
  {value:4,title:'后视图',dir:zDir.BACK},
  {value:5,title:'右视图',dir:zDir.RIGHT},
  {value:6,title:'底视图',dir:zDir.BOTTOM},
]

const changeVision = (item) => {
  activeitem.value = item.value;
  emits('moveCamera',item.dir)
}

const activeopt = ref('');
const arr2 = [
  {value:'B',title:'柜体板件',icon: QIcon,type:1,desc:'柜体外横画添加顶板或底板，竖画添加左右侧板。柜体内横画添加层板，竖画添加立板，斜画添加背板'},
  {value:'T',title:'踢脚底板',icon: WIcon,type:1,desc:'柜体底部横画添加踢脚板'},
  {value:'C',title:'抽屉',icon: EIcon,type:1,desc:'柜体内横画添加抽屉'},
  {value:'Y',title:'衣通',icon: RIcon,type:1,desc:'柜体内横画添加衣通'},
  {value:'S',title:'收口板',icon: TIcon,type:1,desc:'横画添加顶收口板或底收口板，竖画添加左收口板或右收口板'},
  {value:'L',title:'拉条',icon: YIcon,type:1,desc:'柜体内横画添加拉条'},
  {value:'M',title:'门板',icon: UIcon,type:1,desc:'柜体内斜画添加门板'},
  // {value:'BB',title:'背板',icon: UIcon,type:z3dCtrlState.DRAW_BACK},
]

const changeOpt = (item) => {

  activeopt.value =item.value;
  emits('drawPlank',item.type)
}

const createCab = ()=>{
  const boxSize = {x:600,y:600,z:2400}
  // emits('drawPlank','cab')
}

let initWidth = ref(0) // 父元素的宽-自适应值
let initHeight = ref(0) // 父元素的高-自适应值
let startclientX = ref(0) // 元素拖拽前距离浏览器的X轴位置
let startclientY = ref(0) //元素拖拽前距离浏览器的Y轴位置
let elLeft = ref(0) // 元素的左偏移量
let elTop = ref(0) // 元素的右偏移量
let back_box = ref()
// 页面初始化
function initBodySize() {
    initWidth.value = back_box.value.clientWidth; // 拿到父元素宽
    initHeight.value = initWidth.value * ((1080 * 0.88) / (1920 - 1080 * 0.02)); // 根据宽计算高实现自适应
}
  // 拖拽开始事件
  function dragstart(e) {
    console.log(e);
    startclientX.value = e.clientX; // 记录拖拽元素初始位置
    startclientY.value = e.clientY;
  }
  // 拖拽完成事件
  function dragend(e) {
    console.log(e);
    let x = e.clientX - startclientX.value; // 计算偏移量
    let y = e.clientY - startclientY.value;
    elLeft.value += x; // 实现拖拽元素随偏移量移动
    elTop.value += y;
  }
  onMounted(()=>{
    initBodySize()
  })

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "back_box",
      ref_key: "back_box",
      ref: back_box
    }, [
      _createElementVNode("div", {
        class: "drag_box",
        draggable: "true",
        onDragstart: _cache[0] || (_cache[0] = ($event: any) => (dragstart($event))),
        onDragend: _cache[1] || (_cache[1] = ($event: any) => (dragend($event))),
        style: _normalizeStyle(`left:${_unref(elLeft)}px;top:${_unref(elTop)}px`)
      }, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "drag-title" }, " 自由建柜 ", -1)),
        _createVNode(_component_el_scrollbar, { style: {"height":"calc(90vh - 80px)","overflow-y":"auto"} }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "drag-item-title" }, "开始绘制", -1)),
              _createVNode(_component_el_button, {
                class: "plus-but",
                type: "primary",
                plain: "",
                icon: _unref(Plus),
                onClick: createCab
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("新建空柜体")
                ])),
                _: 1
              }, 8, ["icon"]),
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "shuangji" }, "或者右键取消绘制", -1))
            ]),
            _createElementVNode("div", _hoisted_3, [
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "drag-item-title" }, "绘制视角", -1)),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(arr1, (item) => {
                  return _createElementVNode("div", {
                    class: _normalizeClass(["boxbut", activeitem.value===item.value?'boxbutactive':'']),
                    key: item.value,
                    onClick: ($event: any) => (changeVision(item))
                  }, _toDisplayString(item.title), 11, _hoisted_5)
                }), 64))
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "drag-item-title" }, "选择组件", -1)),
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(arr2, (item) => {
                  return _createElementVNode("div", {
                    class: _normalizeClass(["boxbox", activeopt.value===item.value?'boxboxactive':'']),
                    key: item.value,
                    onClick: ($event: any) => (changeOpt(item))
                  }, [
                    _createVNode(_component_el_tooltip, {
                      placement: "top",
                      effect: "light"
                    }, {
                      content: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.desc), 1)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("img", {
                            src: item.icon,
                            alt: ""
                          }, null, 8, _hoisted_9),
                          _createElementVNode("div", _hoisted_10, _toDisplayString(item.title), 1),
                          _createElementVNode("div", _hoisted_11, _toDisplayString(item.value), 1)
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ], 10, _hoisted_8)
                }), 64))
              ])
            ])
          ]),
          _: 1
        })
      ], 36)
    ], 512)
  ]))
}
}

})