import { THREE, Flatten } from '@/core/thirdPart/lib.js'

let svgNs = 'http://www.w3.org/2000/svg'
let svgXLink = 'http://www.w3.org/1999/xlink'

class TzSvgHelper {
  static createSvg(id, style) {
    let svg = document.createElementNS(svgNs, 'svg')
    this.addAttribute(svg, 'xmlns', svgNs)
    this.addAttribute(svg, 'xmlns:xlink', svgXLink)
    if (id) this.addAttribute(svg, 'id', id)
    if (style) this.addAttribute(svg, 'style', style)
    return svg
  }

  static findChild(parent, id) {
    if (parent) {
      for (let i = 0; i < parent.childNodes.length; i++) {
        let elem = parent.childNodes[i]
        if (elem.id === id) return elem
      }
    }
    return null
  }

  static createElement(tag, parent, id, nodeAttr, srcElem, nextElem) {
    let elem = null
    if (srcElem) {
      elem = srcElem
      if (parent !== elem.parentElement) {
        if (nextElem) {
          parent.insertBefore(elem, nextElem)
        } else {
          parent.appendChild(elem)
        }
      }
    } else {
      id = String(id)
      elem = this.findChild(parent, id)
      let bExist = elem !== null
      if (!bExist) elem = document.createElementNS(svgNs, tag)
      if (id) this.addAttribute(elem, 'id', id)
      if (parent && !bExist) {
        if (nextElem && nextElem.parentElement === parent) {
          parent.insertBefore(elem, nextElem)
        } else {
          parent.appendChild(elem)
        }
      }
    }
    if (nodeAttr) nodeAttr.addAttribute(elem)
    return elem
  }

  static addAttribute(elem, key, attr, attrDefault) {
    if (attr === attrDefault) {
      this.removeAttribute(elem, key)
      return
    }
    // let attrOld = elem.getAttribute(key)
    // if (attrOld === attr) return
    elem.setAttribute(key, attr)
  }

  static removeAttribute(elem, key) {
    elem.removeAttribute(key)
  }

  static setElementValue(elem, value) {
    if (elem.innerHTML !== value) elem.innerHTML = value
  }

  static removeElement(elem) {
    if (elem && elem.parentNode) {
      elem.parentNode.removeChild(elem)
    }
  }
}

// export const ORIENTATION = {CCW:-1, CW:1, NOT_ORIENTABLE: 0};
const Tz2dCCW = Flatten.ORIENTATION

const Tz2dPointPosOnLine = {
  ONLINE: 0,
  NEARSP: 1,
  NEAREP: 2
}

// #region Tz2dMatrix
const Tz2dMatrix = Flatten.Matrix
/*
clone()
transform(vector)
multiply(other_matrix)
translate(...args)
rotate(angle)
scale(sx, sy)
equalTo(matrix)
*/
Tz2dMatrix.prototype.reverse = function() {
  return this.scale(-1, -1)
}
Tz2dMatrix.prototype.transformPt = function(...args) {
  let v = []
  if (args.length === 1) {
    v = this.transform([args[0].x, args[0].y])
  } else {
    v = this.transform([args[0], args[1]])
  }
  return new Tz2dPoint(v[0], v[1])
}
// #endregion

// #region Tz2dBox
const Tz2dBox = Flatten.Box
/*
xmin, xmax, ymin, ymax
get center()
not_intersect(other_box) -- boolean
intersect(other_box) -- boolean
merge(other_box)
set(xmin, ymin, xmax, ymax)
toPoints()
toSegments()
*/
Tz2dBox.prototype.setByPts = function(pts) {
  if (!pts || !pts.length) return this
  let xmin = Number.MAX_SAFE_INTEGER
  let xmax = Number.MIN_SAFE_INTEGER
  let ymin = Number.MAX_SAFE_INTEGER
  let ymax = Number.MIN_SAFE_INTEGER
  pts.forEach(pt => {
    xmin = Math.min(xmin, pt.x)
    ymin = Math.min(ymin, pt.y)
    xmax = Math.max(xmax, pt.x)
    ymax = Math.max(ymax, pt.y)
  })
  this.set(xmin, ymin, xmax, ymax)
  return this
}
Tz2dBox.prototype.addPt = function(pt) {
  this.xmin = Math.min(this.xmin, pt.x)
  this.ymin = Math.min(this.ymin, pt.y)
  this.xmax = Math.max(this.xmax, pt.x)
  this.ymax = Math.max(this.ymax, pt.y)
  return this
}
Tz2dBox.prototype.toRect = function() {
  return new Tz2dRect(this.xmin, this.ymin, this.xmax - this.xmin, this.ymax - this.ymin)
}
Tz2dBox.prototype.offset = function(dx, dy) {
  this.xmin += dx
  this.xmax += dx
  this.ymin += dy
  this.ymax += dy
  return this
}
Tz2dBox.prototype.equalTo = function(box) {
  return this.xmin === box.xmin && this.xmax === box.xmax && this.ymin === box.ymin && this.ymax === box.ymax
}
Tz2dBox.prototype.expand = function(d) {
  this.xmin -= d
  this.ymin -= d
  this.xmax += d
  this.xmax += d
  return this
}
Tz2dBox.prototype.owidth = function() {
  return this.xmax - this.xmin
}
Tz2dBox.prototype.oheight = function() {
  return this.ymax - this.ymin
}
// #endregion

// #region Tz2dRect
const Tz2dRectPos = {
  LEFTTOP: 0,
  TOPCENTER: 1,
  RIGHTTOP: 2,
  RIGHTCENTER: 3,
  RIGHTBOTTOM: 4,
  BOTTOMCENTER: 5,
  LEFTBOTTOM: 6,
  LEFTCENTER: 7
}
class Tz2dRect {
  constructor(x = 0, y = 0, w = 0, h = 0) {
    this.x = x
    this.y = y
    this.w = w
    this.h = h
  }

  equalTo(r) {
    return this.x === r.x && this.y === r.y && this.w === r.w && this.h === r.h
  }

  clone() {
    return new Tz2dRect(this.x, this.y, this.w, this.h)
  }

  zero() {
    this.set(0, 0, 0, 0)
  }

  set(x, y, w, h) {
    this.x = x
    this.y = y
    this.w = w
    this.h = h
  }

  setByPts(pts) {
    if (!pts || !pts.length) return this
    let minX = Number.max_safe_interger
    let minY = Number.max_safe_interger
    let maxX = Number.min_safe_interger
    let maxY = Number.min_safe_interger
    pts.forEach(pt => {
      minX = Math.min(minX, pt.x)
      minY = Math.min(minY, pt.y)
      maxX = Math.max(maxX, pt.x)
      maxY = Math.max(maxY, pt.y)
    })
    this.set(minX, minY, maxX - minX, maxY - minY)
    return this
  }

  boolPoly(alpha) {
    let bp = {
      regions: [],
      inverted: false
    }

    let p = []
    let ptArr = this.ptArray4()
    ptArr.forEach(e => {
      if (alpha === 0) {
        p.push([e.x, e.y])
      } else {
        let x1 = e.x * Math.cos(alpha) - e.y * Math.sin(alpha)
        let y1 = e.x * Math.sin(alpha) + e.y * Math.cos(alpha)
        p.push([x1, y1])
      }
    })
    bp.regions.push(p)
    return bp
  }

  offset(dx, dy) {
    this.x += dx
    this.y += dy
  }

  offset00() {
    this.x = -this.w / 2
    this.y = -this.h / 2
  }

  offsetTo(cx, cy) {
    if (arguments.length === 1) {
      this.x = cx.x - this.w / 2
      this.y = cx.y - this.h / 2
    } else {
      this.x = cx - this.w / 2
      this.y = cy - this.h / 2
    }
  }

  offsetRect(dx, dy) {
    return new Tz2dRect(this.x + dx, this.y + dy, this.w, this.h)
  }

  inflate(v) {
    this.x -= v
    this.y -= v
    this.w += v * 2
    this.h += v * 2
  }

  inflate2(vw, vh) {
    this.x -= vw
    this.y -= vh
    this.w += vw * 2
    this.h += vh * 2
  }

  inflate4(vl, vr, vt, vb) {
    this.x -= vl
    this.y -= vt
    this.w += vl + vr
    this.h += vt + vb
  }

  equals(r, eps) {
    let e = typeof eps === 'undefined' ? 0.0001 : eps
    return Math.abs(this.x - r.x) < e && Math.abs(this.y - r.y) < e && Math.abs(this.w - r.w) < e && Math.abs(this.h - r.h) < e
  }

  isEmpty() {
    return this.w === 0 || this.h === 0
  }

  center() {
    return new Tz2dPoint(this.x + this.w / 2, this.y + this.h / 2)
  }

  get cx() {
    return this.x + this.w / 2
  }

  get cy() {
    return this.y + this.h / 2
  }

  get right() {
    return this.x + this.w
  }

  get bottom() {
    return this.y + this.h
  }

  keyPt(pos) {
    switch (pos) {
      case Tz2dRectPos.LEFTTOP:
        return new Tz2dPoint(this.x, this.y)
      case Tz2dRectPos.TOPCENTER:
        return new Tz2dPoint(this.x + this.w / 2, this.y)
      case Tz2dRectPos.RIGHTTOP:
        return new Tz2dPoint(this.x + this.w, this.y)
      case Tz2dRectPos.RIGHTCENTER:
        return new Tz2dPoint(this.x + this.w, this.y + this.h / 2)
      case Tz2dRectPos.RIGHTBOTTOM:
        return new Tz2dPoint(this.x + this.w, this.y + this.h)
      case Tz2dRectPos.BOTTOMCENTER:
        return new Tz2dPoint(this.x + this.w / 2, this.y + this.h)
      case Tz2dRectPos.LEFTBOTTOM:
        return new Tz2dPoint(this.x, this.y + this.h)
      case Tz2dRectPos.LEFTCENTER:
        return new Tz2dPoint(this.x, this.y + this.h / 2)
    }
  }

  get leftTop() {
    return this.keyPt(Tz2dRectPos.LEFTTOP)
  }

  get topCenter() {
    return this.keyPt(Tz2dRectPos.TOPCENTER)
  }

  get rightTop() {
    return this.keyPt(Tz2dRectPos.RIGHTTOP)
  }

  get rightCenter() {
    return this.keyPt(Tz2dRectPos.RIGHTCENTER)
  }

  get rightBottom() {
    return this.keyPt(Tz2dRectPos.RIGHTBOTTOM)
  }

  get bottomCenter() {
    return this.keyPt(Tz2dRectPos.BOTTOMCENTER)
  }

  get leftBottom() {
    return this.keyPt(Tz2dRectPos.LEFTBOTTOM)
  }

  get leftCenter() {
    return this.keyPt(Tz2dRectPos.LEFTCENTER)
  }

  segmentPoint(sp, ep, ratio) {
    // 问题1
    return sp.addScale(this.keyPt(Tz2dRectPos.TOPCENTER), 0.333)
  }

  ptSegmentArray() {
    let pts = []
    let delta = 80
    let count = Math.ceil(this.w / delta)
    pts.push(this.keyPt(Tz2dRectPos.LEFTTOP))
    for (let i = 1; i < count; i++) {
      pts.push(this.keyPt(Tz2dRectPos.LEFTTOP).addScale(this.keyPt(Tz2dRectPos.TOPCENTER), i / count))
    }
    pts.push(this.keyPt(Tz2dRectPos.TOPCENTER))
    for (let i = 1; i < count; i++) {
      pts.push(this.keyPt(Tz2dRectPos.TOPCENTER).addScale(this.keyPt(Tz2dRectPos.RIGHTTOP), i / count))
    }
    count = Math.ceil(this.h / delta)
    pts.push(this.keyPt(Tz2dRectPos.RIGHTTOP))
    for (let i = 1; i < count; i++) {
      pts.push(this.keyPt(Tz2dRectPos.RIGHTTOP).addScale(this.keyPt(Tz2dRectPos.RIGHTCENTER), i / count))
    }
    pts.push(this.keyPt(Tz2dRectPos.RIGHTCENTER))
    for (let i = 1; i < count; i++) {
      pts.push(this.keyPt(Tz2dRectPos.RIGHTCENTER).addScale(this.keyPt(Tz2dRectPos.RIGHTBOTTOM), i / count))
    }
    count = Math.ceil(this.w / delta)
    pts.push(this.keyPt(Tz2dRectPos.RIGHTBOTTOM))
    for (let i = 1; i < count; i++) {
      pts.push(this.keyPt(Tz2dRectPos.RIGHTBOTTOM).addScale(this.keyPt(Tz2dRectPos.BOTTOMCENTER), i / count))
    }
    pts.push(this.keyPt(Tz2dRectPos.BOTTOMCENTER))
    for (let i = 1; i < count; i++) {
      pts.push(this.keyPt(Tz2dRectPos.BOTTOMCENTER).addScale(this.keyPt(Tz2dRectPos.LEFTBOTTOM), i / count))
    }
    count = Math.ceil(this.h / delta)
    pts.push(this.keyPt(Tz2dRectPos.LEFTBOTTOM))
    for (let i = 1; i < count; i++) {
      pts.push(this.keyPt(Tz2dRectPos.LEFTBOTTOM).addScale(this.keyPt(Tz2dRectPos.LEFTCENTER), i / count))
    }
    pts.push(this.keyPt(Tz2dRectPos.LEFTCENTER))
    for (let i = 1; i < count; i++) {
      pts.push(this.keyPt(Tz2dRectPos.LEFTCENTER).addScale(this.keyPt(Tz2dRectPos.LEFTTOP), i / count))
    }
    // pts.forEach(p => {
    //   p.minus(this.center())
    // })
    return pts
  }

  ptArray8() {
    let pts = []
    pts.push(this.keyPt(Tz2dRectPos.LEFTTOP))
    pts.push(this.keyPt(Tz2dRectPos.TOPCENTER))
    pts.push(this.keyPt(Tz2dRectPos.RIGHTTOP))
    pts.push(this.keyPt(Tz2dRectPos.RIGHTCENTER))
    pts.push(this.keyPt(Tz2dRectPos.RIGHTBOTTOM))
    pts.push(this.keyPt(Tz2dRectPos.BOTTOMCENTER))
    pts.push(this.keyPt(Tz2dRectPos.LEFTBOTTOM))
    pts.push(this.keyPt(Tz2dRectPos.LEFTCENTER))
    return pts
  }

  toPoints() {
    return this.ptArray4()
  }

  get tzPoly() {
    let pts = []
    pts.push(this.leftTop)
    pts.push(this.rightTop)
    pts.push(this.rightBottom)
    pts.push(this.leftBottom)
    return new Tz2dPolygon(pts)
  }

  ptArray4() {
    let pts = []
    pts.push(this.leftTop)
    pts.push(this.rightTop)
    pts.push(this.rightBottom)
    pts.push(this.leftBottom)
    return pts
  }

  centerPtArray4() {
    let center = this.center()
    let pts = []
    pts.push(this.leftTop.offsetPt(-center.x, -center.y))
    pts.push(this.rightTop.offsetPt(-center.x, -center.y))
    pts.push(this.rightBottom.offsetPt(-center.x, -center.y))
    pts.push(this.leftBottom.offsetPt(-center.x, -center.y))
    return pts
  }

  area() {
    return this.w * this.h
  }

  ptInside(x, y) {
    let px = arguments.length === 2 ? x : x.x
    let py = arguments.length === 2 ? y : x.y
    return this.x <= px && px <= this.right && this.y <= py && py <= this.bottom
  }

  ptOnEdge(p) {
    return (
      ((p.x === this.x || p.x === this.right) && this.y <= p.y && p.y <= this.bottom) ||
      ((p.y === this.y || p.y === this.bottom) && this.x <= p.x && p.x <= this.right)
    )
  }

  edgeLeft() {
    return new Tz2dLine(this.leftBottom, this.leftTop)
  }

  edgeTop() {
    return new Tz2dLine(this.leftTop, this.rightTop)
  }

  edgeRight() {
    return new Tz2dLine(this.rightTop, this.rightBottom)
  }

  edgeBottom() {
    return new Tz2dLine(this.rightBottom, this.leftBottom)
  }

  edge(index) {
    switch (index) {
      case 0:
        return this.edgeLeft()
      case 1:
        return this.edgeTop()
      case 2:
        return this.edgeRight()
      case 3:
        return this.edgeBottom()
    }
  }

  edgePrev(index) {
    return this.edge(index > 0 ? index - 1 : 3)
  }

  edgeNext(index) {
    return this.edge(index < 3 ? index + 1 : 0)
  }

  ellipseAnglePt(angle) {
    let sp = this.center()
    let a = (Math.PI * angle) / 180
    let ep = new Tz2dPoint(sp.x + this.w * Math.cos(a), sp.y + this.w * Math.sin(a))
    let l = new Tz2dLine(sp, ep)
    let iPts = l.intersectEllipse(this)
    return iPts[0].distanceTo(ep) < iPts[1].distanceTo(ep) ? iPts[0] : iPts[1]
  }

  ellipseArea() {
    return Math.PI * (this.w / 2) * (this.h / 2) // πab
  }

  ellipsePts(slice, quadrant, bClockwise = true) {
    if (this.isEmpty()) return

    if (slice <= 2 || quadrant < 1 || quadrant > 4) return

    let ea = this.w / 2
    let eb = this.h / 2
    let pts = new Array(slice + 1).fill(new Tz2dPoint(0, 0))

    let ll = new Tz2dLine(new Tz2dPoint(-ea, 0), new Tz2dPoint(0, 0))
    let lr = new Tz2dLine(new Tz2dPoint(ea, 0), new Tz2dPoint(0, 0))

    let aStep = (ea * 2) / slice
    let bStep = (eb * 2) / slice
    let rl = -ea
    let rt = -eb * 2
    let rr = ea
    let rb = eb * 2
    let c = this.center
    for (let i = 0; i <= slice; i++) {
      // Quadrant
      // 2   |   1
      // -----------
      // 3   |   4
      switch (quadrant) {
        case 2:
          ll.ep.set(rl + i * aStep, rt)
          lr.ep.set(rl, -i * bStep)
          break
        case 1:
          ll.ep.set(rr, rt + i * bStep)
          lr.ep.set(rl + i * aStep, rt)
          break
        case 4:
          ll.ep.set(rr, i * bStep)
          lr.ep.set(rr - i * aStep, rb)
          break
        case 3:
          ll.ep.set(rr - i * aStep, rb)
          lr.ep.set(rl, rb - i * bStep)
          break
      }

      let iPts = ll.intersectLine(lr)
      if (iPts && iPts.length === 1) {
        pts[bClockwise ? i : slice - i].x = iPts[0].x + c.x
        pts[bClockwise ? i : slice - i].y = iPts[0].y + c.y
      }
    }
    return pts
  }

  fitRect(sw, sh) {
    let r = new Tz2dRect(this.x, this.y, this.w, this.h)
    if (sw === 0 || sh === 0 || this.isEmpty()) return r

    if (this.w / this.h > sw / sh) {
      let scale = this.h / sh
      r.w = sw * scale
      r.x = this.x + (this.w - r.w) / 2
    } else {
      let scale = this.w / sw
      r.h = sh * scale
      r.y = this.y + (this.h - r.h) / 2
    }
    return r
  }

  intersectRect(r) {
    let iRect = new Tz2dRect()
    if (r.x > this.right || r.y > this.bottom || r.right < this.x || r.bottom < this.y) {
      return iRect
    }
    iRect.x = Math.max(this.x, r.x)
    iRect.y = Math.max(this.y, r.y)
    iRect.w = Math.min(this.right, r.right) - iRect.x
    iRect.h = Math.min(this.bottom, r.bottom) - iRect.y
    return iRect
  }

  combineRect(r) {
    let cRect = new Tz2dRect()
    cRect.x = Math.min(this.x, r.x)
    cRect.y = Math.min(this.y, r.y)
    cRect.w = Math.max(this.right, r.right) - this.x
    cRect.h = Math.max(this.bottom, r.bottom) - this.y
    return cRect
  }
}
// #endregion

// #region Tz2dPoint
const Tz2dPoint = Flatten.Point // Flatten.Vector
/*
clone()
get box()
rotate(angle, center = {x: 0, y: 0})
translate(...args)
transform(m)
projectionOn(line)
distanceTo(shape) // 不要用这个过程
on(shape)
toJSON()
svg(attrs = {})
equalTo(pt)
*/
Tz2dPoint.prototype.toThreeVector = function(pt) {
  return new THREE.Vector2(this.x, this.y)
}
Tz2dPoint.prototype.copy = function(pt) {
  this.x = pt.x
  this.y = pt.y
  return this
}

Tz2dPoint.prototype.toM = function() {
  return this.scale(0.001)
}

Tz2dPoint.prototype.toMM = function() {
  return this.scale(1000)
}

Tz2dPoint.prototype.similar = function(pt, e = 0.0001) {
  return Math.abs(this.x - pt.x) < e && Math.abs(this.y - pt.y) < e
}

Tz2dPoint.prototype.min = function(pt) {
  if (pt) {
    this.x = pt.x < this.x ? pt.x : this.x
    this.y = pt.y < this.y ? pt.y : this.y
  }
  return this
}
Tz2dPoint.prototype.max = function(pt) {
  if (pt) {
    this.x = pt.x > this.x ? pt.x : this.x
    this.y = pt.y > this.y ? pt.y : this.y
  }
  return this
}

Tz2dPoint.prototype.clockWise = function(p) {
  // { >0(ClockWise)  <0(UnClockWise) }
  return this.x * p.y - this.y * p.x
}

Tz2dPoint.prototype.clockWise2 = function(p1, p2) {
  let l1 = new Tz2dLine(this.x, this.y, p1.x, p1.y)
  let l2 = new Tz2dLine(this.x, this.y, p2.x, p2.y)
  return l1.clockWise(l2)
}

Tz2dPoint.prototype.set = function(...args) {
  if (args.length === 1) {
    this.x = args[0].x
    this.y = args[0].y
  } else if (args.length === 2) {
    this.x = args[0]
    this.y = args[1]
  }
  return this
}

/** 三点共线 */
Tz2dPoint.prototype.collinear = function(p1, p2) {
  let seg = new Tz2dLine(p1, p2)
  return seg.contains(this)
}

Tz2dPoint.prototype.exchangeValue = function(pt) {
  let temp = this.clone() // 问题2
  this.set(pt)
  pt.set(temp)
}

Tz2dPoint.prototype.toV3 = function(y = 0) {
  return new THREE.Vector3(this.x, y, this.y)
}

Tz2dPoint.prototype.toV2 = function() {
  return new THREE.Vector2(this.x, this.y)
}

Tz2dPoint.prototype.toFixed = function(digit) {
  this.x = Number(this.x.toFixed(digit))
  this.y = Number(this.y.toFixed(digit))
  return this
}

Tz2dPoint.prototype.add = function(...args) {
  if (args.length === 1) {
    return new Tz2dPoint(this.x + args[0].x, this.y + args[0].y)
  } else if (args.length === 2) {
    return new Tz2dPoint(this.x + args[0], this.y + args[1])
  }
}

Tz2dPoint.prototype.sub = function(...args) {
  if (args.length === 1) {
    return new Tz2dPoint(this.x - args[0].x, this.y - args[0].y)
  } else if (args.length === 2) {
    return new Tz2dPoint(this.x - args[0], this.y - args[1])
  }
}

Tz2dPoint.prototype.multiply = function(...args) {
  if (args.length === 1) {
    return new Tz2dPoint(this.x * args[0].x, this.y * args[0].y)
  } else if (args.length === 2) {
    return new Tz2dPoint(this.x * args[0], this.y * args[1])
  }
}

Tz2dPoint.prototype.multiplyScalar = function(s) {
  this.x *= s
  this.y *= s
  return this
}

Tz2dPoint.prototype.scale = function(s) {
  return this.multiply(s, s)
}

Tz2dPoint.prototype.addScale = function(p, s = 1) {
  let deltaX = (-this.x + p.x) * s
  let deltaY = (-this.y + p.y) * s
  return new Tz2dPoint(this.x + deltaX, this.y + deltaY)
}

Tz2dPoint.prototype.offset = function(...args) {
  if (args.length === 1) {
    this.x += args[0].x
    this.y += args[0].y
  } else {
    this.x += args[0]
    this.y += args[1]
  }
  return this
}

Tz2dPoint.prototype.offsetPt = function(dx, dy) {
  return this.clone().offset(dx, dy)
}

Tz2dPoint.prototype.len = function() {
  return Math.sqrt(this.dot(this))
}

Tz2dPoint.prototype.length = function() {
  return Math.sqrt(this.dot(this))
}

Tz2dPoint.prototype.lerp = function(v, t) {
  let x = (v.x - this.x) * t + this.x
  let y = (v.y - this.y) * t + this.y
  this.set(x, y)
}

Tz2dPoint.prototype.normalize = function() {
  let len = this.len()
  return len > 0 ? this.scale(1 / len) : this.clone()
}

Tz2dPoint.prototype.slope = function() {
  let angle = Math.atan2(this.y, this.x)
  if (angle < 0) angle = 2 * Math.PI + angle
  return angle
}

Tz2dPoint.prototype.angle = function() {
  return this.slope()
}

Tz2dPoint.prototype.degree = function() {
  return (this.slope() / Math.PI) * 180
}

Tz2dPoint.prototype.dot = function(pt) {
  return this.x * pt.x + this.y * pt.y
}

Tz2dPoint.prototype.dot2 = function(pt1, pt2) {
  let s1 = new Tz2dLine(this, pt1)
  let s2 = new Tz2dLine(this, pt2)
  return s1.dot(s2)
}

Tz2dPoint.prototype.cross = function(pt) {
  return this.x * pt.y - this.y * pt.x
}

Tz2dPoint.prototype.cos = function(pt1, pt2) {
  let l1 = new Tz2dLine(this, pt1)
  let l2 = new Tz2dLine(this, pt2)
  return l1.cos(l2)
}

Tz2dPoint.prototype.rotateDegree = function(degree, center) {
  return this.rotate((Math.PI * degree) / 180, center)
}

Tz2dPoint.prototype.vecRotate = function(angle) {
  return this.rotate(angle)
}

Tz2dPoint.prototype.vecRotate90CCW = function() {
  return new Tz2dPoint(-this.y, this.x)
}

Tz2dPoint.prototype.vecRotate90CW = function() {
  return new Tz2dPoint(this.y, -this.x)
}

Tz2dPoint.prototype.closestPtTo = function(shape, segmentAsLine = true) {
  let d = this.distanceTo(shape, segmentAsLine)
  return d[1].end
}

Tz2dPoint.prototype.distanceTo = function(shape, segmentAsLine = true) {
  if (shape instanceof Flatten.Point) {
    let dx = shape.x - this.x
    let dy = shape.y - this.y
    return [Math.sqrt(dx * dx + dy * dy), new Flatten.Segment(this, shape)]
  }

  if (shape instanceof Flatten.Line) {
    return Flatten.Distance.point2line(this, shape)
  }

  if (shape instanceof Flatten.Circle) {
    return Flatten.Distance.point2circle(this, shape)
  }

  if (shape instanceof Flatten.Segment) {
    if (segmentAsLine) {
      let line = shape.toLine()
      if (line) {
        return Flatten.Distance.point2line(this, line)
      } else {
        return Flatten.Distance.point2segment(this, shape)
      }
    } else {
      return Flatten.Distance.point2segment(this, shape)
    }
  }

  if (shape instanceof Flatten.Arc) {
    return Flatten.Distance.point2arc(this, shape)
  }

  if (shape instanceof Flatten.Polygon) {
    // let [dist, ...rest] = Distance.point2polygon(this, shape);
    // return dist;
    return Flatten.Distance.point2polygon(this, shape)
  }

  if (shape instanceof Flatten.PlanarSet) {
    return Flatten.Distance.shape2planarSet(this, shape)
  }
}

Tz2dPoint.prototype.leftTo = function(line) {
  let l = line instanceof Flatten.Segment ? line.toLine() : line
  if (!l) return false
  let vec = new Flatten.Vector(l.pt, this)
  let onLeftSemiPlane = Flatten.Utils.GT(vec.dot(l.norm), 0)
  return onLeftSemiPlane
}

Tz2dPoint.prototype.toSvg = function(nodeAttr) {
  let attr = nodeAttr.toSvg()
  return `\n<circle cx="${this.x}" cy="${this.y}" r="1.5" ${attr} />`
}
// #endregion

// #region Tz2dLine
const Tz2dLine = Flatten.Segment
/*
clone()
get box()
contains(pt)
equalTo(seg)
get length()
middle()
get slope()
tangentInStart()
tangentInEnd()
reverse()
*/
Tz2dLine.prototype.copy = function(line) {
  this.ps.copy(line.ps)
  this.pe.copy(line.pe)
  return this
}

Tz2dLine.prototype.set = function(...args) {
  if (args.length === 2) {
    this.ps.set(args[0])
    this.pe.set(args[1])
  } else if (args.length === 4) {
    this.ps.set(args[0], args[1])
    this.pe.set(args[2], args[3])
  }
  return this
}

Tz2dLine.prototype.toPoints = function(ptCount = 6) {
  let pts = []
  pts.push(this.ps)
  pts.push(this.pe)
  return pts
}

Tz2dLine.prototype.equalSplitPts = function(splitCount = 2) {
  let pts = []
  splitCount = Math.max(splitCount, 2)
  let len = this.length / splitCount
  for (let i = 0; i < splitCount - 1; i++) {
    pts.push(this.ptFromSp2Ep(len * (i + 1)))
  }
  return pts
}

Tz2dLine.prototype.exchangeValue = function(line, bReverse = false) {
  if (bReverse) {
    this.ps.exchangeValue(line.pe)
    this.pe.exchangeValue(line.ps)
  } else {
    this.ps.exchangeValue(line.ps)
    this.pe.exchangeValue(line.pe)
  }
}

Tz2dLine.prototype.offset = function(...args) {
  if (args.length === 1) {
    // dist
    let a = this.angle()
    let dx = args[0] * Math.sin(a)
    let dy = -args[0] * Math.cos(a)
    this.ps.offset(dx, dy)
    this.pe.offset(dx, dy)
  } else {
    // dx, dy
    this.ps.offset(args[0], args[1])
    this.pe.offset(args[0], args[1])
  }
  return this
}

Tz2dLine.prototype.offsetPassPt = function(pt) {
  let d = pt.distanceTo(this)
  if (pt.leftTo(this)) d = -d
  return this.offset(d)
}

Tz2dLine.prototype.offsetLine = function(dist) {
  let a = this.angle()
  let dx = dist * Math.sin(a)
  let dy = -dist * Math.cos(a)
  return this.translate(dx, dy)
}

Tz2dLine.prototype.offsetLinePassPt = function(pt) {
  let d = pt.distanceTo(this)
  if (pt.leftTo(this)) d[0] = -d[0]
  return this.offsetLine(d[0])
}

Tz2dLine.prototype.sameLineTo = function(other_line) {
  if (this.ps.distanceTo(other_line)[0] > 0.0001) return false
  if (this.pe.distanceTo(other_line)[0] > 0.0001) return false
  return true
}

Tz2dLine.prototype.parallelTo = function(other_line) {
  if (!this.length) return false
  if (other_line instanceof Flatten.Segment) {
    if (!other_line.length) return false
    return Flatten.Utils.EQ_0(this.norm().cross(other_line.norm()))
  } else if (other_line instanceof Flatten.Line) {
    return Flatten.Utils.EQ_0(this.norm().cross(other_line.norm()))
  } else {
    return false
  }
}

Tz2dLine.prototype.perpendicularTo = function(other_line) {
  if (!(other_line instanceof Tz2dLine)) return false
  if (!this.length || !other_line.length) return false
  return Flatten.Utils.EQ_0(this.norm().cross(other_line.vector()))
}

Tz2dLine.prototype.norm = function() {
  let line = this.toLine()
  return line ? line.norm : new Flatten.Vector(0, 1)
}

Tz2dLine.prototype.vector = function() {
  return new Tz2dPoint(this.pe.x - this.ps.x, this.pe.y - this.ps.y)
}

Tz2dLine.prototype.pointPos = function(pt) {
  if (this.start.equalTo(this.end)) {
    return Tz2dPointPosOnLine.ONLINE
  }

  let v_seg = new Flatten.Vector(this.start, this.end)
  let v_ps2pt = new Flatten.Vector(this.start, pt)
  let v_pe2pt = new Flatten.Vector(this.end, pt)
  let start_sp = v_seg.dot(v_ps2pt)
  /* dot product v_seg * v_ps2pt */
  let end_sp = -v_seg.dot(v_pe2pt)
  /* minus dot product v_seg * v_pe2pt */

  if (Flatten.Utils.GE(start_sp, 0) && Flatten.Utils.GE(end_sp, 0)) {
    /* point inside segment scope */
    return Tz2dPointPosOnLine.ONLINE
  } else if (start_sp < 0) {
    /* point is out of scope closer to ps */
    return Tz2dPointPosOnLine.NEARSP
  } else {
    /* point is out of scope closer to pe */
    return Tz2dPointPosOnLine.NEAREP
  }
}

Tz2dLine.prototype.ptFromSp2Ep = function(dist) {
  let pt = this.ps.clone()
  let len = this.len()
  if (len > 0) {
    let scale = dist / len
    let dx = scale * (this.pe.x - this.ps.x)
    let dy = scale * (this.pe.y - this.ps.y)
    pt.offset(dx, dy)
  }
  return pt
}

Tz2dLine.prototype.ptFromEp2Sp = function(dist) {
  let pt = this.pe.clone()
  let len = this.len()
  if (len > 0) {
    let scale = dist / len
    let dx = scale * (this.ps.x - this.pe.x)
    let dy = scale * (this.ps.y - this.pe.y)
    pt.offset(dx, dy)
  }
  return pt
}

Tz2dLine.prototype.isVert = function() {
  return Flatten.Utils.EQ(this.ps.x, this.pe.x)
}

Tz2dLine.prototype.isHorz = function() {
  return Flatten.Utils.EQ(this.ps.y, this.pe.y)
}

Tz2dLine.prototype.len = function() {
  return this.length
}

Tz2dLine.prototype.cos = function(line) {
  // let len = this.len()
  // if (len === 0) return

  // let v = this.dot(line) / len / len
  // v = Math.min(v, 1)
  // v = Math.max(v, -1)
  // return v
  let v = this.vector().normalize()
  let v1 = line.vector().normalize()
  return v.dot(v1)
}

Tz2dLine.prototype.dot = function(line) {
  let v = this.vector()
  let v1 = line.vector()
  return v.dot(v1)
}

Tz2dLine.prototype.clockWise = function(l) {
  let v = this.vector().normalize()
  let vl = l.vector().normalize()
  return v.clockWise(vl)
}

Tz2dLine.prototype.angle = function() {
  return this.slope
  // let dx = this.pe.x - this.ps.x
  // let dy = this.pe.y - this.ps.y
  // if (Math.abs(dy) < 0.001) {
  //   dy = 0
  // }
  // return Math.atan2(dy, dx)
}

Tz2dLine.prototype.degree = function() {
  return (this.slope / Math.PI) * 180
}

Tz2dLine.prototype.degreeOfUpText = function() {
  let degree = this.degree()
  if ((degree > 135 && degree < 225) || (degree >= 225 && degree < 315)) {
    degree = degree - 180
    if (degree < 0) degree = degree + 360
  }
  return Math.round(degree)
}

Tz2dLine.prototype.angle2Line = function(line) {
  return Math.acos(this.cos(line))
}

Tz2dLine.prototype.minAngleTo = function(line) {
  let angle = this.angle2Line(line)
  angle = angle > Math.PI ? 2 * Math.PI - angle : angle
  return Math.min(angle, Math.PI - angle)
}

Tz2dLine.prototype.cwAngleTo = function(line) {
  let angle = this.angle2Line(line)
  return this.clockWise(line) > 0 ? angle : 2 * Math.PI - angle
}

Tz2dLine.prototype.ccwAngleTo = function(line) {
  let angle = this.angle2Line(line)
  return this.clockWise(line) > 0 ? 2 * Math.PI - angle : angle
}

Tz2dLine.prototype.degree2Line = function(line) {
  let a = this.angle2Line(line)
  return (a / Math.PI) * 180
}

Tz2dLine.prototype.minDegreeTo = function(line) {
  let a = this.minAngleTo(line)
  return (a / Math.PI) * 180
}

Tz2dLine.prototype.cwDegreeTo = function(line) {
  let a = this.cwAngleTo(line)
  return (a / Math.PI) * 180
}

Tz2dLine.prototype.ccwDegreeTo = function(line) {
  let a = this.ccwAngleTo(line)
  return (a / Math.PI) * 180
}

Tz2dLine.prototype.intersectAsSegment = function(shape, segmentAsLine = true) {
  if (shape instanceof Flatten.Segment && segmentAsLine) {
    let shapeLine = shape.toLine()
    if (shapeLine) {
      return this.intersect(shapeLine)
    } else {
      return this.intersect(shape)
    }
  }

  if (shape instanceof Tz2dRect) {
    let iPtRects = []
    for (let i = 0; i < 4; i++) {
      let iPts = this.intersectAsSegment(shape.edge(i), false)
      if (iPts && iPts.length === 1) {
        if (iPtRects.length === 1) {
          if (iPtRects[0].distanceTo(iPts[0]) < 0.000001) continue
        }
        iPtRects.push(iPts[0])
      }
    }
    return iPtRects
  }

  return this.intersect(shape)
}

Tz2dLine.prototype.intersectAsLine = function(shape, segmentAsLine = true) {
  let line = this.toLine()
  if (!line) return []

  if (segmentAsLine && shape instanceof Flatten.Segment) {
    let shapeLine = shape.toLine()
    if (!shapeLine) return []
    return line.intersect(shapeLine)
  }

  if (shape instanceof Tz2dRect) {
    let iPtRects = []
    for (let i = 0; i < 4; i++) {
      let iPts = this.intersectAsLine(shape.edge(i), false)
      if (iPts && iPts.length === 1) {
        if (iPtRects.length === 1) {
          if (iPtRects[0].distanceTo(iPts[0]) < 0.000001) continue
        }
        iPtRects.push(iPts[0])
      }
      if (iPtRects.length === 2) break
    }
    return iPtRects
  }

  return line.intersect(shape)
}

Tz2dLine.prototype.intersectShape = function(shape, selfAsLine = true, segmentAsLine = true) {
  if (selfAsLine) {
    return this.intersectAsLine(shape, segmentAsLine)
  } else {
    return this.intersectAsSegment(shape, segmentAsLine)
  }
}

Tz2dLine.prototype.coord = function(pt) {
  let d = pt.distanceTo(this, true)
  let closestPt = d[1].end
  let x = closestPt.distanceTo(this.ps)[0]
  if (this.pointPos(pt) === Tz2dPointPosOnLine.NEARSP) x = -x
  let y = pt.leftTo(this) ? -d[0] : d[0]
  return new Tz2dPoint(x, y)
}

Tz2dLine.prototype.sortPoints = function(pts) {
  return pts.slice().sort((pt1, pt2) => {
    return this.coord(pt1).x - this.coord(pt2).x
  })
}

Tz2dLine.prototype.toLine = function() {
  if (this.start.equalTo(this.end)) {
    return null
  } else {
    return new Flatten.Line(this.start, this.end)
  }
}

Tz2dLine.prototype.toSvg = function(nodeAttr) {
  let attr = nodeAttr.toSvg()
  if (this.isHorz() || this.isVert()) attr += ' shape-rendering="crispEdges"'
  return `\n<line x1="${this.start.x.toFixed(1)}" y1="${this.start.y.toFixed(1)}" x2="${this.end.x}" y2="${this.end.y}" ${attr} />`
}

Tz2dLine.prototype.createSvgElement = function(parent, id, nodeAttr, srcElem, nextElem) {
  let elem = TzSvgHelper.createElement('line', parent, id, nodeAttr, srcElem, nextElem)
  TzSvgHelper.addAttribute(elem, 'x1', this.ps.x)
  TzSvgHelper.addAttribute(elem, 'y1', this.ps.y)
  TzSvgHelper.addAttribute(elem, 'x2', this.pe.x)
  TzSvgHelper.addAttribute(elem, 'y2', this.pe.y)
  TzSvgHelper.addAttribute(elem, 'vector-effect', 'non-scaling-stroke')
  return elem
}

// #endregion

// #region Tz2dVector, Tz2dRay
const Tz2dVector = Flatten.Vector

const Tz2dRay = Flatten.Ray
Tz2dRay.prototype.set = function(pt, norm) {
  this.pt = pt
  this.norm = norm
  return this
}
Tz2dRay.prototype.intersectShape = function(shape) {
  return this.intersect(shape)
}
// #endregion

// #region Tz2dCircle
const Tz2dCircle = Flatten.Circle
/*
clone()
get box()
get center()
contains(shape)
toArc(counterclockwise = true)
*/

Tz2dCircle.prototype.expand = function(dr) {
  return new Tz2dCircle(this.pc, this.r + dr)
}
Tz2dCircle.prototype.copy = function(circle) {
  this.pc.copy(circle.pc)
  this.r = circle.r
  return this
}

Tz2dCircle.prototype.set = function(pc, r) {
  this.pc = pc
  this.r = r
  return this
}

Tz2dCircle.prototype.toPoints = function(partCount = 8, startAngle = 0) {
  let pts = []
  let step = (2 * Math.PI) / Math.max(partCount, 3)
  for (let i = 0; i < partCount; i++) {
    let pt = this.anglePt(startAngle + i * step)
    pts.push(pt)
  }
  return pts
}

Tz2dCircle.prototype.ptInside = function(pt, includeEdge = true) {
  let d = pt.distanceTo(this.center)[0]
  return includeEdge ? d <= this.r : d < this.r
}

Tz2dCircle.prototype.parallelTo = function(other_circle) {
  if (!(other_circle instanceof Tz2dCircle)) return false
  return this.pc.distanceTo(other_circle.pc)[0] < 0.0001
}

Tz2dCircle.prototype.equalTo = function(c) {
  return this.pc.equalTo(c.pc) && this.r === c.r
}

Tz2dCircle.prototype.setByPt = function(x1, y1, x2, y2, x3, y3) {
  let l1 = new Tz2dLine(x1, y1, x2, y2)
  let l2 = l1.offsetLine(1000)
  let l12 = new Tz2dLine(l1.middle(), l2.middle())

  let l3 = new Tz2dLine(x2, y2, x3, y3)
  let l4 = l3.offsetLine(1000)
  let l34 = new Tz2dLine(l3.middle(), l4.middle())

  let iPts = l12.intersectAsLine(l34)
  if (iPts.length !== 1) return

  this.pc = iPts[0]
  let dx = this.pc.x - x1
  let dy = this.pc.y - y1
  this.r = Math.sqrt(dx * dx + dy * dy)
  return this
}

Tz2dCircle.prototype.area = function() {
  return Math.PI * this.r * this.r
}

Tz2dCircle.prototype.len = function() {
  return 2 * Math.PI * this.r
}

Tz2dCircle.prototype.offset = function(dx, dy) {
  this.pc.offset(dx, dy)
}

Tz2dCircle.prototype.intersectShape = function(shape, segmentAsLine = true) {
  if (shape instanceof Flatten.Segment && segmentAsLine) {
    let shapeLine = shape.toLine()
    if (shapeLine) {
      return this.intersect(shapeLine)
    } else {
      return this.intersect(shape)
    }
  }
  return this.intersect(shape)
}

Tz2dCircle.prototype.anglePt = function(angle) {
  return new Tz2dPoint(this.pc.x + this.r * Math.cos(angle), this.pc.y + this.r * Math.sin(angle))
}

Tz2dCircle.prototype.degreePt = function(degree) {
  let angle = (degree / 180) * Math.PI
  return this.anglePt(angle)
}

Tz2dCircle.prototype.ptDegree = function(pt) {
  let line = new Tz2dLine(this.pc, pt)
  return line.degree()
}

Tz2dCircle.prototype.ptAngle = function(pt) {
  let line = new Tz2dLine(this.pc, pt)
  return line.angle()
}

Tz2dCircle.prototype.tangentLine = function(ptOnCircle) {
  let c = new Tz2dCircle(ptOnCircle, this.r)
  let pt = c.anglePt(c.ptAngle(this.pc) + Math.PI / 2)
  return new Tz2dLine(pt, ptOnCircle)
}

Tz2dCircle.prototype.toSvg = function(nodeAttr) {
  let attr = nodeAttr.toSvg()
  return `\n<circle cx="${this.pc.x}" cy="${this.pc.y}" r="${this.r}" ${attr} />`
}

Tz2dCircle.prototype.createSvgElement = function(parent, id, nodeAttr, srcElem, nextElem) {
  let elem = TzSvgHelper.createElement('circle', parent, id, nodeAttr, srcElem, nextElem)
  TzSvgHelper.addAttribute(elem, 'cx', this.pc.x)
  TzSvgHelper.addAttribute(elem, 'cy', this.pc.y)
  TzSvgHelper.addAttribute(elem, 'r', this.r)
  TzSvgHelper.addAttribute(elem, 'vector-effect', 'non-scaling-stroke')
  return elem
}
// #endregion

// #region Tz2dArc
const Tz2dArc = Flatten.Arc
/*
pc, r, startAngle, endAngle, counterClockwise
get sweep()
get center() // 圆心
get length()
get box()
contains(pt)
split(pt)
middle() // 弧线中点
chordHeight() // 弦高
breakToFunctional() // Breaks arc in extreme point 0, pi/2, pi, 3*pi/2 and returns array of sub-arcs
tangentInStart()
tangentInEnd()
*/
Tz2dArc.prototype.copy = function(arc) {
  this.pc.copy(arc.pc)
  this.r = arc.r
  this.startAngle = arc.startAngle
  this.endAngle = arc.endAngle
  this.counterClockwise = arc.counterClockwise
  return this
}

Tz2dArc.prototype.clone = function() {
  return new Tz2dArc(this.pc, this.r, this.startAngle, this.endAngle, this.counterClockwise)
}

Tz2dArc.prototype.set = function(pc, r, startAngle, endAngle, counterClockwise) {
  this.pc = pc
  this.r = r
  this.startAngle = startAngle
  this.endAngle = endAngle
  this.counterClockwise = counterClockwise
}

Tz2dArc.prototype.equalSplitPts = function(splitCount = 2) {
  let pts = []
  splitCount = Math.max(splitCount, 2)

  // let ccw = this.counterClockwise ? 1 : -1
  let step = this.sweep / splitCount
  let angle = this.startAngle + step

  for (let i = 0; i < splitCount - 1; i++) {
    pts.push(this.anglePt(angle))
    angle += step
  }
  return pts
}

Tz2dArc.prototype.reverse = function() {
  return new Tz2dArc(this.pc, this.r, this.endAngle, this.startAngle, !this.counterClockwise)
}

Tz2dArc.prototype.equalTo = function(arc) {
  return (
    this.pc.equalTo(arc.pc) &&
    this.r === arc.r &&
    this.startAngle === arc.startAngle &&
    this.endAngle === arc.endAngle &&
    this.counterClockwise === arc.counterClockwise
  )
}

Tz2dArc.prototype.offset = function(dx, dy) {
  this.pc.offset(dx, dy)
}

Tz2dArc.prototype.expand = function(dr) {
  return new Tz2dArc(this.pc, this.r + dr, this.startAngle, this.endAngle, this.counterClockwise)
}

Tz2dArc.prototype.intersectShape = function(shape, segmentAsLine = true) {
  if (shape instanceof Flatten.Segment && segmentAsLine) {
    let shapeLine = shape.toLine()
    if (shapeLine) {
      return this.intersect(shapeLine)
    } else {
      return this.intersect(shape)
    }
  }
  return this.intersect(shape)
}

Tz2dArc.prototype.circle = function() {
  return new Tz2dCircle(this.pc, this.r)
}

/** 按点数分割弧 */
Tz2dArc.prototype.toPoints = function(ptCount = 6) {
  let pts = []
  let step = this.sweep / Math.max(ptCount, 3)
  if (!this.counterClockwise) step = -step
  pts.push(this.start)
  for (let i = 1; i < ptCount; i++) {
    let pt = this.anglePt(this.startAngle + i * step)
    pts.push(pt)
  }
  pts.push(this.end)
  return pts
}

/** 按度数分割弧 */
Tz2dArc.prototype.toPointsByDegree = function(degree = 3) {
  let ptCount = Math.round(this.sweep / ((Math.PI / 180) * degree))
  return this.toPoints(ptCount)
}

Tz2dArc.prototype.anglePt = function(angle) {
  return new Tz2dPoint(this.pc.x + this.r * Math.cos(angle), this.pc.y + this.r * Math.sin(angle))
}

Tz2dArc.prototype.startAnglePt = function() {
  return this.anglePt(this.startAngle)
}

Tz2dArc.prototype.endAnglePt = function(angle) {
  return this.anglePt(this.endAngle)
}

Tz2dArc.prototype.toPolygon = function(count) {
  count = Math.max(count, 2)
  let c = new Tz2dCircle(this.pc, this.r)
  let step = this.sweep / count
  if (!this.counterClockwise) step = -step
  let pts = []
  pts.push(c.anglePt(this.startAngle))
  for (let i = 1; i <= count; i++) {
    pts.push(c.anglePt(this.startAngle + i * step))
  }
  return pts
}

Tz2dArc.prototype.parallelTo = function(other_arc) {
  if (!(other_arc instanceof Tz2dArc)) return false
  return this.pc.distanceTo(other_arc.pc)[0] < 0.0001
}

Tz2dArc.prototype.toSvg = function(nodeAttr) {
  if (Flatten.Utils.EQ(this.sweep, 2 * Math.PI)) {
    let circle = this.circle()
    return circle.svg(nodeAttr)
  } else {
    let attr = nodeAttr.toSvg()
    let largeArcFlag = this.sweep <= Math.PI ? '0' : '1'
    let sweepFlag = this.counterClockwise ? '1' : '0'
    return `\n<path d="M${this.start.x},${this.start.y}
      A${this.r},${this.r} 0 ${largeArcFlag},${sweepFlag} ${this.end.x},${this.end.y}" ${attr} />`
  }
}
// #endregion

// #region Tz2dEdge
const Tz2dEdge = Flatten.Edge
/*
get start()
get end()
get length()
setInclusion(polygon)
setOverlap(edge)
isSegment()
isArc()
*/
Tz2dEdge.prototype.toSvg = function(nodeAttr) {}
// #endregion

// #region Tz2dFace
const Tz2dFace = Flatten.Face
/*
get edges()
get shapes()
shapes2face(edges, shapes)
append(edge)
insert(newEdge, edgeBefore)
remove(edge)
reverse()
orientation()
signedArea()
area()
isSimple(edges)
findEdgeByPoint(pt)
toPolygon()
*/
Tz2dFace.prototype.offset = function(x, y) {
  this.edges.forEach(edge => {
    edge.shape.offset(x, y)
  })
  this._box.offset(x, y)
  return this
}
Tz2dFace.prototype.toSvg = function(nodeAttr) {}
Tz2dFace.prototype.fromJson = function(AJson) {
  this.edges = []
  // 如果JSON里面有对应字段才重新赋值
  // if (AJson) {
  //   let jo = JSON.parse(AJson)
  //   this._doFromJson(jo)
  // }
}
Tz2dFace.prototype.padding = function(displacement) {
  let newShapes = []
  for (let i = 0; i < this.edges.length; i++) {
    let edge = this.edges[i]
    let shape = edge.shape.clone()
    if (edge.isArc()) {
      shape.expand(displacement)
    } else {
      shape.offset(displacement)
    }
    newShapes.push(shape)
  }
  for (let i = 0; i < newShapes.length; i++) {
    let shape = newShapes[i]
    let shapePrev = newShapes[i === 0 ? newShapes.length - 1 : i - 1]
    let pts = []
    if (shape instanceof Tz2dLine && shapePrev instanceof Tz2dLine) {
      pts = shape.intersectAsLine(shapePrev)
    } else {
      pts = shape.intersect(shapePrev)
    }
    if (!pts.length) {
      pts.push(shape.start)
    }
    if (shape instanceof Tz2dLine) {
      shape.ps = pts[0]
    } else {
      let startAngle = shape.startAngle
      shape.set(shape.pc, shape.r, startAngle, shape.endAngle, shape.counterClockwise)
    }
    if (shapePrev instanceof Tz2dLine) {
      shapePrev.pe = pts[0]
    } else {
      let endAngle = shape.endAngle
      shapePrev.set(shape.pc, shape.r, shape.startAngle, endAngle, shape.counterClockwise)
    }
  }
  return new Tz2dPolygon(newShapes)
  // return new Tz2dFace(newShapes)
}
Tz2dFace.prototype.toPts = function() {
  let pts = []
  this.edges.forEach(edge => {
    pts.push(edge.start)
    if (edge.isArc()) {
    }
  })
  return pts
}
Tz2dFace.prototype.boolPoly = function(offsetX, offsetY) {
  let bp = {
    regions: [],
    inverted: false
  }
  let p = []
  let orgPts = this.toPts()
  orgPts.forEach(e => {
    p.push([e.x + offsetX, e.y + offsetY])
  })
  p.reverse()
  bp.regions.push(p)
  return bp
}
Tz2dFace.prototype.ptOnside = function(x, y) {
  let px = arguments.length === 1 ? x.x : x
  let py = arguments.length === 1 ? x.y : y
  let P = new Tz2dPoint(px, py)

  let pts = this.toPts()
  for (let i = 0; i < pts.length; i++) {
    let j = (i + 1) % pts.length
    if (P.similar(pts[i]) || P.similar(pts[j])) return true
    let segment = new Tz2dLine(pts[i], pts[j])
    if (segment.contains(P)) return true
  }
  return false
}

Tz2dFace.prototype.ptInside = function(x, y) {
  let px = arguments.length === 1 ? x.x : x
  let py = arguments.length === 1 ? x.y : y
  let P = new Tz2dPoint(px, py)

  let pts = this.toPts()
  let npol = pts.length - 1
  let j = npol
  let inPoly = false
  for (let k = 0; k < pts.length; k++) {
    let nextIndex = (k + 1 + pts.length) % pts.length
    let line = new Tz2dLine(pts[k], pts[nextIndex])
    if (line.contains(P)) {
      return false
    }
  }
  for (let i = 0; i <= npol; i++) {
    if (
      ((pts[i].y <= py && py < pts[j].y) || (pts[j].y <= py && py < pts[i].y)) &&
      px > ((pts[j].x - pts[i].x) * (py - pts[i].y)) / (pts[j].y - pts[i].y) + pts[i].x
    ) {
      inPoly = !inPoly
    }
    j = i
  }
  return inPoly
}

// #endregion

// #region Tz2dPolygon
const Tz2dPolygon = Flatten.Polygon
/*
clone()
addFace(...args)
deleteFace(face)
removeChain(face, edgeFrom, edgeTo)
addVertex(pt, edge)
cut(multiline)
cutFace(pt1, pt2)
findEdgeByPoint(pt)
splitToIslands()
reverse()
*/

Tz2dPolygon.prototype.onEdge = function(pt) {
  for (let edge of this.edges) {
    if (edge.shape.contains(pt)) {
      return true
    }
  }
  return false
}

Tz2dPolygon.prototype.getFace = function(index) {
  let faces = Array.from(this.faces)
  return faces[index]
}
Tz2dPolygon.prototype.offset = function(x, y) {
  this.faces.forEach(face => {
    face.offset(x, y)
  })
}
Tz2dPolygon.prototype.offsetPolygon = function(x, y) {
  let poly = this.clone()
  poly.offset(x, y)
  return poly
}
Tz2dPolygon.prototype.addPie = function(pc, r, startAngle, endAngle, ccw) {
  let arc = new Tz2dArc(pc, r, startAngle, endAngle, ccw)
  let l1 = new Tz2dLine(arc.end, pc)
  let l2 = new Tz2dLine(pc, arc.start)
  this.addFace([arc, l1, l2])
  return this
}
Tz2dPolygon.prototype.containPts = function(pts) {
  for (let i = 0; i < pts.length; i++) {
    let pt = pts[i]
    if (!this.contains(pt)) return false
  }
  return true
}
Tz2dPolygon.prototype.shapesInside = function(shapes) {
  for (let i = 0; i < shapes.length; i++) {
    if (!this.contains(shapes[i])) {
      return false
    }
  }
  return true
}
Tz2dPolygon.prototype.ptsInside = function(pts) {
  for (let i = 0; i < pts.length; i++) {
    if (!this.ptInside(pts[i])) {
      return false
    }
  }
  return true
}
Tz2dPolygon.prototype.ptInside = function(pt, includeEdge = true) {
  if (!includeEdge && this.onEdge(pt)) {
    return false
  }
  return this.contains(pt)
}
Tz2dPolygon.prototype.ptsPos = function(pts) {
  let bSomeIn = false
  let bSomeOut = false
  for (let i = 0; i < pts.length; i++) {
    if (this.ptInside(pts[i])) {
      bSomeIn = true
    } else {
      bSomeOut = true
    }
    if (bSomeIn && bSomeOut) break
  }
  if (bSomeIn && bSomeOut) {
    return 1 // 相交
  } else if (bSomeIn) {
    return 0 // 内部
  } else {
    return -1
  }
  // let pos = 0
  // for (let i = 0; i < pts.length; i++) {
  //   if (this.ptInside(pts[i])) {
  //     pos++
  //   }
  // }
  // if (pos === pts.length) {
  //   return 0 // 内部
  // } else if (pos > 0) {
  //   return 1 // 相交
  // } else {
  //   return -1
  // }
}

/**
 * 重心
 */
Tz2dPolygon.prototype.gravityCenter = function() {
  let pts = this.getFace(0).toPts()
  let signedArea = 0
  let result = new Tz2dPoint(0, 0)
  for (let index in pts) {
    let nextIndex = (parseInt(index) + 1) % pts.length
    let a = pts[index].x * pts[nextIndex].y - pts[nextIndex].x * pts[index].y
    signedArea += a
    result.x += (pts[index].x + pts[nextIndex].x) * a
    result.y += (pts[index].y + pts[nextIndex].y) * a
  }

  if (Math.abs(signedArea) < 0.0001) return result

  signedArea *= 0.5
  result.x = result.x / (6.0 * signedArea)
  result.y = result.y / (6.0 * signedArea)

  return result
}

Tz2dPolygon.prototype.toSvg = function(nodeAttr) {}

// #endregion

// #region Tz2dMath
class Tz2dMath {
  /** 判断多边形是否顺时针 */
  static polygonIsClockWise(pts) {
    let r = 0
    for (let i = 0; i < pts.length; i++) {
      let pt = pts[i]
      let ptNext = pts[i === pts.length - 1 ? 0 : i + 1]
      r += pt.clockWise(ptNext)
    }
    return r < 0
  }
}
// #endregion

export { svgNs, TzSvgHelper }
export { Tz2dPointPosOnLine, Tz2dCCW, Tz2dRectPos }
export { Tz2dMath, Tz2dMatrix, Tz2dBox, Tz2dPoint, Tz2dLine, Tz2dCircle, Tz2dArc, Tz2dPolygon, Tz2dRect, Tz2dVector, Tz2dRay, Tz2dFace }
