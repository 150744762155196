class TzTool {
    static init() {
   
      Object.defineProperty(Window.prototype, 'TzToolInited', {
        value: true,
        writable: false,
        enumerable: false
      })
    //   this._init_TzT()
    //   this._init_getNextId()
    //   this._init_Object_forOf()
    //   this._init_Array_get()
    //   this._init_Array_clone()
    //   this._init_Array_equal()
    //   this._init_Array_remove()
    //   this._init_Array_union()
    //   this._init_Array_intersect()
    //   this._init_Array_subtract()
    //   this._init_String_toArray()
  
      // eslint-disable-next-line no-extend-native
      Number.prototype.round = function(pn = 0) {
        if (pn === null) return this.valueOf()
        let m = Number('10e' + (pn - 1))
        return Math.round(this.valueOf() * m) / m
      }
  
      /** 区间 */
      // eslint-disable-next-line no-extend-native
      Number.prototype.clamp = function(min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER) {
        let v = this.valueOf()
        if (v < min) v = min
        if (v > max) v = max
        return v
      }
  
      Element.prototype.removeChild_try = function(element) {
        return TzTool.elementRemoveChild_try(this, element)
      }
    }
  
    static _init_getNextId() {
      if (!Window.prototype.hasOwnProperty('_uid')) {
        Object.defineProperty(Window.prototype, '_uid', {
          value: 0,
          writable: true
        })
        Object.defineProperty(Window.prototype, 'getNextId', {
          value: function() {
            // eslint-disable-next-line no-return-assign
            return (this._uid += 1)
          },
          writable: false,
          enumerable: false
        })
      }
    }
  
    static _init_TzT() {
      ;(_ => {
        'use strict'
        let g = window || global
        let lastDoFunDatas = []
        function getLastDoFunData(scope, fun) {
          let funString = fun.toString()
          function addData() {
            let __data = { count: 0, funString: funString }
            lastDoFunDatas.push([__data])
            if (scope) scope.zTlastDoId = lastDoFunDatas.length - 1
            return __data
          }
  
          let lastDoFunData, data
          if (scope) lastDoFunData = lastDoFunDatas[scope.zTlastDoId]
          if (!lastDoFunData) data = addData()
          else {
            for (let _data of lastDoFunData) {
              if (_data.funString === funString) {
                data = _data
                break
              }
            }
            if (!data) data = addData()
          }
          return data
        }
        let workers = []
        function getWoker(fun) {
          let blobStr = TzWorker.getBlobString(fun)
          let sameWorkers = []
          for (let i = 0, leni = workers.length; i < leni; i++) {
            let iWorker = workers[i]
            if (iWorker.blobString === blobStr) {
              if (iWorker.isEnd) return iWorker
              sameWorkers.push(iWorker)
            }
          }
          if (sameWorkers.length > 9) {
            let min = -1
            for (let j = 0, lenj = sameWorkers.length; j < lenj; j++) {
              let jWorker = sameWorkers[j]
              let funLength = jWorker.funLength
              if (funLength < min && min > -1) return jWorker
              else if (min < -1) min = funLength
            }
            return sameWorkers[0]
          }
          let worker = new TzWorker(blobStr)
          workers.push(worker)
          return worker
        }
        /* global TzT */
        /** TzT 工具集 */
        g.TzT = {
          init: _ => {
            'use strict'
          },
          /** TzT Worker 把方法放入线程内执行 */
          Worker: fun => {
            if (!fun) return
            let worker = getWoker(fun)
            return worker
          },
          /** TzT Worker 把方法放入线程内执行 */
          RunManager: () => {
            let runManager = new TzRunManager()
            return runManager
          },
          /** TzT lastDo 等待多少秒后执行一次 */
          lastDo: (scope, fun, awattime = 330, isStartDo = false) => {
            // 'use strict'
            if (isStartDo && fun) fun()
            let data = getLastDoFunData(scope, fun)
            data.count++
            setTimeout(function() {
              data.count = Math.max(0, data.count - 1)
              if (data.count === 0) if (fun) fun()
            }, awattime || 330)
          },
          /** TzT awaitFrames 等待多少帧执行  */
          awaitFrames: (count = 1, frameAbltTime = 30) => {
            // 'use strict'
            return TzTool.awaitFrames(count, frameAbltTime)
          }
        }
      })()
      TzT.init()
    }
  
    static _init_Object_forOf() {
      // eslint-disable-next-line no-extend-native
    //   Object.defineProperty(Object.prototype, 'forOf', {
    //     value: function(callBack) {
    //       let self = this.valueOf()
    //       if (Array.isArray(self)) {
    //         for (const item of self) if (callBack && item) callBack(item)
    //       } else if (self.length) {
    //         for (let i = 0; i < self.length; i++) {
    //           let item = self[i]
    //           if (callBack && item) callBack(item)
    //         }
    //       } else callBack(self)
    //     },
    //     writable: false,
    //     enumerable: false
    //   })
    }
  
    static _init_Array_get() {
      // eslint-disable-next-line no-extend-native
    //   Object.defineProperty(Array.prototype, 'get', {
    //     value: function(callBack) {
    //       for (const item of this.valueOf()) {
    //         if (callBack && item !== null && callBack(item)) return item
    //       }
    //       return null
    //     },
    //     writable: false,
    //     enumerable: false
    //   })
    }
  
    static _init_Array_clone() {
      // eslint-disable-next-line no-extend-native
    //   Object.defineProperty(Array.prototype, 'clone', {
    //     value: function() {
    //       return arrayClone(this.valueOf())
    //       function arrayClone(array) {
    //         if (!Array.isArray(array)) return array
    //         let arr = []
    //         for (let ar of array) arr.push(arrayClone(ar))
    //         return arr
    //       }
    //     },
    //     writable: false,
    //     enumerable: false
    //   })
    }
  
    static _init_Array_equal() {
      // eslint-disable-next-line no-extend-native
      Object.defineProperty(Array.prototype, 'equal', {
        value: function(array) {
          return arrayEqual(this.valueOf(), array)
          function arrayEqual(arr0, arr1) {
            if (!Array.isArray(arr0) || !Array.isArray(arr1)) return arr0 === arr1
            if (arr0.length !== arr1.length) return false
            for (let i = arr0.length - 1; i > -1; i--) {
              if (!arrayEqual(arr0[i], arr1[i])) return false
            }
            return true
          }
        },
        writable: false,
        enumerable: false
      })
    }
  
    static _init_Array_remove() {
      // eslint-disable-next-line no-extend-native
      Object.defineProperty(Array.prototype, 'remove', {
        value: function(value) {
          if (value === null) return
          let v = this.valueOf()
          for (let i = v.length - 1; i > -1; i--) {
            if (v[i] === value) {
              v.splice(i, 1)
              break
            }
          }
        },
        writable: false,
        enumerable: false
      })
    }
  
    static _init_Array_union() {
      // eslint-disable-next-line no-extend-native
      Object.defineProperty(Array.prototype, 'union', {
        value: function(vals) {
          if (vals === null || !vals.length) return
          let vs = this.valueOf()
          for (let val of vals) {
            if (!vs.includes(val)) vs.push(val)
          }
        },
        writable: false,
        enumerable: false
      })
    }
  
    static _init_Array_intersect() {
      // eslint-disable-next-line no-extend-native
      Object.defineProperty(Array.prototype, 'intersect', {
        value: function(vals) {
          if (vals === null || !vals.length) return
          let vs = this.valueOf()
          let ovs = vs.clone()
          vs.splice(0, vs.length)
          for (let val of vals) {
            if (ovs.includes(val)) vs.push(val)
          }
        },
        writable: false,
        enumerable: false
      })
    }
  
    static _init_Array_subtract() {
      // eslint-disable-next-line no-extend-native
      Object.defineProperty(Array.prototype, 'subtract', {
        value: function(vals) {
          if (vals === null || !vals.length) return
          let vs = this.valueOf()
          for (let i = vs.length - 1; i > -1; i--) {
            if (vals.includes(vs[i])) {
              vs.splice(i, 1)
            }
          }
        },
        writable: false,
        enumerable: false
      })
    }
  
    static _init_String_toArray() {
      // eslint-disable-next-line no-extend-native
      Object.defineProperty(String.prototype, 'toArray', {
        value: function(j) {
          if (typeof j === 'string') {
            j = j.replace(' ', '')
          } else {
            j = ','
          }
          let str = this.valueOf()
          let a = []
          let ns = str.split(j)
          for (let i = 0, len = ns.length; i < len; i++) {
            let s = ns[i]
            s = s.replace(' ', '')
            if (!s.length) continue
            s = Number(s)
            if (s) a.push(s)
          }
          return a
        },
        writable: false,
        enumerable: false
      })
    }
  
    static elementRemoveChild_try(self, element) {
      let cns = self.childNodes
      for (const i in cns) {
        if (cns[i] === element) {
          self.removeChild(element)
          return
        }
      }
    }
  
    static awaitFrames(count = 1, frameAbltTime = 30) {
      return new Promise((resolve, reject) => {
        let r = function() {
          if (count <= 1) {
            resolve(frameAbltTime)
          } else {
            d(count--)
          }
        }
        let d = function() {
          requestAnimationFrame(r)
          if (count <= 1) {
            setTimeout(() => {
              resolve(frameAbltTime)
            }, frameAbltTime)
          }
        }
        d()
      })
    }
  
    static awaitTime(time = 1) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(time)
        }, time)
      })
    }
  }

  export {TzTool}