import { THREE } from '@/core/thirdPart/lib.js'
import {TzTool} from '@/core/basic/zTool.js'
import { TzDataType, TzSort } from '@/core/basic/zBasic.js'
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { TextureLoader } from 'three'



class TzEventListener {
    constructor() {
      // this.id = window.getNextId()
      this.event = new TzSort([TzDataType.CHAR])
    }
  
    _getKey(fun, n) {
      let s = '' + n ? n : ''
      s += fun.name
      return s
    }
  
    add(fun, n) {
      if (!fun) return
      this.event.addItem(fun, [this._getKey(fun, n)])
    }
  
    remove(fun, n) {
      if (!fun) return
      this.event.deleteItemByKey(null, [this._getKey(fun, n)])
    }
  
    fire(...args) {
      for (const fun of this.event.items) {
        fun(...args)
      }
    }
  }

class Tz3dTool {
    static init() {
      TzTool.init()
      if (window.Tz3dToolInited) return
      Object.defineProperty(Window.prototype, 'Tz3dToolInited', {
        value: true,
        writable: false,
        enumerable: false
      })
      Object.defineProperty(Window.prototype, 'z3dCache', {
        value: new Tz3dCache(),
        writable: false,
        enumerable: false
      })
      // eslint-disable-next-line no-extend-native
      Number.prototype.toM = function() {
        return this.valueOf() * 0.001
      }
      // eslint-disable-next-line no-extend-native
      Number.prototype.toMM = function() {
        return this.valueOf() * 1000
      }
      // eslint-disable-next-line no-extend-native
      Number.prototype.similar = function(n, e = 0.0001) {
        let c = this.valueOf() - n
        return c < e && c > -e
      }
  
      THREE.Vector2.prototype.toThreeVector = function() {
        return this
      }
  
      THREE.Vector2.prototype.randomRound = function(min, max) {
        this.set(Math.random() * (max - min) + min, Math.random() * (max - min) + min)
        return this
      }
  
      THREE.Vector2.prototype.toTz2dPoint = function() {
        return new Tz2dPoint(this.x, this.y)
      }
  
      THREE.Vector2.prototype.v3 = function(y = 0) {
        return new THREE.Vector3(this.x, y, this.y)
      }
      THREE.Vector2.prototype.toV3 = function(y = 0) {
        return new THREE.Vector3(this.x, y, this.y)
      }
      THREE.Vector2.prototype.toM = function() {
        return this.clone().multiplyScalar(0.001)
      }
      THREE.Vector2.prototype.toMM = function() {
        return this.clone().multiplyScalar(1000)
      }
      THREE.Vector2.prototype.abs = function() {
        this.x = Math.abs(this.x)
        this.y = Math.abs(this.y)
        return this
      }
      THREE.Vector2.prototype.toZeroOne = function() {
        return Tz3dMath.v2ToZeroOne(this)
      }
      THREE.Vector2.prototype.mirror = function() {
        return Tz3dMath.v2Mirror(this)
      }
      THREE.Vector2.prototype.similar = function(v, e = 0.0001) {
        if (v.z) return Math.abs(this.x - v.x) < e && Math.abs(this.y - v.z) < e
        else return Math.abs(this.x - v.x) < e && Math.abs(this.y - v.y) < e
      }
      THREE.Vector2.prototype.rotate = function(angle) {
        return this.rotateAround(new THREE.Vector2(), angle)
      }
      THREE.Vector2.prototype.toV3 = function() {
        return new THREE.Vector3(this.x, 0, this.y)
      }
      // 尺寸面积差异-异或 （寻找最匹配的尺寸）
      THREE.Vector2.prototype.areaDifference = function(s) {
        return Math.abs(this.x * this.y + s.x * s.y - 2 * (this.x > s.x ? s.x : this.x) * (this.y > s.y ? s.y : this.y))
      }
  
      THREE.Vector2.prototype.round = function(pn) {
        return Tz3dMath.v2Round(this, pn)
      }
      // THREE.Vector2.prototype.angle360 = function(v) {
      //   return Tz3dMath.v2angle360(this, v)
      // }
      THREE.Vector3.prototype.v2 = function() {
        return new THREE.Vector2(this.x, this.z)
      }
      THREE.Vector3.prototype.getAngleY = function(point) {
        return Tz3dMath.getV3AngleY(this, point)
      }
      THREE.Vector3.prototype.rotateY = function(angle) {
        return this.applyEuler(new THREE.Euler(0, angle, 0))
      }
      THREE.Vector3.prototype.toXZ = function(point) {
        return new THREE.Vector2(this.x, this.z)
      }
      THREE.Vector3.prototype.toM = function() {
        return this.clone().multiplyScalar(0.001)
      }
      THREE.Vector3.prototype.toMM = function() {
        return this.clone().multiplyScalar(1000)
      }
      THREE.Vector3.prototype.minLenth = function() {
        return Tz3dMath.v3MinLenth(this)
      }
      THREE.Vector3.prototype.maxLenth = function() {
        return Tz3dMath.v3MaxLenth(this)
      }
      THREE.Vector3.prototype.abs = function() {
        this.x = Math.abs(this.x)
        this.y = Math.abs(this.y)
        this.z = Math.abs(this.z)
        return this
      }
      // xyz近似相等
      THREE.Vector3.prototype.similar = function(v, e = 0.0001) {
        return Math.abs(this.x - v.x) < e && Math.abs(this.y - v.y) < e && Math.abs(this.z - v.z) < e
      }
  
      // xz近似相等
      THREE.Vector3.prototype.similar2 = function(v, e = 0.0001) {
        if (v.z) return Math.abs(this.x - v.x) < e && Math.abs(this.z - v.z) < e
        else return Math.abs(this.x - v.x) < e && Math.abs(this.z - v.y) < e
      }
      // 计算体积差异-异或 （寻找最匹配的尺寸）
      THREE.Vector3.prototype.volumeDifference = function(s) {
        return Math.abs(
          this.x * this.y * this.z + s.x * s.y * s.z - 2 * (this.x > s.x ? s.x : this.x) * (this.y > s.y ? s.y : this.y) * (this.z > s.z ? s.z : this.z)
        )
      }
      // camera方向向量转euler，忽略Z旋转
      THREE.Vector3.prototype.cameraAngleFromForward = function() {
        let result = new THREE.Euler(0, 0, 0)
        if (this.length() === 0) {
          return result
        }
        let nForward = new THREE.Vector3(0, 0, -1)
        let nTest
        let nRotateByXY = this.clone()
        let nXZ = new THREE.Vector3(nRotateByXY.x, 0, nRotateByXY.z)
        nXZ.normalize()
        if (nXZ.length() !== 0) {
          result.y = nXZ.angleTo(nForward)
          nTest = nForward.clone()
          nTest.applyEuler(new THREE.Euler(0, result.y, 0))
          nTest.normalize()
          if (!nTest.similar(nXZ)) {
            result.y = -result.y
          }
        }
        let nRotateByX = nRotateByXY.applyEuler(new THREE.Euler(0, -result.y, 0))
        nRotateByX.normalize()
        result.x = nForward.angleTo(nRotateByX)
        nTest = nForward.clone()
        nTest.applyEuler(new THREE.Euler(result.x, 0, 0))
        nTest.normalize()
        if (!nTest.similar(nRotateByX)) {
          result.x = -result.x
        }
        return result
      }
  
      // 向量相对于(0, 0, -1)方向的旋转 ay
      THREE.Vector3.prototype.angleFromForward = function() {
        this.normalize()
        let ay = 0
        if (this.length() === 0) {
          return ay
        }
        let nForward = new THREE.Vector3(0, 0, -1)
        ay = this.angleTo(nForward)
        let nTest = nForward.clone()
        nTest.applyEuler(new THREE.Euler(0, ay, 0))
        nTest.normalize()
        if (Math.abs(nTest.x - this.x) > 0.0001 || Math.abs(nTest.y - this.y) > 0.0001 || Math.abs(nTest.this - this.z) > 0.0001) {
          ay = -ay
        }
        return ay
      }
  
      THREE.Vector3.prototype.offset = function(s, e, f) {
        return Tz3dMath.v3Offset(s, e, f)
      }
      THREE.Vector3.prototype.toV2 = function() {
        return new THREE.Vector2(this.x, this.z)
      }
  
      THREE.Vector3.prototype.round = function(pn) {
        return Tz3dMath.v3Round(this, pn)
      }
  
      THREE.Vector3.prototype.clampLength = function(min = 0, max = 0) {
        return Tz3dMath.v3clampLength(this, min, max)
      }
  
      THREE.Vector3.prototype.angleSpEpByNor = function(sp, ep, nor) {
        return Tz3dMath.v3angleSpEpByNor(this, sp, ep, nor)
      }
  
      THREE.Vector3.prototype.toEulerForDegree = function() {
        return new THREE.Euler(this.x * THREE.Math.DEG2RAD, this.y * THREE.Math.DEG2RAD, this.z * THREE.Math.DEG2RAD)
      }
  
      THREE.Vector3.prototype.toEulerForDegree_world = function() {
        return new THREE.Euler(this.x * THREE.Math.DEG2RAD, this.y * THREE.Math.DEG2RAD, this.z * THREE.Math.DEG2RAD, 'ZYX')
      }
  
      THREE.Vector3.prototype.toQuaternionForDegree = function() {
        return new THREE.Quaternion().setFromEuler(this.toEulerForDegree())
      }
  
      THREE.Vector3.prototype.toQuaternionForDegree_world = function() {
        return this.toEulerForDegree().quaternion()
      }
  
      // n：经过自己的点的方向normalize；  pn：平面法向量； pv：平面经过的点
      THREE.Vector3.prototype.lineIntersectPlane = function(n, pn, pv) {
        return Tz3dMath.linePlaneIntersect(n, this, pn, pv)
      }
  
      THREE.Vector3.prototype.toThreeVector = function() {
        return this
      }
  
      // xyzw近似相等
      THREE.Vector4.prototype.similar = function(v, e = 0.0001) {
        return Math.abs(this.x - v.x) < e && Math.abs(this.y - v.y) < e && Math.abs(this.z - v.z) < e && Math.abs(this.w - v.w) < e
      }
      THREE.Euler.prototype.add = function(e) {
        return Tz3dMath.euler_add(this, e)
      }
      THREE.Euler.prototype.quaternion = function() {
        // 获取XYZ 顺序, 需ZYX  运算
        // let quat = new THREE.Quaternion().setFromEuler(new THREE.Euler(0, 0, this.z))
        // quat.multiply(new THREE.Quaternion().setFromEuler(new THREE.Euler(0, this.y, 0)))
        // quat.multiply(new THREE.Quaternion().setFromEuler(new THREE.Euler(this.x, 0, 0)))
        this._order = 'ZYX'
        let quat = new THREE.Quaternion().setFromEuler(this)
        return quat
      }
      THREE.Euler.prototype.matrix = function() {
        let mX = new THREE.Matrix4().identity()
        mX.makeRotationX(this.x)
        let mY = new THREE.Matrix4().identity()
        mY.makeRotationY(this.y)
        let mZ = new THREE.Matrix4().identity()
        mZ.makeRotationZ(this.z)
        let mXY = mY.multiply(mX)
        let mXYZ = mZ.multiply(mXY)
        return mXYZ
      }
      THREE.Quaternion.prototype.euler = function() {
        let result = new THREE.Euler(0, 0, 0)
        result.setFromQuaternion(this)
        return result
      }
      THREE.Quaternion.prototype.toVector3Degree = function() {
        let rf = new THREE.Euler().setFromQuaternion(this)
        let angle = new THREE.Vector3(rf.x, rf.y, rf.z).multiplyScalar(180 / Math.PI)
        return angle
      }
      THREE.Quaternion.prototype.toVector3Degree_world = function() {
        let m = new THREE.Matrix4().makeRotationFromQuaternion(this)
        var te = m.elements
        let m11 = te[0]
        let m12 = te[4]
        // let m13 = te[8]
        let m21 = te[1]
        let m22 = te[5]
        let m23 = te[9]
        let m31 = te[2]
        let m32 = te[6]
        let m33 = te[10]
        let x, y, z
        if (m21.round(6) === 0) {
          x = Math.atan2(-m23, m22)
          y = Math.atan2(-m31, m11)
          z = Math.asin(THREE.MathUtils.clamp(m21, -1, 1))
        } else {
          y = Math.asin(-THREE.MathUtils.clamp(m31, -1, 1))
          if (Math.abs(m31) < 0.9999999) {
            x = Math.atan2(m32, m33)
            z = Math.atan2(m21, m11)
          } else {
            x = Math.atan2(m12, m22)
            z = 0
          }
        }
  
        let angle = new THREE.Vector3(x, y, z).multiplyScalar(180 / Math.PI).round(6)
        return angle
      }
  
      THREE.Matrix4.prototype.toPRS = function() {
        return Tz3dMath.matrixToPRS(this)
      }
      THREE.Matrix4.prototype.toPosQuaScale = function(sxLess0, syLess0, szLess0) {
        return Tz3dMath.matrixToPosQuaScale(this, sxLess0, syLess0, szLess0)
      }
      THREE.Box2.prototype.isBox2 = true
      THREE.Box2.prototype.center = function() {
        return Tz3dMath.getBoxCenter(this)
      }
      THREE.Box2.prototype.size = function() {
        return Tz3dMath.getBoxSize(this)
      }
      THREE.Box2.prototype.compute = function() {
        Tz3dMath.getBoxSize(this)
        Tz3dMath.getBoxCenter(this)
        this.isComputed = true
        return this
      }
      THREE.Box3.prototype.projectPoint = function(point, normal, uvStartMode, texSize, uvMode, uvAngle, offsetUv) {
        return Tz3dMath.box3ProjectPoint(this, point, normal, uvStartMode, texSize, uvMode, uvAngle, offsetUv)
      }
      THREE.Box3.prototype.center = function() {
        return Tz3dMath.getBoxCenter(this)
      }
      THREE.Box3.prototype.size = function() {
        return Tz3dMath.getBoxSize(this)
      }
      THREE.Box3.prototype.compute = function() {
        Tz3dMath.getBoxSize(this)
        Tz3dMath.getBoxCenter(this)
        this.isComputed = true
        return this
      }
      THREE.Box3.prototype.toM = function() {
        return Tz3dMath.box3ToM(this)
      }
      THREE.Box3.prototype.toMM = function() {
        return Tz3dMath.box3ToMM(this)
      }
      THREE.BufferGeometry.prototype.shapeTo3d = function(pos, angle) {
        return Tz3dTool.geometryShapeTo3d(this, pos, angle)
      }
      THREE.Float32BufferAttribute.prototype.concat = function(array = []) {
        return Tz3dMath.Float32BufferAttribute_concat(this, array)
      }
      THREE.Material.prototype.toMeshBasicMaterial = function() {
        return Tz3dTool.toMeshBasicMaterial(this)
      }
      THREE.BufferGeometry.prototype.toPointScale = function(f) {
        return Tz3dMath.toGeometryPointScale(this, f)
      }
      THREE.BufferGeometry.prototype.toM = function() {
        return Tz3dMath.toGeometryPointScale(this, 0.001)
      }
      THREE.BufferGeometry.prototype.toMM = function() {
        return Tz3dMath.toGeometryPointScale(this, 1000)
      }
      THREE.BufferGeometry.prototype.toWireFrame = function() {
        return Tz3dTool.geometryToWireFrame(this)
      }
      THREE.BufferGeometry.prototype.subtract = function(geo) {
        return geo ? threecsg.subtract(this, geo) : this
      }
      THREE.BufferGeometry.prototype.union = function(geo) {
        return geo ? threecsg.union(this, geo) : this
      }
      THREE.BufferGeometry.prototype.intersect = function(geo) {
        return geo ? threecsg.intersect(this, geo) : this
      }
      THREE.BufferGeometry.prototype.computeUv = function() {
        return Tz3dTool.computeBufferGeometryUv(this)
      }
      THREE.Raycaster.prototype.intersectObject = function(object, recursive, optionalTarget, isOnly) {
        return Tz3dTool.raycaster_intersectObject(this, object, recursive, optionalTarget, isOnly)
      }
      THREE.Raycaster.prototype.intersectObjects = function(objects, recursive, optionalTarget, isOnly) {
        return Tz3dTool.raycaster_intersectObjects(this, objects, recursive, optionalTarget, isOnly)
      }
      THREE.Object3D.prototype.normalize = function() {
        Tz3dTool.object3DNormalize(this)
      }
  
      LineGeometry.prototype.setPositions = function(arrays) {
        return Tz3dTool.lineGeometrySetPosition(this, arrays)
      }
    }
  
    static get z3dCache() {
      return window.z3dCache
    }
  
    /** @description 使用svg截面 按路径 创建对象 返回：{@link Tz3dNode}
     * @param {string} svgUrl  svg的url地址
     * @param {number} step  步数 越高截面的细节越高
     * @param {THREE.Vector3} normal 截面up朝向
     * @param {} callback  回调
     * @param {boolean} sizeOverride  大小重置
     * @param {boolean} isCenterOffset  是否中心偏移
     */
    static getSvgToCrosss(svgUrl, step = 5, normal, callback, sizeOverride = null, isCenterOffset = true) {
      this.z3dCache.getSvg(svgUrl, svg => {
        let paths = svg.paths
        let _points = []
        let pointss = []
        for (const _path of paths) {
          const shapes = _path.toShapes(true, true)
          for (const shape of shapes) {
            let points = shape.extractPoints(step).shape
            // console.log(shape.extractPoints(1).shape)
            _points = _points.concat(points)
            pointss.push(points)
          }
        }
        if (isCenterOffset) {
          let box = Tz3dMath.getVBox(_points).compute()
          Tz3dMath.vArraySub(_points, box.center)
          let sizeB = sizeOverride && sizeOverride.isVector2 ? sizeOverride.clone().divide(box.size) : box.size.clone().set(1, 1)
          for (const _pt of _points) {
            _pt.y = -_pt.y
            _pt.multiply(sizeB)
          }
        }
        if (callback) callback(pointss)
      })
    }
  
    static geometryShapeTo3d(geo, pos, angle) {
      if (geo.boundingSphere === null) {
        geo.computeBoundingSphere()
      }
      let _angle = angle || 0
      let _pos = pos || new THREE.Vector3()
      let euler = new THREE.Euler(-Math.PI / 2, 0, _angle)
      let vc = geo.boundingSphere.center
        .clone()
        .negate()
        .applyEuler(euler)
        .add(_pos)
      let matrix = new THREE.Matrix4().makeRotationFromEuler(euler)
      matrix.setPosition(vc.x, vc.y, vc.z)
      geo.applyMatrix4(matrix)
      return geo
    }
  
    static geometryToCenter(geo, center) {
      if (geo.boundingSphere === null) {
        geo.computeBoundingSphere()
      }
      let vc = center && center.isVector3 ? center : geo.boundingSphere.center
      let v3array = Tz3dMath.arrayToV3Array(geo.getAttribute('position').array)
      for (let i in v3array) {
        v3array[i].sub(vc)
      }
      geo.setAttribute('position', new THREE.Float32BufferAttribute(Tz3dMath.v3ArrayToArray(v3array), 3))
      return geo
    }
  
    static filterFace(point2ds, hole2ds) {
      function filterPt(pts) {
        let isFL_equals = pts[0].x === pts[pts.length - 1].x && pts[0].y === pts[pts.length - 1].y
        let lJan = isFL_equals ? -1 : 0
        let prcs = Math.pow(0.1, Tz3dMath.polygonPrecision)
        for (let i = pts.length + lJan - 1; i > -1; i--) {
          let l = pts.length + lJan
          let iL0 = i - 1 < 0
          let iL1 = i + 1 < l
          const pt = pts[i]
          const pt0 = pts[iL0 ? l - 1 : i - 1]
          const pt1 = pts[iL1 ? i + 1 : 0]
          let tPt = new THREE.Vector2()
          let dis = Tz3dMath.pt2line_distance_pt(pt0, pt, pt1, tPt)
          if (dis < prcs) {
            pts.splice(i, 1)
          }
        }
      }
      function filter(pss) {
        let p2ds = Tz3dMath.tfPolygon2v2Array_pa(pss[0])
        let h2ds = []
        for (let i = 1, l = pss.length; i < l; i++) {
          let _2ds = Tz3dMath.tfPolygon2v2Array_pa(pss[i])
          if (_2ds.length) h2ds.push(_2ds)
        }
        filterPt(p2ds)
        return [p2ds, h2ds]
      }
  
      let pg = Tz3dMath.v2Array2TfPolygon(point2ds)
      let pgHole = null
      for (let hole of hole2ds) {
        let holes2d = Tz3dMath.v2Array2TfPolygon(hole)
        pgHole = pgHole ? turf.union(pgHole, holes2d) : holes2d
      }
      let p2ds = Tz3dMath.tfPolygon2v2Array(pg)
      let ms = [[p2ds, []]]
      if (pgHole) {
        let _pg
        try {
          _pg = turf.difference(pg, pgHole)
        } catch {
          console.log('error')
        }
        pg = _pg || pg
        ms = []
        let pss = pg.geometry.coordinates
        if (pss.length > 1) {
          if (pg.geometry.type === 'MultiPolygon') {
            for (let ps of pss) ms.push(filter(ps))
          } else {
            // if (pss.type === 'Polygon')
            ms.push(filter(pss))
          }
        } else {
          p2ds = Tz3dMath.tfPolygon2v2Array(pg)
          ms.push([p2ds, []])
        }
      }
      return ms
    }
  
    static rectFace(points = [], holes = [], normal) {
      let pl = points.length
      if (pl < 3) return points
      let p2ds = []
      let h2ds = []
      let edgePts = [[...points]]
      let ps = points.slice(0, pl)
      let _nor = normal && normal.isVector3 ? normal : Tz3dMath.computeFaceNormal(ps)
      let v3a = Tz3dMath.v3ArrayToArray(ps)
      ps = Tz3dMath.arrayToV3Array(v3a)
      let qua = new THREE.Quaternion().setFromUnitVectors(_nor, new THREE.Vector3(0, 1, 0))
      let y
      for (let p of ps) {
        let v3p = p.clone().applyQuaternion(qua)
        if (!y) y = v3p.y
        p2ds.push(new THREE.Vector2(v3p.x, v3p.z))
      }
      let positionArray = []
      let p3ds = [...ps]
      if (holes && holes.length && holes[0].length) {
        for (let h of holes) {
          let _h2d = []
          for (let p of h) {
            let v3p = p.clone().applyQuaternion(qua)
            _h2d.push(new THREE.Vector2(v3p.x, v3p.z))
          }
          if (_h2d.length) h2ds.push(_h2d)
        }
        let _qua = qua.conjugate()
        let ms = this.filterFace(p2ds, h2ds)
        edgePts = []
        for (let m of ms) {
          let shapes = THREE.ShapeUtils.triangulateShape(m[0], m[1])
          p3ds = []
          addPs(m[0], _qua)
          addHPs(m[1], _qua)
          addPosition(shapes)
        }
      } else {
        let sps = THREE.ShapeUtils.triangulateShape(p2ds, [])
        addPosition(sps)
      }
      function addPs(_p2ds, _qua) {
        let _p3ds = []
        for (let p of _p2ds) _p3ds.push(new THREE.Vector3(p.x, y, p.y).applyQuaternion(_qua))
        p3ds = p3ds.concat(_p3ds)
        edgePts.push([..._p3ds])
      }
      function addHPs(_h2ds, _qua) {
        for (let h of _h2ds) {
          for (let p of h) p3ds.push(new THREE.Vector3(p.x, y, p.y).applyQuaternion(_qua))
        }
      }
      function addPosition(sps) {
        for (let i = 0; i < sps.length; i++) {
          for (let j = sps[i].length - 1; j >= 0; j--) {
            let p = p3ds[sps[i][j]]
            positionArray.push(p.x, p.y, p.z)
          }
        }
      }
      return [positionArray, edgePts]
    }
  
    // geo生成面 shape转法
    static getBufferGeometry(points = [], holes = [], normal) {
      let geo = new THREE.BufferGeometry()
      let face = this.rectFace(points, holes, normal)
      let position = face[0]
      let edge = face[1]
      geo.setAttribute('position', new THREE.Float32BufferAttribute(position, 3))
      geo.computeVertexNormals()
      return [geo, edge]
    }
  
    // geo生成面 截面路径法 近似平面或平面生成
    static getCrossBufferGeometry(
      line = [],
      cross = [],
      normal = null,
      forward = null,
      isLineClose = true,
      isCrossClose = true,
      crossHoles = [[]],
      endVectors = []
    ) {
      let v3Array = []
      let crossPoints = []
      if (THREE.ShapeUtils.isClockWise(cross)) {
        cross = cross.clone().reverse()
      }
      if (!normal || !normal.isVector3) {
        if (line.length > 2) normal = Tz3dMath.computeFaceNormal(line)
        else if (line.length > 1) {
          normal = line[0]
            .clone()
            .sub(line[1])
            .toXZ()
            .normalize()
            .rotate(Math.PI / 2)
            .v3()
        } else normal = new THREE.Vector3(0, 1, 0)
      }
      if (forward) {
        let angle = normal.angleTo(forward)
        cross.forEach(_cp => _cp.rotate(-angle))
      }
      let q = new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(0, 1, 0), normal)
      let qCj = q.clone().conjugate()
      let vf = new THREE.Vector3(0, 0, -1).applyQuaternion(qCj).setY(0)
      vf.round(8).normalize()
      let planeForward = new THREE.Vector3(0, 0, -1)
      let plane = new THREE.Plane(new THREE.Vector3(0, 1, 0).applyQuaternion(q))
      if (!Math.abs(vf.x) && !Math.abs(vf.z)) vf.set(0, 0, normal.y > 0 ? 1 : -1)
  
      // 根据截面计算此点截面点
  
      for (let i = 0, len = line.length; i < len; i++) {
        let p0, p1, crossV3
        let first = i === 0
        let last = i === len - 1
        let p = line[i]
        let n
        if (first) {
          if (isLineClose) {
            p0 = line[len - 1]
          } else if (endVectors[0]) {
            n = endVectors[0].clone().normalize()
          }
        } else p0 = line[i - 1]
        if (last) {
          if (isLineClose) {
            p1 = line[0]
          } else if (endVectors[1]) {
            n = endVectors[1].clone().normalize()
          }
        } else p1 = line[i + 1]
        if (line.length === 2) {
          crossV3 = getCrossPoints_2p(p, p0, p1, n)
        } else {
          crossV3 = Tz3dMath.getCrossPoints(cross, q, qCj, vf, p, p0, p1, n)
        }
        crossPoints.push(crossV3)
      }
      let crossALength = crossPoints.length
      for (let i = 0; i < crossALength; i++) {
        let isLast = i === crossALength - 1
        if (!isLineClose && isLast) continue
        let ps = crossPoints[i]
        let ps1 = isLast ? crossPoints[0] : crossPoints[i + 1]
        for (let j = 0; j < ps.length; j++) {
          let j1 = j + 1
          if (j === ps.length - 1) {
            if (!isCrossClose) continue
            j1 = 0
          }
          v3Array.push(ps[j], ps1[j], ps1[j1], ps1[j1], ps[j1], ps[j])
        }
      }
      let postion = Tz3dMath.v3ArrayToArray(v3Array)
      if (!isLineClose) {
        let lc = crossPoints[crossALength - 1]
        let first = Tz3dTool.rectFace(crossPoints[0], crossHoles)[0]
        let last = Tz3dTool.rectFace(lc.reverse(), crossHoles)[0]
        postion = postion.concat(first)
        postion = postion.concat(last)
      }
      let geo = new THREE.BufferGeometry()
      geo.setAttribute('position', new THREE.Float32BufferAttribute(postion, 3))
      geo.computeVertexNormals()
      return geo
      function getCrossPoints_2p(_p, _p0, _p1, n) {
        let crossV3 = []
        let n0, n1, n2, pn, q
        if (_p0) {
          n0 = _p
            .clone()
            .sub(_p0)
            .normalize()
        }
        if (_p1) {
          n1 = _p1
            .clone()
            .sub(_p)
            .normalize()
        }
        n2 = n0 || n1
        pn = new THREE.Vector3()
        plane.projectPoint(n2, pn)
        pn.normalize()
        q = new THREE.Quaternion().setFromUnitVectors(planeForward, pn)
        n =
          n ||
          (n0 && n1
            ? n0
                .clone()
                .add(n1)
                .normalize()
            : n2)
        for (let c of cross) {
          let pos = new THREE.Vector3(c.x, c.y, 0).applyQuaternion(q)
          pos = Tz3dMath.linePlaneIntersect(n2, pos, n, new THREE.Vector3()) || pos
          pos.add(_p)
          crossV3.push(pos)
        }
        return crossV3
      }
    }
  
    static addMaterial(material, mat) {
      if (material == null || material.length === 0) {
        material = mat
      } else {
        if (material.length > 0) {
          material.push(mat)
        } else {
          material = [material, mat]
        }
      }
      return material
    }
  
    static dispose(obj, isDisposeMarterial) {
      if (!obj || !obj.isObject3D) return
      obj.traverse(function(child) {
        if (child.isMesh) {
          child.geometry.dispose()
          if (child.marterial && isDisposeMarterial) {
            if (child.marterial.length > 0) {
              for (var i in child.marterial) {
                child.marterial[i].dispose()
              }
            } else {
              child.marterial.dispose()
            }
          }
        }
      })
    }
  
    static getMeshWorldPosition(mesh) {
      mesh.geometry.computeBoundingBox()
      let bCenter = Tz3dMath.getBoxCenter(mesh.geometry.boundingBox)
      bCenter.applyMatrix4(mesh.matrixWorld)
      return bCenter
    }
  
    static getMatIdAddMaterial(material, mat) {
      let id = 0
      if (material == null || material.length === 0) {
        material = mat
        id = 0
      } else {
        if (material.length > 0) {
          for (let i = 0; i < material.length; i++) {
            if (material[i].id === mat.id) {
              return { material: material, id: i }
            }
          }
          material.push(mat)
          id = material.length - 1
        } else if (material.id === mat.id) {
          id = 0
        } else {
          material = [material, mat]
          id = 1
        }
      }
      return { material: material, id: id }
    }
  
    static geometryChangeToWorld(obj, scale) {
      let _obj = new THREE.Object3D()
      obj.updateWorldMatrix(true, true)
      obj.traverse(function(child) {
        if (child.isMesh) {
          let matrix = child.matrixWorld.clone()
          if (scale) {
            matrix.multiply(new THREE.Matrix4().makeScale(scale, scale, scale))
          }
          new THREE.BufferGeometry().applyMatrix4()
          child.geometry.applyMatrix4(matrix)
          if (!child.isBone) {
            child.position.set(0, 0, 0)
            child.rotation.set(0, 0, 0)
            child.scale.set(1, 1, 1)
          }
          _obj.attach(child.clone())
        }
      })
      Tz3dTool.dispose(obj, true)
      return _obj
    }
  
    static geometryWriteSortByMats(obj, meshs) {
      let sort = []
      let addLength = 0
      if (meshs) {
        for (let mesh of meshs) {
          let pris = mesh.primitives
          let lenth = pris.length
          for (let i = 0; i < lenth; i++) {
            sort[pris[i].material + addLength] = i + addLength
          }
          addLength += lenth
        }
      }
      obj.sort = sort
    }
  
    static geometryChangeToZero(obj) {
      if (!obj.box) {
        this.setBox_w(obj)
      }
      let box = obj.box
      let center = Tz3dMath.getBoxCenter(box).negate()
      obj.traverse(function(child) {
        if (child.isMesh) {
          let matrix = new THREE.Matrix4().makeTranslation(center.x, center.y, center.z)
          child.geometry.applyMatrix4(matrix)
        }
      })
    }
  
    static geometryMerge(obj) {
      let geometrys = []
      let material = null
      let groups = null
      let start = 0
      obj.traverse(function(child) {
        if (child.isMesh) {
          let geo = null
          if (child.geometry.isGeometry) {
            geo = new THREE.BufferGeometry().fromGeometry(child.geometry)
          } else {
            geo = child.geometry.clone()
          }
          geometrys.push(geo)
          let _groups = geo.groups
          let mat = child.material
          let ids = []
          if (mat.length > 1) {
            material = material == null ? [] : material
            for (let m in mat) {
              let matId = Tz3dTool.getMatIdAddMaterial(material, mat[m])
              material = matId.material
              ids[m] = matId.id
            }
          } else {
            let matId = Tz3dTool.getMatIdAddMaterial(material, mat)
            material = matId.material
            ids[0] = matId.id
          }
          if (_groups != null && _groups.length > 0) {
            let posCount = 0
            for (let i in _groups) {
              groups = groups == null ? [] : groups
              let _group = { start: 0, count: _groups[i].count, materialIndex: 0 }
              _group.start = _groups[i].start + start
              _group.materialIndex = ids[_groups[i].materialIndex]
              groups.push(_group)
              posCount += _groups[i].count
            }
            start += posCount
          } else {
            groups = groups == null ? [] : groups
            let posCount = geo.attributes.position.count
            let _group = { start: start, count: posCount, materialIndex: ids[0] }
            groups.push(_group)
            start += posCount
          }
        }
      })
      let objNew = null
      try {
        let geometry = BufferGeometryUtils.mergeBufferGeometries(geometrys, true)
        for (let i in geometrys) {
          geometrys[i].dispose()
        }
        geometry.groups = groups
        objNew = new THREE.Mesh(geometry, material)
      } catch (e) {
        // console.log(e)
      }
      if (objNew != null) {
        Tz3dTool.dispose(obj)
      } else {
        objNew = obj
      }
      return objNew
    }
  
    static geometryBreak(obj) {
      let _obj = new THREE.Object3D()
  
      let _geometry = obj.geometry
      _geometry = _geometry.isGeometry ? new THREE.BufferGeometry().fromGeometry(_geometry) : _geometry
      let _material = obj.material
      let _groups = _geometry.groups
      let position = _geometry.getAttribute('position')
      let normal = _geometry.getAttribute('normal')
      let uv = _geometry.getAttribute('uv')
      if (_groups != null && _groups.length > 0) {
        let ids = []
        for (let i in _groups) {
          let _mIndex = _groups[i].materialIndex
          if (ids.indexOf(_mIndex) === -1) {
            ids.push(_mIndex)
          }
        }
        ids.sort()
        for (let i = 0; i < ids.length; i++) {
          let geometry = new THREE.BufferGeometry()
          let posArray = []
          let norArray = []
          let uvArray = []
          for (let j in _groups) {
            let _group = _groups[j]
            if (_group.materialIndex === ids[i]) {
              geometry = geometry || new THREE.BufferGeometry()
              let startIndex = _group.start * 3
              let endIndex = (_group.start + _group.count) * 3
              let startIndex_uv = _group.start * 2
              let endIndex_uv = (_group.start + _group.count) * 2
              let _posArray = position.array.slice(startIndex, endIndex)
              let _norArray = normal.array.slice(startIndex, endIndex)
              Tz3dMath.arrayConcat(posArray, _posArray)
              Tz3dMath.arrayConcat(norArray, _norArray)
              if (uv) {
                let _uvArray = uv.array.slice(startIndex_uv, endIndex_uv)
                Tz3dMath.arrayConcat(uvArray, _uvArray)
              }
            }
          }
          let posAtt = new THREE.Float32BufferAttribute(posArray, 3)
          let norAtt = new THREE.Float32BufferAttribute(norArray, 3)
          let uvAtt = new THREE.Float32BufferAttribute(uvArray, 2)
          geometry.setAttribute('position', posAtt)
          geometry.setAttribute('normal', norAtt)
          geometry.setAttribute('uv', uvAtt)
          let material = _material.length > 0 ? (_material.length > ids[i] ? _material[ids[i]] : null) : _material
          let mesh = new THREE.Mesh(_geometry, material)
          _obj.attach(mesh)
        }
        _geometry.dispose()
      } else {
        let material = _material.length > 0 ? _material[0] : _material
        let mesh = new THREE.Mesh(_geometry, material)
        _obj.attach(mesh)
      }
  
      return _obj
    }
  
    static getContinuousPoints(arrays) {
      let points = []
      if (arrays[0].length) {
        for (let arr of arrays) addPoints(arr)
      } else addPoints(arrays)
      points = new Float32Array(points)
      function addPoints(a) {
        let len = a.length - 3
        for (let i = 0; i < len; i += 3) {
          points.push(a[i])
          points.push(a[i + 1])
          points.push(a[i + 2])
          points.push(a[i + 3])
          points.push(a[i + 4])
          points.push(a[i + 5])
        }
      }
      return points
    }
  
    static lineGeometrySetPosition(self, arrays) {
      if (!arrays.length) return self
      let lineSegments = this.getContinuousPoints(arrays)
      var instanceBuffer = new THREE.InstancedInterleavedBuffer(lineSegments, 6, 1) // xyz, xyz
      self.setAttribute('instanceStart', new THREE.InterleavedBufferAttribute(instanceBuffer, 3, 0)) // xyz
      self.setAttribute('instanceEnd', new THREE.InterleavedBufferAttribute(instanceBuffer, 3, 3)) // xyz
      //
      self.computeBoundingBox()
      self.computeBoundingSphere()
      return self
    }
  
    static setBox_w(obj) {
      let box3 = new THREE.Box3()
      obj.traverse(function(child) {
        if (child.isMesh) {
          child.geometry.computeBoundingBox()
          let _box3 = child.geometry.boundingBox.clone()
          box3.min.min(_box3.min)
          box3.max.max(_box3.max)
        }
      })
      obj.box = box3
    }
  
    static getBox(obj) {
      let self = this
      let box3 = null
      obj.updateWorldMatrix(true, true)
      obj.traverse(function(child) {
        if (child.isMesh) {
          let pos = new THREE.Vector3()
          pos = self.getMeshWorldPosition(child)
          let _box3 = child.geometry.boundingBox.clone()
          let size = Tz3dMath.getBoxSize(_box3)
          let matrix = child.matrixWorld.clone().setPosition(0, 0, 0)
          size.applyMatrix4(matrix)
          _box3.setFromCenterAndSize(pos, size)
          box3 = box3 == null ? _box3 : box3.union(_box3)
        }
      })
      return box3 == null ? new THREE.Box3() : box3
    }
  
    static boxSubset(geo, sets = [[4], [5], [0, 3, 1, 2]]) {
      let groups = []
      let uvs = geo.getAttribute('uv').array
      if (sets && sets.isArray && sets[0].isArray) {
        for (let i in sets) {
          let length = sets[i].length
          let uv = true
          for (let j in sets[i]) {
            if (sets[i][j] === 4 || sets[i][j] === 5) {
              uv = false
              break
            }
          }
          for (let j in sets[i]) {
            let group = { start: sets[i][j] * 6, materialIndex: i, count: 6 }
            groups.push(group)
            if (length > 1) {
              if (uv) {
                uvs[sets[i][j] * 12] = j / length
                uvs[sets[i][j] * 12 + 2] = j / length
                uvs[sets[i][j] * 12 + 4] = (j + 1) / length
              } else {
                uvs[sets[i][j] * 12 + 1] = j / length
                uvs[sets[i][j] * 12 + 3] = j / length
                uvs[sets[i][j] * 12 + 5] = (j + 1) / length
              }
            }
          }
        }
      } else {
        groups.push({ start: 0, materialIndex: 0, count: 36 })
      }
      // geo.getAttribute('uv').array
      geo.groups = groups
    }
  
    static getExtension(str) {
      return str.substring(str.lastIndexOf('.'))
    }
  
    static getDicpath(str) {
      return str.substring(0, str.lastIndexOf('.'))
    }
  
    static addEventListener_resize(div, callback) {
      if (document.attachEvent) {
        // ie9-10
        div.attachEvent('onresize', callback)
      } else {
        let obj = document.createElement('object')
        obj.setAttribute(
          'style',
          'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;'
        )
        obj.onload = function() {
          obj.contentDocument.defaultView.addEventListener('resize', callback)
        }
        obj.type = 'text/html'
        div.appendChild(obj)
        obj.data = 'about:blank'
      }
    }
  
    static removeEventListener_resize(div, callback) {
      if (document.attachEvent) {
        // ie9-10
        div.detachEvent('onresize', callback)
      } else {
        for (let cn in div.children) {
          if (div.children[cn].localName === 'object') {
            div.removeChild(div.children[cn])
          }
        }
      }
    }
  
    static getIeVersion() {
      return parseInt(
        navigator.appVersion
          .split(';')[1]
          .replace(/[ ]/g, '')
          .replace('MSIE', '')
      )
    }
  
    static ieVersionLess(n) {
      let isIe = navigator.appName === 'Microsoft Internet Explorer'
      return isIe && this.getIeVersion() < n
    }
  
    static getScreenPointer(domElement, event) {
      if (document.pointerLockElement) {
        return {
          x: 0,
          y: 0,
          button: event.button
        }
      } else {
        let pointer = event.changedTouches ? event.changedTouches[0] : event
        let rect = domElement.getBoundingClientRect()
        return {
          x: ((pointer.clientX - rect.left) / rect.width) * 2 - 1,
          y: (-(pointer.clientY - rect.top) / rect.height) * 2 + 1,
          button: event.button
        }
      }
    }
  
    static getWorldPosToScreenPointer(viewport, worldPos) {
      let v2 = new THREE.Vector2()
      if (viewport && viewport.camera) {
        let v3 = worldPos.clone().project(viewport.camera)
        let a = viewport.canvasCtrl.clientWidth / 2
        let b = viewport.canvasCtrl.clientHeight / 2
        v2.x = Math.round(v3.x * a + a)
        v2.y = Math.round(-v3.y * b + b)
      }
      return v2
    }
  
    static computeBufferGeometryUv(geo) {
      let position = geo.attributes.position
      if (!position) return geo
      let uvs = []
      for (let i = 0, len = position.count; i < len; i++) {
        uvs.push(0, 1)
      }
      return geo.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2))
    }
  
    static mergeBufferGeometries(geometrys, isGroup = true) {
      let geometry = null
      try {
        geometry = BufferGeometryUtils.mergeBufferGeometries(geometrys, true)
      } catch (e) {
        // // console.log('merge failed')
      }
      return geometry
    }
  
    static mergeBufferGeometries_X(geometrys) {
      let geometry = null
      try {
        let _geometrys = []
        let hasUv2 = false
        for (const _geometry of geometrys) {
          let _geo = _geometry
          if (_geometry.index) _geo = _geometry.toNonIndexed()
          if (!_geo.attributes.uv) {
            _geo.computeUv()
          }
          if (_geo.attributes.uv2) hasUv2 = true
          _geometrys.push(_geo)
        }
        if (hasUv2) {
          for (const __geo of _geometrys) {
            if (!__geo.attributes.uv2) __geo.setAttribute('uv2', __geo.attributes.uv)
          }
        }
        geometry = BufferGeometryUtils.mergeBufferGeometries(_geometrys, true)
        if (!geometry) return geometry
  
        // let groups = []
        // let start = 0
        // for (let i = 0; i < geometrys.length; i++) {
        //   let _groups = geometrys[i].groups
        //   if (_groups != null && _groups.length > 0) {
        //     let posCount = 0
        //     for (let i in _groups) {
        //       groups = groups == null ? [] : groups
        //       let _group = {
        //         start: 0,
        //         count: _groups[i].count,
        //         materialIndex: _groups[i].materialIndex
        //       }
        //       _group.start = _groups[i].start + start
        //       groups.push(_group)
        //       posCount += _groups[i].count
        //     }
        //     start += posCount
        //   } else {
        //     groups = groups == null ? [] : groups
        //     let posCount = geometrys[i].attributes.position.count
        //     let _group = { start: start, count: posCount, materialIndex: 0 }
        //     groups.push(_group)
        //     start += posCount
        //   }
        // }
        // geometry.groups = groups
      } catch (e) {
        console.log('merge failed', e)
      }
      return geometry
    }
  
    static mergeMeshs(meshs = [], parentMesh) {
      let pcMax = 300
      let _meshs = []
  
      let geosPC = []
      for (let i = 0, len = meshs.length; i < len; i++) {
        geosPC.push([meshs[i].geometry.attributes.position.count, i])
      }
      geosPC.sort((a, b) => a[0] - b[0])
      let pcMax_ = pcMax - geosPC[0][0]
      for (let i = geosPC.length - 1; i > -1; i--) {
        const geoPC = geosPC[i]
        if (geoPC[0] > pcMax_) {
          let mesh = meshs[geoPC[1]]
          _meshs.push([getGeo(mesh), getMat(mesh)])
          geosPC.splice(i, 1)
        } else break
      }
  
      for (let i = geosPC.length - 1; i > -1; i--) {
        if (!geosPC.length) break
        let curI = geosPC.length - 1
        const geoPC = geosPC[curI]
        let pc = geoPC[0]
        let mesh = meshs[geoPC[1]]
        let geos = []
        let mats = []
        geos.push(getGeo(mesh))
        let mat = getMat(mesh)
        if (Array.isArray(mat)) mats = mats.concat(mat)
        else mats.push(mat)
  
        if (geosPC.length === 1) {
          _meshs.push([geos[0], mats])
          break
        } else {
          let pushed = false
          for (let j = 0, jlen = geosPC.length - 1; j < jlen; j++) {
            const _geoPC = geosPC[j]
            let _pc = _geoPC[0]
            if (pc + _pc > pcMax) {
              _meshs.push([this.mergeBufferGeometries_X(geos), mats])
              geosPC.splice(curI, 1)
              geosPC.splice(0, j)
              pushed = true
              break
            } else {
              pc += _pc
              let _mesh = meshs[_geoPC[1]]
              let _geo = getGeo(_mesh)
              let _mat = getMat(_mesh)
              geos.push(_geo)
              if (Array.isArray(_mat)) mats = mats.concat(_mat)
              else mats.push(_mat)
            }
          }
          if (!pushed) {
            _meshs.push([this.mergeBufferGeometries_X(geos), mats])
            break
          }
        }
      }
  
      if (parentMesh) {
        let matrix = new THREE.Matrix4().getInverse(parentMesh.matrixWorld)
        for (const _mesh of _meshs) if (_mesh[0]) _mesh[0].applyMatrix4(matrix)
      }
      return _meshs
  
      function getGeo(__mesh) {
        return __mesh.geometry.clone().applyMatrix4(__mesh.matrixWorld)
      }
      function getMat(__mesh) {
        let mat = __mesh.material
        if (Array.isArray(mat)) {
          let _mats = []
          for (let _mat of mat) _mats.push(_mat.clone())
          return _mats
        } else return mat.clone()
      }
    }
  
    static createDiv(div, id) {
      let _div = document.createElement('div')
      _div.setAttribute('id', 'div_' + id)
      _div.setAttribute('style', 'position: relative;' + 'width: 100%; height: 100%;' + 'overflow: hidden;')
      if (div) {
        div.appendChild(_div)
      }
      return _div
    }
  
    static createSvg(div, id) {
      let svg = document.createElement('svg')
      svg.setAttribute('id', 'svg_' + id)
      svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
      svg.setAttribute('version', '1.1')
      svg.setAttribute(
        'style',
        'position: relative; width: 100%; height: 100%; top: 0px; left: 0px;' +
          // +'font-weight: bolder;' +
          'color: #333;' +
          // 'text-shadow: 0 1.2px #fff, 1.2px 0 #fff, -1.2px 0 #fff, 0 -1.2px #fff;' +
          'pointer-events: none;' // font-family: "san francisco", "siyuansongti";
      )
      div.appendChild(svg)
      return svg
    }
  
    // static createFont(svg, id) {
    //   let text = document.createElement('text')
    //   text.setAttribute('class', 'font_' + id)
    //   text.setAttribute('stroke-width', '0.2px')
    //   text.setAttribute('color', '#000')
    //   text.setAttribute('stroke', '#fff')
    //   text.setAttribute(
    //     'style',
    //     'transform: translate(0%, 0%) translate(0px, 0px) rotate(0rad);'
    //   )
    //   svg.appendChild(text)
    //   return text
    // }
  
    static createFont(div, id) {
      let span = document.createElement('span')
      span.setAttribute('class', 'fontSpanInp_' + id)
      span.setAttribute(
        'style',
        'position: absolute; top: 0; left: 0; font-size: 10px; ' + 'font-weight: 400; ' + 'transform: translate(-50%, -50%) translate(0px, 0px) rotate(0rad);'
      )
      div.appendChild(span)
      return span
    }
  
    static createInput(div, id) {
      let span = document.createElement('span')
      span.setAttribute('class', 'spanInp_' + id)
      span.setAttribute(
        'style',
        'position: absolute; top: 0; left: 0;width: 5px;height: 22px; overflow: hidden;' + 'transform: translate(-50%, -50%) translate(0px, 0px) rotate(0rad);'
      )
      var input = document.createElement('input')
      input.setAttribute('type', 'text')
      input.setAttribute('maxlength', '32')
      input.setAttribute(
        'style',
        'position: absolute; top: 0; left: 0; width: 100%; font-size: 12px; text-align:center; ' + 'overflow: hidden; line-height: 1; border: 1px solid #378888;'
      )
      span.appendChild(input)
      div.appendChild(span)
      return [span, input]
    }
  
    static toMeshBasicMaterial(self) {
      if (!self.isMeshBasicMaterial) {
        let mat = new THREE.MeshBasicMaterial().copy(self)
        mat.needsUpdate = true
        return mat
      } else {
        return self
      }
    }
  
    static toMeshStandardMaterial(self) {
      if (!self.isMeshStandardMaterial) {
        let mat = new THREE.MeshStandardMaterial().copy(self)
        mat.needsUpdate = true
        return mat
      } else {
        return self
      }
    }
  
    static stringToArray(str, sj) {
      if (typeof sj === 'string') {
        sj = sj.replace(' ', '')
      } else {
        sj = ','
      }
      let a = []
      let ns = str ? str.split(sj) : []
      for (let i = 0, len = ns.length; i < len; i++) {
        let s = ns[i]
        s = s.replace(' ', '')
        if (!s.length) continue
        s = Number(s)
        if (!Number.isNaN(s)) a.push(s)
      }
      return a
    }
  
    static toTexEncoding(obj, encoding = THREE.LinearEncoding) {
      obj.traverse(function(child) {
        if (child.material) {
          let mat = child.material
          if (mat.length > 0) {
            for (let i in mat) {
              if (mat[i].map) {
                mat[i].map.encoding = encoding
                mat[i].needsUpdate = true
              }
            }
          } else if (mat.map) {
            mat.map.encoding = encoding
            mat.needsUpdate = true
          }
        }
      })
    }
  
    static createGridHelper(size, divisions, color1, color2, color3) {
      size = size || 200
      divisions = divisions || 200
      color1 = new THREE.Color(color1 || 0xd6d6d6) // 0xd6d6d6
      color2 = new THREE.Color(color2 || 0xe0e0e0) // 0xdddddd
      color3 = new THREE.Color(color3 || 0xffffff) // 0xffffff
  
      let center = divisions / 2
      let step = size / divisions
      let halfSize = size / 2
  
      let vertices = []
      let colors = []
  
      for (let i = 0, j = 0, k = -halfSize; i <= divisions; i++, k += step) {
        vertices.push(-halfSize, 0, k, halfSize, 0, k)
        vertices.push(k, 0, -halfSize, k, 0, halfSize)
        let is = i.toString()
        let g = Number(is.slice(is.length - 1, is.length))
        let color = i === center ? color1 : g === 0 ? color2 : color3
  
        color.toArray(colors, j)
        j += 3
        color.toArray(colors, j)
        j += 3
        color.toArray(colors, j)
        j += 3
        color.toArray(colors, j)
        j += 3
      }
  
      var geometry = new THREE.BufferGeometry()
      geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3))
      geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3))
  
      var material = new THREE.LineBasicMaterial({
        vertexColors: true,
        toneMapped: false
      })
      return new THREE.LineSegments(geometry, material)
    }
  
    static createWireMaterial() {
      let mat = new THREE.ShaderMaterial({
        vertexShader:
          'attribute vec3 center;\n' +
          'varying vec3 vCenter;\n' +
          'void main() {\n' +
          '    vCenter = center;\n' +
          '    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);\n' +
          '}',
        fragmentShader:
          'uniform float widthFactor;\n' +
          'uniform vec3 color;\n' +
          'uniform float opacity;\n' +
          'varying vec3 vCenter;\n' +
          'float edgeFactorTri() {\n' +
          '    vec3 d = fwidth(vCenter.xyz);\n' +
          '    vec3 a3 = smoothstep(vec3(0.0), d * widthFactor, vCenter.xyz);\n' +
          '    return min(min(a3.x, a3.y), a3.z);\n' +
          '}\n' +
          'void main() {\n' +
          '    if (edgeFactorTri() > 0.99) discard;\n' +
          // '    gl_FragColor = gl_FrontFacing ? vec4(0.9, 0.9, 1.0, 1.0) : vec4(0.4, 0.4, 0.5, 1.0);\n' +
          '    gl_FragColor = vec4(color.x, color.y, color.z, opacity);\n' +
          '}',
        uniforms: {
          widthFactor: {
            value: 100
          },
          color: {
            value: new THREE.Color(0xffffff)
          },
          opacity: {
            value: 1.0
          }
        },
        depthTest: false, // false
        depthWrite: false, // false
        transparent: true,
        side: THREE.DoubleSide
      })
      mat.extensions.derivatives = true
      return mat
    }
  
    static geometryToWireFrame(geometry) {
      var vectors = [new THREE.Vector3(1, 0, 0), new THREE.Vector3(0, 1, 0), new THREE.Vector3(0, 0, 1)]
      var position = geometry.attributes.position
      var centers = new Float32Array(position.count * 3)
      for (var i = 0, l = position.count; i < l; i++) {
        vectors[i % 3].toArray(centers, i * 3)
      }
      geometry.setAttribute('center', new THREE.BufferAttribute(centers, 3))
    }
  
    /**
     * callback(x,y)
     */
    static async getSvgXY(svgUrl, callback, step = 5) {
      let get = { loaded: false, x: 0, y: 0 }
      this.z3dCache.getSvg(svgUrl, svg => {
        let paths = svg.paths
        let _points = []
        for (const _path of paths) {
          const shapes = _path.toShapes(true, true)
          for (const shape of shapes) {
            let points = shape.extractPoints(step).shape
            _points = _points.concat(points)
          }
        }
        let box = Tz3dMath.getVBox(_points).compute()
        get.loaded = true
        get.x = box.size.x
        get.y = box.size.y
        if (callback) {
          callback(get.x, get.y)
        }
      })
      while (!get.loaded) await this.awaitTime(10)
      return [get.x, get.y]
    }
  
    static raycaster_intersectObjectTo(object, raycaster, intersects, recursive, isOnly) {
      if (isOnly && intersects.length > 0) return
      if (object.layers.test(raycaster.layers) && (!object.geometry  || object.geometry.attributes.position)) {
        object.raycast(raycaster, intersects)
      }
      if (recursive === true) {
        var children = object.children
        for (var i = 0, l = children.length; i < l; i++) {
          this.raycaster_intersectObjectTo(children[i], raycaster, intersects, true)
        }
      }
    }
  
    static ascSort(a, b) {
      return a.distance - b.distance
    }
  
    static raycaster_intersectObject(self, object, recursive, optionalTarget, isOnly) {
      var intersects = optionalTarget || []
      this.raycaster_intersectObjectTo(object, self, intersects, recursive, isOnly)
      intersects.sort(this.ascSort)
      return intersects
    }
  
    static raycaster_intersectObjects(self, objects, recursive, optionalTarget, isOnly) {
      var intersects = optionalTarget || []
      if (Array.isArray(objects) === false) {
        console.warn('THREE.Raycaster.intersectObjects: objects is not an Array.')
        return intersects
      }
      for (var i = 0, l = objects.length; i < l; i++) {
        this.raycaster_intersectObjectTo(objects[i], self, intersects, recursive, isOnly)
      }
  
      intersects.sort(this.ascSort)
  
      return intersects
    }
  
    static object3DNormalize(self) {
      self.position.set(0, 0, 0)
      self.rotation.set(0, 0, 0)
      self.scale.set(1, 1, 1)
    }
  
    static awaitTime(time = 1) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(time)
        }, time)
      })
    }
  
    static awaitFrames(count = 1, frameAbltTime = 30) {
      return new Promise((resolve, reject) => {
        let r = function() {
          if (count <= 1) {
            resolve(frameAbltTime)
          } else {
            d(count--)
          }
        }
        let d = function() {
          requestAnimationFrame(r)
          if (count <= 1) {
            setTimeout(() => {
              resolve(frameAbltTime)
            }, frameAbltTime)
          }
        }
        d()
      })
    }
  
    static consoleLogObjects(object) {
      let oCount = { n: 1, meshs: 0, points: 0 }
      getCount(object, oCount)
      console.log(oCount.n, '个物体', oCount.meshs, '个模型', oCount.points, '个顶点')
      function getCount(obj, c) {
        let len = obj.children.length
        c.n += len
        if (obj.geometry) {
          c.meshs += 1
          if (obj.geometry.attributes && obj.geometry.attributes.position) {
            c.points += obj.geometry.attributes.position.count
          }
        }
  
        if (obj.children.length) {
          for (let o of obj.children) {
            getCount(o, c)
          }
        }
      }
    }
  }
  /** Tz3dCache */
  class Tz3dCache {
    constructor() {
      this.manager = new THREE.LoadingManager()
      this.textures = new TzSort([TzDataType.CHAR])
      this.meshs = new TzSort([TzDataType.CHAR])
      this.svgs = new TzSort([TzDataType.CHAR])
      this.edgeGeos = new TzSort([TzDataType.INTEGER])
      this.asyncIds = new TzSort([TzDataType.INTEGER])
  
      this.onProgress = new TzEventListener()
      this.manager.onProgress = (item, loaded, total) => {
        // console.log(item, loaded, total)
        this.onProgress.fire(item, loaded, total)
      }
    }
  
    addTexture(obj, id) {
      this.textures.addItem(obj, [id])
    }
  
    addSvg(obj, id) {
      this.svgs.addItem(obj, [id])
    }
  
    addMesh(obj, id) {
      this.meshs.addItem(obj, [id])
    }
  
    getAsyncById(id, fun) {
      let async = this.asyncIds.findItem(null, [id])
      if (!async) {
        async = {
          id: id,
          asynced: false
        }
        this.asyncIds.addItem(async, [id])
        if (fun) fun(async)
      }
      return async
    }
  
    getTexture(id, callback) {
      let tex = this.textures.findItem(null, [id])
      if (!tex) {
        tex = {
          textureUrl: id,
          loaded: false,
          tex: null
        }
        tex.tex = new TextureLoader().load(
          id,
          _tex => {
            tex.loaded = true
            if (callback) callback(_tex)
          },
          fail => this.removeTexture(id)
        )
        this.addTexture(tex, id)
      } else awaitLoaded()
      return tex.tex
  
      async function awaitLoaded() {
        if (callback) {
          while (!tex.loaded) await Tz3dTool.awaitTime(10)
          callback(tex.tex)
        }
      }
    }
  
    getMesh(id) {
      return this.meshs.findItem(null, [id])
    }
  
    getSvg(id, callback) {
      let svg = this.svgs.findItem(null, [id])
      if (!svg) {
        svg = { svgUrl: id, svg: null }
        new SVGLoader().load(
          id,
          _svg => {
            svg.svg = _svg
            if (callback) callback(_svg)
          },
          pf => {},
          fail => this.removeSvg(id)
        )
        this.addSvg(svg, id)
      } else awaitLoaded()
      return svg.svg
  
      async function awaitLoaded() {
        if (callback) {
          while (!svg.svg) await Tz3dTool.awaitTime(10)
          callback(svg.svg)
        }
      }
    }
  
    getEdgeGeo(id, isAddCache, callback, byAddGeo, thresholdAngle = 20) {
      let self = this
      let geoData = self.edgeGeos.findItem(null, [id])
      if (geoData) {
        if (geoData.geo) callbackGeo()
        else geoData.fun.add(callbackGeo)
      } else {
        geoData = {}
        geoData.fun = new TzFunListener(callbackGeo)
        if (isAddCache) {
          self.edgeGeos.addItem(geoData, [id])
        }
        if (byAddGeo) {
          let index = byAddGeo.addEdgeGeometryIndex
          index = index ? index + 1 : 1
          byAddGeo.addEdgeGeometryIndex = index
          if (byAddGeo.attributes.position.count > 666) {
            // > 666< 0
            Tz3dLoader.loadEdgeGeo(
              byAddGeo,
              __edgeG => {
                if (byAddGeo.addEdgeGeometryIndex === index) callbackAddGeo(__edgeG)
              },
              thresholdAngle
            )
          } else {
            let __edgeG = new THREE.EdgesGeometry(byAddGeo, thresholdAngle)
            __edgeG.deleteAttribute('normal')
            __edgeG.deleteAttribute('uv')
            callbackAddGeo(__edgeG)
          }
        }
      }
      function callbackAddGeo(_edge) {
        geoData.geo = _edge
        if (geoData.fun) geoData.fun.fire()
      }
      function callbackGeo() {
        if (callback && geoData.fun) callback(geoData.geo)
      }
    }
  
    removeTexture(id) {
      this.textures.deleteItemByKey(null, [id])
    }
  
    removeSvg(id) {
      this.svgs.deleteItemByKey(null, [id])
    }
  
    removeMesh(id) {
      this.meshs.deleteItemByKey(null, [id])
    }
  
    removeEdgeGeo(id) {
      let geoData = this.edgeGeos.findItem(null, [id])
      if (geoData) geoData.fun = null
      this.edgeGeos.deleteItemByKey(null, [id])
    }
  }


  class Tz3dUpdate {
    constructor(updateFrame, fun) {
      this._count = 0
      this.updateFrame = updateFrame || -1
      this.fun = fun
      this.displayUpdateAlways = false
      this._isStarted_ = false
    }
  
    start() {
      this._isStarted_ = true
      let self = this
      let r = function() {
        requestAnimationFrame(r)
        if (self._count > 0 || self.displayUpdateAlways) {
          self._count = 0
          self._render()
        }
      }
      requestAnimationFrame(r)
    }
  
    render() {
      this._count += 1
      if (!this._isStarted_) {
        this.start()
      }
    }
  
    _render() {
      if (this.fun) {
        this.fun()
      }
    }
  }

  class Tz3dMath {
    // math
    static v2IsNaN(v) {
      return v && !!(v.x === Number.NaN || v.y === Number.NaN)
    }
  
    static v3IsNaN(v) {
      return v && !!(v.x === Number.NaN || v.y === Number.NaN || v.z === Number.NaN)
    }
  
    static v3MinLenth(v) {
      let l = v.x < v.y ? v.x : v.y
      return l < v.z ? l : v.z
    }
  
    static v3MaxLenth(v) {
      let l = v.x > v.y ? v.x : v.y
      return l > v.z ? l : v.z
    }
  
    static v3Offset(s, e, f) {
      let dir = s.clone().sub(e)
      let add = new THREE.Vector3(-dir.z, 0, dir.x).normalize().multiplyScalar(f)
      return [add.clone().add(s), add.clone().add(e)]
    }
  
    static v3Abs(v) {
      let _v = new THREE.Vector3(Math.abs(v.x), Math.abs(v.y), Math.abs(v.z))
      return _v
    }
  
    static v2Abs(v) {
      let _v = new THREE.Vector2(Math.abs(v.x), Math.abs(v.y))
      return _v
    }
  
    static v3SubAbs(v0, v1) {
      let _v = new THREE.Vector3(Math.abs(v0.x - v1.x), Math.abs(v0.y - v1.y), Math.abs(v0.z - v1.z))
      return _v
    }
  
    static v2SubAbs(v0, v1) {
      let _v = new THREE.Vector2(Math.abs(v0.x - v1.x), Math.abs(v0.y - v1.y))
      return _v
    }
  
    static v2Mirror(v) {
      let xz = Math.floor(v.x)
      if (xz % 2 === 1) {
        v.x = 1 - v.x + xz
      }
      let yz = Math.floor(v.y)
      if (yz % 2 === 1) {
        v.y = 1 - v.y + yz
      }
      return v
    }
  
    static v2ToZeroOne(v) {
      let xz = Math.floor(v.x)
      if (xz % 2 === 1) {
        v.x -= xz
      }
      let yz = Math.floor(v.y)
      if (yz % 2 === 1) {
        v.y -= yz
      }
      return v
    }
  
    static matrixToPRS(matrix) {
      let quaternion = new THREE.Quaternion()
      let position = new THREE.Vector3()
      let scale = new THREE.Vector3()
      matrix.decompose(position, quaternion, scale)
      let r = new THREE.Euler().setFromQuaternion(quaternion)
      let rotation = new THREE.Vector3(r.x, r.y, r.z)
      return [position, rotation, scale]
    }
  
    static matrixToPosQuaScale(matrix, sxLess0, syLess0, szLess0) {
      let pqs = {}
      pqs.qua = new THREE.Quaternion()
      pqs.pos = new THREE.Vector3()
      pqs.scale = new THREE.Vector3()
  
      let _v1 = new THREE.Vector3()
      let te = matrix.elements
      let sx = _v1.set(te[0], te[1], te[2]).length()
      let sy = _v1.set(te[4], te[5], te[6]).length()
      let sz = _v1.set(te[8], te[9], te[10]).length()
      if (sxLess0) sx = -sx
      if (syLess0) sy = -sy
      if (szLess0) sz = -sz
      pqs.pos.x = te[12]
      pqs.pos.y = te[13]
      pqs.pos.z = te[14]
      let _m1 = matrix.clone()
      let invSX = 1 / sx
      let invSY = 1 / sy
      let invSZ = 1 / sz
      _m1.elements[0] *= invSX
      _m1.elements[1] *= invSX
      _m1.elements[2] *= invSX
      _m1.elements[4] *= invSY
      _m1.elements[5] *= invSY
      _m1.elements[6] *= invSY
      _m1.elements[8] *= invSZ
      _m1.elements[9] *= invSZ
      _m1.elements[10] *= invSZ
      pqs.qua.setFromRotationMatrix(_m1)
      pqs.scale.x = sx
      pqs.scale.y = sy
      pqs.scale.z = sz
      return pqs
    }
  
    // 坐标投影相加 三维（y轴向上）/二维
    static projectNorTwo_3_2_y(v0, v1) {
      let _v = new THREE.Vector2(v0.x * v1.x + v0.z * v1.y, v0.x * v1.y + v0.z * v1.x)
      return _v
    }
  
    static axisV_Nor(f) {
      let _v = new THREE.Vector2(Math.cos(f), Math.sin(f))
      return _v
    }
  
    static axisV_Nor_Abs(f) {
      let _v = new THREE.Vector2(Math.cos(f), Math.abs(Math.sin(f)))
      return _v
    }
  
    static euler_add(self, e) {
      self.x += e.x
      self.y += e.y
      self.z += e.z
      return self
    }
  
    static v2Round(v, pn = 0) {
      let m = Number('10e' + (pn - 1))
      v.x = Math.round(v.x * m) / m
      v.y = Math.round(v.y * m) / m
      return v
    }
  
    static v3Round(v, pn = 0) {
      let m = Number('10e' + (pn - 1))
      v.x = Math.round(v.x * m) / m
      v.y = Math.round(v.y * m) / m
      v.z = Math.round(v.z * m) / m
      return v
    }
  
    static v3clampLength(v, min = 0, max = 0) {
      if (min >= max) return v.clone()
      let l = v.length()
      if (l < min) {
        let nmin = v.clone().normalize()
        return nmin.multiplyScalar(min)
      }
      if (l > max) {
        let nmax = v.clone().normalize()
        return nmax.multiplyScalar(max)
      }
      return v.clone()
    }
  
    static v2angle360(v, p) {
      // point0.clone().cross(point1).y > 0 ? point0.angleTo(point1) : -point0.angleTo(point1)
      // let d = v.dot(p)
    }
  
    static v3angleSpEpByNor(v, sp, ep, nor) {
      if (!nor) return 0
      let qua = new THREE.Quaternion().setFromUnitVectors(nor, new THREE.Vector3(0, 1, 0))
      let _v = v.clone().applyQuaternion(qua)
      let _sp = sp.clone().applyQuaternion(qua)
      let _ep = ep.clone().applyQuaternion(qua)
      let sp2 = new THREE.Vector3(_sp.x - _v.x, 0, _sp.z - _v.z)
      let ep2 = new THREE.Vector3(_ep.x - _v.x, 0, _ep.z - _v.z)
      return sp2.getAngleY(ep2)
    }
  
    // 计算二三维点中心
    static getVCenter(points = []) {
      let box = this.getVBox(points)
      return box.max
        .clone()
        .add(box.min)
        .multiplyScalar(0.5)
    }
  
    // 计算二三维点大小
    static getVSize(points = []) {
      let box = this.getVBox(points)
      return box.max.clone().sub(box.min)
    }
  
    // 计算三维点box
    static getVBox(points = []) {
      if (!points.length) return new THREE.Box3()
      let min, max, box
      if (points[0].length && points[0].length > 0) {
        for (let ps of points) set(ps)
      } else set(points)
      if (min) {
        if (min.isVector3) box = new THREE.Box3(min, max)
        else if (min.isVector2) box = new THREE.Box2(min, max)
      } else box = new THREE.Box3()
      function set(pts) {
        for (let p of pts) {
          if (!min) {
            min = p.clone()
            max = p.clone()
          } else {
            min.min(p)
            max.max(p)
          }
        }
      }
      return box
    }
  
    // 计算数组 二三维点 加
    static vArrayAdd(points = [], addPt) {
      if (!points.length) return
      if (points[0].length && points[0].length > 0) {
        for (let ps of points) set(ps)
      } else set(points)
      function set(pts) {
        for (let p of pts) p.add(addPt)
      }
    }
  
    // 计算数组 二三维点 加
    static vArraySub(points = [], subPt) {
      if (!points.length) return
      if (points[0].length && points[0].length > 0) {
        for (let ps of points) set(ps)
      } else set(points)
      function set(pts) {
        for (let p of pts) p.sub(subPt)
      }
    }
  
    static getBoxPositions(size) {
      let positions = []
      let s = size.clone().multiplyScalar(0.5)
      positions.push(s.x, s.y, s.z)
      positions.push(s.x, -s.y, s.z)
      positions.push(s.x, -s.y, -s.z)
      positions.push(s.x, s.y, -s.z)
      positions.push(s.x, s.y, s.z)
      positions.push(-s.x, s.y, s.z)
      positions.push(-s.x, -s.y, s.z)
      positions.push(-s.x, -s.y, -s.z)
      positions.push(-s.x, s.y, -s.z)
      positions.push(-s.x, s.y, s.z)
      positions.push(-s.x, -s.y, s.z)
      positions.push(s.x, -s.y, s.z)
      positions.push(s.x, -s.y, -s.z)
      positions.push(-s.x, -s.y, -s.z)
      positions.push(-s.x, s.y, -s.z)
      positions.push(s.x, s.y, -s.z)
      return positions
    }
  
    static getBoxPoints(size) {
      let points = []
      let s = size.clone().multiplyScalar(0.5)
      points.push(new THREE.Vector3(s.x, s.y, s.z))
      points.push(new THREE.Vector3(s.x, -s.y, s.z))
      points.push(new THREE.Vector3(s.x, -s.y, -s.z))
      points.push(new THREE.Vector3(s.x, s.y, -s.z))
      points.push(new THREE.Vector3(s.x, s.y, s.z))
      points.push(new THREE.Vector3(-s.x, s.y, s.z))
      points.push(new THREE.Vector3(-s.x, -s.y, s.z))
      points.push(new THREE.Vector3(-s.x, -s.y, -s.z))
      points.push(new THREE.Vector3(-s.x, s.y, -s.z))
      points.push(new THREE.Vector3(-s.x, s.y, s.z))
      points.push(new THREE.Vector3(-s.x, -s.y, s.z))
      points.push(new THREE.Vector3(s.x, -s.y, s.z))
      points.push(new THREE.Vector3(s.x, -s.y, -s.z))
      points.push(new THREE.Vector3(-s.x, -s.y, -s.z))
      points.push(new THREE.Vector3(-s.x, s.y, -s.z))
      points.push(new THREE.Vector3(s.x, s.y, -s.z))
      return points
    }
  
    // 坐标数组- 世界坐标导向
    static matrixDivide(m0, m1) {
      // let m = new THREE.Matrix4()
      // let m0_p = new THREE.Vector3()
      // let m0_r = new THREE.Quaternion()
      // let m0_s = new THREE.Vector3()
      // let m1_p = new THREE.Vector3()
      // let m1_r = new THREE.Quaternion()
      // let m1_s = new THREE.Vector3()
      // m0.decompose(m0_p, m0_r, m0_s)
      // m1.decompose(m1_p, m1_r, m1_s)
      // let m_p = m0_p.clone().sub(m1_p).applyQuaternion(m1_r.clone().conjugate()).divide(m1_s)
      // // let m_p = m1_p.clone().sub(m0_p).applyQuaternion(m1_r.clone().conjugate()).divide(m1_s)
    }
  
    // 计算Box3
    static getBoxCenter(box) {
      if (box) {
        let center = box.max
          .clone()
          .add(box.min)
          .multiplyScalar(0.5)
        center = (!center.z ? this.v2IsNaN(center) : this.v3IsNaN(center)) ? new THREE.Vector3() : center
        box.center = center
        return center
      } else {
        return new THREE.Vector3()
      }
    }
  
    static getBoxSize(box) {
      if (box) {
        let size = box.max.clone().sub(box.min)
        size = (!size.z ? this.v2IsNaN(size) : this.v3IsNaN(size)) ? new THREE.Vector3() : size
        box.size = size
        return size
      } else {
        return new THREE.Vector3()
      }
    }
  
    static box3ToM(box3) {
      box3.max.multiplyScalar(0.001)
      box3.min.multiplyScalar(0.001)
      return box3
    }
  
    static box3ToMM(box3) {
      box3.max.multiplyScalar(1000)
      box3.min.multiplyScalar(1000)
      return box3
    }
  
    static vArrayClone(array) {
      let arr = []
      if (!array.length && array.length > 0) return arr
      if (Array.isArray(array[0])) {
        for (let ar of array) {
          let _ar = set(ar)
          if (_ar.length) arr.push(_ar)
        }
      } else arr = set(array)
      function set(a) {
        let _a = []
        for (let v of a) _a.push(v.clone())
        return _a
      }
      return arr
    }
  
    static arrayToV3Array(array) {
      let v3s = []
      let v
      if (array.length && array.length > 0) {
        for (let i = 0; i < array.length / 3; i++) {
          v = new THREE.Vector3(array[i * 3], array[i * 3 + 1], array[i * 3 + 2])
          v3s.push(v)
        }
      } else {
        return []
      }
      return v3s
    }
  
    static arrayToV2Array(array) {
      let v2s = []
      let v
      if (array.length && array.length > 0) {
        for (let i = 0; i < array.length / 2; i++) {
          v = new THREE.Vector2(array[i * 2], array[i * 2 + 1])
          v2s.push(v)
        }
      } else {
        return []
      }
      return v2s
    }
  
    static v3ArrayToArray(array, scale) {
      let a = []
      scale = scale ? Number(scale) : 1
      if (array.length && array.length > 0) {
        for (let v of array) {
          a.push(v.x * scale, v.y * scale, v.z * scale)
        }
      } else {
        return []
      }
      return a
    }
  
    static v2ArrayToArray(array, scale) {
      let a = []
      scale = scale ? Number(scale) : 1
      if (array.length && array.length > 0) {
        for (let v of array) {
          a.push(v.x * scale, v.y * scale)
        }
      } else {
        return []
      }
      return a
    }
  
    static points2array_1(pa, ps = [], scale) {
      let p
      scale = scale ? Number(scale) : 1
      for (let p0 of pa) {
        ps.push(p0.x * 0.001 * scale, p0.y * 0.001 * scale, p0.z * 0.001 * scale)
      }
      if (pa.length > 0) {
        p = pa[0]
        ps.push(p.x * 0.001 * scale, p.y * 0.001 * scale, p.z * 0.001 * scale)
      }
      return ps
    }
  
    /**
     * 三维点一二维数组
     */
    static points2array(pa = [] | [[]], scale) {
      let ps = []
      if (!pa.length && pa.length > 0) return ps
      if (pa[0].length && pa[0].length > 0) {
        for (let p of pa) {
          this.points2array_1(p, ps, scale)
        }
      } else {
        this.points2array_1(pa, ps, scale)
      }
      return ps
    }
  
    /**
     * 三维点一二维数组
     */
    static points2arrays(pa = [] | [[]], scale) {
      let ps = []
      if (!pa.length) return ps
      scale = scale ? Number(scale) : 1
      if (pa[0].length && pa[0].length > 0) {
        for (let p of pa) {
          let _pts = set(p, scale)
          if (_pts.length) ps.push(_pts)
        }
      } else {
        ps = set(pa, scale)
      }
      function set(ps, scale) {
        let pts = []
        for (let p0 of ps) {
          pts.push(p0.x * scale, p0.y * scale, p0.z * scale)
        }
        if (ps.length > 0) {
          let p = ps[0]
          pts.push(p.x * scale, p.y * scale, p.z * scale)
        }
        return pts
      }
      return ps
    }
  
    static get polygonPrecision() {
      return 3
    }
  
    static v2Array2TfPolygon(array, scale) {
      let pa = []
      scale = scale ? Number(scale) : 1
      if (array.length > 0) {
        for (let v of array) {
          let x = v.x * scale
          let y = v.y * scale
          x = x.round(Tz3dMath.polygonPrecision)
          y = y.round(Tz3dMath.polygonPrecision)
          pa.push([x, y])
        }
        pa.push(pa[0])
      }
      return turf.polygon([pa])
    }
  
    static tfPolygon2v2Array(pg, scale) {
      let a = []
      if (pg) {
        scale = scale ? Number(scale) : 1
        let pa = pg.geometry.coordinates[0]
        for (let i = 0, l = pa.length - 1; i < l; i++) {
          let x = pa[i][0].round(Tz3dMath.polygonPrecision)
          let y = pa[i][1].round(Tz3dMath.polygonPrecision)
          a.push(new THREE.Vector2(x, y))
        }
      }
      return a
    }
  
    static tfPolygon2v2Array_pa(pa, scale) {
      let a = []
      if (pa) {
        scale = scale ? Number(scale) : 1
        for (let i = 0, l = pa.length - 1; i < l; i++) {
          let x = pa[i][0].round(Tz3dMath.polygonPrecision)
          let y = pa[i][1].round(Tz3dMath.polygonPrecision)
          a.push(new THREE.Vector2(x, y))
        }
      }
      return a
    }
  
    static pt2line_distance_pt(pt, linePt0, linePt1, tPt) {
      let p = new Tz2dPoint(pt.x, pt.y)
      let p0 = new Tz2dPoint(linePt0.x, linePt0.y)
      let p1 = new Tz2dPoint(linePt1.x, linePt1.y)
      let line = new Tz2dLine(p0, p1)
      let dis = p.distanceTo(line)
      if (!dis) {
        dis = [9999, { x: 0, y: 0 }]
      }
      tPt.set(dis[1].x, dis[1].y)
      return dis[0]
    }
  
    /**
     * 直线与平面的交点
     *  ln 线法线 lv 线经过点 pn 平面法线 pv 平面点
     */
    static linePlaneIntersect(ln, lv, pn, pv) {
      let p = null
      let d = ln.dot(pn)
      // 判断直线不与平面平行
      if (d !== 0) {
        p = new THREE.Vector3()
        let t = pv.clone().sub(lv)
        t.multiply(pn)
        t = (t.x + t.y + t.z) / d // ((pv.x - lv.x) * pn.x + (pv.y - lv.y) * pn.y + (pv.z - lv.z) * pn.z) / d
        p.x = lv.x + ln.x * t
        p.y = lv.y + ln.y * t
        p.z = lv.z + ln.z * t
      }
      return p
    }
  
    /** 两线段交点 null 为不交
     *  ln 线法线 lv 线经过点 pn 平面法线 pv 平面点
     */
    static segmentsIntersect(s0_sp, s0_ep, s1_sp, s1_ep) {
      let p = null
      let v0 = s0_ep.clone().sub(s0_sp)
      let v1 = s1_ep.clone().sub(s1_sp)
      let d = v0.dot(v1)
      // 判断两线段平行否
      if (d !== 0) {
        // 有向面积求法
        let v2 = s0_sp.clone().sub(s1_sp)
        let a0 = v0.clone().cross(v1)
        let a1 = v2.clone().cross(v1)
        let n = v2.dot(a0)
        if (n >= 0.000001 || n <= 0.000001) return p
        let b = a1.dot(a0) * a0.lengthSq()
        if (b > 1 || b < 0) return p
        p = s0_sp.clone().add(v0.clone().multiplyScalar(b))
      }
      return p
    }
  
    static box3ProjectPoint(box3, point, normal, uvSM, texSize, uvMode, uvAngle = 0, offsetUv) {
      let normalAbs = Tz3dMath.v3Abs(normal)
      let maxLenth = Tz3dMath.v3MaxLenth(normalAbs)
      let size = new THREE.Vector3(box3.max.x - box3.min.x, box3.max.y - box3.min.y, box3.max.z - box3.min.z)
      let lf = uvSM === 0 || uvSM === 3 ? 1 : -1
      let tb = uvSM === 2 || uvSM === 3 ? 1 : -1
      let uv = new THREE.Vector2()
      let dv = new THREE.Vector2(1, 1)
      if (uvMode !== 2) {
        if (maxLenth === normalAbs.x) {
          // /size.z  /size.y
          uv.x = point.z - ((normal.x > 0 ? 1 : -1) * lf > 0 ? box3.max.z : box3.min.z)
          uv.y = point.y - (tb > 0 ? box3.min.y : box3.max.y)
          if (uvMode === 2) dv.set(size.z, size.y)
        } else if (maxLenth === normalAbs.y) {
          // /size.x /size.z
          uv.x = point.x - ((normal.y > 0 ? 1 : -1) * lf > 0 ? box3.min.x : box3.max.x)
          uv.y = point.z - (tb > 0 ? box3.min.z : box3.max.z)
          if (uvMode === 2) dv.set(size.x, size.z)
        } else if (maxLenth === normalAbs.z) {
          // /size.x /size.y
          uv.x = point.x - ((normal.z > 0 ? 1 : -1) * lf > 0 ? box3.min.x : box3.max.x)
          uv.y = point.y - (tb > 0 ? box3.max.y : box3.min.y)
          if (uvMode === 2) dv.set(size.x, size.y)
        }
        uv.divide(dv)
        let rad = (uvAngle * Math.PI) / 180
        uv.rotate(-rad)
        if (uvMode !== 2) uv.divide(texSize)
        if (offsetUv) {
          offsetUv = offsetUv.clone().rotate(rad)
          uv.x -= offsetUv.x
          uv.y += offsetUv.y
        }
      } else {
        let _lf, _tb
        if (maxLenth === normalAbs.x) {
          // /size.z  /size.y
          _lf = (normal.x > 0 ? 1 : -1) * lf
          uv.x = _lf > 0 ? box3.max.z - point.z : point.z - box3.min.z
          uv.y = tb > 0 ? box3.max.y - point.y : point.y - box3.min.y
          dv.set(size.z, size.y)
        } else if (maxLenth === normalAbs.y) {
          // /size.x /size.z
          _tb = (normal.y < 0 ? 1 : -1) * tb
          uv.x = lf > 0 ? box3.max.x - point.x : point.x - box3.min.x
          uv.y = _tb > 0 ? box3.max.z - point.z : point.z - box3.min.z
          dv.set(size.x, size.z)
        } else if (maxLenth === normalAbs.z) {
          // /size.x /size.y
          _lf = (normal.z < 0 ? 1 : -1) * lf
          uv.x = _lf > 0 ? box3.max.x - point.x : point.x - box3.min.x
          uv.y = tb > 0 ? box3.max.y - point.y : point.y - box3.min.y
          dv.set(size.x, size.y)
        }
        uv.divide(dv)
        uv.abs()
      }
      return uv
    }
  
    static mappingPoints(points, normals, normal, uvSM, texSize, uvMode, uvAngle = 0, offsetUv) {
      let fn = new THREE.Vector3(0, 0, 1)
      if (!normal) {
        normal = normals[0].clone()
        let nlen = normals.length
        normals.forOf(n => normal.add(n))
        normal.divideScalar(nlen).normalize()
      }
      // let nnoy = normal.clone().setY(0).normalize()
      let qua = new THREE.Quaternion().setFromUnitVectors(normal, fn) // .multiply(new THREE.Quaternion().setFromUnitVectors(normal, nnoy))
      let npq = null
      let sp = points[0].clone().applyQuaternion(qua)
      let sfIndex = 0
      let spIndex = 0
  
      let uvs = []
      let faces = []
      let faceUvs = []
      let nors = []
      for (let m = 0, len = points.length; m < len; m += 3) {
        faces.push([points[m], points[m + 1], points[m + 2]])
        nors.push(normals[m])
      }
  
      for (let i = 0, len = faces.length; i < len; i++) {
        let face = faces[i]
        let faceUv = []
        npq = new THREE.Quaternion().setFromUnitVectors(nors[i], fn)
        for (let j = 0, jlen = face.length; j < jlen; j++) {
          const point = face[j]
          compareSp(point, i, j)
          let v3 = point.clone().applyQuaternion(npq)
          faceUv.push(new THREE.Vector2(v3.x, v3.y))
        }
        faceUvs.push(faceUv)
      }
  
      let spt = faces[sfIndex][spIndex].clone().sub(sp)
      spt.applyQuaternion(
        new THREE.Quaternion().setFromUnitVectors(
          spt.clone().normalize(),
          spt
            .clone()
            .setZ(0)
            .normalize()
        )
      )
  
      computeFace(new THREE.Vector2(spt.x, spt.y), sfIndex, spIndex)
  
      function computeFace(_spt, _sfI, _spI) {
        let _fuv = faceUvs[_sfI]
        let _sp = _fuv[_spI]
        let _nps = []
        let __npIs = []
        for (let k = 0, fulen = _fuv.length; k < fulen; k++) {
          if (k !== _spI) {
            let _fuvp = _fuv[k]
            _fuvp = _fuv[k]
            _fuvp.sub(_sp).add(_spt)
            _nps.push(_fuvp)
            __npIs.push(k)
          }
        }
        _sp.copy(_spt)
        let _f = faces[_sfI]
        _f.push(true)
        for (let _n = 0, _nLen = _nps.length; _n < _nLen; _n++) {
          let _np = _nps[_n]
          let _nfp = _f[__npIs[_n]]
          for (let g = 0, flen = faces.length; g < flen; g++) {
            let __f = faces[g]
            if (__f.length > 3) continue
            let _npI = -1
            for (let l = 0, _flen = __f.length; l < _flen; l++) {
              let __fp = __f[l]
              if (__fp.similar(_nfp, 0.0000001)) {
                _npI = l
                break
              }
            }
            if (_npI < 0) continue
            computeFace(_np, g, _npI)
            return
          }
        }
      }
  
      let box2 = new THREE.Box2(
        new THREE.Vector2(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER),
        new THREE.Vector2(Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER)
      )
      let rad = (uvAngle * Math.PI) / 180
      if (offsetUv) offsetUv = offsetUv.clone().rotate(rad)
      for (const faceUv of faceUvs) {
        for (const fuv of faceUv) {
          box2.min.min(fuv)
          box2.max.max(fuv)
          uvs.push(getUv(fuv))
        }
      }
      return uvs
  
      function getUv(uv) {
        uv = uv.clone()
        uv.rotate(rad)
        if (uvMode !== 2) uv.divide(texSize)
        if (offsetUv) {
          uv.x -= offsetUv.x
          uv.y -= offsetUv.y
        }
        return uv
      }
  
      function compareSp(_point, _i, _j) {
        let _p = _point.clone().applyQuaternion(qua)
        function set() {
          sfIndex = _i
          spIndex = _j
        }
        function setX() {
          sp.x = _point.x
          sp.z = _point.z
        }
        function setY() {
          sp.y = _point.y
          sp.z = _point.z
        }
        if (uvSM === 0) {
          if (_p.x < sp.x) setX()
          if (_p.y < sp.y) setY()
          if (_p.x + _p.y < sp.x + sp.y) set()
        } else if (uvSM === 1) {
          if (_p.x > sp.x) setX()
          if (_p.y < sp.y) setY()
          if (_p.x - _p.y > sp.x - sp.y) set()
        } else if (uvSM === 2) {
          if (_p.x > sp.x) setX()
          if (_p.y > sp.y) setY()
          if (_p.x + _p.y > sp.x + sp.z) set()
        } else {
          if (_p.x < sp.x) setX()
          if (_p.y > sp.y) setY()
          if (_p.x - _p.y < sp.x - sp.y) set()
        }
      }
    }
  
    static getV3AngleY(point0, point1) {
      return point0.clone().cross(point1).y > 0 ? point0.angleTo(point1) : -point0.angleTo(point1)
    }
  
    static get2PointAngle(point0, point1) {
      let p = point1.clone().sub(point0)
      let x = new THREE.Vector3(1, 0, 0)
      let _angle = p.angleTo(x)
      let angle = p.clone().cross(x).y > 0 ? _angle : -_angle
      angle = _angle > Math.PI / 2 ? Math.PI + angle : angle
      return angle
    }
  
    static pushShapeV3ArrayBy2d(points = [], bottom = 0, top = 0) {
      let array = []
      for (let i = 0; i < points.length; i++) {
        array.push(new THREE.Vector3(points[i].x, bottom, points[i].y))
      }
      for (let i = points.length - 1; i >= 0; i--) {
        array.push(new THREE.Vector3(points[i].x, top, points[i].y))
      }
      return array
    }
  
    static getCrossPoints(cross, qua, quaCj, vf, p, p0, p1, n) {
      let crossV3 = []
      let n0, n1, n2, q
      if (p0) {
        n0 = p
          .clone()
          .sub(p0)
          .normalize()
      }
      if (p1) {
        n1 = p1
          .clone()
          .sub(p)
          .normalize()
      }
      n2 = n0 || n1
  
      let n2p = n2
        .clone()
        .applyQuaternion(quaCj)
        .setY(0)
        .round(8)
      q = new THREE.Quaternion().setFromUnitVectors(vf, n2p.normalize()).premultiply(qua)
      n =
        n ||
        (n0 && n1
          ? n0
              .clone()
              .add(n1)
              .normalize()
          : n2)
      for (let c of cross) {
        let pt = c.clone().rotate(-Math.PI / 2)
        let pos = new THREE.Vector3(pt.x, pt.y, 0)
        pos.applyQuaternion(q)
        pos = this.linePlaneIntersect(n2, pos, n, new THREE.Vector3()) || pos
        pos.add(p)
        crossV3.push(pos)
      }
      return crossV3
    }
  
    static computeFaceNormal(points) {
      if (points.length < 3) return new THREE.Vector3(0, 1, 0)
      let ba = new THREE.Vector3()
      let cb = new THREE.Vector3()
      let n
      let ns = []
      for (var i = 0, il = points.length; i < il; i++) {
        let ia = i === 0 ? il - 1 : i - 1
        let ic = i === il - 1 ? 0 : i + 1
        let pa = points[ia]
        let pc = points[ic]
        let pb = points[i]
  
        ba.subVectors(pb, pa)
        cb.subVectors(pc, pb)
        ba.cross(cb)
        n = n ? n.add(ba) : ba.clone()
        ns.push(ba.clone())
      }
      n.normalize()
      return n
    }
  
    static arrayConcat(array, array1) {
      for (let i = 0; i < array1.length; i++) {
        array.push(array1[i])
      }
    }
  
    static Float32BufferAttribute_concat(self, array) {
      let newArray = []
      let likeArray = self.array
      for (let i = 0; i < likeArray.length; i++) {
        newArray.push(likeArray[i])
      }
      for (let i = 0; i < array.length; i++) {
        newArray.push(array[i])
      }
      self.array = new Float32Array(newArray)
    }
  
    static stringCut(str, start, end) {
      if (str && str.length > 0) {
        let startIndex = str.lastIndexOf(start) + 1
        let endIndex = str.lastIndexOf(end)
        return str.slice(startIndex < 0 ? 0 : startIndex, endIndex)
      } else {
        return ''
      }
    }
  
    static toGeometryPointScale(self, f) {
      self.applyMatrix4(new THREE.Matrix4().makeScale(f, f, f))
    }
  
    static v2ProjectPoint(point, p0, p1) {
      let v0 = point.clone().sub(p0)
      let v1 = p1.clone().sub(p0)
      let v1abs = v1.clone().abs()
      let k = v0.dot(v1) / v1abs.dot(v1abs)
      let p = v1.clone().multiplyScalar(k)
      p.add(p0)
      return p
    }
  
    static _rectConvexPoints(points, len) {
      let pss = []
      let has, startV, ps
      let pStart = 0
      for (let i = 0; i < len; i++) {
        let i2 = i < 2 ? (i === 1 ? len - 1 : len - 2) : i - 2
        let i1 = i > 0 ? i - 1 : len - 1
        let p0 = points[i1].clone().sub(points[i2])
        let p1 = points[i].clone().sub(points[i2])
        let p2 = points[i].clone().sub(points[i1])
        let a = p0.clone().cross(p1)
        let aV = startV ? startV.clone().cross(p2) : 1
        has = ps && ps.length > 0
        if (a < 0 || aV < 0) {
          if (has) pss.push(ps)
          else pStart = i
          ps = [points[i1], points[i]]
          startV = p2
        } else if (has) ps.push(points[i])
      }
      if (!has) {
        pStart = len
        ps = []
      }
      for (let i = 0; i < pStart; i++) {
        let i1 = i > 0 ? i - 1 : len - 1
        let p2 = points[i].clone().sub(points[i1])
        let aV = startV ? startV.clone().cross(p2) : 1
        if (aV < 0) {
          if (has) pss.push(ps)
          ps = [points[i1], points[i]]
          startV = p2
        } else ps.push(points[i])
      }
      if (ps.length > 0) pss.push(ps)
      return pss
    }
  
    static getConvexTzBox2(_points, _point, isMore) {
      let points = []
      let point = new THREE.Vector2(_point.x, _point.z)
      let len = _points.length
      for (let i = 0; i < len; i++) {
        points.push(new THREE.Vector2(_points[i].x, _points[i].z))
      }
      let pss = this._rectConvexPoints(points, len)
      let box2 = null
      let minDis = Number.MAX_SAFE_INTEGER
      for (let i = 0, b2len = pss.length; i < b2len; i++) {
        let ps = pss[i]
        let psLen = ps.length
        if (psLen > 2) {
          if (b2len > 1) {
            let p0 = this.v2ProjectPoint(ps[psLen - 1], ps[1], ps[0])
            let p1 = this.v2ProjectPoint(ps[0], ps[psLen - 2], ps[psLen - 1])
            let v0 = p0.clone().sub(ps[1])
            let v1 = p1.clone().sub(ps[psLen - 2])
            let v0More = v0.length() > v1.length()
            if (v0More === !!isMore) ps[0] = p0
            else ps[psLen - 1] = p1
          }
          let _box2 = new TzBox2().makeScatteredPoints2_xz(ps)
          let _point = point.clone()
          let length = _point.sub(_box2.pos).length()
          if (length < minDis) {
            minDis = length
            box2 = _box2
          }
        }
      }
      return box2
    }
  }

  class TzTimer {
    constructor(ableTime = 0) {
      // this.id = window.getNextId()
      this._timeStamp = 0
      this._doing = false
      this._ableTime = ableTime
      this._dest = {}
    }
  
    get isAbleStart() {
      let able = Date.now() - this._timeStamp > this._ableTime
      return able
    }
  
    get doing() {
      return this._doing
    }
  
    set abletime(v) {
      this._ableTime = Number(v)
    }
  
    get dest() {
      return this._dest
    }
  
    set dest(v) {
      this._dest = v
    }
  
    start() {
      this._doing = true
      this._timeStamp = Date.now()
    }
  
    end() {
      this._doing = false
    }
  }

export { Tz3dTool,Tz3dCache ,Tz3dUpdate,Tz3dMath,TzEventListener,TzTimer}