<template>
  <div class="cabinet">
    <div class="cabinet-title">
      <div>柜体库</div>
      <div>
        <el-icon  @click="closeWin"><Close /></el-icon>
      </div>
    </div>

      <div class="cabinet-main">
        <ul>
          <li 
          v-for="(item,k) in boardArr" 
          :key="k" 
          :class="active===item.value?'liactive':''"
          @click="getActive(item)">
          <div class="cabinetli">
            <div class="cabinetli-img">
               <img src="@/assets/tools/cabinet/cab1.png" alt="">
            </div>
            <div class="cabinetli-tit">{{ item.title }}</div>
          </div>
        </li>
        </ul>
        <div class="cabinet-main-tab">
          <el-scrollbar style="height: calc(90vh - 30px);overflow-y: auto;">
            <!-- 侧包顶底 -->
            <sidePackage v-if="active===1"/>
            <!-- 顶底盖侧 -->
            <sideCover v-if="active===2"/>
            <!-- 顶盖侧 -->
            <sideCoverTop v-if="active===3"/>
            <!-- 底盖侧 -->
            <sideCoverBot v-if="active===4"/>
            <!-- 自定义 -->
            <defineCabinet v-if="active===5"/>
          </el-scrollbar>
        </div>
      </div>

  </div>
</template>

<script setup lang="ts">
import {ref} from 'vue';
import sidePackage from './side-package.vue';
import sideCover from './side-cover.vue';
import sideCoverTop from './side-cover-top.vue';
import sideCoverBot from './side-cover-bot.vue';
import defineCabinet from './define-cabinet.vue';

const modelgoods = defineModel();
const boardArr = [
  {title: '侧包顶底',value:1},
  {title: '顶底盖侧',value:2},
  {title: '顶盖侧',value:3},
  {title: '底盖侧',value:4},
  {title: '自定义',value:5},
]
const active = ref(1);
const getActive = (val:any) => {
  active.value = val.value;
}
const closeWin = () => {
  modelgoods.value = -1;
}
</script>

<style scoped lang="less">
.cabinet {
  width: 100%;
  // height: 30px;
  // background: pink;
  &-title {
    width: 100%;
    // height: 50px;
    // background: pink;
    display: flex;
    padding: 6px 10px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F1F1F3;
  }
  &-main {
    width: 100%;
    // padding: 0 10px;
    height: calc(90vh - 30px);
    display: flex;
    ul {
      list-style: none;
      width: 90px;
      height: 100%;
      padding: 5px;
      // background: pink;
      border-right: 1px solid #F1F1F3;
      li {
        width: 100%;
        // height: 25px;
        // line-height: 25px;
        .cabinetli {
         width: 80px;
         margin: 0 auto 10px;
          &-img {
            width: 80px;
            height: 80px;
            line-height: 75px;
            border: 1px solid #F1F1F3;
            border-radius: 5px;
            text-align: center;
            margin-bottom: 5px;
            img {
              vertical-align: middle;
              width: 54px;
              height: 70px;
            }
          }
          
          &-tit {
            width: 100%;
            text-align: center;
          }
        }
      }
      .liactive {
        color: #409EFF;
        .cabinetli-img {
          border: 1px solid #409EFF;
        }
      }
    }
    &-tab {
      flex: 1;
    }
  }
}

</style>